<template>
  <v-container>
    <h4> Error </h4>
    <h1> 403 </h1>
    <a
      style="text-decoration: none;"
      @click="routeToHome()"
    > Back to the website </a>
  </v-container>
</template>

<script>
export default {
  methods: {
    routeToHome () {
      if (this.$router.name != '/') {
        this.$router.push('/')
      }
    }
  },
}
</script>

<style>
</style>