import Dexie from 'dexie';
export const db = new Dexie('customerTrolley');
db.version(1).stores({
	trolleyItems: 'sk, productName, quantity, productUnit, imageUrl, productCode',
});

export default {
	namespaced: true,

	state: {
		cart: [],
		cartItemsCount: 0,
		isLoading: false,
		isCartLoaded: false,
		purchaseOrder: '',
	},

	mutations: {
		setPurchaseOrder(state, payload) {
			state.purchaseOrder = payload;
		},
		addItemCount(state, payload) {
			state.cartItemsCount++;
			return payload;
		},
		resetItemCount(state) {
			state.cartItemsCount = 0;
		},
		addToCart(state, payload) {
			state.cart.push(payload);
		},
		setCart(state, payload) {
			state.cart = payload;
		},
		setCartCount(state, payload) {
			state.cartItemsCount = payload;
		},
		updateItemInCart(state, payload) {
			for (var i = 0; i < state.cart.length; i++) {
				if (state.cart[i].sk == payload.sk) {
					state.cart[i] = payload;
				}
			}
		},
		removeItemInCart(state, payload) {
			for (var i = 0; i < state.cart.length; i++) {
				if (state.cart[i].sk == payload.sk) {
					state.cart.splice(i, 1);
				}
			}
		},
	},

	actions: {
		async quantityDecimalCheck(context, payload) {
			let unit = payload.product.productUnit;

			if (unit == 'KG') {
				payload.product.quantity = parseFloat(payload.product.quantity).toFixed(2);
			} else {
				payload.product.quantity = parseFloat(payload.product.quantity).toFixed(0);
			}

			return payload;
		},
		async clearCart({ commit }) {
			await db.trolleyItems.clear();
			commit('setPurchaseOrder', '');
			commit('setCart', []);
			commit('resetItemCount');
		},
		async initCartCount({ state, commit }) {
			let cartListFromDB = await db.table('trolleyItems').toArray();
			await commit('setCart', cartListFromDB);

			state.isCartLoaded = true;
		},
		async updateQuantity({ rootState, state, commit, dispatch }, payload) {
			payload = await dispatch('quantityDecimalCheck', payload);

			if (!payload.product.inCart) return;

			if (payload.product.quantity <= 0) {
				dispatch('removeFromCart', payload);
				return;
			}
			state.isLoading = true;

			let checkProductExists = await db.trolleyItems.get({ sk: payload.product.sk });
			if (checkProductExists) {
				//
				let chosenItemKey = await db.trolleyItems.get({ sk: payload.product.sk });
				// try to update chosen item
				db.trolleyItems
					.update(chosenItemKey, {
						quantity: payload.product.quantity,
					})
					.then(update => {
						return update;
					});

				//Update vuex too
				commit('updateItemInCart', payload.product);
				state.isLoading = false;
			}

			let productList = rootState.products.productList;

			for (let i = 0; i < productList.length; i++) {
				const element = productList[i];

				if (element.sk == payload.product.sk) {
					element.inCart = payload.product.inCart;
					element.quantity = payload.product.quantity;
				}
			}

			state.isLoading = false;
		},

		async addToCart({ rootState, state, commit, rootGetters, dispatch }, payload) {
			payload = await dispatch('quantityDecimalCheck', payload);
			let count = 0;
			state.isLoading = true;
			let checkProductExists = await db.trolleyItems.get({ sk: payload.product.sk });
			if (checkProductExists == null || checkProductExists == undefined) {
				//if sk doesn exist then add item to cart
				//  adds item to indexedDB storage
				let customerId = rootGetters['customers/authenticatedUser']
					? rootGetters['customers/authenticatedUser'].sk
					: null;
				payload.product.customerId = customerId;
				let cartObj = {
					...payload.product,
					// sk: payload.product.sk,
					// productName: payload.product.productName,
					// quantity: payload.product.quantity,
					// productUnit: payload.product.productUnit,
					// imageUrl: payload.product.imageUrl,
					// productCode: payload.product.productCode,
				};

				//Add to indexDB
				await db.trolleyItems.add(cartObj);

				//Add to our cart list in vuex
				commit('addToCart', cartObj);

				// this.$store.commit("cartTotal/addItemCount")
			} else {
				//return selected product and increment quantity
				let chosenItemKey = await db.trolleyItems.get({ sk: payload.product.sk });
				if (payload.product.productUnit == 'KG') {
					count = Number.parseFloat(chosenItemKey.quantity);
					count = ++count;
					count = count.toFixed(2);
				} else {
					count = Number.parseFloat(chosenItemKey.quantity);
					count = count += 1;
					count = count.toFixed(0);
				}

				// this.products[payload.index].quantity = count;
				// try to update chosen item
				db.trolleyItems
					.update(chosenItemKey, {
						quantity: count,
					})
					.then(update => {
						// this.itemCount = chosenItemKey.quantity;
						return update;
					});

				//Update vuex too
				payload.product.quantity = count;
				commit('updateItemInCart', payload.product);
			}

			let productList = rootState.products.productList;

			for (let i = 0; i < productList.length; i++) {
				const element = productList[i];

				if (element.sk == payload.product.sk) {
					element.inCart = payload.product.inCart;
					element.quantity = payload.product.quantity;
				}
			}
			state.isLoading = false;

			return count;
		},

		async removeFromCart({ rootState, state, commit }, payload) {
			state.isLoading = true;
			db.trolleyItems.where('sk').equals(payload.product.sk).delete();
			commit('removeItemInCart', payload.product);
			state.isLoading = false;

			let productList = rootState.products.productList;

			for (let i = 0; i < productList.length; i++) {
				const element = productList[i];

				if (element.sk == payload.product.sk) {
					element.inCart = false;
					element.quantity = payload.product.quantity;
				}
			}
		},

		async minusFromCart({ state, commit, dispatch }, payload) {
			payload = await dispatch('quantityDecimalCheck', payload);
			state.isLoading = true;
			let count = 0;
			let chosenItemKey = await db.trolleyItems.get({
				sk: payload.product.sk,
			});

			if (payload.product.productUnit == 'KG') {
				count = Number.parseFloat(chosenItemKey.quantity);
				count = count -= 1;
				count = count.toFixed(2);
			} else {
				count = Number.parseFloat(chosenItemKey.quantity);
				count = count -= 1;
				count = count.toFixed(0);
			}

			// this.products[index].quantity = count;

			if (count > 0) {
				db.trolleyItems
					.update(chosenItemKey, {
						quantity: count,
					})
					.then(update => {
						// this.itemCount = this.chosenItemKey.quantity;

						return update;
					});

				//Update vuex too
				payload.product.quantity = count;
				commit('updateItemInCart', payload.product);
			} else {
				dispatch('removeFromCart', payload);
			}

			state.isLoading = false;

			return count;
		},
	},

	getters: {
		purchaseOrder: state => state.purchaseOrder,
		cartCount: state => {
			// let cart = state.cart;
			// let cartItemsCount = 0;
			// for (var i = 0; i < cart.length; i++) {
			// 	cartItemsCount += cart[i].quantity;
			// }
			// return cartItemsCount;
			return state.cart.length;
		},
		cartItemsCount: state => state.cartItemsCount,
		cart: state => state.cart,
		isCartLoaded: state => state.isCartLoaded,
	},
};
