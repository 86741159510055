export default {
	namespaced: true,
	state: {
		searchString: null,
		customerList: [],
		selectedCustomer: null,
		authenticatedUser: null,
	},
	mutations: {
		// For customer side, settings customer details for
		//user profile
		setAuthenticatedUser (state, payload) {
			state.authenticatedUser = payload;
		},
		setSearchString (state, payload) {
			state.searchString = payload;
		},
		setSelectedCustomer (state, payload) {
			state.selectedCustomer = payload;
		},
		resetSelectedCustomer (state) {
			state.selectedCustomer = {
				companyName: '',
				firstName: '',
				lastName: '',
				email: '',
				mobile: '',
				// priceLevel: '',
				reckonAttributes: {
					editSequence: '',
					reckonId: '',
					isActive: true,
					companyAddress: {
						addr1: '',
						addr2: '',
						addr3: '',
						city: '',
						postalCode: '',
					},
				},
			};
		},
	},
	actions: {
		//
	},
	getters: {
		selectedCustomer: state => state.selectedCustomer,
		searchString: state => state.searchString,
		customerList: state => state.customerList,
		authenticatedUser: state => state.authenticatedUser,
	},
};
