<template>
	<div>
		<v-container :class="!isMobile() ? `px-12 ml-6` : ``" fluid>
			<v-card outlined elevation="4">
				<v-card-title>
					<strong>
						Price Levels
						<span
							:class="priceLevelList.length > 90 ? `red--text` : `green--text`"
							style="font-size: 11px"
							>({{ ' ' + priceLevelList.length }}/100 Levels )</span
						>
					</strong>

					<v-spacer></v-spacer>
					<v-btn @click="initAddLevel()" elevation="4" color="secondary" :loading="isLoading">
						<v-icon small class="mr-2"> mdi-plus </v-icon>
						Add New Price Level
					</v-btn>
				</v-card-title>

				<!-- Add Level Form -->
				<v-dialog
					v-if="selectedPriceLevel"
					v-model="addLevelDialog"
					persistent
					scrollable
					max-width="800px"
					transition="dialog-transition"
				>
					<v-card height="100%" v-if="isAdd" :loading="isLoading">
						<v-form ref="form" onSubmit="return false;">
							<v-card-title>
								<strong> {{ isAdd ? `Add Price Level` : `Edit Price Level` }} </strong>
								<v-spacer></v-spacer>
								<v-icon v-if="!isAdd" @click="initDeletePriceLevel()" color="red" large>
									mdi-delete
								</v-icon>
								<v-icon @click="addLevelDialog = false" large v-if="!confirmationLoading">
									mdi-close
								</v-icon>
							</v-card-title>
							<v-container>
								<v-text-field
									v-model="selectedPriceLevel.name"
									outlined
									label="Price Level"
									:rules="priceLevelRules"
									:disabled="confirmationLoading"
								/>
								<v-card-actions class="pt-6 mr-1">
									<v-row justify="end">
										<v-btn
											class="mr-2 white--text"
											color="red lighten-2"
											@click="cancelAction()"
											:disabled="confirmationLoading"
										>
											Cancel
										</v-btn>
										<v-btn
											@click="isAdd ? createPriceLevel() : updatePriceLevel()"
											:loading="confirmationLoading"
											color="secondary"
										>
											{{ isAdd ? `Add Price Level` : `Update Price Level` }}
										</v-btn>
									</v-row>
								</v-card-actions>
							</v-container>
						</v-form>
					</v-card>
					<v-card v-else height="80vh" :loading="searchLoading">
						<div style="z-index: 99; top: 0; width: 100%">
							<v-card-title>
								<strong> {{ selectedPriceLevel.name }} </strong>
								<v-spacer></v-spacer>
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											v-on="on"
											v-bind="attrs"
											text
											:loading="isLoading"
											@click="initDeletePriceLevel()"
										>
											<v-icon v-if="!isAdd" color="red" large> mdi-delete </v-icon>
										</v-btn>
									</template>
									<span>Delete Price Level</span>
								</v-tooltip>

								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											v-on="on"
											v-bind="attrs"
											:loading="isLoading"
											text
											@click="updateCustomPrices()"
										>
											<v-icon color="primary" class="mr-2" large>mdi-update</v-icon>
										</v-btn>
									</template>
									<span>Update Custom Prices</span>
								</v-tooltip>

								<v-btn text @click="addLevelDialog = false">
									<v-icon color="gray" large> mdi-close </v-icon>
								</v-btn>
							</v-card-title>

							<v-card-actions>
								<v-text-field
									v-model="searchText"
									outlined
									label="Search Product"
									solo
									@keyup="initSearch()"
								></v-text-field>
							</v-card-actions>
						</div>

						<div v-if="!isMobile()" style="overflow-y: scroll; max-height: 85%">
							<v-card
								v-for="(item, i) in productList"
								v-bind:key="i"
								elevation="0"
								:style="i <= 30 ? `border: 1px solid green; overflow: hidden` : ``"
								class="ma-4"
							>
								<v-row style="width: 100%" v-if="i <= 30">
									<v-col cols="4">
										<v-card-text class="text-left"
											>[<b style="color: #1b8600">{{ item.productCode }}</b
											>]{{ ' ' + item.productName }}</v-card-text
										>
									</v-col>
									<v-col cols="8">
										<div class="mt-4 d-flex">
											<div style="width: 160px" class="mr-1">
												<v-text-field
													color="primary"
													prepend-inner-icon="mdi-currency-usd"
													label="Default Price"
													outlined
													:value="item.defaultPrice.toFixed(2)"
													readonly
													dense
													background-color="primary"
												></v-text-field>
											</div>

											<v-text-field
												prepend-inner-icon="mdi-currency-usd"
												v-model.number="item.newPrice"
												outlined
												label="Custom Price"
												dense
												class="mr-4"
												v-on:keypress="numbersOnly"
												@change="customPriceChange(item)"
											>
												<template v-slot:append> </template>
											</v-text-field>
										</div>
									</v-col>
								</v-row>
							</v-card>
						</div>

						<!-- --------------------------- MOBILE VIEW --------------------------- -->
						<div v-if="isMobile()" style="overflow-y: scroll; max-height: 85%">
							<v-card
								v-for="(item, i) in productList"
								v-bind:key="i"
								elevation="0"
								:style="i <= 30 ? `border: 1px solid green; overflow: hidden` : ``"
								class="ma-4"
							>
								<v-row style="width: 100%" v-if="i <= 30">
									<v-col cols="12">
										<v-card-title class="justify-center"
											><b>{{ item.productCode }}</b
											>{{ ' ' + item.productName }}</v-card-title
										>
									</v-col>

									<v-container class="ml-6">
										<v-col cols="12">
											<v-text-field
												color="primary"
												prepend-inner-icon="mdi-currency-usd"
												label="Default Price"
												outlined
												:value="item.defaultPrice.toFixed(2)"
												readonly
												dense
												background-color="primary"
												hide-details
											></v-text-field>
										</v-col>

										<v-col cols="12">
											<v-text-field
												prepend-inner-icon="mdi-currency-usd"
												v-model.number="item.newPrice"
												outlined
												label="Custom Price"
												dense
												v-on:keypress="numbersOnly"
												@change="customPriceChange(item)"
											/>
										</v-col>
									</v-container>
								</v-row>
							</v-card>
						</div>
					</v-card>
				</v-dialog>

				<!-- Confirm delete dialog -->
				<v-dialog
					v-model="confirmDeleteDialog"
					persistent
					style="position: aboslut; z-index: 999999"
					max-width="360"
				>
					<v-card>
						<v-card-title>
							Confirm Delete
							<v-spacer></v-spacer>
							<v-icon @click="confirmDeleteDialog = false" v-if="!confirmationLoading">
								mdi-close
							</v-icon>
						</v-card-title>
						<v-card-text style="font-weight: 900">
							Are you sure you want to delete this price Level?
						</v-card-text>
						<v-spacer></v-spacer>
						<v-card-actions>
							<v-row justify="end" class="ma-0">
								<v-btn
									@click="confirmDeleteDialog = false"
									class="mr-2"
									color="red"
									outlined
									:disabled="confirmationLoading"
								>
									Cancel
								</v-btn>
								<v-btn @click="deletePriceLevel()" :loading="confirmationLoading" color="primary">
									Confirm
								</v-btn>
							</v-row>
						</v-card-actions>
					</v-card>
				</v-dialog>

				<!-- Price Level Table -->
				<v-data-table
					:loading="isLoading"
					:headers="headers"
					:items="priceLevelList"
					:items-per-page="50"
					:footer-props="{ 'items-per-page-options': [50, 100, 150, -1] }"
					class="elevation-1"
					mobile-breakpoint="0"
					:search="search"
				>
					<template v-slot:top>
						<v-text-field
							outlined
							v-model="search"
							label="Search Price Level"
							class="mx-4 pt-4"
						></v-text-field>
					</template>

					<template v-slot:[`item.name`]="{ item }">
						<tr>
							<td>
								{{ item.name }}
							</td>
						</tr>
					</template>

					<template v-slot:[`item.actions`]="{ item, index }">
						<tr class="d-flex justify-end">
							<td class="pr-2">
								<v-icon
									v-if="!isLoading"
									class="d-flex justify-end"
									color="primary"
									size="20"
									@click="initUpdatePriceLevel(item, index)"
									>mdi-clipboard-edit-outline</v-icon
								>

								<v-progress-circular
									v-else
									indeterminate
									size="20"
									color="primary"
									width="3"
								></v-progress-circular>
							</td>
						</tr>
					</template>
				</v-data-table>
			</v-card>

			<!-- notification  -->
			<v-snackbar v-model="notify" class="pt-16" top right :timeout="2500" :color="color">
				{{ notificationText }}
			</v-snackbar>
		</v-container>
	</div>
</template>

<script>
	// import AdminNavigation from "../../components/AdminViewComponents/AdminNavigation.vue";
	import { API, graphqlOperation, Auth } from 'aws-amplify';
	import {
		createPriceLevel,
		deletePriceLevel,
		addCustomPrice,
		deleteCustomPrice,
	} from '@/graphql/mutations';
	import {
		getPriceLevel,
		listPriceLevels,
		filterProducts,
		listCustomPricesByPriceLevel,
	} from '@/graphql/queries';
	// import * as subscriptions from "@/graphql/subscriptions";
	import axios from 'axios';

	export default {
		methods: {
			async updateCustomPrices() {
				try {
					this.isLoading = true;
					let latestPriceLevel = await this.getPriceLevel(this.selectedPriceLevel.sk);

					/*********UPDATE RECKON QB BATCH**********/
					//Axios Post request
					let mergedList = this.deletedCustomPriceList.concat(this.newAddedCustomPriceList);

					if (mergedList.length == 0) {
						this.color = 'orange';
						this.notificationText = 'Theres nothing to update.';
						this.notify = true;
						this.isLoading = false;

						return;
					}

					let auth = await Auth.currentAuthenticatedUser();
					let headers = {
						headers: {
							authorization: 'Bearer ' + auth.signInUserSession.accessToken.jwtToken,
						},
					};

					let reckonRes = await axios.post(
						this.$store.getters['global/endpoint'] + '/api/reckon/customprice/update',
						{
							priceLevel: latestPriceLevel,
							items: mergedList,
						},
						headers
					);

					if (reckonRes.data.statusSeverity == 'Error' && reckonRes.data.statusCode == '3140') {
						throw ('ERROR: ', reckonRes.data.statusMessage);
					} else if (reckonRes.data.statusSeverity == 'Error') {
						throw 'ERROR UPDATING CUSTOM PRICE. TRY AGAIN.';
					}

					await API.graphql({
						query: createPriceLevel,
						variables: {
							input: reckonRes.data,
						},
					});

					/***********Loop Remove Here***************/
					for (var i = 0; i < this.deletedCustomPriceList.length; i++) {
						let deleteData = {
							productId: this.deletedCustomPriceList[i].sk,
							priceLevel: this.selectedPriceLevel.sk,
						};
						await API.graphql({
							query: deleteCustomPrice,
							variables: {
								input: deleteData,
							},
						});
					}

					/************Loop Add here AWS *************/
					for (var j = 0; j < this.newAddedCustomPriceList.length; j++) {
						let createData = {
							priceLevel: this.selectedPriceLevel.sk,
							productId: this.newAddedCustomPriceList[j].sk,
							customPrice: parseFloat(this.newAddedCustomPriceList[j].newPrice).toFixed(2),
						};
						await API.graphql({
							query: addCustomPrice,
							variables: {
								input: createData,
							},
						});
					}

					mergedList = [];
					this.deletedCustomPriceList = [];
					this.newAddedCustomPriceList = [];
					this.addLevelDialog = false;
					this.isLoading = false;
					this.color = 'primary';
					this.notificationText = 'Updated Custom Prices!';
					this.notify = true;
				} catch (error) {
					// console.log(error);
					console.log(error);
					this.color = 'red';
					this.notificationText = 'There was an error updating price level';
					this.notify = true;
					this.isLoading = false;
				}
			},
			checkCustomPriceInLists(item) {
				this.deletedCustomPriceList = this.deletedCustomPriceList.filter(
					customPrice => customPrice.sk != item.sk
				);
				this.newAddedCustomPriceList = this.newAddedCustomPriceList.filter(
					customPrice => customPrice.sk != item.sk
				);
				return;
			},
			customPriceChange(item) {
				let data = null;
				data = {
					...item,
					priceLevel: { ...this.selectedPriceLevel },
				};

				if (item.newPrice && item.newPrice > 0) {
					this.checkCustomPriceInLists(data);
					this.newAddedCustomPriceList.push(data);
				} else {
					this.checkCustomPriceInLists(data);
					this.deletedCustomPriceList.push(data);
				}
			},

			initDeletePriceLevel() {
				this.confirmDeleteDialog = true;
			},
			initSearch() {
				this.searchLoading = true;
				if (this.timer) {
					clearTimeout(this.timer);
					this.timer = null;
				}
				this.timer = setTimeout(() => {
					if (this.searchText.length > 3) {
						this.searchProducts();
					} else if (this.searchText == '') {
						this.loadProducts();
					}
				}, 300);
			},

			isMobile() {
				if (this.$vuetify.breakpoint.width < 800) {
					return true;
				}
			},
			cancelAction() {
				this.listPriceLevels();
				this.addLevelDialog = false;
			},
			async deletePriceLevel() {
				try {
					this.confirmationLoading = true;
					if (this.customPriceList.length > 0) {
						this.color = 'error';
						this.notificationText =
							'Unable To Delete Price Level. Please Delete Custom Prices inside first!';
						this.notify = true;
						this.confirmationLoading = false;
						this.confirmDeleteDialog = false;
						return;
					}

					//Remove from reckon
					let auth = await Auth.currentAuthenticatedUser();
					let headers = {
						headers: {
							authorization: 'Bearer ' + auth.signInUserSession.accessToken.jwtToken,
						},
					};

					await axios.post(
						this.$store.getters['global/endpoint'] + '/api/reckon/pricelevel/delete',
						this.selectedPriceLevel,
						headers
					);

					//Check if custom price exist first
					let cat = this.selectedPriceLevel;
					let res = await API.graphql(
						graphqlOperation(deletePriceLevel, { priceLevel: cat.sk })
					).then(res => {
						return res;
					});

					this.priceLevelList.forEach((pl, i) => {
						if (pl.sk == res.data.deletePriceLevel.sk) {
							this.priceLevelList.splice(i, 1);
						}
					});

					this.confirmDeleteDialog = false;
					this.addLevelDialog = false;
					this.confirmationLoading = false;
				} catch (error) {
					// console.log(error);
					this.color = 'red';
					this.notificationText = 'Error Deleting Price Level. Please try again.';
					this.notify = true;
					this.isLoading = false;
				}
			},

			async createPriceLevel() {
				let isValid = this.$refs.form.validate();

				if (isValid) {
					this.confirmationLoading = true;
					try {
						let auth = await Auth.currentAuthenticatedUser();
						let headers = {
							headers: {
								authorization: 'Bearer ' + auth.signInUserSession.accessToken.jwtToken,
							},
						};
						let res = await axios.post(
							this.$store.getters['global/endpoint'] + '/api/reckon/pricelevel/create',
							this.selectedPriceLevel,
							headers
						);

						let result = await API.graphql({
							query: createPriceLevel,
							variables: {
								input: res.data,
							},
						});

						this.priceLevelList.unshift(result.data.createPriceLevel);
						this.confirmationLoading = false;
						this.addLevelDialog = false;
						this.color = 'success';
						this.notificationText = 'Price Level Created!';
						this.notify = true;
						this.$refs.form.reset();
					} catch (error) {
						this.confirmationLoading = false;
						// console.log("Error creating priceLevel: ", error);
						this.color = 'red';
						this.notificationText = 'Error Creating Price Level. Please try again.';
						this.notify = true;
					}
				} else {
					this.confirmationLoading = false;
					console.log('Error creating priceLevel');
				}
			},

			async listPriceLevels() {
				this.priceLevelList = [];
				let res = await API.graphql({
					query: listPriceLevels,
					authMode: 'AMAZON_COGNITO_USER_POOLS',
				});
				let priceLevelList = res.data.listPriceLevels.items.sort((a, b) =>
					a.productName > b.productName ? 1 : b.productName > a.productName ? -1 : 0
				);

				this.priceLevelList = priceLevelList;
			},

			initAddLevel() {
				if (this.priceLevelList.length == 100) {
					this.color = 'error';
					this.notificationText =
						'Price Level has a limit of 100.\nPlease delete some first before creating new levels.';
					this.notify = true;
					return;
				}
				this.isLoading = true;
				this.isAdd = true;
				this.selectedPriceLevel = { sk: '', pk: '' };
				this.addLevelDialog = true;
				this.isLoading = false;
			},

			async initUpdatePriceLevel(priceLevel) {
				this.selectedPriceLevel = await priceLevel;
				this.isLoading = true;
				this.customPriceList = [];
				await this.loadProducts();
				this.isAdd = false;
				this.addLevelDialog = true;
				this.isLoading = false;
			},

			async searchProducts() {
				this.searchLoading = true;
				this.productList = [];
				let nextToken = null;
				let res = await API.graphql({
					query: filterProducts,
					variables: {
						searchString: this.searchText.toLowerCase(),
						nextToken: nextToken,
					},
					authMode: 'AMAZON_COGNITO_USER_POOLS',
				});

				nextToken = res.data.filterProducts.nextToken;
				this.productList = res.data.filterProducts.items;

				while (nextToken) {
					let res = await API.graphql({
						query: filterProducts,
						variables: {
							searchString: this.searchText.toLowerCase(),
							nextToken: nextToken,
						},
						authMode: 'AMAZON_COGNITO_USER_POOLS',
					});

					nextToken = res.data.filterProducts.nextToken;
					this.productList = this.productList.concat(res.data.filterProducts.items);
				}

				await this.loadCustomPrices();
				this.searchLoading = false;
			},

			async loadProducts() {
				this.searchLoading = true;
				let nextToken = null;
				let res = await API.graphql({
					query: filterProducts,
					authMode: 'AMAZON_COGNITO_USER_POOLS',
					variables: {
						// searchText: this.searchText.toLowerCase(),
						// category: catFilter,
						nextToken: nextToken,
					},
				});

				nextToken = res.data.filterProducts.nextToken;
				this.productList = res.data.filterProducts.items;

				this.priceLevelDialog = true;
				await this.loadCustomPrices(this.selectedPriceLevel);
				this.searchLoading = false;
			},

			numbersOnly(evt) {
				evt = evt ? evt : window.event;
				var charCode = evt.which ? evt.which : evt.keyCode;
				if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
					evt.preventDefault();
				} else {
					return true;
				}
			},

			async getPriceLevel(priceLevel) {
				let res = await API.graphql({
					query: getPriceLevel,
					variables: {
						priceLevel: priceLevel,
					},
				});

				return res.data.getPriceLevel;
			},

			async loadCustomPrices(priceLevel) {
				let res = null;

				if (priceLevel && this.customPriceList.length == 0) {
					res = await API.graphql({
						query: listCustomPricesByPriceLevel,
						variables: {
							priceLevel: priceLevel.sk,
						},
						authMode: 'AMAZON_COGNITO_USER_POOLS',
					});
					this.customPriceList = res.data.listCustomPricesByPriceLevel.items;
				}

				this.productList.forEach(product => {
					this.customPriceList.forEach(customPrice => {
						if (product.sk == customPrice.productId) {
							product.newPrice = customPrice.customPrice.toFixed(2);
							product.customPrice = customPrice;
						}
					});
				});

				this.productList.forEach(product => {
					this.newAddedCustomPriceList.forEach(customPrice => {
						if (product.sk == customPrice.sk) {
							product.newPrice = parseFloat(customPrice.newPrice).toFixed(2);
							product.customPrice = customPrice.customPrice;
						}
					});
				});

				this.productList.forEach(product => {
					this.deletedCustomPriceList.forEach(customPrice => {
						if (product.sk == customPrice.sk) {
							product.newPrice = customPrice.newPrice;
							product.customPrice = null;
						}
					});
				});
			},

			resetCustomPriceLists() {
				this.deletedCustomPriceList = [];
				this.newAddedCustomPriceList = [];
			},

			async checkUser() {
				await Auth.currentAuthenticatedUser({
					bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
				})
					.then(user => {
						if (user.signInUserSession.accessToken.payload['cognito:groups'] == 'customers') {
							this.$router.push('/');
						}
					})
					.catch(err => {
						if (err == 'The user is not authenticated') {
							this.$router.push('/');
						}
					});
			},
		},

		async mounted() {
			await this.checkUser();
			this.listPriceLevels();
		},

		watch: {
			addLevelDialog: function () {
				this.searchText = '';
				this.deletedCustomPriceList = [];
				this.newAddedCustomPriceList = [];
			},
		},

		computed: {
			priceRule: {
				get() {
					return this.$store.getters['products/priceRule'];
				},
			},
		},

		components: {
			// AdminNavigation,
		},

		data: () => ({
			notify: false,
			notificationText: '',
			color: null,
			deletedCustomPriceList: [],
			newAddedCustomPriceList: [],
			confirmationLoading: false,
			search: '',
			searchLoading: false,
			searchText: '',
			timer: null,
			customPriceList: [],
			productList: [],
			selectedPriceLevel: null,
			priceLevelRules: [v => !!v || 'Price Level required'],
			priceLevelList: [],
			isLoading: false,
			confirmDeleteDialog: false,
			isAdd: null,
			addLevelDialog: false,
			headers: [
				{
					text: 'Price Level',
					align: 'start',
					sortable: true,
					value: 'name',
				},
				{
					text: 'Actions',
					align: 'end',
					value: 'actions',
					sortable: false,
				},
			],
		}),
	};
</script>

<style>
	/*  */
</style>
