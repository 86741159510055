/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSettingsVariables = /* GraphQL */ `
  query GetSettingsVariables {
    getSettingsVariables {
      pk
      sk
      cutoffTime
      freightCharge
      freightFreeOver
    }
  }
`;
export const listAdminPanelOrders = /* GraphQL */ `
  query ListAdminPanelOrders($orderStatus: String) {
    listAdminPanelOrders(orderStatus: $orderStatus) {
      items {
        pk
        sk
        customer {
          pk
          sk
          firstName
          lastName
          email
          mobile
          priceLevel
          companyName
          customerLevel
          reckonAttributes {
            reckonId
            editSequence
            isActive
            companyAddress {
              addr1
              addr2
              addr3
              city
              postalCode
            }
          }
          customerCode
        }
        customerId
        orderStatus
        orderDate
        orderId
        products {
          pk
          sk
          productId
          productName
          categoryName
          description
          quantity
          packedWeight
          imageUrl
          productUnit
          productCode
          defaultPrice
          newPrice
          isEnabled
          reckonTxnId
          notes
          customerId
        }
        invoiceRef {
          reckonRefNumber
          reckonTxnId
          reckonEditSequence
        }
        productionRef
        notes
        purchaseOrder
        line
        isDelivery
        freight
        cutting
      }
      nextToken
    }
  }
`;
export const listEnabledRecurringOrders = /* GraphQL */ `
  query ListEnabledRecurringOrders($nextToken: String) {
    listEnabledRecurringOrders(nextToken: $nextToken) {
      items {
        pk
        sk
        customer {
          pk
          sk
          firstName
          lastName
          email
          mobile
          priceLevel
          companyName
          customerLevel
          reckonAttributes {
            reckonId
            editSequence
            isActive
            companyAddress {
              addr1
              addr2
              addr3
              city
              postalCode
            }
          }
          customerCode
        }
        products {
          pk
          sk
          productId
          productName
          categoryName
          description
          quantity
          packedWeight
          imageUrl
          productUnit
          productCode
          defaultPrice
          newPrice
          isEnabled
          reckonTxnId
          notes
          customerId
        }
        notes
        isActive
        frequency
        startingDate
        selectedDays
        freight
        cutting
        isDelivery
      }
      nextToken
    }
  }
`;
export const getProductNotes = /* GraphQL */ `
  query GetProductNotes($customerId: String, $productId: String) {
    getProductNotes(customerId: $customerId, productId: $productId) {
      pk
      sk
      notes
      customerId
      productId
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications($sk: String) {
    listNotifications(sk: $sk) {
      items {
        pk
        sk
        user
        message
        notificationTime
        notificationValueSk
      }
    }
  }
`;
export const getRecurringOrder = /* GraphQL */ `
  query GetRecurringOrder($sk: String) {
    getRecurringOrder(sk: $sk) {
      pk
      sk
      customer {
        pk
        sk
        firstName
        lastName
        email
        mobile
        priceLevel
        companyName
        customerLevel
        reckonAttributes {
          reckonId
          editSequence
          isActive
          companyAddress {
            addr1
            addr2
            addr3
            city
            postalCode
          }
        }
        customerCode
      }
      products {
        pk
        sk
        productId
        productName
        categoryName
        description
        quantity
        packedWeight
        imageUrl
        productUnit
        productCode
        defaultPrice
        newPrice
        isEnabled
        reckonTxnId
        notes
        customerId
      }
      notes
      isActive
      frequency
      startingDate
      selectedDays
      freight
      cutting
      isDelivery
    }
  }
`;
export const filterRecurringOrders = /* GraphQL */ `
  query FilterRecurringOrders($frequency: String, $nextToken: String) {
    filterRecurringOrders(frequency: $frequency, nextToken: $nextToken) {
      items {
        pk
        sk
        customer {
          pk
          sk
          firstName
          lastName
          email
          mobile
          priceLevel
          companyName
          customerLevel
          reckonAttributes {
            reckonId
            editSequence
            isActive
            companyAddress {
              addr1
              addr2
              addr3
              city
              postalCode
            }
          }
          customerCode
        }
        products {
          pk
          sk
          productId
          productName
          categoryName
          description
          quantity
          packedWeight
          imageUrl
          productUnit
          productCode
          defaultPrice
          newPrice
          isEnabled
          reckonTxnId
          notes
          customerId
        }
        notes
        isActive
        frequency
        startingDate
        selectedDays
        freight
        cutting
        isDelivery
      }
      nextToken
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($orderId: String) {
    getOrder(orderId: $orderId) {
      pk
      sk
      customer {
        pk
        sk
        firstName
        lastName
        email
        mobile
        priceLevel
        companyName
        customerLevel
        reckonAttributes {
          reckonId
          editSequence
          isActive
          companyAddress {
            addr1
            addr2
            addr3
            city
            postalCode
          }
        }
        customerCode
      }
      customerId
      orderStatus
      orderDate
      orderId
      products {
        pk
        sk
        productId
        productName
        categoryName
        description
        quantity
        packedWeight
        imageUrl
        productUnit
        productCode
        defaultPrice
        newPrice
        isEnabled
        reckonTxnId
        notes
        customerId
      }
      invoiceRef {
        reckonRefNumber
        reckonTxnId
        reckonEditSequence
      }
      productionRef
      notes
      purchaseOrder
      line
      isDelivery
      freight
      cutting
    }
  }
`;
export const filterCustomers = /* GraphQL */ `
  query FilterCustomers($searchString: String, $nextToken: String) {
    filterCustomers(searchString: $searchString, nextToken: $nextToken) {
      items {
        pk
        sk
        firstName
        lastName
        email
        mobile
        priceLevel
        companyName
        customerLevel
        reckonAttributes {
          reckonId
          editSequence
          isActive
          companyAddress {
            addr1
            addr2
            addr3
            city
            postalCode
          }
        }
        customerCode
      }
      nextToken
    }
  }
`;
export const listOrdersByCustomer = /* GraphQL */ `
  query ListOrdersByCustomer(
    $customerId: String
    $orderStatus: String
    $nextToken: String
  ) {
    listOrdersByCustomer(
      customerId: $customerId
      orderStatus: $orderStatus
      nextToken: $nextToken
    ) {
      items {
        pk
        sk
        customer {
          pk
          sk
          firstName
          lastName
          email
          mobile
          priceLevel
          companyName
          customerLevel
          reckonAttributes {
            reckonId
            editSequence
            isActive
            companyAddress {
              addr1
              addr2
              addr3
              city
              postalCode
            }
          }
          customerCode
        }
        customerId
        orderStatus
        orderDate
        orderId
        products {
          pk
          sk
          productId
          productName
          categoryName
          description
          quantity
          packedWeight
          imageUrl
          productUnit
          productCode
          defaultPrice
          newPrice
          isEnabled
          reckonTxnId
          notes
          customerId
        }
        invoiceRef {
          reckonRefNumber
          reckonTxnId
          reckonEditSequence
        }
        productionRef
        notes
        purchaseOrder
        line
        isDelivery
        freight
        cutting
      }
      nextToken
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($customerId: String!) {
    getCustomer(customerId: $customerId) {
      pk
      sk
      firstName
      lastName
      email
      mobile
      priceLevel
      companyName
      customerLevel
      reckonAttributes {
        reckonId
        editSequence
        isActive
        companyAddress {
          addr1
          addr2
          addr3
          city
          postalCode
        }
      }
      customerCode
    }
  }
`;
export const searchCustomers = /* GraphQL */ `
  query SearchCustomers($searchString: String, $nextToken: String) {
    searchCustomers(searchString: $searchString, nextToken: $nextToken) {
      items {
        pk
        sk
        firstName
        lastName
        email
        mobile
        priceLevel
        companyName
        customerLevel
        reckonAttributes {
          reckonId
          editSequence
          isActive
          companyAddress {
            addr1
            addr2
            addr3
            city
            postalCode
          }
        }
        customerCode
      }
      nextToken
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers {
    listCustomers {
      items {
        pk
        sk
        firstName
        lastName
        email
        mobile
        priceLevel
        companyName
        customerLevel
        reckonAttributes {
          reckonId
          editSequence
          isActive
          companyAddress {
            addr1
            addr2
            addr3
            city
            postalCode
          }
        }
        customerCode
      }
      nextToken
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts($searchString: String, $nextToken: String) {
    listProducts(searchString: $searchString, nextToken: $nextToken) {
      items {
        pk
        sk
        productName
        categoryName
        description
        quantity
        imageUrl
        productUnit
        productCode
        defaultPrice
        isEnabled
        reckonProductAttributes {
          listID
          editSequence
          isActive
          productType
        }
        productGroup
        isInStock
      }
      nextToken
    }
  }
`;
export const filterProductsForCustomers = /* GraphQL */ `
  query FilterProductsForCustomers(
    $searchString: String
    $category: String
    $nextToken: String
  ) {
    filterProductsForCustomers(
      searchString: $searchString
      category: $category
      nextToken: $nextToken
    ) {
      items {
        pk
        sk
        productName
        categoryName
        description
        quantity
        imageUrl
        productUnit
        productCode
        defaultPrice
        isEnabled
        reckonProductAttributes {
          listID
          editSequence
          isActive
          productType
        }
        productGroup
        isInStock
      }
      nextToken
    }
  }
`;
export const searchProductsForCustomers = /* GraphQL */ `
  query SearchProductsForCustomers($searchString: String, $nextToken: String) {
    searchProductsForCustomers(
      searchString: $searchString
      nextToken: $nextToken
    ) {
      items {
        pk
        sk
        productName
        categoryName
        description
        quantity
        imageUrl
        productUnit
        productCode
        defaultPrice
        isEnabled
        reckonProductAttributes {
          listID
          editSequence
          isActive
          productType
        }
        productGroup
        isInStock
      }
      nextToken
    }
  }
`;
export const filterProducts = /* GraphQL */ `
  query FilterProducts(
    $searchString: String
    $category: String
    $nextToken: String
  ) {
    filterProducts(
      searchString: $searchString
      category: $category
      nextToken: $nextToken
    ) {
      items {
        pk
        sk
        productName
        categoryName
        description
        quantity
        imageUrl
        productUnit
        productCode
        defaultPrice
        isEnabled
        reckonProductAttributes {
          listID
          editSequence
          isActive
          productType
        }
        productGroup
        isInStock
      }
      nextToken
    }
  }
`;
export const filterOrders = /* GraphQL */ `
  query FilterOrders(
    $searchString: String
    $orderStatus: String
    $nextToken: String
  ) {
    filterOrders(
      searchString: $searchString
      orderStatus: $orderStatus
      nextToken: $nextToken
    ) {
      items {
        pk
        sk
        customer {
          pk
          sk
          firstName
          lastName
          email
          mobile
          priceLevel
          companyName
          customerLevel
          reckonAttributes {
            reckonId
            editSequence
            isActive
            companyAddress {
              addr1
              addr2
              addr3
              city
              postalCode
            }
          }
          customerCode
        }
        customerId
        orderStatus
        orderDate
        orderId
        products {
          pk
          sk
          productId
          productName
          categoryName
          description
          quantity
          packedWeight
          imageUrl
          productUnit
          productCode
          defaultPrice
          newPrice
          isEnabled
          reckonTxnId
          notes
          customerId
        }
        invoiceRef {
          reckonRefNumber
          reckonTxnId
          reckonEditSequence
        }
        productionRef
        notes
        purchaseOrder
        line
        isDelivery
        freight
        cutting
      }
      nextToken
    }
  }
`;
export const listCategories = /* GraphQL */ `
  query ListCategories {
    listCategories {
      items {
        pk
        sk
        categoryName
      }
      nextToken
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders($orderStatus: String) {
    listOrders(orderStatus: $orderStatus) {
      items {
        pk
        sk
        customer {
          pk
          sk
          firstName
          lastName
          email
          mobile
          priceLevel
          companyName
          customerLevel
          reckonAttributes {
            reckonId
            editSequence
            isActive
            companyAddress {
              addr1
              addr2
              addr3
              city
              postalCode
            }
          }
          customerCode
        }
        customerId
        orderStatus
        orderDate
        orderId
        products {
          pk
          sk
          productId
          productName
          categoryName
          description
          quantity
          packedWeight
          imageUrl
          productUnit
          productCode
          defaultPrice
          newPrice
          isEnabled
          reckonTxnId
          notes
          customerId
        }
        invoiceRef {
          reckonRefNumber
          reckonTxnId
          reckonEditSequence
        }
        productionRef
        notes
        purchaseOrder
        line
        isDelivery
        freight
        cutting
      }
      nextToken
    }
  }
`;
export const listAdmins = /* GraphQL */ `
  query ListAdmins {
    listAdmins
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($productId: String) {
    getProduct(productId: $productId) {
      pk
      sk
      productName
      categoryName
      description
      quantity
      imageUrl
      productUnit
      productCode
      defaultPrice
      isEnabled
      reckonProductAttributes {
        listID
        editSequence
        isActive
        productType
      }
      productGroup
      isInStock
    }
  }
`;
export const getProductCustomPrice = /* GraphQL */ `
  query GetProductCustomPrice($productId: String, $priceLevel: String) {
    getProductCustomPrice(productId: $productId, priceLevel: $priceLevel) {
      pk
      sk
      product {
        pk
        sk
        productName
        categoryName
        description
        quantity
        imageUrl
        productUnit
        productCode
        defaultPrice
        isEnabled
        reckonProductAttributes {
          listID
          editSequence
          isActive
          productType
        }
        productGroup
        isInStock
      }
      priceLevelDetails {
        pk
        sk
        name
        reckonListID
        reckonEditSequence
      }
      productId
      customPrice
      priceLevel
    }
  }
`;
export const listCustomPricesByPriceLevel = /* GraphQL */ `
  query ListCustomPricesByPriceLevel($priceLevel: String) {
    listCustomPricesByPriceLevel(priceLevel: $priceLevel) {
      items {
        pk
        sk
        product {
          pk
          sk
          productName
          categoryName
          description
          quantity
          imageUrl
          productUnit
          productCode
          defaultPrice
          isEnabled
          reckonProductAttributes {
            listID
            editSequence
            isActive
            productType
          }
          productGroup
          isInStock
        }
        priceLevelDetails {
          pk
          sk
          name
          reckonListID
          reckonEditSequence
        }
        productId
        customPrice
        priceLevel
      }
      nextToken
    }
  }
`;
export const listPriceLevels = /* GraphQL */ `
  query ListPriceLevels {
    listPriceLevels {
      items {
        pk
        sk
        name
        reckonListID
        reckonEditSequence
      }
      nextToken
    }
  }
`;
export const getPriceLevel = /* GraphQL */ `
  query GetPriceLevel($priceLevel: String) {
    getPriceLevel(priceLevel: $priceLevel) {
      pk
      sk
      name
      reckonListID
      reckonEditSequence
    }
  }
`;
