<template>
	<div>
		<v-container fluid>
			<v-card-title>
				<h2>My Orders</h2>
			</v-card-title>

			<v-data-table
				:headers="headers"
				:items="customerOrders"
				:items-per-page="50"
				:footer-props="{
					'items-per-page-options': [50, 100, 150, -1],
					'items-per-page-text': '',
					showFirstLastPage: false,
				}"
				class="elevation-4 pt-2"
				mobile-breakpoint="0"
				:sort-by.sync="sortBy"
				:sort-desc.sync="sortDesc"
				item-key="sk"
				:loading="isLoading"
			>
				<template v-slot:top>
					<v-row class="pa-4 d-flex">
						<v-col cols="12" lg="4" md="4" sm="4" xs="4">
							<v-select label="Status" v-model="orderStatus" :items="filterStatus" dense outlined />
						</v-col>
					</v-row>
				</template>

				<template v-slot:item="row">
					<tr class="justify-center">
						<td class="text-left">
							{{ displayDate(row.item.orderDate) }}
						</td>

						<td class="text-left">
							{{ limitString(row.item.orderId).toUpperCase() }}
						</td>

						<td class="text-left">
							<v-icon class="ml-7" v-if="row.item.invoiceRef != null" color="green">
								mdi-check
							</v-icon>
						</td>

						<td class="text-left">
							<p class="ml-6 mt-4">
								{{ row.item.products.length }}
							</p>
						</td>

						<td class="text-left">
							<v-chip style="color: white" :color="colors[row.item.orderStatus]">
								{{ isMobile() ? limitStringShort(row.item.orderStatus) : row.item.orderStatus }}
							</v-chip>
						</td>

						<td class="text-left">
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-icon @click="orderDetails(row.item)" v-on="on" v-bind="attrs" color="secondary"
										>mdi-eye</v-icon
									>
								</template>
								<span>View</span>
							</v-tooltip>
						</td>
					</tr>
				</template>

				<!-- expanded item -->
				<!-- <template v-slot:expanded-item="{ headers, item }">
					<td class="pa-4 text-left" :colspan="headers.length">
						<tr v-if="item.products">
							<h4 style="font-size: 12px">
								<span> Products : </span>
								<span v-for="(prod, i) in item.products" :key="i">
									<v-chip small outlined color="green" class="mr-2">
										{{ displayProduct(prod) }}
									</v-chip>
								</span>
							</h4>
						</tr>
					</td>
				</template> -->
			</v-data-table>
		</v-container>

		<v-dialog
			:fullscreen="isMobile()"
			:width="isMobile() ? `100%` : `70%`"
			v-model="orderDetailsDialog"
			style="z-index: 9999"
		>
			<v-card :height="isMobile() ? `100vh` : `600px`" style="overflow: hidden">
				<div class="text-right">
					<v-icon x-large @click="orderDetailsDialog = false">mdi-close</v-icon>
				</div>
				<div
					style="height: 100%; overflow-x: hidden; overflow-y: scroll"
					class="text-left"
					v-if="item"
				>
					<v-card-title
						>{{ limitString(item.sk).toUpperCase() }}
						<v-spacer></v-spacer>
						<v-chip style="color: white" :color="colors[item.orderStatus]">
							{{ item.orderStatus }}
						</v-chip></v-card-title
					>
					<v-card-actions>
						<v-row>
							<v-col cols="12" md="3">
								<v-text-field readonly outlined label="Date" :value="displayDate(item.orderDate)">
								</v-text-field>
							</v-col>
							<v-col v-show="item.purchaseOrder" cols="12" md="3"
								><v-text-field
									readonly
									label="Purchase Order"
									outlined
									v-model="item.purchaseOrder"
								></v-text-field>
							</v-col>
							<v-col v-show="item.cutting" cols="12" md="3">
								<v-text-field
									readonly
									outlined
									label="Cutting Charge"
									prepend-inner-icon="mdi-currency-usd"
									:value="item.cutting"
								></v-text-field
							></v-col>
							<v-col v-show="item.freight" cols="12" md="3">
								<v-text-field
									readonly
									outlined
									label="Freight Charge"
									prepend-inner-icon="mdi-currency-usd"
									:value="item.freight"
								></v-text-field
							></v-col>
							<v-col v-show="item.orderStatus == 'COMPLETED'" cols="12" md="3"
								><v-text-field
									readonly
									outlined
									label="Sub Total"
									prepend-inner-icon="mdi-currency-usd"
									:value="subTotalCalculated(item)"
								></v-text-field>
							</v-col>
							<v-col v-show="item.orderStatus == 'COMPLETED'" cols="12" md="3">
								<v-text-field
									readonly
									outlined
									label="GST"
									prepend-inner-icon="mdi-currency-usd"
									:value="gstCalculated(item)"
								></v-text-field
							></v-col>

							<v-col v-show="item.orderStatus == 'COMPLETED'" cols="12" md="3"
								><v-text-field
									readonly
									outlined
									label="Total"
									prepend-inner-icon="mdi-currency-usd"
									:value="totalCalculated(item)"
								></v-text-field>
							</v-col>
						</v-row>
					</v-card-actions>

					<v-card-subtitle class="text-left"><h4>Products</h4></v-card-subtitle>
					<v-card-actions style="padding-bottom: 100px">
						<v-row>
							<v-col v-for="(product, i) in item.products" :key="i" cols="12" md="6" lg="4"
								><v-card color="secondary" dark height="200px" class="ma-2">
									<v-card-title style="word-break: break-word; font-size: 18px; height: 100px"
										>{{ product.productName }} ({{ product.productCode }})</v-card-title
									>
									<br />
									<v-card-subtitle>
										Qty:
										{{ item.orderStatus == 'PENDING' ? product.quantity : product.packedWeight }}
										<br />
										Price: ${{
											product.newPrice
												? product.newPrice.toFixed(2)
												: product.defaultPrice.toFixed(2)
										}}
										-
										{{ product.productUnit }}
									</v-card-subtitle>
								</v-card></v-col
							>
						</v-row>
					</v-card-actions>
				</div>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import moment from 'moment';
	import { API, Auth } from 'aws-amplify';
	import { listOrdersByCustomer } from '@/graphql/queries';

	export default {
		methods: {
			subTotalCalculated(item) {
				let subTotal = 0;
				item.products.forEach(prod => {
					if (prod.packedWeight) {
						if (prod.newPrice) {
							subTotal += parseFloat(prod.packedWeight) * parseFloat(prod.newPrice) + 0.00001;
						} else {
							subTotal += parseFloat(prod.packedWeight) * parseFloat(prod.defaultPrice) + 0.00001;
						}
					}
				});

				if (item.freight) {
					subTotal += parseFloat(item.freight);
				}

				if (item.cutting) {
					subTotal += parseFloat(item.cutting);
				}

				return subTotal.toFixed(2);
			},
			gstCalculated(item) {
				/**
				 * CALCULATE GST BY THE SUBTOTAL
				 */
				// let gst = 0;
				// let subTotal = this.subTotalCalculated(item);

				// gst = parseFloat(subTotal) * 0.15;

				/**
				 * CALCULATE GST PER ITEM
				 */
				let gstTotal = 0;
				item.products.forEach(prod => {
					if (!prod.packedWeight) return;
					if (prod.newPrice) {
						let gstPrice = parseFloat(
							parseFloat(prod.packedWeight * parseFloat(prod.newPrice) * 0.15 + 0.00001).toFixed(2)
						);
						// console.log('New Price: ', gstPrice);
						gstTotal += gstPrice;
					} else {
						let gstPrice = parseFloat(
							parseFloat(
								prod.packedWeight * parseFloat(prod.defaultPrice) * 0.15 + 0.00001
							).toFixed(2)
						);

						// console.log('Default Price: ', gstPrice);
						gstTotal += gstPrice;
					}
				});

				if (item.freight) {
					gstTotal += parseFloat((parseFloat(item.freight) * 0.15).toFixed(2));
				}

				if (item.cutting) {
					gstTotal += parseFloat((parseFloat(item.cutting) * 0.15).toFixed(2));
				}

				return gstTotal.toFixed(2);
			},

			totalCalculated(item) {
				let subTotal = this.subTotalCalculated(item);
				let gst = this.gstCalculated(item);

				return (parseFloat(subTotal) + parseFloat(gst)).toFixed(2);
			},
			orderDetails(item) {
				this.orderDetailsDialog = true;

				this.item = item;
			},
			displayProduct(obj) {
				let quantity = 0;
				if (obj.packedWeight) {
					quantity = parseFloat(obj.packedWeight);
				} else {
					quantity = parseFloat(obj.quantity);
				}
				let isEach =
					obj.productName + ' | ' + 'Qty: ' + ' ' + quantity.toFixed(0) + ' - ' + obj.productUnit;
				let isElse = obj.productName + ' - ' + quantity.toFixed(2) + ' ' + obj.productUnit;
				if (obj.productUnit != 'KG') {
					return isEach;
				} else {
					return isElse;
				}
			},
			// async orderAgain (val) {
			//   console.log(val)
			// },
			objectManager(obj) {
				let map = obj.map(name => name.productName);
				return map.join(', ');
			},
			async listCustomerOrders() {
				this.isLoading = true;
				this.customerOrders = [];
				let customer = await Auth.currentAuthenticatedUser();
				let customerId = customer.username;
				let nextToken = null;
				let orderList = [];
				// console.log(customerId)
				if (this.orderStatus == 'ALL') {
					let result = await API.graphql({
						query: listOrdersByCustomer,
						variables: {
							customerId: customerId,
						},
						authMode: 'AMAZON_COGNITO_USER_POOLS',
					});

					nextToken = result.data.listOrdersByCustomer.nextToken;
					orderList = result.data.listOrdersByCustomer.items;

					while (nextToken) {
						let res = await API.graphql({
							query: listOrdersByCustomer,
							variables: {
								customerId: customerId,
								nextToken: nextToken,
							},
							authMode: 'AMAZON_COGNITO_USER_POOLS',
						});

						orderList = orderList.concat(res.data.listOrdersByCustomer.items);
						nextToken = res.data.listOrdersByCustomer.nextToken;
					}
				} else {
					let result = await API.graphql({
						query: listOrdersByCustomer,
						variables: {
							customerId: customerId,
							// orderStatus: this.orderStatus,
						},
						authMode: 'AMAZON_COGNITO_USER_POOLS',
					});

					nextToken = result.data.listOrdersByCustomer.nextToken;
					orderList = result.data.listOrdersByCustomer.items;

					while (nextToken) {
						let res = await API.graphql({
							query: listOrdersByCustomer,
							variables: {
								customerId: customerId,
								orderStatus: this.orderStatus,
								nextToken: nextToken,
							},
							authMode: 'AMAZON_COGNITO_USER_POOLS',
						});

						orderList = orderList.concat(res.data.listOrdersByCustomer.items);
						nextToken = res.data.listOrdersByCustomer.nextToken;
					}
				}
				let customerOrders = await orderList.sort((a, b) => {
					a.orderDate > b.orderDate ? 1 : b.orderDate > a.orderDate ? -1 : 0;
				});

				customerOrders = customerOrders.map(cust => {
					if (cust.orderStatus == 'PACKED') {
						cust.orderStatus = 'PENDING';
					} else if (cust.orderStatus == 'CANCELLED') {
						cust.orderStatus = 'COMPLETED';
					}
					return cust;
				});

				if (this.orderStatus != 'ALL') {
					customerOrders = customerOrders.filter(x => x.orderStatus == this.orderStatus);
				}

				this.customerOrders = customerOrders;

				this.isLoading = false;
			},
			limitString(string) {
				if (string) {
					let limit = string.substr(0, 5);
					return limit;
				}
			},
			limitStringShort(string) {
				if (string) {
					let limit = string.substr(0, 1);
					return limit;
				}
			},
			convertDate(d) {
				if (d) {
					return d && d.length > 0 ? moment(d).format('DD/MM/YYYY') : '';
				}
			},
			displayDate(val) {
				return moment(val).format('DD/MM/YYYY');
			},
			isMobile() {
				if (this.$vuetify.breakpoint.width < 800) {
					return true;
				}
			},
		},

		async mounted() {
			await Auth.currentAuthenticatedUser({
				bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
			})
				.then(user => {
					user;
				})
				.catch(err => {
					err;
					this.$router.push('/Login');
					return;
				});

			await this.listCustomerOrders();
		},

		watch: {
			orderStatus: function (status) {
				if (status == 'ALL') {
					this.listCustomerOrders();
				} else {
					this.listCustomerOrders();
				}
			},
		},

		data() {
			return {
				item: null,
				orderDetailsDialog: false,
				isLoading: false,
				isExpanded: false,
				orderProducts: [],
				sortDesc: true,
				sortBy: 'orderDate',
				displayedItem: null,
				colors: {
					COMPLETED: 'green',
					PENDING: 'orange',
					CANCELLED: 'red',
					PACKED: 'blue',
				},
				orderStatus: 'PENDING',
				filterStatus: ['ALL', 'COMPLETED', 'PENDING'],
				selectedDate: null,
				dateFilterDialog: false,
				productCategory: ['Test', '123', 'XYZ'],
				addOrder: false,
				filters: false,
				headers: [
					{
						text: 'Order Date',
						value: 'orderDate',
						sortable: true,
					},
					// {
					//   text: 'Purchase order',
					//   value: 'purchaseOrder',
					//   sortable: false,
					// },
					{
						text: 'Order ID',
						value: 'orderId',
						sortable: false,
					},
					{
						text: 'Invoice Sent',
						value: 'invoiceRef',
						sortable: false,
					},
					{
						text: 'Items',
						value: 'quantity',
						sortable: false,
					},
					{
						text: 'Status',
						value: 'orderStatus',
						sortable: false,
					},
					{
						text: 'Action',
						value: 'orderAgain',
						sortable: false,
					},
				],
				customerOrders: [],
				actions: [{ title: 'Edit' }, { title: 'Delete' }],
			};
		},
	};
</script>

<style>
	/*  */
</style>
