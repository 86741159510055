<template>
	<v-container>
		<!-- <BreadAndSearch /> -->

		<v-row v-if="searchText" class="justify-center mt-8">
			<v-card-title style="word-break: break-word"
				><h2>Search results for "{{ searchText }}"</h2></v-card-title
			>
		</v-row>
		<v-row v-if="!isMobile()">
			<!-- Category filter -->
			<v-col sm="12" md="4" lg="3" cols="12">
				<v-select
					full-width
					class="mt-12"
					label="Category"
					outlined
					filled
					color="primary"
					v-model="selectedCategoryFilter"
					:items="categories"
					:disabled="isLoading"
				></v-select>
			</v-col>
			<v-spacer></v-spacer>
		</v-row>

		<!-- Filter on mobile -->
		<v-row v-if="isMobile()">
			<v-col cols="12">
				<v-container>
					<v-select
						v-model="selectedCategoryFilter"
						:items="categories"
						label="Category"
						outlined
						:disabled="isLoading"
					/>
				</v-container>
			</v-col>
		</v-row>

		<v-row class="mb-16" style="margin-top: -50px">
			<v-col
				class="d-flex justify-center"
				v-for="(item, i) in products"
				v-bind:key="i"
				:lg="$vuetify.breakpoint.width > 1903 ? `2` : `3`"
				md="4"
				sm="6"
				xs="12"
			>
				<v-hover v-slot="{ hover }">
					<v-fade-transition>
						<v-card
							:class="
								hover
									? `ma-2 pa-2 transition-swing elevation-${hover ? 8 : 6}`
									: `ma-4 pa-2 transition-swing`
							"
							height="410"
							width="100%"
							min-width="280"
							style="transition-property: background-color"
						>
							<v-fade-transition>
								<div style="background: #1b8600" v-if="item.inCart" class="ribbon">Added</div>
							</v-fade-transition>
							<!--  Out of stock -->
							<v-fade-transition>
								<div
									style="background: red"
									v-if="
										(item.isInStock == null && authenticatedUser) ||
										(item.isInStock == false && authenticatedUser)
									"
									class="ribbon"
								>
									OUT OF STOCK
								</div>
							</v-fade-transition>
							<div
								@click="routeToSingleProduct(item)"
								class="productClicker"
								:style="
									isMobile() ? `width: 88%;  margin-left: 6%; height: 300px` : `height: 300px`
								"
							></div>
							<v-card-text
								class="mt-3"
								style="height: 60px; white-space: normal; overflow: hidden; text-overflow: ellipsis"
							>
								<!-- {{computedName(item.productName)}} -->
								<strong> {{ item.productName }} </strong>
							</v-card-text>

							<v-card-text>
								<v-img
									style="margin: auto"
									:src="item.imageUrl ? item.imageUrl : require(`@/assets/no-img.jpg`)"
									height="180"
									max-width="200"
								></v-img>
							</v-card-text>

							<div class="d-flex" v-if="authenticatedUser">
								<h3 v-if="item.newPrice" class="ml-4" style="font-weight: 900px; color: #6fc276">
									$
									{{
										item.newPrice
											? parseFloat(item.newPrice).toFixed(2)
											: item.defaultPrice
											? parseFloat(item.defaultPrice).toFixed(2)
											: ``
									}}
								</h3>
								<h3 v-else class="ml-4" style="font-weight: 900px">
									${{
										item.newPrice
											? parseFloat(item.newPrice).toFixed(2)
											: item.defaultPrice
											? parseFloat(item.defaultPrice).toFixed(2)
											: ``
									}}
								</h3>
								<v-spacer></v-spacer>
								<v-chip small class="mt-1 mr-4">
									{{ item.productUnit }}
								</v-chip>
							</div>

							<v-card-text>
								<div style="width: 100%; margin: auto" class="d-flex justify-center">
									<v-btn
										v-show="item.inCart"
										outlined
										height="40"
										class="mr-1"
										x-small
										color="secondary"
										@click="minusFromTrolley(item, i)"
										:loading="isLoading"
									>
										<v-icon>mdi-minus</v-icon>
									</v-btn>

									<!--v-on:keydown.enter="item.inCart ? updateQuantityFromField(item, i) : initTrolley(item, i)" -->
									<v-text-field
										@change="updateQuantityFromField(item, i)"
										v-model="item.quantity"
										filled
										outlined
										dense
										v-on:keypress="numbersOnly"
										:append-icon="item.productUnit == 'kg' ? `mdi-weight-kilogram` : ``"
										:loading="isLoading"
										:disabled="item.isInStock == null || item.isInStock == false"
									></v-text-field>
									<!-- :value="itemCount" -->
									<!-- :value="item.productUnit == 'kg' ? `quantity` : ``" -->
									<v-btn
										v-show="item.inCart"
										outlined
										height="40"
										class="ml-1"
										x-small
										color="secondary"
										@click="addToTrolley(item, i)"
										:loading="isLoading"
									>
										<v-icon>mdi-plus</v-icon>
									</v-btn>
									<v-btn
										v-show="!item.inCart"
										outlined
										height="40"
										class="ml-1"
										x-small
										:loading="isLoading"
										color="secondary"
										@click="initTrolley(item, i)"
										:disabled="item.isInStock == null || item.isInStock == false"
									>
										Add
									</v-btn>
								</div>
								<div style="font-size: 13px; margin-top: -20px; color: #bebebe" class="text-left">
									<strong>{{ item.productCode }}</strong>
								</div>
							</v-card-text>
						</v-card>
					</v-fade-transition>
				</v-hover>
			</v-col>
		</v-row>

		<v-row v-if="!isLoading && products.length == 0">
			<v-col>
				<h3>No results found.</h3>
			</v-col>
		</v-row>
		<!-- Pagination -->
		<v-row>
			<v-col v-if="!isLoading"
				>Viewing <b>{{ this.products.length }}</b> products</v-col
			>

			<v-col cols="12">
				<v-btn
					width="180px"
					height="80px"
					outlined
					color="primary"
					:disabled="!nextToken"
					@click="loadMoreProducts()"
					:loading="isLoading"
					>Load More</v-btn
				>
			</v-col>
			<v-col cols="12">
				<v-btn @click="scrollToTop()" text small color="primary"> Back to top </v-btn>
			</v-col>
		</v-row>

		<!-- notification  -->
		<v-snackbar v-model="notify" class="pt-16" top :timeout="2500" :color="color">
			{{ notificationText }}
		</v-snackbar>
	</v-container>
</template>

<script>
// import BreadAndSearch from "../../components/CustomerViewComponents/BreadAndSearch.vue";
import { API } from "aws-amplify";
import {
  listProducts,
  filterProductsForCustomers,
  listCategories,
} from "@/graphql/queries";

export default {
  components: {
    // BreadAndSearch,
  },

  async mounted() {
    this.listCategories();
    if (this.$store.getters["products/productList"].length > 0) return;
    this.$store.commit("products/setNextTokenFilter", null);
    if (this.selectedCategoryFilter == "All") {
      this.listProducts();
    } else {
      this.filterProductsByCategory();
    }
  },
  watch: {
    chosenItemKey: function (val) {
      return this.itemCount, val.quantity;
    },
    selectedCategoryFilter: function (val) {
      this.$store.commit("products/resetProductList");
      this.isLoading = true;
      this.$store.commit("breadcrumbs/setSelectedCategory", {
        key: "items",
        index: 1,
        value: val,
      });
      // if (this.$route.query.search) return;
      if (val == "All") {
        this.nextToken = null;
        this.listProducts();
      } else {
        this.nextToken = null;
        this.filterProductsByCategory();
      }

      switch (this.selectedCategoryFilter) {
        case "ALL":
          document.title = "Farms Chicken - Order Now";
          break;
        case "Beef":
          document.title = "Beef";
          break;
        case "Chicken":
          document.title = "Chicken";
          break;
        case "Pork":
          document.title = "Pork";
          break;
        case "Duck":
          document.title = "Duck";
          break;
        case "Lamb":
          document.title = "Lamb";
          break;
        case "Other":
          document.title = "Other";
          break;
        default:
          document.title = "All Products";
          break;
      }
    },
  },

  methods: {
    dynamicTitle() {
      let title = "";
      if (this.searchText) {
        title = this.searchText;
        document.title = `Search result for "${title}"`;
      } else {
        if (this.selectedCategoryFilter == "ALL") {
          document.title = this.selectedCategoryFilter;
        } else {
          document.title = "Products";
        }
      }
      // if (this.selectedCategoryFilter != "ALL") {
      //   title += " | " + this.selectedCategoryFilter;
      // }
    },
    numbersOnly(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async updateQuantityFromField(product, index) {
      this.isLoading = true;

      if (product.quantity <= 0) {
        this.removeFromTrolley(product, index);
        this.isLoading = false;
      } else if (!product.inCart && product.quantity >= 0.1) {
        this.initTrolley(product, index);
        this.isLoading = false;
      } else {
        await this.$store.dispatch("cartTotal/updateQuantity", {
          product: product,
        });
      }

      this.isLoading = false;
      setTimeout(() => {
        this.$forceUpdate();
      }, 10);
    },
    returnItemCount(selectedProduct) {
      return selectedProduct;
    },

    async initTrolley(item, index) {
      if (!this.authenticatedUser) {
        this.color = "info";
        this.notificationText = "Please Login As Customer To Order.";
        this.notify = true;
        return;
      }
      if (item.quantity >= 0.1) {
        item.inCart = true;
      }
      if (item.inCart == true) {
        this.isLoading = false;
        await this.addToTrolley(item, index);
      }

      this.$forceUpdate();
      // if (!this.authenticatedUser) {
      //   this.color = "info";
      //   this.notificationText = "Please Login As Customer To Order.";
      //   this.notify = true;
      //   return;
      // }
      // let productUnit = item.productUnit;
      // if (productUnit == "kg") {
      //   if (item.quantity > 0) {
      //     this.isLoading = true;
      //     item.inCart = true;
      //     if (item.inCart == true) {
      //       this.isLoading = false;
      //       this.addToTrolley(item, index);
      //     }
      //   }
      // } else {
      //   this.isLoading = true;
      //   item.inCart = true;
      //   if (item.inCart == true) {
      //     this.isLoading = false;
      //     this.addToTrolley(item, index);
      //   }
      // }
    },

    async addToTrolley(product, index) {
      let count = await this.$store.dispatch("cartTotal/addToCart", {
        product: product,
      });

      if (!this.authenticatedUser) {
        return;
      }

      if (count > 0) this.products[index].quantity = count;
    },

    async removeFromTrolley(product, index) {
      this.products[index].inCart = false;
      this.$store.dispatch("cartTotal/removeFromCart", { product: product });
    },

    async minusFromTrolley(product, index) {
      let count = await this.$store.dispatch("cartTotal/minusFromCart", {
        product: product,
      });

      this.products[index].quantity = count;
      if (count <= 0) {
        this.removeFromTrolley(product, index);
        this.products[index].quantity = 1;
      }
    },

    async filterProductsByCategory() {
      let res = await API.graphql({
        query: filterProductsForCustomers,
        variables: {
          category: this.selectedCategoryFilter,
          searchString: this.searchString
            ? this.searchString.toLowerCase()
            : null,
          nextToken: this.nextToken,
        },
        authMode: this.authenticatedUser
          ? "AMAZON_COGNITO_USER_POOLS"
          : "API_KEY",
      });

      let enabledProducts = [];
      let results = res.data.filterProductsForCustomers.items;
      for (let i = 0; i < results.length; i++) {
        if (results[i].isEnabled == true) {
          enabledProducts.push(results[i]);
        }
      }

      if (!this.nextToken) {
        this.products = enabledProducts;
      } else {
        this.products = this.products.concat(enabledProducts);
      }

      this.nextToken = res.data.filterProductsForCustomers.nextToken;
    },

    async loadMoreProducts() {
      // let loaded = 0;
      if (this.selectedCategoryFilter != "All") {
        this.filterProductsByCategory();
      } else {
        this.isLoading = true;

        let res = await API.graphql({
          query: listProducts,
          variables: {
            searchString: this.searchString
              ? this.searchString.toLowerCase()
              : null,
            nextToken: this.nextToken,
          },
          authMode: this.authenticatedUser
            ? "AMAZON_COGNITO_USER_POOLS"
            : "API_KEY",
        });

        let enabledProducts = [];
        let results = res.data.listProducts.items;
        for (let i = 0; i < results.length; i++) {
          if (
            results[i].isEnabled == true ||
            results[i].isEnabled == null ||
            results[i].isEnabled == undefined
          ) {
            enabledProducts.push(results[i]);
          }
        }

        this.nextToken = res.data.listProducts.nextToken;
        this.products = this.products.concat(enabledProducts);
        this.$forceUpdate();

        // this.isLoading = false;
      }
    },

    computedName(item) {
      if (item.length > 30) {
        item = item.substring(0, 40) + "...";
        return item;
      } else {
        return item;
      }
    },
    async listProducts() {
      if (this.$route.query) {
        this.searchString = this.$route.query.search;
      }
      this.isLoading = true;
      let res = await API.graphql({
        query: listProducts,
        variables: {
          searchString: this.searchString
            ? this.searchString.toLowerCase()
            : null,
          nextToken: this.nextToken,
        },
        authMode: this.authenticatedUser
          ? "AMAZON_COGNITO_USER_POOLS"
          : "API_KEY",
      });

      let enabledProducts = [];
      let results = await res.data.listProducts.items;
      for (let i = 0; i < results.length; i++) {
        if (results[i].isEnabled == null || results[i].isEnabled == true) {
          enabledProducts.push(results[i]);
        }
      }

      this.nextToken = res.data.listProducts.nextToken;

      if (this.$route.query.search) {
        while (this.nextToken && enabledProducts.length < 20) {
          let newRes = await API.graphql({
            query: listProducts,
            variables: {
              searchString: this.searchString
                ? this.searchString.toLowerCase()
                : null,
              nextToken: this.nextToken,
            },
            authMode: this.authenticatedUser
              ? "AMAZON_COGNITO_USER_POOLS"
              : "API_KEY",
          });

          let filterResResult = newRes.data.listProducts.items;
          for (let i = 0; i < filterResResult.length; i++) {
            if (
              filterResResult[i].isEnabled == null ||
              filterResResult[i].isEnabled == true
            ) {
              enabledProducts.push(filterResResult[i]);
            }
          }

          this.nextToken = newRes.data.listProducts.nextToken;
        }
      }

      this.products = this.products.concat(enabledProducts);

      // this.isLoading = false;
      this.dynamicTitle();
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    isMobile() {
      if (this.$vuetify.breakpoint.width < 800) {
        return true;
      }
    },

    routeToSingleProduct(item) {
      this.$router.push(
        `/order/product?id=${item.sk}&name=${item.productName}`
      );
    },

    async listCategories() {
      let res = await API.graphql({
        query: listCategories,
        authMode: this.authenticatedUser
          ? "AMAZON_COGNITO_USER_POOLS"
          : "API_KEY",
      });
      let objects = res.data.listCategories.items;
      let categoryList = [];
      objects.forEach((obj) => {
        categoryList.push(obj.categoryName);
      });

      this.categories = this.categories.concat(categoryList);
    },
  },

  computed: {
    searchText: {
      get() {
        return this.$route.query.search;
      },
    },
    isLoading: {
      get() {
        return this.$store.getters["products/isLoading"];
      },
      set(val) {
        this.$store.commit("products/setIsLoading", val);
      },
    },
    authenticatedUser: {
      get() {
        return this.$store.getters["customers/authenticatedUser"];
      },
    },
    cartItemsCount: {
      get() {
        return this.$store.getters["cartTotal/cartItemsCount"];
      },
    },
    selectedCartitem: {
      get() {
        return this.$store.getters["cartItems/selectedCartItem"];
      },
    },
    selectedCategoryFilter: {
      get() {
        return this.$store.getters["categories/selectedCategoryFilter"];
      },
      set(val) {
        this.$store.commit("categories/setSelectedCategoryFilter", val);
      },
    },
    nextToken: {
      get() {
        return this.$store.getters["products/nextTokenFilter"];
      },
      set(val) {
        this.$store.commit("products/setNextTokenFilter", val);
      },
    },
    products: {
      get() {
        return this.$store.getters["products/productList"];
      },
      set(val) {
        this.$store.dispatch("products/setProductList", val);
      },
    },
    searchString: {
      get() {
        return this.$store.getters["products/searchString"];
      },
      set(val) {
        this.$store.commit("products/setSearchString", val);
      },
    },
  },

  data: () => ({
    color: "white",
    notificationText: "",
    notify: false,
    validateInput: "",
    chosenItemKey: null,
    itemCount: "",
    request: null,
    // isLoading: false,
    // nextToken: null,
    types: {
      row: "avatar, chip, avatar",
      test: "paragraph",
    },

    attrs: {
      class: "mb-6",
      boilerplate: false,
      elevation: 2,
    },
    selectedPage: 1,
    sortBy: "Low to High",
    sortOptions: ["Low to High", "High to Low"],
    categories: ["All"],
  }),
};
</script>

<style lang="scss" scoped>
	.break {
		flex-basis: 100%;
		height: 0;
	}
	.productClicker {
		position: absolute;
		width: 100%;
		height: 80%;
		top: 0;
		left: 0;
		z-index: 1;
		cursor: pointer;
	}

	.home.v-seleton-loader__row {
		display: flex;
	}

	.ribbon {
		--f: 5px;
		/* control the folded part*/
		--r: 15px;
		/* control the ribbon shape */
		--t: 3px;
		/* the top offset */

		position: absolute;
		inset: var(--t) calc(-1 * var(--f)) auto auto;
		padding: 0 10px var(--f) calc(10px + var(--r));
		clip-path: polygon(
			0 0,
			100% 0,
			100% calc(100% - var(--f)),
			calc(100% - var(--f)) 100%,
			calc(100% - var(--f)) calc(100% - var(--f)),
			0 calc(100% - var(--f)),
			var(--r) calc(50% - var(--f) / 2)
		);
		box-shadow: 0 calc(-1 * var(--f)) 0 inset #0005;
		color: white;
	}
</style>
