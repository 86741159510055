<template>
	<div>
		<!-- Banner Img -->
		<v-img
			class="bannerImg"
			src="https://images.pexels.com/photos/1639561/pexels-photo-1639561.jpeg"
			gradient="to top right, rgba(0,0,0,.33), rgba(25,32,72,.7)"
		>
			<v-container
				:style="
					isMobile()
						? `margin-top: 10vh; color: black; font-weight: 200; font-size: 16px;`
						: `margin-top: 20vh; color: black; font-weight: 0; font-size: 1.5em; max-width: 70vw;`
				"
				class="text-center"
			>
				<span style="color: white; background-color: rgba(0, 0, 0, 0.1)">
					Farm’s Chicken is a family owned &amp; operated one stop shop for poultry, pork, beef and
					lamb products. We aim to help all sized customers whether it’s small, medium or large. Our
					products and services are tailored to your needs. Our purpose is to provide great prices
					without compromising on the quality of our meat. Farm’s Chicken started from humble
					beginnings as a poultry only business in 2001 and expanded into whole meat market step by
					step during the past 20 years. Today with our own plant and production team we are able to
					price our meat at very competitive prices without skimping on quality.
				</span>
				<br />
				<v-btn class="mt-8" width="300" height="100" outlined @click="viewProducts()" dark>
					ORDER NOW
				</v-btn>
			</v-container>
		</v-img>

		<!-- Cards -->
		<v-card flat tile color="#">
			<v-container class="pa-4 mt-6">
				<h1 class="pb-2">Top Sellers</h1>
				<v-row justify="center">
					<!-- Chicken Card -->
					<v-col cols="12" lg="3" md="3" sm="3" xs="3">
						<v-card elevation="1" tile min-width="175px">
							<v-img
								alt="chicken"
								src="https://a.fsimg.co.nz/product/retail/fan/image/200x200/5114311.png"
							/>
							<v-card-title class="justify-center"> Chicken </v-card-title>
							<v-card-actions class="justify-center">
								<!-- <v-btn
                  @click="redirect('Chicken')"
                  color="accent"
                  width="100%"
                > Order Now </v-btn> -->
							</v-card-actions>
						</v-card>
					</v-col>

					<!-- Pork Card -->
					<v-col cols="12" lg="3" md="3" sm="3" xs="3">
						<v-card elevation="1" tile min-width="175px">
							<v-img
								alt="pork"
								src="https://a.fsimg.co.nz/product/retail/fan/image/200x200/5101477.png"
							/>
							<v-card-title class="justify-center"> Pork </v-card-title>
							<v-card-actions class="justify-center">
								<!-- <v-btn
                  @click="redirect('Pork')"
                  width="100%"
                  color="accent"
                > Order Now </v-btn> -->
							</v-card-actions>
						</v-card>
					</v-col>

					<!-- Beef Card -->
					<v-col cols="12" lg="3" md="3" sm="3" xs="3">
						<v-card elevation="1" tile min-width="175px">
							<v-img
								alt="beef"
								src="https://a.fsimg.co.nz/product/retail/fan/image/200x200/5131134.png"
							/>
							<v-card-title class="justify-center"> Beef </v-card-title>
							<v-card-actions class="justify-center">
								<!-- <v-btn
                  @click="redirect('Beef')"
                  width="100%"
                  color="accent"
                > Order Now </v-btn> -->
							</v-card-actions>
						</v-card>
					</v-col>

					<!-- Lamb Card -->
					<v-col cols="12" lg="3" md="3" sm="3" xs="3">
						<v-card elevation="1" tile min-width="175px">
							<v-img
								alt="chicken"
								src="https://a.fsimg.co.nz/product/retail/fan/image/200x200/5104657.png"
							/>
							<v-card-title class="justify-center"> Lamb </v-card-title>
							<v-card-actions class="justify-center">
								<!-- <v-btn
                  @click="redirect('Lamb')"
                  width="100%"
                  color="accent"
                > Order Now </v-btn> -->
							</v-card-actions>
						</v-card>
					</v-col>
				</v-row>
				<v-row class="pb-2 pt-2" justify="center">
					<v-btn class="pa-2" color="primary" xl @click="viewProducts()"> View All Products </v-btn>
				</v-row>
			</v-container>
		</v-card>
	</div>
</template>

<script>
	// @ is an alias to /src

	export default {
		methods: {
			viewProducts() {
				this.$store.commit('products/resetProductList');
				this.$router.push('/order');
			},
			redirect(data) {
				this.$store.commit('categories/setSelectedCategoryFilter', data);
				this.$router.push('/order');
			},
			isMobile() {
				if (this.$vuetify.breakpoint.width < 800) {
					return true;
				}
			},
		},

		components: {
			//
		},

		computed: {
			//
		},

		data() {
			return {
				//
			};
		},
	};
</script>

<style scoped>
	.bannerImg {
		background-position: center;
		background-attachment: fixed;
		min-height: 100vh;
	}
</style>
