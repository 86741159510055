<template>
	<v-container class="mb-16 pb-16">
		<!-- <BreadAndSearch style="position: absolute; z-index: 2; top: 150;" /> -->
		<v-card v-if="!isLoading" style="min-height: 500px" elevation="0">
			<div class="pt-16" />
			<v-card-title>
				<v-row>
					<v-col lg="4" md="5" cols="12">
						<v-card flat outlined class="justify-center d-flex">
							<v-fade-transition>
								<div style="background: #1b8600" v-if="selectedProduct.inCart" class="ribbon">
									Added
								</div>
							</v-fade-transition>
							<v-scale-transition>
								<div v-if="!selectedProduct.isInStock" class="ribbon">OUT OF STOCK</div>
							</v-scale-transition>

							<!-- <div
                v-show="selectedProduct.special"
                class="ribbon-special"
              >Special 50% off</div> -->
							<v-img
								max-height="350"
								max-width="300"
								:src="
									selectedProduct.imageUrl
										? selectedProduct.imageUrl
										: require(`@/assets/no-img.jpg`)
								"
							/>
						</v-card>
					</v-col>
					<v-col
						lg="8"
						md="7"
						cols="12"
						:class="$vuetify.breakpoint.width < 960 ? `text-center` : `text-left`"
						style="display: inline-block; word-break: break-word"
					>
						<strong>{{ selectedProduct.productName }}</strong>
						<div style="font-size: 14px">{{ selectedProduct.productCode }}</div>
						<v-divider></v-divider>
						<div
							:class="isMobile() ? `d-flex justify-center mt-4` : `d-flex mt-4`"
							v-if="currentAuthenticatedCustomer"
						>
							<h3 :style="discountedPrice ? `text-decoration: line-through` : ``">
								$
								{{
									this.selectedProduct.defaultPrice
										? parseFloat(this.selectedProduct.defaultPrice).toFixed(2)
										: `Price Missing!`
								}}
							</h3>
							<v-chip small class="mt-1 ml-1"> {{ this.selectedProduct.productUnit }} </v-chip>
						</div>
						<h3 v-if="discountedPrice">
							$ {{ parseFloat(this.discountedPrice).toFixed(2) }}
							<span style="color: green; font-size: 12px"> (discount)</span>
						</h3>

						<div
							:style="$vuetify.breakpoint.width < 960 ? `width:204px; margin: auto` : `width:204px`"
							class="d-flex justify-center pt-6"
						>
							<v-btn
								v-show="selectedProduct.inCart"
								outlined
								height="40"
								class="mr-1"
								x-small
								color="secondary"
								:loading="isLoading"
								@click="minus(selectedProduct)"
							>
								<v-icon>mdi-minus</v-icon>
							</v-btn>
							<v-text-field
								@change="updateQuantityFromField(selectedProduct)"
								v-model="selectedProduct.quantity"
								filled
								outlined
								dense
								v-on:keyup.enter="
									selectedProduct.inCart
										? updateQuantityFromField(selectedProduct)
										: initTrolley(selectedProduct)
								"
								v-on:keypress="numbersOnly"
								:append-icon="selectedProduct.productUnit == 'kg' ? `mdi-weight-kilogram` : ``"
								:disabled="!selectedProduct.isInStock"
							></v-text-field>
							<v-btn
								v-show="!selectedProduct.inCart"
								outlined
								height="40"
								class="ml-1"
								x-small
								color="secondary"
								@click="initTrolley(selectedProduct)"
								:disabled="!selectedProduct.isInStock"
							>
								Add
							</v-btn>
							<v-btn
								v-show="selectedProduct.inCart"
								outlined
								height="40"
								class="ml-1"
								x-small
								color="secondary"
								:loading="isLoading"
								@click="addToTrolley(selectedProduct)"
							>
								<v-icon>mdi-plus</v-icon>
							</v-btn>
						</div>

						<!-- <v-divider></v-divider>
            <v-card-text :style=" isMobile() ? `display: inline-block; word-break: break-word;` : `margin-left:-15px;display: inline-block; word-break: break-word;`">
              <strong>Options</strong><br>{{selectedProduct.description}}
            </v-card-text> -->

						<v-divider></v-divider>
						<v-card-text
							:style="
								isMobile()
									? `display: inline-block; word-break: break-word;`
									: `margin-left:-15px;display: inline-block; word-break: break-word;`
							"
						>
							<strong style="text-decoration: underline">Description</strong><br />{{
								selectedProduct.description
							}}
						</v-card-text>
					</v-col>
				</v-row>
			</v-card-title>
		</v-card>
		<div v-else class="mt-12">
			<v-progress-circular color="primary" indeterminate size="70"></v-progress-circular>
		</div>

		<!-- notification  -->
		<v-snackbar v-model="notify" class="pt-16" top :timeout="2500" :color="color">
			{{ notificationText }}
		</v-snackbar>
	</v-container>
</template>

<script>
	// import BreadAndSearch from "../../components/CustomerViewComponents/BreadAndSearch.vue";
	import { getProduct, getProductCustomPrice } from '@/graphql/queries';
	import { API } from 'aws-amplify';
	// import * as subscriptions from "@/graphql/subscriptions";

	export default {
		components: {
			// BreadAndSearch,
		},

		async mounted() {
			// this.watchSubscriptions();
			await this.loadProduct();
			await this.loadCustomPrice();
			this.$forceUpdate();
			// this.$store.commit("breadcrumbs/setSelectedCategory", {
			//   key: "items",
			//   index: 1,
			//   value: await this.selectedProduct.categoryName,
			// });
			// this.$store.commit("breadcrumbs/setSelectedProduct", {
			//   key: "items",
			//   index: 2,
			//   value: await this.selectedProduct.productName,
			// });
		},

		data: () => ({
			color: 'white',
			notificationText: '',
			notify: false,
			discountedPrice: null,
			itemCount: '',
			isLoading: true,
			chosenItemKey: null,
			interval: null,
			timerSpeed: 500,
			inCart: false,
			selectedProduct: {},
			priceIsLoading: true,
		}),

		methods: {
			// watchSubscriptions () {
			//   //adding product
			//   API.graphql(graphqlOperation(subscriptions.addedProduct)).subscribe({
			//     next: ({ value }) => {
			//       if (value) {
			//         this.loadProduct();
			//       }
			//     },
			//     error: (error) => console.warn(error),
			//   });
			//   //updating product
			//   API.graphql(graphqlOperation(subscriptions.updatedProduct)).subscribe({
			//     next: ({ value }) => {
			//       if (value) {
			//         this.loadProduct();
			//       }
			//     },
			//     error: (error) => console.warn(error),
			//   });
			//   //deleting product
			//   API.graphql(graphqlOperation(subscriptions.deletedProduct)).subscribe({
			//     next: ({ value }) => {
			//       if (value) {
			//         this.loadProduct();
			//       }
			//     },
			//     error: (error) => console.warn(error),
			//   });
			// },
			numbersOnly(evt) {
				evt = evt ? evt : window.event;
				var charCode = evt.which ? evt.which : evt.keyCode;
				if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
					evt.preventDefault();
				} else {
					return true;
				}
			},
			async removeFromTrolley(product) {
				await this.$store.dispatch('cartTotal/removeFromCart', {
					product: product,
				});
				this.selectedProduct.inCart = false;
			},

			async loadCustomPrice() {
				let customer = await this.currentAuthenticatedCustomer;
				if (!customer) {
					return;
				}
				try {
					if (customer.priceLevel == null || customer.priceLevel == '') {
						return;
					} else {
						if (this.selectedProduct.defaultPrice) {
							let res = await API.graphql({
								query: getProductCustomPrice,
								variables: {
									productId: this.selectedProduct.sk,
									priceLevel: customer.priceLevel,
								},
								authMode: 'AMAZON_COGNITO_USER_POOLS',
							});

							if (res.data.getProductCustomPrice) {
								this.discountedPrice = res.data.getProductCustomPrice.customPrice;
								this.selectedProduct.newPrice = res.data.getProductCustomPrice.customPrice;
							}
						} else {
							setTimeout(() => {
								this.loadCustomPrice();
							}, 50);
						}
					}
				} catch (error) {
					console.log(error);
				}
			},

			async updateQuantityFromField(product) {
				this.isLoading = true;
				if (product.quantity <= 0) {
					this.removeFromTrolley(product);
					this.isLoading = false;
				} else if (!product.inCart && product.quantity >= 0.1) {
					this.initTrolley(product);
					this.isLoading = false;
				} else {
					await this.$store.dispatch('cartTotal/updateQuantity', {
						product: product,
					});
				}

				this.isLoading = false;

				setTimeout(() => {
					this.$forceUpdate();
				}, 10);
			},

			async minus(product) {
				let count = await this.$store.dispatch('cartTotal/minusFromCart', {
					product: product,
				});

				this.selectedProduct.quantity = count;
				if (count <= 0) {
					this.removeFromTrolley(product);
					this.selectedProduct.quantity = 1.0;
				}

				setTimeout(() => {
					this.$forceUpdate();
				}, 10);
			},

			async initTrolley(item) {
				if (!this.currentAuthenticatedCustomer) {
					this.color = 'info';
					this.notificationText = 'Please Login As Customer To Order.';
					this.notify = true;
					return;
				}
				// console.log(item.quantity);
				// this.$store.commit("products/setSelectedProduct", item);
				if (item.quantity >= 0.1) {
					item.inCart = true;
				}
				if (item.inCart == true) {
					this.isLoading = false;
					await this.addToTrolley(item);
				}
			},

			async addToTrolley(product) {
				let count = await this.$store.dispatch('cartTotal/addToCart', {
					product: product,
				});

				if (count > 0) this.selectedProduct.quantity = count;

				this.$forceUpdate();
			},

			isMobile() {
				if (this.$vuetify.breakpoint.width < 800) {
					return true;
				}
			},

			async loadProduct() {
				let loaded = this.$store.getters['cartTotal/isCartLoaded'];
				this.isLoading = true;
				if (loaded) {
					const productId = this.$route.query.id;
					let res = await API.graphql({
						query: getProduct,
						variables: { productId: productId },
						authMode: this.authenticatedUser ? 'AMAZON_COGNITO_USER_POOLS' : 'API_KEY',
					});
					let product = res.data.getProduct;
					this.cart.forEach(item => {
						if (item.sk == product.sk) {
							product.inCart = true;
							product.quantity = item.quantity <= 0 ? 1 : item.quantity;
						}
					});

					this.selectedProduct = await product;
					if (this.selectedProduct.quantity == null || this.selectedProduct.quantity == ' ') {
						if (this.selectedProduct.productUnit == 'each') {
							this.selectedProduct.quantity = 1;
						} else if (this.selectedProduct.productUnit == 'kg') {
							this.selectedProduct.quantity = 1;
						} else {
							this.selectedProduct.quantity = 0;
						}
					}
					this.isLoading = false;
					document.title = this.selectedProduct.productName;
				} else {
					setTimeout(() => {
						this.loadProduct();
					}, 50);
				}
			},
		},

		computed: {
			currentAuthenticatedCustomer: {
				get() {
					return this.$store.getters['customers/authenticatedUser'];
				},
			},
			cartItemsCount: {
				get() {
					return this.$store.getters['cartTotal/cartItemsCount'];
				},
			},
			selectedCartitem: {
				get() {
					return this.$store.getters['cartItems/selectedCartItem'];
				},
			},
			products: {
				get() {
					return this.$store.getters['products/productList'];
				},
				set(val) {
					this.$store.dispatch('products/setProductList', val);
				},
			},
			cart: {
				get() {
					return this.$store.getters['cartTotal/cart'];
				},
			},
			quantity: {
				get() {
					return this.selectedProduct.quantity;
				},
				set(val) {
					this.selectedProduct.quantity = parseInt(val);
				},
			},
			breadcrumbItems: {
				get() {
					return this.$store.getters['breadcrumbs/items'];
				},
			},
		},

		watch: {
			currentAuthenticatedCustomer: function (val) {
				if (val) this.loadCustomPrice();
			},
			selectedProduct: function (product) {
				this.$store.commit('breadcrumbs/setSelectedCategory', {
					key: 'items',
					index: 1,
					value: product.categoryName,
				});
				this.$store.commit('breadcrumbs/setSelectedProduct', {
					key: 'items',
					index: 2,
					value: product.productName,
				});

				document.title = product.productName;
			},
			chosenItemKey: function (val) {
				return this.itemCount, val.quantity;
			},
			'selectedProduct.quantity': function (val) {
				if (val < 0 || isNaN(val)) {
					setTimeout(() => {
						this.selectedProduct.quantity = 0;
					}, 500);
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.ribbon {
		z-index: 2;
		--f: 5px; /* control the folded part*/
		--r: 15px; /* control the ribbon shape */
		--t: 3px; /* the top offset */

		position: absolute;
		inset: var(--t) calc(-1 * var(--f)) auto auto;
		padding: 0 10px var(--f) calc(10px + var(--r));
		clip-path: polygon(
			0 0,
			100% 0,
			100% calc(100% - var(--f)),
			calc(100% - var(--f)) 100%,
			calc(100% - var(--f)) calc(100% - var(--f)),
			0 calc(100% - var(--f)),
			var(--r) calc(50% - var(--f) / 2)
		);
		background: red;
		box-shadow: 0 calc(-1 * var(--f)) 0 inset #0005;
		color: white;
	}
	.ribbon-special {
		--f: 5px; /* control the folded part*/
		--r: 15px; /* control the ribbon shape */
		--t: 40px; /* the top offset */

		position: absolute;
		inset: var(--t) calc(-1 * var(--f)) auto auto;
		padding: 0 10px var(--f) calc(10px + var(--r));
		clip-path: polygon(
			0 0,
			100% 0,
			100% calc(100% - var(--f)),
			calc(100% - var(--f)) 100%,
			calc(100% - var(--f)) calc(100% - var(--f)),
			0 calc(100% - var(--f)),
			var(--r) calc(50% - var(--f) / 2)
		);
		background: #f57f17;
		box-shadow: 0 calc(-1 * var(--f)) 0 inset #0005;
		color: white;
		z-index: 9999;
	}
</style>
