import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
	options: {
		customProperties: true,
	},
	theme: {
		dark: false,
		themes: {
			light: {
				primary: '#4CAF50', //#4CAF50 //F8F5E9
				secondary: '#1B8600', //#1B8600 //C1BBAC
				accent: '#43A047',
				error: '#FF5252',
				info: '#2196F3',
				success: '#4CAF50',
				warning: '#FFC107',
				background: '#87CEEB',
			},
			dark: {
				// insert dark colours here
				primary: '#1B8600',
				secondary: '#3CB800',
				accent: '#2A9E00',
				error: '#FF5252',
				info: '#2196F3',
				success: '#4CAF50',
				warning: '#FFC107',
				background: '#87CEEB',
			},
		},
	},
});
