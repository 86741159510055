<template>
	<v-container>
		<v-row class="pt-8 pb-8 pl-2">
			<h1>
				<v-icon size="69px" color="secondary"> mdi-tune </v-icon>
				Settings
			</h1>
		</v-row>
		<v-card elevation="4">
			<v-toolbar flat color="primary" dark>
				<v-toolbar-title> My Account </v-toolbar-title>
			</v-toolbar>
			<v-tabs grow>
				<v-tab>
					<v-icon left> mdi-account </v-icon>
					Account Details
				</v-tab>
				<!-- <v-tab disabled>
          <v-icon left>
            mdi-lock
          </v-icon>
          Security
        </v-tab>
        <v-tab disabled>
          <v-icon left>
            mdi-access-point
          </v-icon>
          Option 3
        </v-tab> -->

				<v-tab-item>
					<v-card min-height="48vh" flat>
						<v-card-title>
							<strong style="text-decoration: underline"> My Details </strong>
							<!-- <v-spacer></v-spacer>
              <v-btn
                @click=" isEdit ? cancelAction() : isEdit = true"
                class="ml-4"
                small
                color="primary"
                large
              >
                {{ isEdit ? 'Cancel' : 'Edit' }}
              </v-btn> -->
						</v-card-title>
						<v-container>
							<v-form ref="form">
								<v-card :loading="isLoading" v-if="authenticatedUser" flat :disabled="!isEdit">
									<v-row>
										<v-col cols="12" lg="8" md="8" sm="8">
											<!-- Business Name -->
											<v-text-field
												v-model="authenticatedUser.companyName"
												label="Business Name"
												outlined
												dense
												:rules="rules"
											/> </v-col
									></v-row>
									<v-row>
										<v-col cols="12" lg="4" md="4" sm="4">
											<!-- First Name -->
											<v-text-field
												:value="authenticatedUser.firstName + ` ` + authenticatedUser.lastName"
												label="Contact Person"
												outlined
												dense
												:rules="rules"
											/>
										</v-col>
										<v-col cols="12" lg="4" md="4" sm="4">
											<!-- Mobile -->
											<v-text-field
												v-model="authenticatedUser.mobile"
												label="Phone Number"
												outlined
												dense
												v-on:keypress="numbersOnly()"
											/>
											<!-- Last Name -->
											<!-- <v-text-field
												v-model="authenticatedUser.lastName"
												label="Last Name"
												outlined
												dense
												:rules="rules"
											/> -->
										</v-col>
										<v-col cols="12" lg="8" md="8" sm="8">
											<!--  Email -->
											<v-text-field
												v-model="authenticatedUser.email"
												label="Email"
												outlined
												dense
												:rules="rules"
											/>
											<v-text-field
												v-if="authenticatedUser.reckonAttributes"
												:value="
													authenticatedUser.reckonAttributes.companyAddress.addr2 +
													` ` +
													authenticatedUser.reckonAttributes.companyAddress.addr3
												"
												label="Business Address"
												outlined
												dense
											/>
										</v-col>
									</v-row>
									<v-card-actions v-if="isEdit">
										<v-btn @click="cancelAction()" color="red" outlined> Cancel </v-btn>
										<v-btn color="secondary" @click="updateCustomer()"> Save </v-btn>
									</v-card-actions>
									<v-card-subtitle
										>Please contact Farms Chicken if any of your information has been
										updated.</v-card-subtitle
									>
								</v-card>
							</v-form>
						</v-container>
					</v-card>
				</v-tab-item>
				<v-tab-item>
					<v-card min-height="48vh" flat>
						<v-form lazy-validation refs="form" type="form">
							<v-card-title>
								<strong style="text-decoration: underline"> Change Password </strong>
							</v-card-title>
							<v-spacer></v-spacer>
							<v-card-actions>
								<v-row>
									<v-col cols="12" lg="4" md="4" sm="4">
										<!-- New password  -->
										<v-text-field
											label="New password"
											outlined
											dense
											append-icon="mdi-eye"
											@click:append="
												{
												}
											"
										/>
										<!-- Confirm password -->
										<v-text-field
											label="Confirm password"
											outlined
											dense
											append-icon="mdi-eye"
											@click:append="
												{
												}
											"
										/>
										<v-card-actions>
											<v-btn color="secondary"> Save </v-btn>
										</v-card-actions>
									</v-col>
								</v-row>
							</v-card-actions>
						</v-form>
					</v-card>
				</v-tab-item>
				<v-tab-item>
					<v-card min-height="48vh" flat>
						<v-card-text>
							<p>
								Fusce a quam. Phasellus nec sem in justo pellentesque facilisis. Nam eget dui. Proin
								viverra, ligula sit amet ultrices semper, ligula arcu tristique sapien, a accumsan
								nisi mauris ac eros. In dui magna, posuere eget, vestibulum et, tempor auctor,
								justo.
							</p>

							<p class="mb-0">
								Cras sagittis. Phasellus nec sem in justo pellentesque facilisis. Proin sapien
								ipsum, porta a, auctor quis, euismod ut, mi. Donec quam felis, ultricies nec,
								pellentesque eu, pretium quis, sem. Nam at tortor in tellus interdum sagittis.
							</p>
						</v-card-text>
					</v-card>
				</v-tab-item>
			</v-tabs>
		</v-card>

		<!-- notification  -->
		<v-snackbar v-model="notify" class="pt-16" top right :timeout="2500" :color="color">
			{{ notificationText }}
		</v-snackbar>
	</v-container>
</template>

<script>
	import { Auth, API } from 'aws-amplify';
	import { getCustomer } from '@/graphql/queries';
	import { updateCustomer } from '@/graphql/mutations';

	export default {
		methods: {
			numbersOnly(evt) {
				evt = evt ? evt : window.event;
				var charCode = evt.which ? evt.which : evt.keyCode;
				if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
					evt.preventDefault();
				} else {
					return true;
				}
			},
			async updateCustomer() {
				this.isLoading = true;
				let valid = this.$refs.form.validate();
				try {
					let customer = {
						sk: this.authenticatedUser.sk,
						companyName: this.authenticatedUser.companyName,
						firstName: this.authenticatedUser.firstName,
						lastName: this.authenticatedUser.lastName,
						email: this.authenticatedUser.email,
						mobile: this.authenticatedUser.mobile,
						priceLevel: this.authenticatedUser.priceLevel,
						customerLevel: this.authenticatedUser.customerLevel,
						reckonAttributes: this.authenticatedUser.reckonAttributes,
					};
					if (valid) {
						await API.graphql({
							query: updateCustomer,
							variables: {
								input: customer,
							},
							authMode: 'AMAZON_COGNITO_USER_POOLS',
						});
						this.notify = true;
						this.color = 'green darken-1';
						this.notificationText = 'Details Updated';
						this.isLoading = false;
						this.isEdit = false;
					} else {
						this.notify = true;
						this.color = 'red';
						this.notificationText = 'Error updating details';
						this.isLoading = false;
					}
				} catch (error) {
					console.log('Error: ', error);
				}
			},
			cancelAction() {
				this.getCurrentUser();
				this.isEdit = false;
			},
			async getCurrentUser() {
				let user = await Auth.currentAuthenticatedUser();
				this.userId = user.username;
				this.getCustomerDetails(this.userId);
			},
			async getCustomerDetails(user) {
				if (user) {
					let res = await API.graphql({
						query: getCustomer,
						variables: {
							customerId: user,
						},
						authMode: 'AMAZON_COGNITO_USER_POOLS',
					});
					let authenticatedUser = res.data.getCustomer;

					if (authenticatedUser.reckonAttributes == null) {
						authenticatedUser.reckonAttributes = {
							reckonId: '',
							editSequence: '',
							isActive: '',
							companyAddress: '',
						};
					}
					this.$store.commit('customers/setAuthenticatedUser', authenticatedUser);

					this.$forceUpdate();
				}
			},
			isMobile() {
				if (this.$vuetify.breakpoint.width < 800) {
					return true;
				}
			},
		},

		async mounted() {
			await Auth.currentAuthenticatedUser({
				bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
			})
				.then(user => {
					user;
				})
				.catch(err => {
					err;
					this.$router.push('/Login');
					return;
				});
			// await this.getCurrentUser();
		},

		computed: {
			authenticatedUser: {
				get() {
					return this.$store.getters['customers/authenticatedUser'];
				},
				set(val) {
					this.$store.commit('customers/resetSelectedCustomer', val);
				},
			},
		},

		data() {
			return {
				rules: [v => !!v || 'This field is required'],
				notify: false,
				notificationText: '',
				color: null,
				isLoading: false,
				isEdit: false,
				userId: ' ',
			};
		},
	};
</script>

<style></style>
