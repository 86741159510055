<template>
	<v-app>
		<NavbarComponent
			v-if="!['Login', 'Admin', '404', 'FirstTimeSignIn', '403'].includes($route.meta)"
		/>
		<AdminNavigationVue v-if="['Admin'].includes($route.meta)" />

		<v-main v-if="$route.meta != `Admin`">
			<div id="app">
				<v-scroll-x-transition leave-absolute>
					<router-view :key="$route.fullPath" />
				</v-scroll-x-transition>
			</div>
		</v-main>
		<v-main v-else>
			<div id="app">
				<router-view />
			</div>
		</v-main>
		<FooterComponent
			v-if="!['Login', 'Admin', '404', 'FirstTimeSignIn', '403'].includes($route.meta)"
		/>
	</v-app>
</template>

<script>
	import NavbarComponent from './components/NavbarComponent.vue';
	import FooterComponent from './components/FooterComponent.vue';
	import { Auth, API } from 'aws-amplify';
	import * as subscriptions from '@/graphql/subscriptions';
	import { getCustomer } from '@/graphql/queries';
	import AdminNavigationVue from './components/AdminViewComponents/AdminNavigation.vue';
	import axios from 'axios';

	export default {
		name: 'App',
		methods: {
			limitString(string) {
				if (string) {
					let limit = string.substr(0, 5);
					return limit;
				}
			},

			routeToLogin() {
				if (window.location.pathname != '/login') {
					this.$router.push('/login');
				}
			},
			async signOut() {
				try {
					this.$store.dispatch('cartTotal/clearCart');
					await Auth.signOut();
					this.routeToLogin();
				} catch (error) {
					console.log('error signing out: ', error);
				}
			},

			async watchCustomerUpdating() {
				//updating customer
				await API.graphql({
					query: subscriptions.updatedCustomer,
					authMode: 'AMAZON_COGNITO_USER_POOLS',
				}).subscribe({
					next: async ({ value }) => {
						// console.log("Subscription value: ", value)
						if (value) {
							let payload = await Auth.currentAuthenticatedUser();
							let user = payload.username;
							if (value.data.updatedCustomer.sk == user) {
								let isActive = value.data.updatedCustomer.reckonAttributes.isActive;
								if (isActive == false) {
									this.signOut();
									alert(
										"Sorry, your account has been disabled. Please contact Farm's Chicken for questions. "
									);
								}
							}
						}
					},
					error: error => console.warn(error),
				});
			},

			async watchSubscriptions() {
				const createNotification = API.graphql({
					query: subscriptions.createdNotification,
					authMode: 'AMAZON_COGNITO_USER_POOLS',
				}).subscribe({
					next: ({ value }) => {
						return value;
					},
					error: error => console.warn(error),
				});
				createNotification.unsubscribe();
			},

			async checkUserGroup() {
				try {
					let payload = await Auth.currentAuthenticatedUser();
					let user = payload.username;

					if (user) {
						let res = await API.graphql({
							query: getCustomer,
							variables: {
								customerId: user,
							},
							authMode: 'AMAZON_COGNITO_USER_POOLS',
						});

						let authenticatedUser = res.data.getCustomer;
						this.$store.commit('customers/setAuthenticatedUser', authenticatedUser);
					}
					let result = payload.signInUserSession.accessToken.payload;
					let cognitoGroup = result['cognito:groups'];
					if (cognitoGroup == undefined) {
						await Auth.signOut();
						this.$router.push('/login');
					} else if (cognitoGroup == 'admin') {
						this.watchSubscriptions();
						try {
							let isBackendConnected = await axios.get('https://api.farmschicken.co.nz/connection');
							console.log('CONNECTION: ', isBackendConnected.data);
						} catch (error) {
							console.log('CONNECTION: ', error.message);
						}

						// NOTHING AT the moment
						// let params = {
						//   UserPoolId: payload.pool.userPoolId,
						//   Username: user,
						// };

						// await Auth.adminGetUser(params)
						//   .then((res) => {
						//     console.log("Cognito Response: ", res);
						//   })
						//   .catch((err) => {
						//     console.log("Error: ", err);
						//   });
						return result;
					} else {
						this.watchCustomerUpdating();
						return;
					}
				} catch (err) {
					err;
					if (err == 'The user is not authenticated' && this.$route.name != 'Login') {
						console.log(err);
						// this.$router.push("/login");
					} else {
						// console.log(err);
						return;
					}
				}
			},
		},

		watch: {
			// cart: function (val) {
			//   console.log(val);
			// },
			isDark: function (val) {
				if (val == true) {
					document.body.style.backgroundColor = '#1B8600';
				} else {
					document.body.style.backgroundColor = '#4CAF50';
				}
			},
		},

		async mounted() {
			await this.checkUserGroup();
			this.isDark;
		},

		components: {
			NavbarComponent,
			FooterComponent,
			AdminNavigationVue,
		},

		data() {
			return {
				//
			};
		},

		computed: {
			isDark: {
				get() {
					return this.$vuetify.theme.dark;
				},
			},
		},
	};
</script>

<style lang="scss">
	@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&family=Poiret+One&family=Pompiere&display=swap');

	#app {
		font-family: 'Comfortaa', cursive;
		// font-family: "Pompiere", cursive;
		// font-family: "Poiret One", cursive;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		touch-action: manipulation;
	}

	nav {
		padding: 30px;

		a {
			font-weight: bold;
			color: #2c3e50;

			&.router-link-exact-active {
				color: #42b983;
			}
		}
	}

	body {
		background-color: #4caf50;
	}
</style>
