<template>
  <v-container>
    <h1> 404 Page not found </h1>
  </v-container>
</template>

<script>
export default {

}
</script>

<style>
</style>