<template>
	<div>
		<v-btn width="200" height="200" @click="variableDialog = true" color="secondary" dark>
			Setting Variables
		</v-btn>

		<v-dialog persistent width="50%" v-model="variableDialog">
			<v-card height="45vh">
				<v-form ref="form">
					<v-card-title>
						Freight <v-spacer></v-spacer>
						<v-icon @click="variableDialog = false"> mdi-close</v-icon></v-card-title
					>
					<v-card-actions>
						<v-text-field
							v-model="freightCharge"
							prepend-inner-icon="mdi-currency-usd"
							outlined
							label="Freight Charge (including gst)"
							class="ma-2"
							:rules="rules"
						></v-text-field>

						<v-text-field
							v-model="freightFreeOver"
							prepend-inner-icon="mdi-currency-usd"
							outlined
							label="Free Over (excluding gst)"
							class="ma-2"
							:rules="rules"
						></v-text-field>
					</v-card-actions>
					<v-card-title> Cutoff Time </v-card-title>
					<v-card-actions>
						<v-select
							v-model="cutoffTime"
							append-icon="mdi-clock"
							:items="timeList"
							outlined
							label="Cutoff Time"
							class="ma-2"
							:rules="rules"
						></v-select>
					</v-card-actions>
				</v-form>
				<v-card-actions class="justify-center">
					<v-btn width="200" color="primary" @click="updateSettingsVariables()"> SAVE </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import { getSettingsVariables } from '@/graphql/queries';
	import { API } from 'aws-amplify';
	import { updateSettingsVariable } from '@/graphql/mutations';

	export default {
		components: {},

		async mounted() {
			await this.getSettingsVariables();
		},

		methods: {
			async getSettingsVariables() {
				let res = await API.graphql({
					query: getSettingsVariables,
				});

				let data = res.data.getSettingsVariables;

				this.cutoffTime = data.cutoffTime;
				this.freightCharge = data.freightCharge;
				this.freightFreeOver = data.freightFreeOver;
			},

			async updateSettingsVariables() {
				let valid = this.$refs.form.validate();

				if (!valid) return;

				await API.graphql({
					query: updateSettingsVariable,
					variables: {
						input: {
							cutoffTime: this.cutoffTime,
							freightCharge: this.freightCharge,
							freightFreeOver: this.freightFreeOver,
						},
					},
				});

				this.variableDialog = false;
			},
		},

		watch: {},

		computed: {
			timeList() {
				let listOfTimes = [];
				for (let i = 0; i < 24; i++) {
					if (i.toString().length == 1) {
						listOfTimes.push('0' + i.toString() + ':00');
					} else {
						listOfTimes.push(i.toString() + ':00');
					}
				}
				return listOfTimes;
			},
		},

		data() {
			return {
				variableDialog: false,
				cutoffTime: '05:00',
				freightCharge: 0,
				freightFreeOver: 0,
				rules: [v => !!v || 'Required Field'],
			};
		},
	};
</script>

<style></style>
