var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"mb-16 pb-16"},[(!_vm.isLoading)?_c('v-card',{staticStyle:{"min-height":"500px"},attrs:{"elevation":"0"}},[_c('div',{staticClass:"pt-16"}),_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"lg":"4","md":"5","cols":"12"}},[_c('v-card',{staticClass:"justify-center d-flex",attrs:{"flat":"","outlined":""}},[_c('v-fade-transition',[(_vm.selectedProduct.inCart)?_c('div',{staticClass:"ribbon",staticStyle:{"background":"#1b8600"}},[_vm._v(" Added ")]):_vm._e()]),_c('v-scale-transition',[(!_vm.selectedProduct.isInStock)?_c('div',{staticClass:"ribbon"},[_vm._v("OUT OF STOCK")]):_vm._e()]),_c('v-img',{attrs:{"max-height":"350","max-width":"300","src":_vm.selectedProduct.imageUrl
										? _vm.selectedProduct.imageUrl
										: require(`@/assets/no-img.jpg`)}})],1)],1),_c('v-col',{class:_vm.$vuetify.breakpoint.width < 960 ? `text-center` : `text-left`,staticStyle:{"display":"inline-block","word-break":"break-word"},attrs:{"lg":"8","md":"7","cols":"12"}},[_c('strong',[_vm._v(_vm._s(_vm.selectedProduct.productName))]),_c('div',{staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(_vm.selectedProduct.productCode))]),_c('v-divider'),(_vm.currentAuthenticatedCustomer)?_c('div',{class:_vm.isMobile() ? `d-flex justify-center mt-4` : `d-flex mt-4`},[_c('h3',{style:(_vm.discountedPrice ? `text-decoration: line-through` : ``)},[_vm._v(" $ "+_vm._s(this.selectedProduct.defaultPrice ? parseFloat(this.selectedProduct.defaultPrice).toFixed(2) : `Price Missing!`)+" ")]),_c('v-chip',{staticClass:"mt-1 ml-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(this.selectedProduct.productUnit)+" ")])],1):_vm._e(),(_vm.discountedPrice)?_c('h3',[_vm._v(" $ "+_vm._s(parseFloat(this.discountedPrice).toFixed(2))+" "),_c('span',{staticStyle:{"color":"green","font-size":"12px"}},[_vm._v(" (discount)")])]):_vm._e(),_c('div',{staticClass:"d-flex justify-center pt-6",style:(_vm.$vuetify.breakpoint.width < 960 ? `width:204px; margin: auto` : `width:204px`)},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedProduct.inCart),expression:"selectedProduct.inCart"}],staticClass:"mr-1",attrs:{"outlined":"","height":"40","x-small":"","color":"secondary","loading":_vm.isLoading},on:{"click":function($event){return _vm.minus(_vm.selectedProduct)}}},[_c('v-icon',[_vm._v("mdi-minus")])],1),_c('v-text-field',{attrs:{"filled":"","outlined":"","dense":"","append-icon":_vm.selectedProduct.productUnit == 'kg' ? `mdi-weight-kilogram` : ``,"disabled":!_vm.selectedProduct.isInStock},on:{"change":function($event){return _vm.updateQuantityFromField(_vm.selectedProduct)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.selectedProduct.inCart
										? _vm.updateQuantityFromField(_vm.selectedProduct)
										: _vm.initTrolley(_vm.selectedProduct)},"keypress":_vm.numbersOnly},model:{value:(_vm.selectedProduct.quantity),callback:function ($$v) {_vm.$set(_vm.selectedProduct, "quantity", $$v)},expression:"selectedProduct.quantity"}}),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.selectedProduct.inCart),expression:"!selectedProduct.inCart"}],staticClass:"ml-1",attrs:{"outlined":"","height":"40","x-small":"","color":"secondary","disabled":!_vm.selectedProduct.isInStock},on:{"click":function($event){return _vm.initTrolley(_vm.selectedProduct)}}},[_vm._v(" Add ")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedProduct.inCart),expression:"selectedProduct.inCart"}],staticClass:"ml-1",attrs:{"outlined":"","height":"40","x-small":"","color":"secondary","loading":_vm.isLoading},on:{"click":function($event){return _vm.addToTrolley(_vm.selectedProduct)}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-divider'),_c('v-card-text',{style:(_vm.isMobile()
									? `display: inline-block; word-break: break-word;`
									: `margin-left:-15px;display: inline-block; word-break: break-word;`)},[_c('strong',{staticStyle:{"text-decoration":"underline"}},[_vm._v("Description")]),_c('br'),_vm._v(_vm._s(_vm.selectedProduct.description)+" ")])],1)],1)],1)],1):_c('div',{staticClass:"mt-12"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"70"}})],1),_c('v-snackbar',{staticClass:"pt-16",attrs:{"top":"","timeout":2500,"color":_vm.color},model:{value:(_vm.notify),callback:function ($$v) {_vm.notify=$$v},expression:"notify"}},[_vm._v(" "+_vm._s(_vm.notificationText)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }