import { API } from 'aws-amplify';
import { listCustomPricesByPriceLevel } from '@/graphql/queries';

export default {
	namespaced: true,
	state: {
		isLoading: true,
		nextTokenFilter: null,
		searchString: null,
		// categoryFilter: null,
		productList: [],
		// authorizedCustomPrices: { currentPriceLevel: null, customPriceList: [] },
		currentPriceLevel: null,
		customPriceList: [],
		selectedProduct: null,
		priceRule: v => {
			if (v) return true;
			if (!isNaN(parseFloat(v)) && v >= 0 && v <= 99999) return true;
			return 'Number has to be between 0 and 99999';
		},
		productGroups: [
			'Pork A',
			'Chicken C',
			'Lamb A',
			'Chicken B',
			'Beef B',
			'Lamb B',
			'Chicken A',
			'Duck',
			'Butcher',
			'Pork B',
			'Other',
			'Beef A',
			'Chicken D',
		],
	},
	mutations: {
		setIsLoading (state, payload) {
			state.isLoading = payload;
		},
		resetProductList (state) {
			state.productList = [];
		},
		setSearchString (state, payload) {
			state.searchString = payload;
		},
		setNextTokenFilter (state, payload) {
			state.nextTokenFilter = payload;
		},
		// setCategoryFilter(state, payload) {
		// 	state.categoryFilter = payload;
		// },

		addToProductList (state, payload) {
			state.productList = state.productList.concat(payload);
		},
		setSelectedProduct (state, payload) {
			let unit = payload.productUnit;
			if (unit == 'each') {
				payload.quantity = 1;
			} else if (unit == 'kg') {
				payload.quantity = 1.0;
			} else {
				payload.quantity = 1;
			}
			if (!payload.reckonProductAttributes) {
				payload.reckonProductAttributes = {};
			}

			state.selectedProduct = payload;
		},
		resetSelectedProduct (state) {
			state.selectedProduct = {
				categoryName: '',
				description: '',
				imageUrl: '',
				productCode: '',
				productName: '',
				productUnit: '',
				quantity: '',
				isEnabled: true,
				isInStock: true,
				defaultPrice: '',
				reckonProductAttributes: {},
			};
		},

		// setCustomPrices(state, payload) {
		// 	state.currentPriceLevel = payload.currentPriceLevel;
		// 	state.customPriceList = payload.customPriceList;
		// },
	},
	actions: {
		async loadCustomPrice({ rootGetters }, payload) {
			//TODO: Get Customer Price Level
			let customer = rootGetters['customers/authenticatedUser'];
			if (!customer) return;
			let customerPriceLevel = customer.priceLevel;

			//TODO: Get All Custom Prices From Price Level
			//TODO: If price level is null then return
			if (!customerPriceLevel) return;

			let res = await API.graphql({
				query: listCustomPricesByPriceLevel,
				variables: {
					priceLevel: customerPriceLevel,
				},
			});

			let customPriceList = res.data.listCustomPricesByPriceLevel.items;
			// console.log(payload);
			// console.log(res);

			payload.filter(prod =>
				customPriceList.some(cp => {
					if (prod.sk == cp.productId) {
						prod.newPrice = cp.customPrice;
					}
				})
			);
		},
		async setProductList ({ state, dispatch, rootGetters }, payload) {
			const sleep = async ms => {
				return new Promise(resolve => setTimeout(resolve, ms));
			};
			//Check if the cart finished loading.
			let loaded = rootGetters['cartTotal/isCartLoaded'];
			if (loaded) {
				await sleep(10);
				let cart = rootGetters['cartTotal/cart'];

				//TODO: init custom prices.
				await dispatch('loadCustomPrice', payload);

				// Syncs item quantity from trolley to the list
				payload.forEach(async product => {
					switch (product.productUnit) {
						case 'each':
							product.quantity = 1;
							break;

						case 'kg':
							product.quantity = 1.0;
							break;

						default:
							product.quantity = 1;
							break;
					}
					cart.forEach(item => {
						if (product.sk == item.sk) {
							product.inCart = true;
							product.quantity = item.quantity;
						}
					});
				});

				state.isLoading = false;
				state.productList = payload;
			} else {
				setTimeout(() => {
					dispatch('setProductList', payload);
				}, 20);
			}
		},
	},
	getters: {
		selectedProduct: state => state.selectedProduct,
		nextTokenFilter: state => state.nextTokenFilter,
		searchString: state => state.searchString,
		// categoryFilter: state => state.categoryFilter,
		productList: state => state.productList,
		priceRule: state => state.priceRule,
		productGroups: state => state.productGroups,
		isLoading: state => state.isLoading,
	},
};
