<template>
	<div>
		<v-container :class="!isMobile() ? `px-12 ml-6` : ``" fluid>
			<!-- <AdminNavigation /> -->
			<ConfirmDialog ref="confirm" />

			<v-card elevation="4" outlined>
				<v-card-title>
					<strong> Customers </strong>
					<v-spacer></v-spacer>
				</v-card-title>
				<v-data-table
					:headers="isMobile() ? mobileHeaders : headers"
					:items="customerList"
					:items-per-page="50"
					:loading="isLoading"
					:footer-props="{ 'items-per-page-options': [25, 40, 50, -1] }"
					class="elevation-1"
					mobile-breakpoint="0"
				>
					<template v-slot:top>
						<v-row class="pa-4 d-flex">
							<v-col cols="12" lg="9" md="9" sm="12" xs="12">
								<v-text-field
									v-model="searchText"
									label="Search Customers"
									append-icon="mdi-magnify"
									outlined
									dense
									@keyup="initSearch()"
								></v-text-field>
							</v-col>

							<v-col cols="12" lg="3" md="3" sm="12" xs="12">
								<v-btn @click="initCreateCustomer()" color="secondary" class="white--text" block>
									Add Customer
								</v-btn>
							</v-col>
						</v-row>
					</template>
					<template v-slot:[`item.name`]="{ item }">
						<td class="text-left">
							{{ item.firstName + ' ' + item.lastName }}
						</td>
					</template>

					<template v-slot:[`item.email`]="{ item }">
						<td @click="sendEmail(item)" class="text-left" style="cursor: pointer">
							{{ item.email }}
						</td>
					</template>

					<template v-slot:[`item.mobile`]="{ item }">
						<td @click="callMobile(item)" class="text-left" style="cursor: pointer">
							{{ item.mobile }}
						</td>
					</template>

					<template v-slot:[`item.reckonAttributes.isActive`]="{ item }">
						<td>
							<v-tooltip top :color="item.reckonAttributes.isActive == true ? 'primary' : 'red'">
								<template #activator="{ on, attrs }">
									<v-icon
										v-on="on"
										v-bind="attrs"
										:color="item.reckonAttributes.isActive == true ? 'primary' : 'red'"
									>
										mdi-account-check
									</v-icon>
								</template>
								<span>
									{{
										item.reckonAttributes.isActive == true
											? 'Customer enabled'
											: 'Customer disabled'
									}}
								</span>
							</v-tooltip>
						</td>
					</template>

					<template v-slot:[`item.actions`]="{ item, index }">
						<td>
							<v-icon color="primary" size="20" @click="initUpdateCustomer(item, index)"
								>mdi-pencil</v-icon
							>
						</td>
					</template>
				</v-data-table>
			</v-card>

			<!-- Add Customer Dialog -->
			<v-dialog
				v-model="customerDialog"
				v-if="selectedCustomer"
				persistent
				scrollable
				max-width="80%"
				transition="dialog-transition"
			>
				<v-card style="overflow: auto">
					<v-form ref="form" lazy-validation>
						<v-container>
							<v-card-title>
								<strong> {{ isAdd ? 'Add Customer' : 'Update Customer' }} </strong>
								<v-spacer></v-spacer>
								<!-- <v-icon
                v-if="!isAdd"
                @click="this.deleteDialog = true"
                color="red"
              > mdi-delete </v-icon> -->
								<v-icon large @click="cancelAction()"> mdi-close </v-icon>
							</v-card-title>
						</v-container>

						<v-container class="pa-6">
							<v-row class="ma-0" no-gutters>
								<v-col cols="12" lg="12" md="12" sm="12">
									<!-- Toggle enable/disable customer -->
									<v-row no-gutters>
										<v-tooltip bottom>
											<template #activator="{ on, attrs }">
												<v-icon v-on="on" v-bind="attrs"> mdi-information-outline </v-icon>
											</template>
											<span> Enable or Disable customer </span>
										</v-tooltip>
										<v-col cols="1">
											<v-switch
												v-model="selectedCustomer.reckonAttributes.isActive"
												:label="selectedCustomer.reckonAttributes.isActive ? `Enabled` : `Disabled`"
											/>
										</v-col>
									</v-row>
									<v-container>
										<p class="d-flex" style="text-size: 6px; color: #808080">
											Fields marked with [*] are required
										</p>

										<h4 class="text-left my-5">Basic Info</h4>
										<!-- Company Name -->
										<!-- <v-text-field
                      dense
                      outlined
                      label="Customer Name"
                      v-model="selectedCustomer.companyName"
                    /> -->
										<!--  Custtomer Code -->
										<v-text-field
											dense
											outlined
											label="Customer Name/Code"
											v-model="selectedCustomer.customerCode"
											:rules="nameRules"
										/>
										<!-- Name -->
										<v-row>
											<v-col cols="6">
												<v-text-field
													dense
													outlined
													label="First Name *"
													v-model="selectedCustomer.firstName"
													:rules="nameRules"
												/>
												<v-text-field
													dense
													outlined
													label="Last Name *"
													v-model="selectedCustomer.lastName"
													:rules="nameRules"
												/>
											</v-col>
											<v-col cols="6">
												<!-- Email -->
												<v-text-field
													dense
													outlined
													label="Email *"
													v-model="selectedCustomer.email"
													:rules="emailRules"
												/>
												<!-- Mobile -->
												<v-text-field
													dense
													outlined
													label="Mobile"
													v-model="selectedCustomer.mobile"
												/>
											</v-col>
										</v-row>

										<h4 class="text-left my-5">Billing Address</h4>
										<!-- Company Addresses -->
										<!-- style="cursor: not-allowed; pointer-events: none;" -->
										<v-text-field
											dense
											outlined
											label="Company Name"
											v-model="selectedCustomer.companyName"
										/>
										<v-row>
											<v-col cols="6">
												<v-text-field
													dense
													outlined
													label="Street Name"
													v-model="selectedCustomer.reckonAttributes.companyAddress.addr2"
												/>
												<v-text-field
													dense
													outlined
													label="Suburb"
													v-model="selectedCustomer.reckonAttributes.companyAddress.addr3"
												/>
											</v-col>
											<v-col cols="6">
												<v-text-field
													dense
													outlined
													label="City"
													v-model="selectedCustomer.reckonAttributes.companyAddress.city"
												/>
												<v-text-field
													dense
													outlined
													label="Contact Number"
													v-model="selectedCustomer.reckonAttributes.companyAddress.postalCode"
												/>
											</v-col>
										</v-row>

										<h4 class="text-left my-5">Additional Info</h4>
										<v-row>
											<v-col cols="6">
												<v-autocomplete
													v-model="selectedCustomer.priceLevel"
													:items="priceLevels"
													outlined
													label="Price Level (Optional)"
													item-text="name"
													item-value="name"
													dense
												></v-autocomplete>
											</v-col>
											<v-col cols="6">
												<!-- <v-text-field
                          v-model="selectedCustomer.customerLevel"
                          dense
                          outlined
                          label="Tax Reg ID / Customer Level *"
                          maxlength="10"
                          hint="Max character length is 10"
                        >
                        </v-text-field> -->
												<v-select
													v-model="selectedCustomer.customerLevel"
													:rules="nameRules"
													dense
													outlined
													label="Tax Reg ID / Customer Level *"
													:items="['S', 'A', 'B', 'C', 'D', 'E', 'N']"
													item-color="blue"
												></v-select>
											</v-col>
										</v-row>
									</v-container>
								</v-col>
							</v-row>
							<!-- <div :class="isMobile() ? `` : `ma-6`">
              <h4 class="text-left">More Customer Details</h4>
              <br>
              <v-row>
                <v-expansion-panels accordion>
                  <v-expansion-panel>
                    <v-expansion-panel-header>Reckon Settings</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-container>
                        <v-text-field
                          dense
                          outlined
                          label="Company Name"
                          v-model="selectedCustomer.companyName"
                        />
                        <v-text-field
                          dense
                          outlined
                          label="Company Address"
                          v-model="addr1"
                        />
                        <v-text-field
                          dense
                          outlined
                          label="First Name"
                          v-model="selectedCustomer.firstName"
                          :rules="nameRules"
                        />
                        <v-text-field
                          dense
                          outlined
                          label="Last Name"
                          v-model="selectedCustomer.lastName"
                          :rules="nameRules"
                        />
                        <v-text-field
                          dense
                          outlined
                          label="Email"
                          v-model="selectedCustomer.email"
                          :rules="emailRules"
                        />
                        <v-text-field
                    dense
                    outlined
                    label="Password"
                    v-model="password"
                  />
                        <v-text-field
                          dense
                          outlined
                          label="Mobile"
                          v-model="selectedCustomer.mobile"
                        />

                        <v-select
                    dense
                    outlined
                    label="Preffered Communication"
                    :items="preferredCommunication"
                  />
                      </v-container>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel>
                    <v-expansion-panel-header>Back Production System</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      Some content
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                </v-expansion-panels>
              </v-row>
            </div> -->
							<v-row class="pa-0 px-0 ma-0 mx-0" justify="end">
								<v-card-actions>
									<v-btn
										@click="cancelAction()"
										:class="isMobile() ? `mr-1` : `ml-6`"
										color="red"
										outlined
										:disabled="isLoading"
									>
										Cancel
									</v-btn>

									<!-- <v-btn
                    v-if="!isAdd"
                    @click="loadProducts()"
                    color="grey"
                    outlined
                    :disabled="isLoading"
                  >Set Product Notes</v-btn> -->

									<v-btn
										v-if="!isMobile()"
										@click="isAdd ? createCustomer() : updateCustomer()"
										class="mr-1"
										color="secondary"
										:loading="isLoading"
									>
										{{ isAdd ? `Create Customer` : `Update User` }}
									</v-btn>
									<v-btn
										v-else
										@click="isAdd ? createCustomer() : updateCustomer()"
										class="mr-1"
										color="secondary"
										:loading="isLoading"
									>
										{{ isAdd ? `Create` : `Update` }}
									</v-btn>
								</v-card-actions>
							</v-row>
						</v-container>
					</v-form>
				</v-card>
			</v-dialog>

			<!-- Delete Dialog -->
			<v-dialog
				v-model="deleteDialog"
				persistent
				style="position: aboslut; z-index: 999999"
				max-width="360"
			>
				<v-card>
					<v-card-title>
						Confirm Delete
						<v-spacer></v-spacer>
						<v-icon @click="cancelAction()"> mdi-close </v-icon>
					</v-card-title>
					<v-card-text style="font-weight: 900">
						Are you sure you want to delete this customer?
					</v-card-text>
					<v-spacer></v-spacer>
					<v-card-actions>
						<v-row justify="end" class="ma-0">
							<v-btn
								@click="deleteDialog = false"
								class="mr-2"
								color="red"
								outlined
								:disabled="isLoading"
							>
								Cancel
							</v-btn>
							<v-btn @click="deleteCustomer()" :loading="isLoading" color="primary">
								Confirm
							</v-btn>
						</v-row>
					</v-card-actions>
				</v-card>
			</v-dialog>

			<!-- notification  -->
			<v-snackbar v-model="notify" class="pt-16" top right :timeout="2500" :color="color">
				{{ notificationText }}
			</v-snackbar>

			<!--------- Dialog for product note ------------>
			<!-- <v-dialog
        v-model="priceLevelDialog"
        :width="isMobile() ? `100vw` : `70vw`"
        persistent
        :fullscreen="isMobile()"
      >
        <v-card
          :height="isMobile() ? `100vh` : `70vh`"
          style="overflow: hidden"
        >
          <div style=" z-index: 99; top: 0; width: 100%;">
            <v-card-title>Add Notes To Product<v-spacer></v-spacer>
              <v-btn
                text
                @click="priceLevelDialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>

            <v-card-actions>
              <v-text-field
                @keyup="initSearchProduct()"
                v-model="productSearchText"
                outlined
                label="Search Product"
                solo
              ></v-text-field>
            </v-card-actions>
          </div>

          <div
            style="overflow-y: scroll; max-height: 85%"
            class="pb-16"
          >
            <v-card
              v-for="(item, i) in productList"
              v-bind:key="i"
              elevation="0"
              style="border: 1px solid green; overflow: hidden"
              class="ma-4"
            >
              <v-row style="width: 100%">
                <v-col cols="3">
                  <v-card-text class="text-left">{{item.productName}}</v-card-text>
                </v-col>
                <v-col cols="9">
                  <div
                    class="mt-4"
                    style="min-height: 100px"
                  >
                    <v-textarea
                      v-if="item.notes"
                      v-model="item.notes"
                      outlined
                      label="Notes"
                      class="mr-4"
                    ><template v-slot:append-outer>
                        <div v-if="!item.productNoteLoading || item.productNoteLoading == false">
                          <v-icon
                            color="error"
                            @click="deleteNotes(item)"
                          >mdi-close</v-icon>
                          <v-icon
                            color="success"
                            @click="addNotes(item)"
                          >mdi-check</v-icon>
                        </div>
                        <div v-else>
                          <v-progress-circular
                            indeterminate
                            size="20"
                            width="2"
                            color="green"
                          ></v-progress-circular>
                        </div>
                      </template></v-textarea>
                    <v-btn
                      v-else
                      outlined
                      block
                      @click="checkNotes(item)"
                    >Load Notes</v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-card>
      </v-dialog> -->
		</v-container>
	</div>
</template>

<script>
	// import AdminNavigation from "../../components/AdminViewComponents/AdminNavigation.vue";
	import { Auth, API } from 'aws-amplify';
	import {
		createCustomer,
		updateCustomer,
		deleteCustomer,
		addCustomPrice,
		addProductNotes,
		deleteProductNotes,
	} from '../../graphql/mutations';
	import {
		listCustomers,
		filterProducts,
		filterCustomers,
		listPriceLevels,
		listCustomPricesByPriceLevel,
		getProductNotes,
	} from '../../graphql/queries';
	import * as subscriptions from '@/graphql/subscriptions';
	import axios from 'axios';
	import ConfirmDialog from '../../components/ConfirmDialog.vue';

	export default {
		beforeDestroy() {
			this.subscriptionsList.forEach(fnc => {
				fnc.unsubscribe();
			});
		},
		methods: {
			async deleteNotes(data) {
				data.productNoteLoading = true;
				this.$forceUpdate();
				let dialogResult = await this.$refs.confirm.open(
					'Confirm',
					'Are you sure you want to delete this product note?'
				);

				if (!dialogResult) {
					data.productNoteLoading = false;
					this.$forceUpdate();
					return;
				}

				let customerId = this.selectedCustomer.sk;
				let param = {
					pk: customerId,
					sk: data.sk,
					productId: data.sk,
					notes: data.notes.trim(),
					customerId: customerId,
				};
				await API.graphql({
					query: deleteProductNotes,
					variables: {
						input: param,
					},
					authMode: 'AMAZON_COGNITO_USER_POOLS',
				});

				data.notes = null;
				data.producNoteLoading = false;
				this.$forceUpdate();
			},
			async addNotes(data) {
				data.productNoteLoading = true;
				this.$forceUpdate();

				let customerId = this.selectedCustomer.sk;

				let param = {
					pk: customerId,
					sk: data.sk,
					productId: data.sk,
					notes: data.notes.trim(),
					customerId: customerId,
				};
				if (!param.notes || param.notes == '' || param.notes == ' ') {
					data.productNoteLoading = false;
					this.$forceUpdate();
					return;
				}

				await API.graphql({
					query: addProductNotes,
					variables: {
						input: param,
					},
					authMode: 'AMAZON_COGNITO_USER_POOLS',
				});
				data.productNoteLoading = false;
				this.$forceUpdate();
			},
			async checkNotes(data) {
				data.producNoteLoading = true;
				//check notes query
				const customerId = this.selectedCustomer.sk;
				const productId = data.sk;

				let res = await API.graphql({
					query: getProductNotes,
					variables: {
						customerId: customerId,
						productId: productId,
					},
					authMode: 'AMAZON_COGNITO_USER_POOLS',
				});
				if (res.data.getProductNotes == null || res.data.getProductNotes.notes == '') {
					data.notes = ' ';
				} else {
					data.notes = res.data.getProductNotes.notes;
				}

				data.producNoteLoading = false;
				this.$forceUpdate();
			},
			async callMobile(obj) {
				if (
					(await this.$refs.confirm.open('Confirm', 'Would you like to call this number?')) == true
				) {
					window.location.href = `tel:${obj}`;
				}
			},
			async sendEmail(obj) {
				if (
					(await this.$refs.confirm.open('Confirm', 'Would you like to send an email?')) == true
				) {
					window.location.href = `mailto:${obj.email}`;
				}
			},
			async filterCustomers() {
				let customersList = [];
				let nextToken = null;
				let res = await API.graphql({
					query: filterCustomers,
					variables: {
						searchString: this.searchText.toLowerCase(),
					},
					authMode: 'AMAZON_COGNITO_USER_POOLS',
				});

				nextToken = res.data.filterCustomers.nextToken;
				customersList = customersList.concat(res.data.filterCustomers.items);

				while (nextToken) {
					let res = await API.graphql({
						query: filterCustomers,
						variables: {
							searchText: this.searchText.toLowerCase(),
							nextToken: nextToken,
						},
						authMode: 'AMAZON_COGNITO_USER_POOLS',
					});

					nextToken = res.data.filterCustomers.nextToken;
					customersList = customersList.concat(res.data.filterCustomers.items);
				}

				customersList = customersList.sort((a, b) => {
					a.firstName > b.firstName ? 1 : b.firstName > a.firstName ? -1 : 0;
				});

				this.customerList = customersList;

				this.isLoading = false;
			},
			initSearch() {
				this.isLoading = true;
				if (this.timer) {
					clearTimeout(this.timer);
					this.timer = null;
				}
				this.timer = setTimeout(() => {
					this.filterCustomers();
				}, 300);
			},

			initSearchProduct() {
				if (this.timer) {
					clearTimeout(this.timer);
					this.timer = null;
				}
				this.timer = setTimeout(() => {
					this.loadProducts();
				}, 300);
			},

			numbersOnly(evt) {
				evt = evt ? evt : window.event;
				var charCode = evt.which ? evt.which : evt.keyCode;
				if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
					evt.preventDefault();
				} else {
					return true;
				}
			},

			watchSubscriptions() {
				//adding customer
				const addCustomer = API.graphql({
					query: subscriptions.createdCustomer,
					authMode: 'AMAZON_COGNITO_USER_POOLS',
				}).subscribe({
					next: ({ value }) => {
						if (value) {
							this.listCustomers();
						}
					},
					error: error => console.warn(error),
				});
				//updating customer
				const updateCustomer = API.graphql({
					query: subscriptions.updatedCustomer,
					authMode: 'AMAZON_COGNITO_USER_POOLS',
				}).subscribe({
					next: ({ value }) => {
						if (value) {
							this.listCustomers();
						}
					},
					error: error => console.warn(error),
				});
				//deleting customer
				const deleteCustomer = API.graphql({
					query: subscriptions.deletedCustomer,
					authMode: 'AMAZON_COGNITO_USER_POOLS',
				}).subscribe({
					next: ({ value }) => {
						if (value) {
							this.listCustomers();
						}
					},
					error: error => console.warn(error),
				});
				this.subscriptionsList.push(addCustomer);
				this.subscriptionsList.push(updateCustomer);
				this.subscriptionsList.push(deleteCustomer);
			},

			async addCustomPrice(item) {
				if (!item.customPrice) {
					this.color = 'error';
					this.notificationText = 'Please enter a custom price.';
					this.notify = true;
					return;
				}
				let data = {
					customerId: this.selectedCustomer.sk,
					productId: item.sk,
					customPrice: item.customPrice,
				};

				let res = await API.graphql({
					query: addCustomPrice,
					variables: {
						input: data,
					},
					authMode: 'AMAZON_COGNITO_USER_POOLS',
				});

				if (res.data.addCustomPrice) {
					this.color = 'success';
					this.notificationText = 'Custom Price Added';
					this.notify = true;
				}
			},

			async loadCustomPrices() {
				let res = await API.graphql({
					query: listCustomPricesByPriceLevel,
					variables: {
						priceLevel: this.selectedCustomer.priceLevel,
					},
					authMode: 'AMAZON_COGNITO_USER_POOLS',
				});
				this.customPriceList = res.data.listCustomPricesByPriceLevel.items;
			},

			async loadProducts() {
				this.priceLevelDialog = true;
				let nextToken = null;
				let res = await API.graphql({
					query: filterProducts,
					variables: {
						searchString: this.productSearchText,
						nextToken: nextToken,
					},
					authMode: 'AMAZON_COGNITO_USER_POOLS',
				});

				nextToken = res.data.filterProducts.nextToken;
				this.productList = res.data.filterProducts.items;

				while (nextToken && this.productList.length < 25) {
					let whileRes = await API.graphql({
						query: filterProducts,
						variables: {
							searchString: this.productSearchText,
							nextToken: nextToken,
						},
						authMode: 'AMAZON_COGNITO_USER_POOLS',
					});

					nextToken = whileRes.data.filterProducts.nextToken;
					this.productList = this.productList.concat(whileRes.data.filterProducts.items);
				}

				if (this.productList.length > 25) {
					this.productList.length = 25;
				}
				this.priceLevelDialog = true;
			},

			async deleteCustomer() {
				this.isLoading = true;
				let customer = this.selectedCustomer;
				if (customer) {
					await API.graphql({
						query: deleteCustomer,
						sk: customer.sk,
						authMode: 'AMAZON_COGNITO_USER_POOLS',
					}).then(res => {
						this.isLoading = false;
						this.deleteDialog = false;
						this.customerDialog = false;
						this.color = 'primary';
						this.notificationText = 'Customer deleted.';
						this.notify = true;
						return res;
					});
				} else {
					this.color = 'error';
					this.notificationText = 'Error deleting customer.';
					this.notify = true;
				}
			},

			initDeleteCustomer() {
				this.deleteDialog = true;
			},

			async updateCustomer() {
				try {
					let isValid = this.$refs.form.validate();
					if (isValid) {
						this.isLoading = true;
						let auth = await Auth.currentAuthenticatedUser();
						let headers = {
							headers: {
								authorization: 'Bearer ' + auth.signInUserSession.accessToken.jwtToken,
							},
						};

						// Update Reckon
						let reckonUpdate = await axios.patch(
							this.$store.getters['global/endpoint'] + '/api/reckon/customer/update',
							this.selectedCustomer,
							headers
						);

						let reckonAttr = reckonUpdate.data;

						let customerData = {
							sk: this.selectedCustomer.sk,
							firstName: this.selectedCustomer.firstName,
							lastName: this.selectedCustomer.lastName,
							email: this.selectedCustomer.email,
							mobile: this.selectedCustomer.mobile,
							customerCode: this.selectedCustomer.customerCode,
							companyName: this.selectedCustomer.companyName,
							priceLevel: this.selectedCustomer.priceLevel,
							reckonAttributes: {
								editSequence: reckonAttr.editSequence, //edit sequence
								reckonId: reckonAttr.reckonId,
								isActive: this.selectedCustomer.reckonAttributes.isActive,
								companyAddress: {
									addr1: this.selectedCustomer.companyName,
									addr2: this.selectedCustomer.reckonAttributes.companyAddress.addr2,
									addr3: this.selectedCustomer.reckonAttributes.companyAddress.addr3,
									city: this.selectedCustomer.reckonAttributes.companyAddress.city,
									postalCode: this.selectedCustomer.reckonAttributes.companyAddress.postalCode,
								},
							},
							customerLevel: this.selectedCustomer.customerLevel,
						};

						// console.log(customerData);
						// Update GraphQL
						if (reckonAttr) {
							await API.graphql({
								query: updateCustomer,
								variables: {
									input: customerData,
								},
								authMode: 'AMAZON_COGNITO_USER_POOLS',
							}).then(res => {
								// console.log(res);
								res;
							});
						}

						// Update SQL data
						let axiosUpdate = await axios.patch(
							this.$store.getters['global/endpoint'] + '/api/production/customer/update',
							customerData,
							headers
						);
						axiosUpdate;
						// console.log(axiosUpdate);

						this.isLoading = false;
						this.customerDialog = false;
						this.color = 'primary';
						this.notificationText = 'Customer updated successfully.';
						this.notify = true;
					} else {
						this.color = 'red';
						this.notificationText = 'Error updating customer';
						this.notify = true;
					}
				} catch (error) {
					this.isLoading = false;
					this.color = 'error';
					if (error.errors[0].errorType == 'AliasExistsException') {
						this.notificationText = 'Error updating customer. Email already exists.';
					} else {
						this.notificationText = 'There was an error updating customer. Try again later.';
					}
					console.error(error);
					this.notify = true;
				}
			},

			cancelAction() {
				this.isLoading = false;
				this.customerDialog = false;
			},

			initCreateCustomer() {
				this.isAdd = true;
				this.$store.commit('customers/resetSelectedCustomer');
				this.customerDialog = true;
			},

			async listPriceLevels() {
				let res = await API.graphql({
					query: listPriceLevels,
					authMode: 'AMAZON_COGNITO_USER_POOLS',
				});

				this.priceLevels = res.data.listPriceLevels.items;
			},
			initUpdateCustomer(val) {
				if (this.selectedCustomer && val.sk != this.selectedCustomer.sk) {
					this.productList = [];
					this.customPriceList = [];
				}
				this.isAdd = false;
				this.$store.commit('customers/setSelectedCustomer', val);
				this.customerDialog = true;
				this.$forceUpdate();
			},

			async listCustomers() {
				this.customerList = [];
				let nextToken = null;

				let res = await API.graphql({
					query: listCustomers,
					authMode: 'AMAZON_COGNITO_USER_POOLS',
				});

				let customerList = res.data.listCustomers.items;
				this.customerList = customerList;
				nextToken = res.data.listCustomers.nextToken;

				while (nextToken) {
					let res2 = await API.graphql({
						query: listCustomers,
						authMode: 'AMAZON_COGNITO_USER_POOLS',
						variables: {
							nextToken: nextToken,
						},
					});

					this.customersList = this.customersList.concat(res2.data.listCustomers.items);

					nextToken = res2.data.listCustomers.nextToken;
				}
			},

			async checkUser() {
				await Auth.currentAuthenticatedUser({
					bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
				})
					.then(user => {
						if (user.signInUserSession.accessToken.payload['cognito:groups'] == 'customers') {
							this.$router.push('/');
						}
					})
					.catch(err => {
						if (err == 'The user is not authenticated') {
							this.$router.push('/');
						}
					});
			},

			async createCustomer() {
				let valid = this.$refs.form.validate();
				if (valid) {
					this.isLoading = true;
					try {
						let auth = await Auth.currentAuthenticatedUser();
						let headers = {
							headers: {
								authorization: 'Bearer ' + auth.signInUserSession.accessToken.jwtToken,
							},
						};

						// console.log(this.selectedCustomer);
						// let arr = this.file

						// for (let k = 0; arr.length > k; k++) {

						// for (let i = 0; arr.length > i; i++) {
						// let selectedCustomer = {
						//   firstName: "First",
						//   lastName: "Last",
						//   email: `${arr[i].Name.replace(/\s/g, '')}@email.com`,
						//   mobile: "",
						//   customerCode: arr[i].Code,
						//   companyName: arr[i].Code ? arr[i].Code + "xyz" : arr[i].Name.replace(/\s/g, '') + "xyz",
						//   priceLevel: "",
						//   reckonAttributes: {
						//     editSequence: "", //edit sequence
						//     reckonId: "", //list id
						//     isActive: true,
						//     companyAddress: {
						//       addr1: "",
						//     },
						//   },
						//   customerLevel: arr[i].level,
						// }

						//create user in RECKON
						let reckonCreateCustomer = await axios.post(
							this.$store.getters['global/endpoint'] + '/api/reckon/customer/create',
							this.selectedCustomer, //selectedCustomer
							headers
						);

						let reckonAttr = reckonCreateCustomer.data;
						// let customerData = {
						//   firstName: "First",
						//   lastName: "Last",
						//   email: `${arr[i].Name.replace(/\s/g, '')}@email.com`,
						//   mobile: "",
						//   customerCode: arr[i].Code,
						//   companyName: arr[i].Name,
						//   priceLevel: "",
						//   reckonAttributes: {
						//     editSequence: reckonAttr.editSequence, //edit sequence
						//     reckonId: reckonAttr.listId, //list id
						//     isActive: true,
						//     companyAddress: {
						//       addr1: "",
						//     },
						//   },
						//   customerLevel: arr[i].level,
						// };

						/**
           *firstName: this.selectedCustomer.firstName,
            lastName: this.selectedCustomer.lastName,
            email: this.selectedCustomer.email,
            mobile: this.selectedCustomer.mobile,
            companyName: this.selectedCustomer.companyName,
            priceLevel: this.selectedCustomer.priceLevel,
            reckonAttributes: {
              editSequence: reckonAttr.editSequence, //edit sequence
              reckonId: reckonAttr.listId, //list id
              isActive: this.selectedCustomer.reckonAttributes.isActive,
              companyAddress: {
                addr1: this.selectedCustomer.reckonAttributes.companyAddress.addr1,
              },
            },
            customerLevel: this.selectedCustomer.customerLevel,
           */

						let customerData = {
							firstName: this.selectedCustomer.firstName,
							lastName: this.selectedCustomer.lastName,
							email: this.selectedCustomer.email,
							mobile: this.selectedCustomer.mobile,
							customerCode: this.selectedCustomer.customerCode,
							customerLevel: this.selectedCustomer.customerLevel,
							companyName: this.selectedCustomer.companyName,
							priceLevel: this.selectedCustomer.priceLevel,
							reckonAttributes: {
								editSequence: reckonAttr.editSequence, //edit sequence
								reckonId: reckonAttr.listId, //list id
								isActive: this.selectedCustomer.reckonAttributes.isActive,
								companyAddress: {
									addr1: this.selectedCustomer.companyName,
									addr2: this.selectedCustomer.reckonAttributes.companyAddress.addr2,
									addr3: this.selectedCustomer.reckonAttributes.companyAddress.addr3,
									city: this.selectedCustomer.reckonAttributes.companyAddress.city,
									postalCode: this.selectedCustomer.reckonAttributes.companyAddress.postalCode,
								},
							},
						};

						// console.log('CUSTOMER DATA: ', customerData);

						// Create user in DynamoDB
						let dynamoRes = await API.graphql({
							query: createCustomer,
							variables: {
								input: customerData,
							},
							authMode: 'AMAZON_COGNITO_USER_POOLS',
						});

						// create customer in SQL
						// console.log('DYNAMO: ', dynamoRes);
						if (dynamoRes) {
							let customerData = {
								sk: dynamoRes.data.createCustomer.sk,
								firstName: dynamoRes.data.createCustomer.firstName,
								lastName: dynamoRes.data.createCustomer.lastName,
								email: dynamoRes.data.createCustomer.email,
								mobile: dynamoRes.data.createCustomer.mobile,
								customerCode: dynamoRes.data.createCustomer.customerCode,
								customerLevel: this.selectedCustomer.customerLevel,
								companyName: dynamoRes.data.createCustomer.companyName,
								priceLevel: dynamoRes.data.createCustomer.priceLevel,
								reckonAttributes: {
									editSequence: reckonAttr.editSequence, //edit sequence
									reckonId: reckonAttr.listId, //list id
									isActive: dynamoRes.data.createCustomer.reckonAttributes.isActive,
									companyAddress: {
										addr1: this.selectedCustomer.companyName,
										addr2: this.selectedCustomer.reckonAttributes.companyAddress.addr2,
										addr3: this.selectedCustomer.reckonAttributes.companyAddress.addr3,
										city: this.selectedCustomer.reckonAttributes.companyAddress.city,
										postalCode: this.selectedCustomer.reckonAttributes.companyAddress.postalCode,
									},
								},
							};

							let result = await axios.post(
								this.$store.getters['global/endpoint'] + '/api/production/customer/create',
								customerData,
								headers
							);
							result;
							// console.log("SQL Result: ", result);
						}
						if (this.selectedCustomer) {
							this.color = 'primary';
							this.notificationText = 'Customer created successfully.';
							this.notify = true;
							this.customerEmail = '';
							this.listCustomers();
							this.customerDialog = false;
						} else {
							this.color = 'red';
							this.notificationText =
								'There was an unknown issue creating customer. Please try again.';
							this.notify = true;
						}
						// }
						// }

						// console.log("FROM AXIOS:", result);
					} catch (error) {
						// console.log(error)
						if (error.errors && error.errors[0].errorType == 'InvalidParameterException') {
							this.color = 'red';
							this.notificationText = 'Invalid email address';
							this.notify = true;
						} else if (error.errors && error.errors[0].errorType == 'UsernameExistsException') {
							this.color = 'red';
							this.notificationText =
								'This email already exists, please try a different email address';
							this.notify = true;
						} else if (
							error.response &&
							error.response.data.includes('Customer Address Too Long')
						) {
							this.color = 'red';
							this.notificationText = error.response.data;
							this.notify = true;
							console.log('error signing up:', error);
						} else {
							console.log(error);
						}
					} finally {
						this.isLoading = false;
					}
				} else {
					this.color = 'orange';
					this.notificationText = 'Please fill in the required fields';
					this.notify = true;
				}
			},

			isMobile() {
				if (this.$vuetify.breakpoint.width < 800) {
					return true;
				}
			},
		},

		watch: {
			selectedCustomer: function (val) {
				if (val) {
					val.reckonAttributes.companyAddress.addr1 = val.companyName;
				}
			},
		},

		async mounted() {
			await this.checkUser();
			await this.listCustomers();
			this.watchSubscriptions();
			this.listPriceLevels();
		},

		components: {
			// AdminNavigation,
			ConfirmDialog,
		},

		computed: {
			priceRule: {
				get() {
					return this.$store.getters['products/priceRule'];
				},
			},
			selectedCustomer: {
				get() {
					return this.$store.getters['customers/selectedCustomer'];
				},
			},
		},

		data() {
			return {
				subscriptionsList: [],
				productNoteLoading: false,
				productSearchText: '',
				productNote: '',
				addr1: '',
				searchText: '',
				priceLevels: [],
				customPriceList: [],
				productList: [],
				priceLevelDialog: false,
				notificationText: '',
				color: null,
				notify: false,
				updateCustomerList: null,
				deleteDialog: false,
				isAdd: null,
				isLoading: false,
				customerEmail: '',
				isAdmin: false,
				customerDialog: false,
				actions: [{ title: 'Edit' }, { title: 'Delete' }],
				preferredCommunication: ['SMS', 'Email', 'Both'],
				headers: [
					{ text: 'Company', value: 'customerCode', sortable: true },
					{
						text: 'Customer Name',
						align: 'start',
						value: 'name',
					},
					{ text: 'Email', value: 'email' },
					{
						text: 'Mobile',
						sortable: false,
						value: 'mobile',
					},
					{
						text: 'Status',
						sortable: false,
						value: 'reckonAttributes.isActive',
					},
					{
						text: 'Actions',
						value: 'actions',
						sortable: false,
					},
				],
				mobileHeaders: [
					{ text: 'Company', value: 'companyName', sortable: true },
					{
						text: 'Actions',
						value: 'actions',
						sortable: false,
					},
				],
				customerList: [],
				emailRules: [
					v => !!v || 'E-mail is required',
					v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid',
				],
				nameRules: [v => !!v || 'Name is required'],
				// file: [
				//   {
				//     "Code": 93,
				//     "Orders": "Orders",
				//     "Name": "93 On Main",
				//     "Contact": "",
				//     "Phone": "",
				//     "Active": "",
				//     "Priority": "Normal",
				//     "level": "A"
				//   },
				//   {
				//     "Code": 101,
				//     "Orders": "Orders",
				//     "Name": "Chopsticks 101",
				//     "Contact": "",
				//     "Phone": "",
				//     "Active": "",
				//     "Priority": "Normal",
				//     "level": "S"
				//   },
				//   {
				//     "Code": 310,
				//     "Orders": "Orders",
				//     "Name": "The Three Ten Foodstop",
				//     "Contact": "",
				//     "Phone": "",
				//     "Active": "",
				//     "Priority": "Normal",
				//     "level": "A"
				//   },
				//   {
				//     "Code": "1040(I)",
				//     "Orders": "Orders",
				//     "Name": "1040 Takeaway",
				//     "Contact": "",
				//     "Phone": "",
				//     "Active": "",
				//     "Priority": "Normal",
				//     "level": "B"
				//   },
				//   {
				//     "Code": "3ESariSari",
				//     "Orders": "Orders",
				//     "Name": "3E SarSari Mobile Store",
				//     "Contact": "",
				//     "Phone": "",
				//     "Active": "",
				//     "Priority": "Normal",
				//     "level": "E"
				//   }
				// ]
			};
		},
	};
</script>

<style>
	.v-autocomplete.v-select--is-menu-active .v-input__icon--append .v-icon {
		transform: none;
	}
</style>
