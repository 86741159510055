<template>
	<!--           -->
	<v-footer v-if="!checkRoute()" color="primary" dark>
		<v-row justify="center" class="my-4">
			<v-col cols="12" lg="4" md="4" sm="4">
				<strong class="pa-4"> <v-icon small> mdi-map-marker </v-icon> Location </strong>
				<div class="pb-2">
					<a
						style="text-decoration: none; color: white"
						target="_"
						href="https://www.google.com/maps/place/14+Hands+Road,+Middleton,+Christchurch+8024/@-43.5431926,172.5913108,17z/data=!3m1!4b1!4m5!3m4!1s0x6d318a9ac435a69b:0x311cff297ccbfca!8m2!3d-43.5431965!4d172.5935048"
						>14 Hands Road Middleton, Christchurch
					</a>
				</div>
				<br />
				<strong class="pa-4"> <v-icon small> mdi-email </v-icon> Email </strong>
				<div class="pb-2">
					<a
						style="text-decoration: none; color: white"
						:href="`mailto:${'info@farmschicken.co.nz'}`"
					>
						info@farmschicken.co.nz
					</a>
				</div>
				<br />

				<strong class="pa-4"> <v-icon small> mdi-phone </v-icon> Phone </strong>
				<div class="pb-2">
					<a style="text-decoration: none; color: white" :href="`tel:${'033480086'}`">
						(03) 3480086
					</a>
				</div>
			</v-col>

			<v-col cols="12" lg="4" md="4" sm="4">
				<v-container class="d-flex justify-center">
					<v-img src="https://www.farmschicken.co.nz/img/logo.png" max-width="165px"></v-img>
				</v-container>
			</v-col>

			<v-col cols="12" lg="4" md="4" sm="4">
				<strong class="pa-4"> Get in touch </strong>
				<div class="pb-2 pt-2">
					<a style="text-decoration: none; color: white" href="/About"> About </a>
				</div>
				<div class="pb-2">
					<a style="text-decoration: none; color: white" href="/Contact"> Contact us </a>
				</div>

				<v-container>
					<v-divider></v-divider>
				</v-container>

				<strong class="pa-4"> Connect With Us </strong>
				<div class="pb-2">
					<a target="_" href="https://www.facebook.com/Farmschicken">
						<v-icon> mdi-facebook </v-icon>
					</a>
				</div>

				<div>
					<v-icon @click="switchMode()">{{
						mode ? `mdi-weather-sunny` : `mdi-weather-night`
					}}</v-icon>
				</div>
			</v-col>

			<!-- <v-col
          class="mt-4"
          cols="12"
        >Powered by <a
            target="_"
            style="text-decoration: none; color: white;"
            href="https://www.canit.co.nz/"
          > CanIT Limited </a></v-col> -->
		</v-row>
	</v-footer>

	<!-- Old one
<v-footer
    v-if="!checkRoute()"
    padless
  >
    <v-card
      flat
      tile
      height="35vh"
      width="100%"
      color="#3CB800"
    >
    
      <v-img
        absolute
        style="top: -80px; left: 25px"
        max-height="150"
        max-width="150"
        src="https://www.farmschicken.co.nz/img/logo.png"
        alt="logo"
      />
    </v-card>
  </v-footer>

   -->
</template>

<script>
	export default {
		mounted() {
			let nightMode = localStorage.getItem('nightMode');

			if (nightMode) {
				this.mode = nightMode == 'false' ? false : true;
			}
		},
		computed: {
			mode: {
				get() {
					return this.$vuetify.theme.dark;
				},

				set(val) {
					this.$vuetify.theme.dark = val;
				},
			},
		},
		methods: {
			isMobile() {
				if (this.$vuetify.breakpoint.width < 800) {
					return true;
				}
			},
			switchMode() {
				this.mode = !this.mode;
				localStorage.setItem('nightMode', this.mode);
			},
			checkRoute() {
				this.$route.name == '404';
			},
		},
		data() {
			return {
				//
			};
		},
	};
</script>

<style>
	.divider {
		border-left: 2px solid green;
		height: auto;
	}
</style>
