export default {
	namespaced: true,
	state: {
		selectedOrder: null,
		selectedProduct: null,
		selectedProductsList: [],
	},
	mutations: {
		setSelectedOrder(state, payload) {
			state.selectedOrder = payload;
		},
		resetSelectedOrder(state) {
			state.selectedOrder = {
				sk: '',
				pk: '',
				products: [],
				customer: null,
				customerId: null,
				invoiceNumber: null,
				isInvoiceCreated: null,
				orderDate: null,
				orderId: null,
				orderStatus: 'PENDING',
				totalCost: null,
				line: null,
				freight: 0,
				isDelivery: true,
			};
		},
	},
	actions: {
		//
	},
	getters: {
		selectedOrder: state => state.selectedOrder,
	},
};
