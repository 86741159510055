<template>
	<v-dialog
		v-model="dialog"
		:max-width="options.width"
		:style="{ zIndex: options.zIndex }"
		@keydown.esc="cancel"
		persistent
	>
		<v-card dense flat :color="options.color" class="pa-4">
			<div class="pa-4">
				<h4>{{ title }}</h4>
			</div>
			<h5>{{ message }}</h5>
			<v-card-actions class="pa-2">
				<v-row v-if="options.okBtn" justify="center" class="ma-0 pa-2">
					<v-btn @click.native="cancel" class="mr-2" color="primary"> OK </v-btn>
				</v-row>
				<v-row v-else justify="center" class="ma-0 pa-2">
					<v-btn v-if="!options.noconfirm" @click.native="cancel" class="mr-2" outlined color="red">
						{{ options.yesNoBtn ? `No` : `Cancel` }}
					</v-btn>
					<v-btn @click.native="agree" style="color: white" color="green darken-2">
						{{ options.yesNoBtn ? `Yes` : `Confirm` }}
					</v-btn>
				</v-row>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	export default {
		name: 'ConfirmDlg',
		data() {
			return {
				dialog: false,
				resolve: null,
				reject: null,
				message: null,
				title: null,
				options: {
					// color: 'white',
					width: 400,
					zIndex: 200,
					noconfirm: false,
					yesNoBtn: false,
					okBtn: false,
				},
			};
		},

		methods: {
			open(title, message, options) {
				this.dialog = true;
				this.title = title;
				this.message = message;
				this.options = Object.assign(this.options, options);

				return new Promise((resolve, reject) => {
					this.resolve = resolve;
					this.reject = reject;
				});
			},
			agree() {
				this.resolve(true);
				this.dialog = false;
				this.reset();
			},
			cancel() {
				this.resolve(false);
				this.dialog = false;
				this.reset();
			},
			reset() {
				setTimeout(() => {
					this.options = {
						// color: 'dark',
						width: 400,
						zIndex: 200,
						noconfirm: false,
						yesNoBtn: false,
						okBtn: false,
					};
				}, 50);
			},
		},
	};
</script>
