<template>
	<v-container>
		<!-----Search bar --->
		<v-menu
			v-model="searchMenu"
			transition="fade-transition"
			:close-on-content-click="false"
			max-height="550px"
			:max-width="searchWidth - 2"
			:min-width="searchWidth - 2"
			:position-x="searchX"
			:position-y="isMobile() ? searchY + 15 : searchY + 73"
			absolute
			attach="#productSearchBar"
		>
			<v-card width="100%">
				<v-container>
					<div
						v-for="(item, i) in limitedSearchResult"
						v-bind:key="i"
						style="max-height: 100%; cursor: pointer"
						@click="productClicked(item)"
					>
						<v-hover v-slot="{ hover }">
							<v-card-text
								:style="hover ? `background-color: #7DCEA0` : ``"
								class="transition-swing text-left"
							>
								<v-row>
									<v-col cols="2">
										<v-avatar class="mr-6" size="34" tile>
											<img
												:src="item.imageUrl ? item.imageUrl : require(`@/assets/no-img.jpg`)"
												:alt="item.productName"
											/>
										</v-avatar>
									</v-col>
									<v-col>
										{{ searchTermHighlight(item.productName) }}
									</v-col>
								</v-row>
								<v-chip
									v-if="item.isInStock == false"
									class="ml-2"
									color="red"
									x-small
									style="color: white"
								>
									Out Of Stock
								</v-chip>
							</v-card-text>
						</v-hover>

						<v-divider v-if="i != limitedSearchResult.length - 1"></v-divider>
					</div>
				</v-container>
				<v-card-text v-if="limitedSearchResult.length <= 0 && isLoading == false">
					No product found for "<span style="font-weight: 900">{{ searchString }}</span
					>"
				</v-card-text>
				<v-card @click="limitedSearchResult.length <= 0 ? null : showAllSearchResults()">
					<v-card-text v-show="limitedSearchResult.length >= 1">
						<b> View All Results </b>
						<v-icon>mdi-chevron-right</v-icon>
					</v-card-text>
				</v-card>
			</v-card>
		</v-menu>

		<div ref="searchBar" id="productSearchBar">
			<v-text-field
				style="border-radius: 0px"
				v-model="searchString"
				class="bar pt-8"
				width="100%"
				color="warning"
				solo
				dense
				placeholder="Search for items e.g chicken breast.."
				append-icon="mdi-magnify"
				:loading="isLoading"
				@focus="checkContent"
				@keyup="initSearchBar"
			>
			</v-text-field>
		</div>

		<!-----Search bar end --->
	</v-container>
</template>

<script>
	import { API } from 'aws-amplify';
	import { searchProductsForCustomers } from '@/graphql/queries';

	export default {
		mounted() {
			//
		},

		computed: {
			authenticatedUser: {
				get() {
					return this.$store.getters['customers/authenticatedUser'];
				},
			},
			searchString: {
				get() {
					return this.$store.getters['products/searchString'];
				},
				set(val) {
					this.$store.commit('products/setSearchString', val);
				},
			},
			products: {
				get() {
					return this.$store.getters['products/productList'];
				},
				set(val) {
					this.$store.commit('products/setProductList', val);
				},
			},
		},
		data: () => ({
			searchUrl: '',
			interval: null,
			nextToken: null,
			searchWidth: 500,
			searchX: 0,
			searchY: 0,
			searchMenu: false,
			isLoading: false,
			limitedSearchResult: [],
		}),

		watch: {
			searchString: function (val) {
				if (!val || val == '') {
					this.searchMenu = false;
				}
			},
		},
		methods: {
			showAllSearchResults() {
				this.searchMenu = false;
				this.searchUrl = `/order?search=${this.searchString}`;
				if (this.$route.fullPath == this.searchUrl) return;
				this.$store.commit('products/resetProductList');
				this.$store.commit('categories/setSelectedCategoryFilter', 'All');
				this.$store.commit('products/setNextTokenFilter', null);
				this.$router.push(this.searchUrl);
			},
			async productClicked(item) {
				if (!this.$route.fullPath.includes(item.sk)) {
					this.$router.push(`/order/product?id=${item.sk}&name=${item.productName}`);
					this.searchMenu = false;
					this.isLoading = false;
				}
				// this.$router.go();
			},
			searchTermHighlight(productName) {
				// const regex = `/${this.searchString}/i`;
				// console.log(regex);
				// console.log(productName);
				// return productName.replace(
				//   regex,
				//   `<strong>${this.searchString}</strong>`
				// );
				return productName;
			},
			checkContent() {
				if (this.searchString != '' && this.searchString != null) {
					this.initSearchBar();
					// this.searchMenu = true;
				}
			},
			isMobile() {
				if (this.$vuetify.breakpoint.width < 800) {
					return true;
				}
			},
			initSearchBar() {
				this.searchWidth = this.$refs.searchBar.getBoundingClientRect().width;
				this.searchY = this.$refs.searchBar.getBoundingClientRect().y;
				this.searchX = this.$refs.searchBar.getBoundingClientRect().x;

				this.isLoading = true;

				if (this.timer) {
					clearTimeout(this.timer);
					this.timer = null;
				}
				this.timer = setTimeout(() => {
					this.searchProducts();
				}, 300);
			},
			closeSearchBar() {
				// this.isLoading = false;
				this.searchMenu = false;
			},

			async searchProducts() {
				this.limitedSearchResult = [];
				if (!this.searchString || this.searchString == '' || this.searchString.length < 3) {
					this.isLoading = false;
					return;
				}
				this.isLoading = true;
				let res = await API.graphql({
					query: searchProductsForCustomers,
					variables: {
						nextToken: this.nextToken,
						searchString: this.searchString ? this.searchString.toLowerCase() : null,
					},
					authMode: this.authenticatedUser ? 'AMAZON_COGNITO_USER_POOLS' : 'API_KEY',
				});

				let enabledProducts = [];
				let results = await res.data.searchProductsForCustomers.items;
				for (let i = 0; i < results.length; i++) {
					if (
						(results[i].isEnabled == null || results[i].isEnabled == true) &&
						enabledProducts.length < 5
					) {
						enabledProducts.push(results[i]);
					}
				}
				this.nextToken = res.data.searchProductsForCustomers.nextToken;
				this.limitedSearchResult = this.limitedSearchResult.concat(enabledProducts);

				while (this.limitedSearchResult.length < 5 && this.nextToken != null) {
					// console.log("searching agian");
					let limitedRes = await API.graphql({
						query: searchProductsForCustomers,
						variables: {
							nextToken: this.nextToken,
							searchString: this.searchString ? this.searchString.toLowerCase() : null,
						},
						authMode: this.authenticatedUser ? 'AMAZON_COGNITO_USER_POOLS' : 'API_KEY',
					});
					let limtedResults = [];
					let limitedResult = await limitedRes.data.searchProductsForCustomers.items;
					for (let j = 0; j < limitedResult.length; j++) {
						if (limitedResult[j].isEnabled == null || limitedResult[j].isEnabled == true) {
							limtedResults.push(limitedResult[j]);
						}
					}

					this.nextToken = limitedRes.data.searchProductsForCustomers.nextToken;
					this.limitedSearchResult = this.limitedSearchResult.concat(limtedResults);

					// console.log(res);
					// console.log(this.nextToken);
				}

				// if (this.limitedSearchResult.length > 0) {
				this.searchMenu = true;
				// }

				this.isLoading = false;
				//   this.products = res.data.searchProductsForCustomers.items;
			},
		},
	};
</script>
