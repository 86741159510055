import Vue from 'vue';
import VueRouter from 'vue-router';
import HomePage from '../views/HomePage.vue';
import LoginPage from '../views/LoginPage.vue';
import AdminPage from '../views/AdminViews/AdminPage.vue';
import NotFoundPage from '../views/404Page.vue';
import CustomerProducts from '../views/CustomerViews/CustomerProducts.vue';
import CartPage from '../views/CustomerViews/CartPage.vue';
import AboutPage from '../views/CustomerViews/AboutPage.vue';
import ContactPage from '../views/CustomerViews/ContactPage.vue';
import SingleProductPage from '../views/CustomerViews/SingleProduct.vue';
import CustomerProfile from '../views/CustomerViews/CustomerProfile.vue';
import OrderHistoryPage from '../views/CustomerViews/OrderHistory.vue';
import CustomersTab from '../views/AdminViews/CustomersTab.vue';
import OrdersTab from '../views/AdminViews/OrdersTab.vue';
import ProductsTab from '../views/AdminViews/ProductsTab.vue';
import Oopsie from '../views/403Page.vue';
import CategoriesTab from '../views/AdminViews/CategoriesTab.vue';
import SingleOrder from '../views/AdminViews/SingleOrder.vue';
import PriceLevels from '../views/AdminViews/PriceLevels.vue';
import ScheduledOrdersPage from '../views/AdminViews/ScheduledOrdersPage.vue';
import ScheduledOrder from '../views/AdminViews/ScheduledOrder.vue';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Home',
		component: HomePage,
	},
	{
		path: '/Login',
		name: 'Login',
		component: LoginPage,
		meta: 'Login',
	},
	{
		path: '/Admin',
		name: 'Admin',
		component: AdminPage,
		meta: 'Admin',
	},
	{
		path: '/404',
		name: '404 Not Found',
		component: NotFoundPage,
	},
	{
		path: '/Order',
		name: 'Browse Products',
		component: CustomerProducts,
		meta: 'Auth',
	},
	{
		path: '/Cart',
		name: 'Cart',
		component: CartPage,
		meta: 'Auth',
	},
	{
		path: '/About',
		name: 'About',
		component: AboutPage,
	},
	{
		path: '/Contact',
		name: 'Contact',
		component: ContactPage,
	},
	{
		path: '/Order/:product',
		name: 'Single Product',
		component: SingleProductPage,
		meta: 'Auth',
	},
	{
		path: '/Profile',
		name: 'Profile',
		component: CustomerProfile,
		meta: 'Auth',
	},
	{
		path: '/myorders',
		name: 'Order History',
		component: OrderHistoryPage,
		meta: 'Auth',
	},
	{
		path: '/admin/customers',
		name: 'Customers',
		component: CustomersTab,
		meta: 'Admin',
	},
	{
		path: '/admin/orders',
		name: 'Orders',
		component: OrdersTab,
		meta: 'Admin',
	},
	{
		path: '/admin/products',
		name: 'Products',
		component: ProductsTab,
		meta: 'Admin',
	},
	{
		path: '/admin/categories',
		name: 'Categories',
		component: CategoriesTab,
		meta: 'Admin',
	},
	{
		path: '/403',
		name: 'Error 403 - Forbidden',
		component: Oopsie,
	},
	{
		path: '/admin/order/:id',
		name: 'Order',
		component: SingleOrder,
		meta: 'Admin',
	},
	{
		path: '/admin/pricelevels',
		name: 'PriceLevels',
		component: PriceLevels,
		meta: 'Admin',
	},
	{
		path: '*',
		redirect: '/',
	},
	{
		path: '/admin/scheduled-orders',
		name: 'Scheduled Orders',
		component: ScheduledOrdersPage,
		meta: 'Admin',
	},
	{
		path: '/admin/scheduled-order/:id',
		name: 'Scheduled Order',
		component: ScheduledOrder,
		meta: 'Admin',
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior() {
		document.getElementById('app').scrollIntoView({
			behavior: 'smooth',
		});
	},
});

const DEFAULT_TITLE = `404 Not Found`;
router.afterEach(to => {
	Vue.nextTick(() => {
		document.title = to.name + ` | Farm's Chicken` || DEFAULT_TITLE + ` | Farm's Chicken`;
	});
});
export default router;
