import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import '@aws-amplify/ui-components';
import { applyPolyfills, defineCustomElements } from '@aws-amplify/ui-components/loader';

Amplify.configure(awsconfig);

Vue.config.productionTip = false;

Vue.config.ignoredElements = [/amplify-\w*/];

applyPolyfills().then(() => {
	defineCustomElements(window);
});

new Vue({
	vuetify,
	router,
	store,
	render: h => h(App),
}).$mount('#app');
