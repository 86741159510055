<template>
	<div>
		<ConfirmDialog ref="confirm" />
		<v-app-bar elevate-on-scroll fixed app color="secondary">
			<v-app-bar-nav-icon @click.stop="drawer = !drawer" color="white" v-if="isMobile()" />
			<h3 :class="!isMobile() ? `white--text ml-14` : `white--text`">Admin Dashboard</h3>
			<v-spacer></v-spacer>

			<!-- Notification Card -->
			<v-snackbar
				v-model="notificationCard"
				class="included pt-12"
				top
				right
				color="green darken-2"
				:timeout="notificationTimeout"
				width="1000px"
			>
				<v-card-title class="ma-0 pa-0 ml-4">
					<h3>Notification Center ({{ notificationsList.length }})</h3>
					<v-spacer class="mr-2"></v-spacer>
					<v-card-actions>
						<v-btn text @click="notificationCard = false"> Close </v-btn>
					</v-card-actions>
				</v-card-title>
				<v-card
					id="style-2"
					flat
					color="green darken-2"
					max-height="75vh"
					style="overflow-y: scroll"
					width="100%"
				>
					<v-divider></v-divider>
					<v-list
						v-for="(notification, i) in notificationsList"
						:key="i"
						subheader
						multiple
						color="green darken-2"
						class="included overflow-auto"
						max-height="55vh"
						min-width="50px"
						max-width="1000px"
					>
						<v-list-item
							@click="
								{
								}
							"
						>
							<v-list-item-content style="max-width: 100%; overflow-wrap: break-word">
								<v-list-item-title
									@click="routeToNotification(notification)"
									style="max-width: 80%; overflow-wrap: break-word"
								>
									{{ notification.message }}
								</v-list-item-title>
								<br />
								<v-list-item-subtitle
									@click="routeToNotification(notification)"
									style="max-width: 80%"
								>
									{{ notification.user }}
								</v-list-item-subtitle>
							</v-list-item-content>
							<v-list-item-action style="max-width: 50px">
								<v-list-item-action-text
									v-text="displayDate(notification.notificationTime)"
								></v-list-item-action-text>
								<v-icon @click="deleteNotification(notification)" class="included mb-6" small>
									mdi-close-circle
								</v-icon>
							</v-list-item-action>
						</v-list-item>
					</v-list>
					<v-divider></v-divider>
				</v-card>
				<v-container v-if="notificationsList.length <= 0">
					<v-row justify="center">
						<span class="ma-4"> No new notifications </span>
					</v-row>
				</v-container>
				<v-card-actions v-if="notificationsList.length >= 1" class="justify-center">
					<v-btn @click="deleteNotification()" class="mt-4" text> Clear Notifications </v-btn>
				</v-card-actions>
			</v-snackbar>
			<!-- Account Picture -->
			<v-menu offset-y>
				<template #activator="{ on, attrs }">
					<v-icon color="white" size="42px" v-on="on" v-bind="attrs"> mdi-account-circle </v-icon>
				</template>
				<v-list dense>
					<v-list-item href="/">
						<v-list-item-title>Back To Website</v-list-item-title>
					</v-list-item>
					<v-container>
						<v-divider />
					</v-container>
					<v-list-item @click="settingsDialog = true">
						<v-list-item-title>Settings</v-list-item-title>
					</v-list-item>
					<v-list-item @click="signOut()">
						<v-list-item-title>Logout</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
			<!-- Notifications -->
			<v-badge
				v-if="notificationsList.length >= 1"
				color="error"
				dot
				style="z-index: 1"
				offset-x="-17"
				offset-y="-5"
			/>
			<v-icon
				v-click-outside="{
					handler: onClickOutside,
					include: include,
				}"
				@click="(models.base = true), openNotification()"
				size="35px"
				class="ml-3"
				color="white"
			>
				mdi-bell
			</v-icon>
		</v-app-bar>
		<!-- Settings Dialog -->
		<v-dialog v-model="settingsDialog" max-width="600px" persistent>
			<v-card
				height="100%"
				v-if="!myAccount && !otherAdmins && !resetPass"
				style="overflow: hidden; margin: auto"
			>
				<div class="text-right" style="position: absolute; width: 100%">
					<v-icon @click="switchMode()">{{
						mode ? `mdi-weather-sunny` : `mdi-weather-night`
					}}</v-icon>

					<v-icon class="ma-4" @click="settingsDialog = false">mdi-close-circle</v-icon>
				</div>
				<v-row class="mt-16 mb-16">
					<v-col lg="6" md="6" cols="12">
						<v-btn
							width="200"
							height="200"
							@click="
								() => {
									myAccount = true;
									otherAdmins = false;
									newAdmin = false;
								}
							"
							color="secondary"
							dark
						>
							My Account
						</v-btn>
					</v-col>
					<v-col lg="6" md="6" cols="12">
						<v-btn
							width="200"
							height="200"
							@click="
								() => {
									myAccount = false;
									otherAdmins = true;
									newAdmin = false;
									listAdmins();
								}
							"
							color="secondary"
						>
							Other Admins
						</v-btn>
					</v-col>
					<v-col lg="6" md="6" cols="12"><VariablesWindow /></v-col>
					<v-col lg="6" md="6" cols="12">
						<v-btn width="200" height="200" color="secondary" @click="initCustomerPasswordReset()">
							Reset Customer <br />Password.
						</v-btn>
					</v-col>
				</v-row>
			</v-card>
			<v-card v-else-if="myAccount == true">
				<v-card-title>
					{{ settingsTitle }}
					<v-spacer></v-spacer>
					<v-icon
						@click="
							() => {
								myAccount = false;
								otherAdmins = false;
								resetPass = false;
							}
						"
					>
						mdi-arrow-left-circle
					</v-icon>
				</v-card-title>
				<v-container class="pa-6">
					<v-form ref="form">
						<!-- first name -->
						<v-row no-gutters>
							<v-col cols="12" lg="2" md="2" sm="2">
								<p class="mt-2">First Name</p>
							</v-col>
							<v-col cols="12" lg="10" md="10" sm="10">
								<v-text-field
									v-model="selectedAdmin.firstName"
									dense
									outlined
									:loading="isLoading"
									:disabled="isLoading"
								/>
							</v-col>
						</v-row>
						<!-- last name -->
						<v-row no-gutters>
							<v-col cols="12" lg="2" md="2" sm="2">
								<p class="mt-2">Last Name</p>
							</v-col>
							<v-col cols="12" lg="10" md="10" sm="10">
								<v-text-field
									v-model="selectedAdmin.lastName"
									dense
									outlined
									:loading="isLoading"
									:disabled="isLoading"
								/>
							</v-col>
						</v-row>

						<!-- email -->
						<v-row no-gutters>
							<v-col cols="12" lg="2" md="2" sm="2">
								<p class="mt-2">Email</p>
							</v-col>
							<v-col cols="12" lg="10" md="10" sm="10">
								<v-text-field
									dense
									outlined
									v-model="selectedAdmin.email"
									:rules="emailRules"
									:loading="isLoading"
									:disabled="isLoading"
								/>
							</v-col>
						</v-row>
						<v-row v-if="myAccount == true && otherAdmins == false && newAdmin == false" no-gutters>
							<v-col cols="12" lg="2" md="2" sm="2">
								<v-btn
									@click="
										() => {
											this.resetPass = true;
											this.myAccount = false;
											this.otherAdmins = false;
										}
									"
									text
									color="secondary"
									small
								>
									Change Password
								</v-btn>
							</v-col>
						</v-row>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="secondary" @click="checkSettingsButton()" :loading="isLoading">
								<v-icon small class="mr-2"> mdi-pencil </v-icon>
								{{ adminSettingsButton }}
							</v-btn>
						</v-card-actions>
					</v-form>
				</v-container>
			</v-card>
			<v-card v-else-if="otherAdmins == true" height="100%" style="overflow: hidden">
				<v-card-title>
					Other Admins
					<v-spacer></v-spacer>
					<v-icon
						@click="
							() => {
								otherAdmins = false;
							}
						"
					>
						mdi-arrow-left-circle
					</v-icon>
				</v-card-title>
				<v-row class="pt-12 pb-12 mr-4 ml-4">
					<v-btn class="ma-2" @click="addNewAdmin()">Add new admin</v-btn><br />
					<!-- <v-btn class="ma-2">Change an admin password</v-btn><br>
<v-btn class="ma-2">Remove admin</v-btn> -->
					<v-data-table
						:headers="headers"
						:items="adminList"
						:items-per-page="50"
						item-key="id"
						class="elevation-1"
						:loading="isLoading"
						:footer-props="{ 'items-per-page-options': [50, 100, 150, -1] }"
						loading-text="Loading admin list..."
						disable-sort
					>
						<!-- <template v-slot:[`item.name`]="{item}">
{{item.firstName + " " + item.lastName}}
</template>
<template v-slot:[`item.actions`]="{item}">
<v-tooltip bottom>
<template v-slot:activator="{ on, attrs }">
<v-icon
@click="resetAdmin(item)"
v-bind="attrs"
v-on="on"
class="mr-3"
>mdi-lock-reset</v-icon>
</template>
<span>Reset password</span>
</v-tooltip> -->

						<template v-slot:[`item.name`]="{ item }">
							{{ item.firstName + ' ' + item.lastName }}
						</template>
						<template v-slot:[`item.actions`]="{ item }">
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-icon
										v-if="!isLoading"
										@click="removeAdmin(item)"
										v-bind="attrs"
										v-on="on"
										class="mr-3"
										>mdi-close</v-icon
									>

									<v-progress-circular v-else indeterminate color="primary"></v-progress-circular>
								</template>
								<span>Remove admin</span>
							</v-tooltip>
						</template>
					</v-data-table>
				</v-row>
			</v-card>
			<!-- TEST -->
			<v-card height="100%" v-if="resetPass" style="overflow: hidden; margin: auto">
				<v-card-title>
					Change Password
					<v-spacer></v-spacer>
					<v-icon class="ma-4" @click="resetPass = false">mdi-arrow-left-circle</v-icon>
				</v-card-title>

				<v-container>
					<v-row>
						<v-col cols="12" lg="12" md="12" sm="12">
							<v-text-field
								v-model="oldPassword"
								outlined
								dense
								:type="viewPass ? `text` : `password`"
								:append-icon="viewPass ? `mdi-eye-off` : `mdi-eye`"
								@click:append="() => (viewPass = !viewPass)"
								label="Old password"
							/>
							<v-text-field
								v-model="newPassword"
								outlined
								dense
								:type="viewPass ? `text` : `password`"
								label="New password"
							/>
						</v-col>
					</v-row>
				</v-container>

				<v-card-actions class="d-flex flex-row-reverse">
					<v-btn color="secondary" @click="resetAdminPass()"> Confirm New Password </v-btn>
				</v-card-actions>
			</v-card>
			<!-- TEST -->
		</v-dialog>

		<!-- Navigation drawer -->
		<v-navigation-drawer
			v-model="drawer"
			style="z-index: 5; position: fixed; overflow-y: auto"
			:absolute="!isMobile()"
			:permanent="!isMobile()"
			:expand-on-hover="!isMobile()"
		>
			<v-list>
				<v-list-item @click="routeToHome()" class="px-2">
					<v-list-item-avatar>
						<v-img src="https://www.farmschicken.co.nz/img/logo.png"></v-img>
					</v-list-item-avatar>
					<v-list-item-title>Farm's Chicken</v-list-item-title>
				</v-list-item>
			</v-list>
			<v-divider class="ml-2 mr-2"></v-divider>
			<v-list nav dense>
				<v-list-item @click="routeToOrders()" :disabled="$route.name == 'Orders'" link>
					<v-list-item-icon>
						<v-icon :color="checkRoute('Orders') ? `#4CAF50` : ``">mdi-clipboard-text</v-icon>
					</v-list-item-icon>
					<v-list-item-title>Orders</v-list-item-title>
				</v-list-item>

				<v-list-item
					@click="routeToScheduledOrders()"
					:disabled="$route.name == 'Scheduled Orders'"
					link
				>
					<v-list-item-icon>
						<v-icon :color="checkRoute('Scheduled Orders') ? `#4CAF50` : ``"
							>mdi-calendar-clock</v-icon
						>
					</v-list-item-icon>
					<v-list-item-title>Recurring Orders</v-list-item-title>
				</v-list-item>

				<v-list-item @click="routeToProducts()" :disabled="$route.name == 'Products'" link>
					<v-list-item-icon>
						<v-icon :color="checkRoute('Products') ? `#4CAF50` : ``">mdi-cart</v-icon>
					</v-list-item-icon>
					<v-list-item-title>Products</v-list-item-title>
				</v-list-item>

				<v-list-item @click="routeTo('Categories')" :disabled="$route.name == 'Categories'" link>
					<v-list-item-icon>
						<v-icon :color="checkRoute('Categories') ? `#4CAF50` : ``">mdi-apps</v-icon>
					</v-list-item-icon>
					<v-list-item-title>Categories</v-list-item-title>
				</v-list-item>

				<v-list-item @click="routeToCustomers()" :disabled="$route.name == 'Customers'" link>
					<v-list-item-icon>
						<v-icon :color="checkRoute('Customers') ? `#4CAF50` : ``">mdi-account-multiple</v-icon>
					</v-list-item-icon>
					<v-list-item-title>Customers</v-list-item-title>
				</v-list-item>
				<v-list-item @click="routeTo('PriceLevels')" :disabled="$route.name == 'PriceLevels'" link>
					<v-list-item-icon>
						<v-icon :color="checkRoute('PriceLevels') ? `#4CAF50` : ``">mdi-cash-multiple</v-icon>
					</v-list-item-icon>
					<v-list-item-title>Price Levels</v-list-item-title>
				</v-list-item>
			</v-list>
			<v-list style="bottom: 0px; position: absolute">
				<v-list-item>
					<v-list-item-icon>
						<v-icon>mdi-bug-stop-outline</v-icon>
					</v-list-item-icon>
					<v-list-item-title>v 1.2.0</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-navigation-drawer>
		<!-- notification  -->
		<v-snackbar v-model="notify" class="pt-16" top :timeout="2500" :color="color">
			{{ notificationText }}
		</v-snackbar>

		<!-- Customer Password Reset Dialog -->
		<v-dialog v-model="customerPasswordResetDialog" width="640px" persistent>
			<v-card>
				<v-form ref="customerResetPasswordForm">
					<v-card-title>
						Reset A Customer Password
						<v-spacer></v-spacer>
						<v-icon @click="customerPasswordResetDialog = false"> mdi-close</v-icon>
					</v-card-title>
					<v-container>
						<v-autocomplete
							dense
							outlined
							:rules="[v => !!v || 'Required']"
							label="Select Customer"
							v-model="selectedCustomer"
							:items="customerList"
							item-text="companyName"
							item-value="email"
							:loading="isLoading"
						></v-autocomplete>
						<v-text-field
							v-model="resetPasswordString"
							dense
							outlined
							label="Set Password"
							:rules="passwordRules"
							append-outer-icon="mdi-compost"
							@click:append-outer="randomizePassword"
							:loading="isLoading"
						></v-text-field>
						<v-btn :loading="isLoading" color="secondary" @click="resetCustomerPassword()"
							>Reset Password</v-btn
						>
					</v-container></v-form
				>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import { API, Auth } from 'aws-amplify';
	import { listAdmins, listCustomers, listNotifications } from '@/graphql/queries';
	import {
		createAdmin,
		removeAdmin,
		deleteNotification,
		resendTempPassword,
		sendContactEmail,
	} from '../../graphql/mutations';
	import moment from 'moment';
	import * as subscriptions from '@/graphql/subscriptions';
	import ConfirmDialog from '../ConfirmDialog.vue';
	import VariablesWindow from '../AdminViewComponents/SettingVariables.vue';

	export default {
		components: {
			ConfirmDialog,
			VariablesWindow,
		},
		methods: {
			resetAdminPass() {
				Auth.currentAuthenticatedUser()
					.then(user => {
						return Auth.changePassword(user, this.oldPassword, this.newPassword);
					})
					.then(() => {
						this.notificationText = 'Password changed successfully';
						this.color = 'secondary';
						this.notify = true;
						this.resetPass = false;
					})
					.catch(err => {
						if (err.name == 'NotAuthorizedException') {
							this.notificationText = 'Old password is incorrect';
							this.color = 'orange';
							this.notify = true;
						} else {
							console.log(err);
						}
					});
			},
			routeToNotification(notification) {
				if (notification.notificationValueSk) {
					if (this.$route.params.id && this.$route.params.id == notification.notificationValueSk)
						return;
					this.$router.push(`/admin/order/${notification.notificationValueSk}`);
				}

				this.notificationCard = false;
			},
			include() {
				return [document.querySelector('.included')];
			},
			async deleteNotification(val) {
				if (val == undefined) {
					let notificationListCopy = await JSON.parse(JSON.stringify(this.notificationsList));
					this.notificationsList = [];
					for (let i = 0; i < notificationListCopy.length; i++) {
						await API.graphql({
							query: deleteNotification,
							variables: {
								sk: notificationListCopy[i].sk,
							},
							authMode: 'AMAZON_COGNITO_USER_POOLS',
						});
					}
				} else {
					await API.graphql({
						query: deleteNotification,
						variables: {
							sk: val.sk,
						},
						authMode: 'AMAZON_COGNITO_USER_POOLS',
					});
				}
				this.listNotifications();
			},
			watchSubscriptions() {
				if (this.initNotification) {
					console.log('Subscription already running');
					return;
				}

				this.initNotification = API.graphql({
					query: subscriptions.createdNotification,
					authMode: 'AMAZON_COGNITO_USER_POOLS',
				}).subscribe({
					next: async ({ value }) => {
						if (value) {
							if (this.notificationsList.length >= 30) {
								let toBeDeleted = this.notificationsList.splice(29);

								for (let i = 0; i < toBeDeleted.length; i++) {
									await API.graphql({
										query: deleteNotification,
										variables: {
											sk: toBeDeleted[i].sk,
										},
										authMode: 'AMAZON_COGNITO_USER_POOLS',
									});
								}
							}
							this.listNotifications();
						}
						// return () => this.initNotification.unsubscribe();
					},
					error: error => console.log(error),
				});
			},
			displayDate(date) {
				return moment(parseInt(date)).format('LLL');
			},
			async listNotifications() {
				let res = await API.graphql({
					query: listNotifications,
					authMode: 'AMAZON_COGNITO_USER_POOLS',
				});
				let items = res.data.listNotifications.items;
				let sortedNotifications = items.sort((a, b) => b.notificationTime - a.notificationTime);
				this.notificationsList = sortedNotifications;
			},
			async removeAdmin(adminObj) {
				let text = 'Are you sure you want this admin removed from the system?';

				if ((await this.$refs.confirm.open('Confirm', text)) == true) {
					this.isLoading = true;
					delete adminObj['id'];
					let res = await API.graphql({
						query: removeAdmin,
						variables: {
							input: adminObj,
						},
						authMode: 'AMAZON_COGNITO_USER_POOLS',
					});

					if (res.data.removeAdmin == 'Admin Removed.') {
						this.color = 'success';
						this.notificationText = 'Admin Removed.';
						this.notify = true;
						this.settingsDialog = false;
					}
				}

				this.isLoading = false;
			},
			async addNewAdmin() {
				this.myAccount = true;
				this.newAdmin = true;
				this.otherAdmins = false;
			},
			async signOut() {
				try {
					let text = 'Are you sure you want to logout?';

					if ((await this.$refs.confirm.open('Confirm', text)) == true) {
						this.isLoading = true;
						await Auth.signOut();
						this.$router.push('/login');
					}
					this.isLoading = false;
				} catch (error) {
					console.log('error signing out: ', error);
					this.isLoading = false;
				}
			},
			checkRoute(name) {
				if (this.$route.name == name) {
					return true;
				} else {
					return false;
				}
			},
			routeTo(routeName) {
				if (this.$route.name != routeName) {
					this.$router.push('/Admin/' + routeName);
				}
			},
			routeToHome() {
				if (this.$route.name != 'Admin') {
					this.$router.push('/Admin');
				}
			},
			routeToScheduledOrders() {
				if (this.$route.name != 'Scheduled Orders') {
					this.$router.push('/Admin/Scheduled-Orders');
				}
			},
			routeToOrders() {
				if (this.$route.name != 'Orders') {
					this.$router.push('/Admin/Orders');
				}
			},
			routeToProducts() {
				if (this.$route.name != 'Products') {
					this.$router.push('/Admin/Products');
				}
			},
			routeToCustomers() {
				if (this.$route.name != 'Customers') {
					this.$router.push('/Admin/Customers');
				}
			},
			openNotification() {
				this.notificationCard = !this.notificationCard;
			},
			onClickOutside() {
				this.notificationCard = false;
				this.models.base = false;
			},
			isMobile() {
				if (this.$vuetify.breakpoint.width < 800) {
					return true;
				}
			},
			checkSettingsButton() {
				this.isLoading = true;
				if (this.adminSettingsButton == 'Update Account') {
					this.updateAdmin();
				} else if (this.adminSettingsButton == 'Add Account') {
					this.isLoading = false;
					this.createNewAdmin();
				}
			},
			async initCustomerPasswordReset() {
				await this.listCustomers();
				this.customerPasswordResetDialog = true;
			},
			async createNewAdmin() {
				this.isLoading = true;
				try {
					let res = await API.graphql({
						query: createAdmin,
						variables: {
							input: this.selectedAdmin,
						},
						authMode: 'AMAZON_COGNITO_USER_POOLS',
					});

					if (res.data.createAdmin == 'Farm Chicken Admin Created') {
						this.color = 'success';
						this.notificationText = 'Admin Created Successfully.';
						this.notify = true;
						this.customerEmail = '';
						this.settingsDialog = false;
					} else {
						this.color = 'error';
						this.notificationText =
							'There was an unknown issue creating customer. Please try again.';
						this.notify = true;
					}
				} catch (error) {
					if (error.errors[0].errorType == 'InvalidParameterException') {
						this.color = 'error';
						this.notificationText = 'Invalid Email Address';
						this.notify = true;
					} else if (error.errors[0].errorType == 'UsernameExistsException') {
						this.color = 'error';
						this.notificationText = 'This email already exist.';
						this.notify = true;
					} else {
						this.color = 'error';
						this.notificationText = 'There was an error making admin user.';
						this.notify = true;
					}
					console.error(error.errors[0].message);
				} finally {
					this.isLoading = false;
				}
			},
			async listAdmins() {
				this.adminList = [];
				this.isLoading = true;
				let res = await API.graphql({
					query: listAdmins,
					authMode: 'AMAZON_COGNITO_USER_POOLS',
				});

				let adminList = JSON.parse(res.data.listAdmins).Users;

				for (var i = 0; i < adminList.length; i++) {
					let adminTemplate = {
						email: '',
						firstName: 'No',
						lastName: ' Name',
						id: null,
					};
					let adminAttrs = adminList[i].Attributes;

					for (var x = 0; x < adminAttrs.length; x++) {
						if (adminAttrs[x].Name == 'email') {
							adminTemplate.email = adminAttrs[x].Value;
						}
						if (adminAttrs[x].Name == 'custom:FirstName') {
							adminTemplate.firstName = adminAttrs[x].Value;
						}
						if (adminAttrs[x].Name == 'custom:LastName') {
							adminTemplate.lastName = adminAttrs[x].Value;
						}
						if (adminAttrs[x].Name == 'sub') {
							adminTemplate.id = adminAttrs[x].Value;
						}
					}

					this.adminList.push(adminTemplate);
				}
				this.isLoading = false;
			},

			async resetCustomerPassword() {
				let valid = this.$refs.customerResetPasswordForm.validate();

				if (!valid) return;

				this.isLoading = true;

				try {
					let result = await API.graphql({
						query: resendTempPassword,
						variables: {
							input: JSON.stringify({
								user: this.selectedCustomer,
								password: this.resetPasswordString,
							}),
						},
					});
					if (result.data.resendTempPassword == '"PASSWORD_RESETTED"') {
						// SendEmail here
						const emailTemplate = `<html>
	  <body
	    style="
	      background-color: white;
	      font-family: PT Sans, Trebuchet MS, sans-serif;
	    "
	  >
	    <div
	      style="
	        margin: 0 auto;
	        width: 600px;
	        background-color: #fff;
	        font-size: 1.2rem;
	        font-style: normal;
	        font-weight: normal;
	        line-height: 19px;
	      "
	      align="center"
	    >
	      <div style="padding: 20">
	        <img
	          style="
	            border: 0;
	            display: block;
	            height: auto;
	            width: 150px;
	          "
	          alt="farms chicken logo"
	          src="https://www.farmschicken.co.nz/img/logo.png"
	        />
	        <h2
	          style="
	            font-size: 28px;
	            margin-top: 20px;
	            margin-bottom: 0;
	            font-style: normal;
	            font-weight: bold;
	            color: #000;
	            font-size: 24px;
	            line-height: 32px;
	            text-align: center;
	          "
	        >
	          Hello!
	        </h2>
	        <p
	          style="
	            margin-top: 20px;
	            margin-bottom: 0;
	            font-size: 16px;
	            line-height: 24px;
	            color: #000;
	          "
	        >
			Here is your temporary login details.<br/>
			(Use this within 24 hours or it will expire)
	        </p>
	        <div style="display: inline-block; margin: 0 auto">
	          <h2
	            style="
	              margin-top: 20px;
	              margin-bottom: 0;
	              font-size: 16px;
	              line-height: 24px;
	              color: #000;
	              text-align: left;
	            "
	          >
	           Email: ${' ' + this.selectedCustomer}
	          </h2>
	          <h2
	            style="
	              margin-top: 20px;
	              margin-bottom: 0;
	              font-size: 16px;
	              line-height: 24px;
	              color: #000;
	              text-align: left;
	            "
	          >
	            Temporary Password: ${' ' + this.resetPasswordString}
	          </h2>
	        </div>
	      </div>
	    </div>
	  </body>
	</html>
	`;
						let body = {
							emailTo: [this.selectedCustomer],
							replyTo: ['info@farmschicken.co.nz'],
							emailFrom: 'Web | Farms Chicken <info@farmschicken.co.nz>',
							emailHtmlBody: emailTemplate,
							emailSubject: 'Password Reset - Farms Chicken',
							emailTextBody:
								'Please view this email in a updated browser to see more details.Thank You! Farms Chicken.',
						};

						await API.graphql({
							query: sendContactEmail,
							variables: {
								input: JSON.stringify(body),
							},
							authMode: 'API_KEY',
						});

						this.resetPasswordString = '';
						this.selectedCustomer = null;
						this.customerPasswordResetDialog = false;
					}
				} catch (error) {
					console.error(error);
					this.isLoading = false;
				}

				this.isLoading = false;
			},

			async updateAdmin() {
				const user = await Auth.currentAuthenticatedUser();

				await Auth.updateUserAttributes(user, {
					email: this.selectedAdmin.email,
					'custom:FirstName': this.selectedAdmin.firstName,
					'custom:LastName': this.selectedAdmin.lastName,
					email_verified: true,
				});

				this.settingsDialog = false;
				this.isLoading = false;
			},

			async listCustomers() {
				let nextToken = null;
				let result = await API.graphql({
					query: listCustomers,
				});

				nextToken = result.data.listCustomers.nextToken;
				let customerList = result.data.listCustomers.items;

				while (nextToken) {
					let result2 = await API.graphql({
						query: listCustomers,
						variables: {
							nextToken: nextToken,
						},
					});

					nextToken = result2.listCustomers.nextToken;
					customerList = customerList.concat(result2.listCustomers.items);
				}

				this.customerList = customerList;
			},

			randomizePassword() {
				this.resetPasswordString = Math.random().toString(36).slice(-8);
			},

			switchMode() {
				this.mode = !this.mode;
				localStorage.setItem('nightMode', this.mode);
			},
		},

		watch: {
			settingsTitle: async function (val) {
				this.isLoading = true;
				if (val == 'My Settings') {
					this.adminSettingsButton = 'Update Account';
					let user = await Auth.currentUserInfo();
					this.selectedAdmin = {
						firstName: user.attributes['custom:FirstName'],
						lastName: user.attributes['custom:LastName'],
						email: user.attributes.email,
					};
				} else if (val == 'Add new administrator') {
					this.adminSettingsButton = 'Add Account';
					this.isAddingNewAdmin = true;
					this.selectedAdmin = {};
				} else {
					this.adminSettingsButton = 'Update Account';
				}
				this.isLoading = false;
			},
		},

		async mounted() {
			this.listNotifications();
			this.watchSubscriptions();

			let nightMode = localStorage.getItem('nightMode');

			if (nightMode) {
				this.mode = nightMode == 'false' ? false : true;
			}
		},

		computed: {
			mode: {
				get() {
					return this.$vuetify.theme.dark;
				},

				set(val) {
					this.$vuetify.theme.dark = val;
				},
			},
			settingsTitle() {
				if (this.myAccount && !this.otherAdmins && !this.newAdmin) {
					return 'My Settings';
				} else if (this.myAccount && this.otherAdmins && !this.newAdmin) {
					return `${this.selectedAdmin.name}'s Settings`;
				} else if (this.myAccount && !this.otherAdmins && this.newAdmin) {
					return `Add new administrator`;
				} else {
					return ``;
				}
			},
		},

		data() {
			return {
				customerList: [],
				selectedCustomer: null,
				resetPasswordString: '',
				customerPasswordResetDialog: false,
				isAddingNewAdmin: false,
				initNotification: null,
				oldPassword: '',
				newPassword: '',
				viewPass: false,
				resetPass: false,
				color: 'white',
				notify: false,
				notificationText: '',
				notificationCreated: false,
				adminSettingsButton: '',
				isLoading: false,
				selectedAdmin: {},
				headers: [
					{
						text: 'Administrator',
						align: 'start',
						value: 'name',
					},
					{ text: 'Email', value: 'email' },
					{ text: 'Actions', value: 'actions' },
				],
				adminList: [],
				notificationsList: [],
				myAccount: false,
				otherAdmins: false,
				drawer: false,
				settingsDialog: false,
				notificationCard: false,
				notificationTimeout: 99999999999,
				models: {
					base: null,
				},

				hiddenPassword: '*******',
				loading: false,

				//Rules
				emailRules: [
					v => !!v || 'E-mail is required',
					v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid',
				],

				passwordRules: [value => !!value || 'Required.', v => v.length >= 8 || 'Min 8 characters'],
			};
		},
	};
</script>

<style>
	#style-2::-webkit-scrollbar-track {
		width: 10px;
		/* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
		border-radius: 10px;
		background-color: #1b8600;
	}

	#style-2::-webkit-scrollbar {
		width: 7px;
		background-color: #1b8600;
	}

	#style-2::-webkit-scrollbar-thumb {
		border-radius: 100px;

		/* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
		background-color: rgb(0, 100, 0);
	}
</style>
