<template>
	<div>
		<v-dialog v-model="uploadDialog" width="350px">
			<v-card height="100%" class="pa-6" color="error">
				<v-card-text style="color: white">
					Image size: <b>{{ uploadFileSize.toFixed(2) }}MB</b>.
					<br />
					<br />
					This image size is too large. Maximum image size is 2MB, reduce the image size and try
					again.</v-card-text
				>
				<v-btn text class="mt-9" @click="uploadDialog = false">Close</v-btn>
				<v-card-actions></v-card-actions>
			</v-card>
		</v-dialog>
		<!-- <AdminNavigation /> -->
		<v-container :class="!isMobile() ? `px-12 ml-6` : ``" fluid>
			<v-card outlined elevation="4">
				<v-card-title>
					<strong> Products List </strong>
					<v-spacer></v-spacer>
					<v-btn @click="initAddProduct()" elevation="4" color="secondary" :loading="isLoading">
						<v-icon small class="mr-2"> mdi-plus </v-icon>
						Add Product
					</v-btn>

					<!-- BATCH ADD PRODUCTS FROM JSON FILE -->
					<!-- <v-btn color="orange" class="ml-3" @click="runScript()">
						<v-icon>mdi-script</v-icon>
						RUN SCRIPT
					</v-btn> -->
				</v-card-title>

				<!-- Add Product Form -->
				<v-dialog
					v-model="addProductDialog"
					v-if="selectedProduct"
					persistent
					scrollable
					transition="dialog-transition"
					class="container"
					width="80%"
				>
					<v-card style="overflow: auto">
						<v-container :class="!isMobile() ? `` : ``">
							<v-form ref="form">
								<v-card-title>
									<strong> {{ isAdd ? `Add Product` : `Edit Product` }} </strong>
									<v-spacer></v-spacer>
									<v-icon v-if="!isAdd" color="red" @click="initDeleteProduct()" large class="mr-3">
										mdi-delete
									</v-icon>
									<v-icon
										@click="
											() => {
												addProductDialog = false;
												cancelAction();
											}
										"
										large
									>
										mdi-close
									</v-icon>
								</v-card-title>

								<!-- Toggle enable/disable customer -->
								<v-row no-gutters class="pa-0 px-0 ma-0 mx-0">
									<v-col class="d-flex pl-2" cols="12" lg="6" md="6" sm="6">
										<v-row no-gutters class="ma-0 mx-0 pa-0 px-0">
											<v-switch
												v-model="selectedProduct.isInStock"
												:label="selectedProduct.isInStock ? `In Stock` : `Out Of Stock`"
											/>
											<v-divider class="ml-2" vertical inset />
											<p class="ma-5">
												{{
													selectedProduct.isInStock ? `Mark as Out of Stock` : `Mark as In Stock`
												}}
											</p>
										</v-row>
									</v-col>
									<!-- <v-spacer></v-spacer> -->
									<v-col cols="12" lg="6" md="6" sm="6">
										<v-row class="d-flex flex-row-reverse pr-2" no-gutters>
											<v-switch
												v-model="selectedProduct.isEnabled"
												:label="selectedProduct.isEnabled ? `Enabled` : `Disabled`"
											/>
											<v-tooltip color="secondary" top>
												<template #activator="{ on, attrs }">
													<v-icon class="mr-2" v-on="on" v-bind="attrs" color="info">
														mdi-information-outline
													</v-icon>
												</template>
												<span>
													Disabling the product will prevent <br />
													customers from seeing this item.
												</span>
											</v-tooltip>
										</v-row>
									</v-col>
								</v-row>
								<v-container>
									<v-row>
										<v-col cols="6">
											<v-text-field
												v-model="selectedProduct.productName"
												outlined
												label="Product Name"
												:rules="productNameRules"
											/>
										</v-col>
										<v-col cols="3">
											<v-text-field
												v-model="selectedProduct.productCode"
												outlined
												label="Product Code"
												:rules="categoryRules"
											/>
										</v-col>
										<v-col cols="3">
											<v-select
												outlined
												v-model="selectedProduct.reckonProductAttributes.productType"
												label="Product Type"
												:items="productTypes"
												:rules="categoryRules"
												:disabled="!isAdd"
											/>
										</v-col>
									</v-row>

									<v-textarea
										v-model="selectedProduct.description"
										outlined
										dense
										no-resize
										label="Product Description"
									/>

									<v-row>
										<v-col cols="4">
											<v-text-field
												v-model="selectedProduct.defaultPrice"
												outlined
												label="Default Price"
												:rules="categoryRules"
												item
												prepend-inner-icon="mdi-currency-usd"
												@keypress="isNumber($event)"
											/>
										</v-col>

										<v-col cols="4">
											<v-select
												v-model="selectedProduct.productUnit"
												outlined
												label="Unit"
												:items="units"
												:rules="categoryRules"
												item
											/>
										</v-col>
										<v-col cols="4">
											<v-select
												v-model="selectedProduct.categoryName"
												outlined
												label="Product Category"
												:items="categoryList"
												item-text="categoryName"
												item-value="categoryName"
												:rules="categoryRules"
												item
											/>
										</v-col>
									</v-row>

									<v-row>
										<!-- <v-col cols="2">
                      <v-text-field
                        outlined
                        label="Custom Price"
                        item
                        @keypress="isNumber($event)"
                      />
                    </v-col> -->

										<v-col cols="4">
											<v-autocomplete
												outlined
												label="Product Group"
												:items="productGroups"
												item-value="productGroups"
												v-model="selectedProduct.productGroup"
												:rules="categoryRules"
											/>
										</v-col>
									</v-row>

									<v-card-title> Product Image </v-card-title>
									<v-card outlined elevation="4">
										<v-card-title>
											<v-spacer></v-spacer>
											<v-tooltip v-if="selectedProduct.imageUrl" top color="secondary">
												<template #activator="{ on, attrs }">
													<v-btn v-on="on" v-bind="attrs" @click="removeImg()" icon>
														<v-icon color="red"> mdi-close </v-icon>
													</v-btn>
												</template>
												<span> Remove Image </span>
											</v-tooltip>
										</v-card-title>
										<v-img
											style="margin: auto; margin-top: 20px"
											v-if="selectedProduct.imageUrl"
											:alt="selectedProduct.productName"
											:src="selectedProduct.imageUrl"
											max-width="200px"
											max-height="400px"
										></v-img>

										<v-icon v-else class="pt-4" size="42" color="info"> mdi-image </v-icon>
										<v-card-text>
											<v-btn @click="addImage()" color="primary">
												{{ selectedProduct.imageUrl ? `Change Image` : `Add Image` }}
											</v-btn>
										</v-card-text>
										<input
											class="d-none"
											ref="uploader"
											type="file"
											accept="image/*"
											@change="onFileChanged"
										/>
									</v-card>
									<v-card-actions class="pt-6 mr-1">
										<v-row justify="end">
											<v-btn
												class="mr-2 white--text"
												color="red lighten-2"
												outlined
												@click="cancelAction()"
											>
												Cancel
											</v-btn>
											<v-btn
												@click="isAdd ? createProduct() : updateProduct()"
												:loading="isLoading"
												color="secondary"
											>
												{{ isAdd ? `Add Product` : `Update Product` }}
											</v-btn>
										</v-row>
									</v-card-actions>
								</v-container>
							</v-form>
						</v-container>
					</v-card>
				</v-dialog>

				<!-- Confirm delete dialog -->
				<v-dialog
					v-model="confirmDeleteDialog"
					persistent
					style="position: aboslut; z-index: 999999"
					max-width="360"
				>
					<v-card>
						<v-card-title>
							Confirm Delete
							<v-spacer></v-spacer>
							<v-icon @click="confirmDeleteDialog = false"> mdi-close </v-icon>
						</v-card-title>
						<v-card-text style="font-weight: 900">
							Are you sure you want to delete this product? This may BREAK the system.<br /><br />(Note:
							this will NOT delete the product in reckon. You have to manually do that)
						</v-card-text>
						<v-spacer></v-spacer>
						<v-card-actions>
							<v-row justify="end" class="ma-0">
								<v-btn @click="confirmDeleteDialog = false" class="mr-2" color="red" outlined>
									Cancel
								</v-btn>
								<v-btn @click="deleteProduct()" :loading="isLoading" color="primary">
									Confirm
								</v-btn>
							</v-row>
						</v-card-actions>
					</v-card>
				</v-dialog>

				<v-row class="mx-1">
					<!-- Search products -->
					<v-col cols="12" lg="8" md="8" sm="8">
						<v-text-field
							v-model="searchText"
							dense
							label="Search Product"
							outlined
							@keyup="initSearch()"
						/>
					</v-col>
					<!-- Filter products dropdown -->
					<v-col cols="12" lg="4" md="4" sm="4">
						<v-select
							v-model="selectedCategoryFilter"
							dense
							label="Filter by Category"
							outlined
							:items="categoryListFilter"
							item-text="categoryName"
							item-value="categoryName"
						/>
					</v-col>
					<!-- Filter products dropdown -->
					<!-- <v-col
            cols="12"
            lg="3"
            md="3"
            sm="3"
          >
            <v-select
              v-model="selectedActiveFilter"
              dense
              label="Filter Active Customers"
              outlined
              :items="[`All`,`
              Enabled`, `Disabled`
              ]"
              item-text="categoryName"
              item-value="categoryName"
            />
          </v-col> -->
				</v-row>
				<!-- Products Table -->
				<v-data-table
					:loading="isLoading"
					:headers="headers"
					:items="productsList"
					:items-per-page="50"
					:footer-props="{ 'items-per-page-options': [50, 100, 150, -1] }"
					class="elevation-1"
					mobile-breakpoint="0"
				>
					<template v-slot:[`item.productName`]="{ item }">
						<tr>
							<td>
								[<b style="color: #1b8600">{{ item.productCode }}</b
								>]{{ ' ' + item.productName }}
								<v-chip
									v-if="item.isInStock == false"
									class="ml-2"
									x-small
									color="red"
									text-color="white"
								>
									OUT OF STOCK
								</v-chip>
							</td>
						</tr>
					</template>

					<template v-slot:[`item.isEnabled`]="{ item }">
						<tr>
							<td v-if="item.isEnabled == false" class="pl-7">
								<v-icon color="red"> mdi-close </v-icon>
							</td>

							<td v-else class="pl-7">
								<v-icon color="green"> mdi-check </v-icon>
							</td>
						</tr>
					</template>

					<template v-slot:[`item.defaultPrice`]="{ item }">
						<tr>
							<td>${{ parseFloat(item.defaultPrice).toFixed(2) }}</td>
						</tr>
					</template>

					<template v-slot:[`item.actions`]="{ item, index }">
						<tr>
							<td class="pl-3">
								<v-icon color="primary" size="20" @click="initUpdateProduct(item, index)"
									>mdi-pencil</v-icon
								>
							</td>
						</tr>
					</template>
				</v-data-table>
			</v-card>
		</v-container>
		<!-- notification  -->
		<v-snackbar v-model="notify" class="pt-16" top right :timeout="2500" :color="color">
			{{ notificationText }}
		</v-snackbar>
	</div>
</template>

<script>
	// import AdminNavigation from "../../components/AdminViewComponents/AdminNavigation.vue";
	import { API, Auth, Storage } from 'aws-amplify';
	import {
		createProduct,
		updateProduct,
		deleteProduct,
		createNotification,
	} from '@/graphql/mutations';
	import { listProducts, listCategories, filterProducts, listPriceLevels } from '@/graphql/queries';
	import * as subscriptions from '@/graphql/subscriptions';
	import config from '@/aws-exports';
	import axios from 'axios';

	Storage.configure({
		region: config.aws_user_files_s3_bucket_region,
		bucket: config.aws_user_files_s3_bucket,
		identityPoolId: config.aws_user_pools_id,
		level: 'public',
	});

	// import json from '../../scripts/product_list.json';

	export default {
		methods: {
			removeImg() {
				this.selectedProduct.imageUrl = '';
				this.$forceUpdate();
			},
			/**************************
			 * PRODUCT SCRIPT
			 **************************/
			// async runScript() {
			// 	//TODO: Init product list
			// 	let finalProdList = [];

			// 	// Read in the CSV file
			// 	for (let i = 0; i < json.length; i++) {
			// 		const element = json[i];

			// 		//TODO: convert csv data to fit schema
			// 		let productSchema = {
			// 			productName: element.Name,
			// 			categoryName: element.Group,
			// 			description: '',
			// 			quantity: null,
			// 			imageUrl: '',
			// 			productUnit: element.SoldBy,
			// 			productCode: element.Code,
			// 			defaultPrice: element.Retail,
			// 			isEnabled: true,
			// 			reckonProductAttributes: {},
			// 			productGroup: element.Group,
			// 			isInStock: true,
			// 		};

			// 		finalProdList.push(productSchema);
			// 	}

			// 	//TODO: GET LIST OF PRODUCTS
			// 	let auth = await Auth.currentAuthenticatedUser();
			// 	let headers = {
			// 		headers: {
			// 			authorization: 'Bearer ' + auth.signInUserSession.accessToken.jwtToken,
			// 		},
			// 	};

			// 	/**************************************
			// 	 * IF Getting List from Reckon
			// 	 *************************************/
			// 	let productListRes = await axios.get(
			// 		this.$store.getters['global/endpoint'] + '/api/reckon/products/list',
			// 		headers
			// 	);
			// 	let products = productListRes.data.ItemInventoryRet;

			// 	// console.log('Init Final List: ', finalProdList);

			// 	// let finalFinalList = await products.filter(el =>
			// 	// 	finalProdList.some(prod => {
			// 	// 		if (prod.productCode == el.Name[0]) {
			// 	// 			prod.description = el.SalesDesc[0];
			// 	// 			prod.quantity = 0;
			// 	// 			prod.imageUrl = null;
			// 	// 			prod.defaultPrice = el.SalesPrice[0];
			// 	// 			prod.reckonProductAttributes = {
			// 	// 				listID: el.ListID[0],
			// 	// 				editSequence: el.EditSequence[0],
			// 	// 				isActive: true,
			// 	// 				productType: 'Product',
			// 	// 			};

			// 	// 			return el;
			// 	// 		}
			// 	// 	})
			// 	// );

			// 	let finalFinalList = await finalProdList.filter(prod =>
			// 		products.some(el => {
			// 			if (prod.productCode == el.Name[0]) {
			// 				if (prod.productGroup.includes('Chicken')) {
			// 					prod.categoryName = 'Chicken';
			// 				} else if (prod.productGroup.includes('Beef')) {
			// 					prod.categoryName = 'Beef';
			// 				} else if (prod.productGroup.includes('Pork')) {
			// 					prod.categoryName = 'Pork';
			// 				} else if (prod.productGroup.includes('Lamb')) {
			// 					prod.categoryName = 'Lamb';
			// 				} else if (prod.productGroup.includes('Duck')) {
			// 					prod.categoryName = 'Duck';
			// 				} else {
			// 					prod.categoryName = 'Other';
			// 				}
			// 				prod.description = el.SalesDesc[0];
			// 				prod.quantity = 0;
			// 				prod.imageUrl = null;
			// 				prod.defaultPrice = el.SalesPrice[0];
			// 				prod.reckonProductAttributes = {
			// 					listID: el.ListID[0],
			// 					editSequence: el.EditSequence[0],
			// 					isActive: true,
			// 					productType: 'Product',
			// 				};

			// 				return el;
			// 			}
			// 		})
			// 	);

			// 	// console.log(finalFinalList);

			// 	for (let i = 0; i < finalFinalList.length; i++) {
			// 		this.selectedProduct = finalFinalList[i];

			// 		console.log(this.selectedProduct);
			// 		await this.createProduct();
			// 	}

			// 	// COGSAccountRef[0].FullName[0]
			// 	// FullName[0]
			// 	// IncomeAccountRef[0].FullName[0]
			// 	// IsActive[0]
			// 	// ListId[0]
			// 	// EditSequence[0]
			// 	// Name[0]
			// 	// PurchaseCost[0]
			// 	// PurchaseDesc[0]
			// 	// SalesDesc[0]
			// 	// SalesPrice[0]
			// },

			async initCurrentUser() {
				const userInfo = await Auth.currentUserInfo();
				const attributes = userInfo.attributes;
				const firstName = attributes['custom:FirstName'];
				const lastName = attributes['custom:LastName'];
				this.currentUser = firstName + ' ' + lastName;
			},
			isNumber(evt) {
				evt = evt ? evt : window.event;
				var charCode = evt.which ? evt.which : evt.keyCode;
				if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
					evt.preventDefault();
				} else {
					return true;
				}
			},

			async listPriceLevels() {
				this.priceLevelList = [];
				let res = await API.graphql({
					query: listPriceLevels,
					authMode: 'AMAZON_COGNITO_USER_POOLS',
				});
				let priceLevelList = res.data.listPriceLevels.items.sort((a, b) =>
					a.productName > b.productName ? 1 : b.productName > a.productName ? -1 : 0
				);

				this.priceLevelList = priceLevelList;
			},
			async listCategories() {
				let res = await API.graphql({
					query: listCategories,
					authMode: 'AMAZON_COGNITO_USER_POOLS',
				});
				let categoryList = res.data.listCategories.items.sort(
					(a, b) => a.categoryName - b.categoryName
				);

				this.categoryListFilter = this.categoryListFilter.concat(categoryList);
				this.categoryList = this.categoryList.concat(categoryList);
			},
			watchSubscriptions() {
				//adding product
				API.graphql({
					query: subscriptions.addedProduct,
					authMode: 'AMAZON_COGNITO_USER_POOLS',
				}).subscribe({
					next: ({ value }) => {
						if (value) {
							this.filterProducts();
						}
					},
					error: error => console.warn(error),
				});
				//updating product
				API.graphql({
					query: subscriptions.updatedProduct,
					authMode: 'AMAZON_COGNITO_USER_POOLS',
				}).subscribe({
					next: ({ value }) => {
						if (value) {
							this.filterProducts();
						}
					},
					error: error => console.warn(error),
				});
				//deleting product
				API.graphql({
					query: subscriptions.deletedOrder,
					authMode: 'AMAZON_COGNITO_USER_POOLS',
				}).subscribe({
					next: ({ value }) => {
						if (value) {
							this.filterProducts();
						}
					},
					error: error => console.warn(error),
				});
			},
			cancelAction() {
				this.filterProducts();
				this.addProductDialog = false;
			},
			async deleteProduct() {
				try {
					let auth = await Auth.currentAuthenticatedUser();
					let headers = {
						headers: {
							authorization: 'Bearer ' + auth.signInUserSession.accessToken.jwtToken,
						},
					};

					let product = this.selectedProduct;
					let productionResult = await axios.delete(
						this.$store.getters['global/endpoint'] + `/api/production/product/delete`,
						{
							data: product,
							...headers,
						}
					);
					if (productionResult.status != 200) {
						this.color = 'primary';
						this.notificationText = 'Error deleting product. Try again later.';
						this.notify = true;

						return;
					}
					await API.graphql({
						query: deleteProduct,
						variables: {
							sk: product.sk,
						},
						authMode: 'AMAZON_COGNITO_USER_POOLS',
					}).then(async res => {
						let params = {
							user: this.currentUser,
							message: `Deleted Product: ${this.selectedProduct.productName}`,
							notificationTime: Date.now(),
						};
						await API.graphql({
							query: createNotification,
							variables: {
								input: params,
							},
							authMode: 'AMAZON_COGNITO_USER_POOLS',
						});
						this.confirmDeleteDialog = false;
						this.addProductDialog = false;
						this.color = 'primary';
						this.notificationText = 'Product deleted.';
						this.notify = true;

						this.filterProducts();
						return res;
					});
				} catch (error) {
					this.confirmDeleteDialog = false;
					this.color = 'error';
					this.notificationText = 'Theres an error deleting product. Please try again later.';
					this.notify = true;
				}
			},
			initDeleteProduct() {
				this.confirmDeleteDialog = true;
			},
			async updateProduct() {
				try {
					this.isLoading = true;
					let isValid = this.$refs.form.validate();

					if (isValid) {
						let auth = await Auth.currentAuthenticatedUser();
						let headers = {
							headers: {
								authorization: 'Bearer ' + auth.signInUserSession.accessToken.jwtToken,
							},
						};
						if (this.selectedImageFile) {
							let imageUrl = null;
							let imgSize = this.selectedImageFile.size;
							let converted = imgSize / 1e6;
							let rounded = Math.round(converted * 100) / 100;
							if (imgSize > 2000000) {
								// alert(
								// 	`Image size: ${rounded}MB. \nThis image size is too large. Maximum image size is 2MB, reduce the image size and try again.`
								// );
								this.uploadFileSize = rounded;
								this.uploadDialog = true;
								this.isLoading = false;
								return;
							} else {
								let res = await this.uploadPhotoToDB(this.selectedImageFile);
								imageUrl =
									'https://farmschickenbucket131937-dev.s3.ap-southeast-2.amazonaws.com/public/' +
									res.key;
								this.selectedProduct.imageUrl = imageUrl;
								this.selectedImageFile = null;
							}
						}
						let reckonProduct = await axios.post(
							this.$store.getters['global/endpoint'] + '/api/reckon/product/update',
							this.selectedProduct,
							headers
						);

						//update product in Dynamo
						let res = await API.graphql({
							query: updateProduct,
							variables: {
								input: reckonProduct.data,
							},
							authMode: 'AMAZON_COGNITO_USER_POOLS',
						});

						// update product in SQL
						let axiosRes = await axios.patch(
							this.$store.getters['global/endpoint'] + '/api/production/product/update',
							res.data.updateProduct,
							headers
						);
						this.selectedProduct = axiosRes.data;

						let params = {
							user: this.currentUser,
							message: `Updated Product: ${this.selectedProduct.productName}`,
							notificationTime: Date.now(),
						};
						await API.graphql({
							query: createNotification,
							variables: {
								input: params,
							},
							authMode: 'AMAZON_COGNITO_USER_POOLS',
						});

						this.isLoading = false;
						this.addProductDialog = false;
						this.color = 'primary';
						this.notificationText = 'Product updated.';
						this.notify = true;
					} else {
						this.color = 'orange';
						this.notificationText = 'Error updating product.';
						this.notify = true;
						this.isLoading = false;
					}
				} catch (error) {
					console.error(error);
					this.isLoading = false;
					this.color = 'error';
					this.notificationText = 'Error Updating Product! Please Try Again.';
					this.notify = true;
				}
			},
			initAddProduct() {
				this.isAdd = true;
				this.$store.commit('products/resetSelectedProduct');
				this.addProductDialog = true;
			},
			initUpdateProduct(product) {
				this.isAdd = false;
				this.$store.commit('products/setSelectedProduct', product);
				this.addProductDialog = true;
			},
			async listProducts() {
				this.productsList = [];
				let res = await API.graphql({
					query: listProducts,
					authMode: 'AMAZON_COGNITO_USER_POOLS',
				});
				let productsList = res.data.listProducts.items.sort((a, b) =>
					a.productName > b.productName ? 1 : b.productName > a.productName ? -1 : 0
				);

				this.productsList = productsList;
			},

			async uploadPhotoToDB(file) {
				let res = await Storage.put('productImages/' + file.name, file, {
					acl: 'public-read',
				});

				return res;
			},
			async createProduct() {
				try {
					let auth = await Auth.currentAuthenticatedUser();
					let headers = {
						headers: {
							authorization: 'Bearer ' + auth.signInUserSession.accessToken.jwtToken,
						},
					};
					this.isLoading = true;
					let isValid = this.$refs.form.validate();

					//SCRIPT
					// let isValid = true;

					if (isValid) {
						if (this.selectedImageFile) {
							let imageUrl = null;
							let imgSize = this.selectedImageFile.size;
							let converted = imgSize / 1e6;
							let rounded = Math.round(converted * 100) / 100;
							if (imgSize > 2000000) {
								// alert(
								// 	`Image size: ${rounded}MB. \nThis image size is too large. Maximum image size is 2MB, reduce the image size and try again.`
								// );
								this.uploadFileSize = rounded;
								this.uploadDialog = true;
								this.isLoading = false;
								return;
							} else {
								let res = await this.uploadPhotoToDB(this.selectedImageFile);
								imageUrl =
									'https://farmschickenbucket131937-dev.s3.ap-southeast-2.amazonaws.com/public/' +
									res.key;
								this.selectedProduct.imageUrl = imageUrl;
								this.selectedImageFile = null;
							}
						}

						//SCRIPT UNCOMMENT RECKON ADDING SINCE RECKON ALREADY HAVE PRODUCTS //COMMENT THIS
						// Reckon create product
						let reckonProduct = await axios.post(
							this.$store.getters['global/endpoint'] + '/api/reckon/product/create',
							this.selectedProduct,
							headers
						);

						//SCRIPT CHANGE RECKON PRODUCT FROM API RESPONSE TO CSV DATA //UNCOMMENT THIS
						// let reckonProduct = this.selectedProduct;

						// GraphQL create product
						let product = await API.graphql({
							query: createProduct,
							variables: {
								input: reckonProduct.data,
							},
							authMode: 'AMAZON_COGNITO_USER_POOLS',
						});

						// Create product SQL
						await axios.post(
							this.$store.getters['global/endpoint'] + '/api/production/product/create',
							product.data.createProduct,
							headers
						);

						//SCRIPT COMMENT BLOCK TO AVOID NOTIFICATION REQUESTS
						let params = {
							user: this.currentUser,
							message: `Created Product: ${this.selectedProduct.productName} `,
							notificationTime: Date.now(),
						};
						await API.graphql({
							query: createNotification,
							variables: {
								input: params,
							},
							authMode: 'AMAZON_COGNITO_USER_POOLS',
						});

						this.color = 'primary';
						this.notificationText = 'Product created.';
						this.notify = true;
						this.isLoading = false;
						this.addProductDialog = false;

						//SCRIPT COMMENT RESET TO AVOID ERROR
						this.$refs.form.reset();
					} else {
						this.isLoading = false;
						this.color = 'orange';
						this.notificationText = 'Please fill in the required fields.';
						this.notify = true;
					}
				} catch (error) {
					console.log(error);
					this.isLoading = false;
					this.color = 'error';
					this.notificationText = 'Error Creating Product';
					this.notify = true;
				}
			},
			async checkUser() {
				await Auth.currentAuthenticatedUser({
					bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
				})
					.then(user => {
						if (user.signInUserSession.accessToken.payload['cognito:groups'] == 'customers') {
							this.$router.push('/');
						}
					})
					.catch(err => {
						if (err == 'The user is not authenticated') {
							this.$router.push('/');
						}
					});
			},
			isMobile() {
				if (this.$vuetify.breakpoint.width < 800) {
					return true;
				}
			},
			addImage() {
				window.addEventListener('focus', () => {
					// once: true;
				});
				this.$refs.uploader.click();
			},
			async onFileChanged(e) {
				this.selectedImageFile = e.target.files[0];
				if (!this.selectedImageFile) return;

				this.selectedProduct.imageUrl = URL.createObjectURL(this.selectedImageFile);
				this.$forceUpdate();
				// this.filesList.push(selectFile);
				// this.displayList.push(URL.createObjectURL(selectFile));
			},

			initSearch() {
				this.isLoading = true;
				if (this.timer) {
					clearTimeout(this.timer);
					this.timer = null;
				}
				this.timer = setTimeout(() => {
					this.filterProducts();
				}, 300);
			},

			async filterProducts() {
				this.isLoading = true;
				const count = 800;
				let catFilter;
				let productList = [];
				let nextToken = null;

				if (this.selectedCategoryFilter == 'All') {
					catFilter = null;
				} else {
					catFilter = this.selectedCategoryFilter;
				}

				let res = await API.graphql({
					query: filterProducts,
					authMode: 'AMAZON_COGNITO_USER_POOLS',
					variables: {
						searchString: this.searchText.toLowerCase(),
						category: catFilter,
					},
				});
				nextToken = res.data.filterProducts.nextToken;
				productList = productList.concat(res.data.filterProducts.items);

				while (nextToken && productList.length < count) {
					let res = await API.graphql({
						query: filterProducts,
						authMode: 'AMAZON_COGNITO_USER_POOLS',
						variables: {
							searchString: this.searchText.toLowerCase(),
							category: catFilter,
							nextToken: nextToken,
						},
					});

					nextToken = res.data.filterProducts.nextToken;
					productList = productList.concat(res.data.filterProducts.items);
				}

				productList = productList.sort((a, b) =>
					a.productName > b.productName ? 1 : b.productName > a.productName ? -1 : 0
				);

				this.productsList = productList;

				this.isLoading = false;
			},
		},

		async mounted() {
			await this.checkUser();
			await this.listCategories();
			await this.filterProducts();
			this.watchSubscriptions();
			this.initCurrentUser();
		},

		watch: {
			addProductDialog: function (val) {
				if (val == true) {
					let product = this.selectedProduct;
					product.isEnabled == null ? (product.isEnabled = true) : ' ';

					this.listPriceLevels();
				}
			},
			selectedCategoryFilter: function () {
				this.filterProducts();
			},
		},

		computed: {
			selectedProduct: {
				get() {
					return this.$store.getters['products/selectedProduct'];
				},
				set(val) {
					this.$store.commit('products/setSelectedProduct', val);
				},
			},
			productGroups: {
				get() {
					return this.$store.getters['products/productGroups'];
				},
			},
		},

		components: {
			// AdminNavigation,
		},

		data() {
			return {
				uploadDialog: false,
				uploadFileSize: 0,
				addProductDialog: false,
				selectedActiveFilter: 'All',
				currentUser: null,
				productTypes: ['Product', 'Service'],
				priceLevelList: [],
				categoryListFilter: [{ categoryName: 'All', pk: '0', sk: '0' }],
				units: ['KG', 'Each', 'Pieces', 'CTN'],
				selectedImageFile: null,
				timer: null,
				searchText: '',
				selectedCategoryFilter: 'All',
				categoryList: [],
				categoryRules: [v => !!v || 'Category is required'],
				productNameRules: [v => !!v || 'Product name is required'],
				notificationText: '',
				color: null,
				notify: false,
				confirmDeleteDialog: false,
				isLoading: false,
				actions: [{ title: 'View' }, { title: 'Edit' }, { title: 'Delete' }],
				headers: [
					{
						text: 'Product Name',
						sortable: true,
						value: 'productName',
					},
					{
						text: 'Product Status',
						sortable: false,
						value: 'isEnabled',
					},
					// {
					//   text: "Product Status",
					//   sortable: false,
					//   value: "isEnabled",
					// },
					{
						text: 'Category',
						sortable: true,
						value: 'categoryName',
					},
					{
						text: ' Price',
						sortable: false,
						value: 'defaultPrice',
					},
					{
						text: 'Actions',
						value: 'actions',
						sortable: false,
					},
				],
				selectedIndex: null,
				productsList: [],
				productStatus: '',
				isAdd: null,
				updateProductsList: null,
			};
		},
		beforeDestroy() {
			// //adding product
			// API.graphql(graphqlOperation(subscriptions.addedProduct)).unsubscribe();
			// //updating product
			// API.graphql(graphqlOperation(subscriptions.updatedProduct)).unsubscribe();
			// //deleting product
			// API.graphql(graphqlOperation(subscriptions.deletedProduct)).unsubscribe();
		},
	};
</script>

<style>
	/* hide scrollbar but still able to scroll */
	.container {
		-ms-overflow-style: none; /* Internet Explorer 10+ */
		scrollbar-width: none; /* Firefox */
	}
	.container::-webkit-scrollbar {
		display: none; /* Safari and Chrome */
	}
</style>
