<template>
  <div>
    <!-- <AdminNavigation /> -->
    <v-container
      :class="!isMobile() ? `px-12 ml-6` : ``"
      fluid
    >
      <v-card
        elevation="4"
        outlined
      >
        <v-card-title>
          <strong> Orders </strong>
          <!-- <v-btn
            @click="test1()"
            color="red"
          > Test Daily Total Sales </v-btn>
          <v-btn
            @click="test2()"
            color="orange"
          > Test Month-To-Date Sales </v-btn>
          <v-btn
            @click="test3()"
            color="yellow"
          > Test Financial-Year-To-Date Sales </v-btn>
          <v-btn
            @click="test4()"
            color="green"
          > Test Year-To-Date Sales </v-btn> -->
          <v-spacer></v-spacer>
          <v-tooltip
            color="#6200EE"
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-btn
                color="#6200EE"
                v-on="on"
                v-bind="attrs"
                @click="batchCreateInvoices()"
                class="mr-3"
                v-show=" selectedOrderStatus == 'DEFAULT'"
              >
                <v-icon
                  small
                  color="white"
                > mdi-note-multiple-outline </v-icon>
              </v-btn>
            </template>
            <span> Batch Create Invoices</span>
          </v-tooltip>
          <v-tooltip
            :color="dateFilterRange.length > 1 ? `error` : `secondary`"
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-btn
                :color="dateFilterRange.length > 1 ? `error` : `secondary`"
                v-on="on"
                v-bind="attrs"
                @click="initFilterDialog()"
                class="mr-3"
              >
                <v-icon small> mdi-filter </v-icon>
              </v-btn>
            </template>
            <span> Filter By Date Range </span>
          </v-tooltip>
          <v-tooltip
            color="#666666"
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-btn
                v-show="
									
									selectedOrderStatus == 'DEFAULT'
								"
                @click="syncDatabase()"
                color="#666666"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                :loading="isLoading"
              >
                <v-icon color="white">mdi-database-refresh</v-icon>
              </v-btn>
            </template>
            <span> Sync Database</span>
          </v-tooltip>
          <v-tooltip
            color="accent"
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-btn
                elevation="4"
                color="secondary"
                v-on="on"
                v-bind="attrs"
                @click="initOrder()"
              >
                <v-icon small> mdi-plus </v-icon>
              </v-btn>
            </template>
            <span> Create Order </span>
          </v-tooltip>
        </v-card-title>

        <!-- Add Product Form -->

        <v-container fluid>
          <v-row>
            <v-col
              cols="12"
              lg="9"
              md="9"
              sm="9"
            >
              <v-text-field
                v-model="searchText"
                label="Search Orders"
                dense
                @keyup="initSearch()"
                outlined
                append-icon="mdi-search"
                hide-details
              />
            </v-col>

            <!-- Status Dropdown -->
            <v-col
              cols="12"
              lg="3"
              md="3"
              sm="3"
            >
              <v-select
                v-model="selectedOrderStatus"
                label="Filter by Status"
                :items="filterStatus"
                dense
                outlined
                hide-details
              />
            </v-col>
          </v-row>
        </v-container>

        <!-- Orders Table -->
        <v-data-table
          :headers="headers"
          :items="ordersList"
          :items-per-page="50"
          :loading="isLoading"
          :footer-props="{ 'items-per-page-options': [50, 100, 150, -1] }"
          mobile-breakpoint="0"
          sort-by="orderDate"
          sort-desc
        >
          <template v-slot:[`item.companyName`]="{ item }">
            <tr>
              <td v-if="item.customer">
                {{ item.customer.companyName }}
              </td>
              <td v-else>
                Unknown Customer
                <span style="font-size: 9px; font-weight: 900; color: red">(Unable to find in Database)</span>
              </td>
            </tr>
          </template>

          <template v-slot:[`item.orderId`]="{ item }">
            <tr>
              <td>
                {{ limitString(item.orderId).toUpperCase() }}
              </td>
            </tr>
          </template>

          <template v-slot:[`item.orderStatus`]="{ item }">
            <tr>
              <td>
                <v-chip
                  style="color: white"
                  :color="colors[item.orderStatus]"
                >
                  {{ isMobile() ? limitStringShort(item.orderStatus) : item.orderStatus }}
                </v-chip>
              </td>
            </tr>
          </template>

          <template v-slot:[`item.invoiceRef`]="{ item }">
            <tr>
              <td>
                <v-icon
                  v-if="item.invoiceRef != null"
                  color="primary"
                > mdi-check </v-icon>
              </td>
            </tr>
          </template>

          <template v-slot:[`item.orderDate`]="{ item }">
            <tr>
              <td class="pl-3">
                {{ displayDate(item.orderDate) }}
              </td>
            </tr>
          </template>

          <template v-slot:[`item.actions`]="{ item, index }">
            <tr>
              <td class="pl-3">
                <v-icon
                  color="primary"
                  size="20"
                  @click="initOrder(item, index)"
                >mdi-pencil</v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    <!-- notification  -->
    <v-snackbar
      v-model="notify"
      class="pt-16"
      top
      right
      :timeout="2500"
      :color="color"
    >
      {{ notificationText }}
    </v-snackbar>

    <v-dialog
      width="60vw"
      v-model="filterDialog"
    >
      <!-- <v-card height="70vh">
				<v-card-title><h2>Filters</h2></v-card-title>
				<v-card-actions>
					<v-card-subtitle>By date range</v-card-subtitle>
				</v-card-actions>
				<v-card-actions>

				</v-card-actions>
			</v-card> -->
      <v-date-picker
        :selected-items-text="dateFilterRangeText"
        elevation="2"
        full-width
        v-model="dateFilterRange"
        range
        color="secondary"
      >
        <v-row class="justify-center ma-4">
          <v-btn
            :disabled="dateFilterRange.length == 0"
            @click="dateFilterRange = []"
            outlined
            color="error"
          >Clear Filter</v-btn>
          <!-- <v-btn :disabled="dateFilterRange.length < 2" color="success" text
						>Set Filter</v-btn
					> -->
        </v-row>
      </v-date-picker>
    </v-dialog>

    <v-dialog
      width="500"
      persistent
      v-model="batchInvoiceLoading"
    >
      <v-card
        height="420"
        elevation="0"
        class="justify-center align-center"
        style="magin: auto"
      >
        <v-progress-circular
          indeterminate
          color="primary"
          size="300"
          class="mt-12"
          width="9"
        >
          <v-fade-transition>
            <span v-show="batchInvoiceTextShow">{{ batchInvoiceText }}</span>
          </v-fade-transition>
        </v-progress-circular>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment';
// import AdminNavigation from "@/components/AdminViewComponents/AdminNavigation.vue";
import { Auth, API } from 'aws-amplify';
import { listOrders, listProducts, filterOrders } from '@/graphql/queries';
import axios from 'axios';
import * as subscriptions from '@/graphql/subscriptions';

export default {
  beforeDestroy () {
    this.subscriptionList.forEach(fnc => {
      fnc.unsubscribe();
    });
  },
  methods: {
    async batchCreateInvoices () {
      this.batchInvoiceLoading = true;
      this.batchInvoiceText = 'Checking for packed orders... ';
      this.batchInvoiceTextShow = true;
      await this.syncDatabase();
      let packedOrders = this.ordersList.filter(order => order.orderStatus == 'PACKED');
      if (packedOrders.length == 0) {
        setTimeout(() => {
          this.batchInvoiceTextShow = false;
        }, 1000);
        setTimeout(() => {
          this.batchInvoiceText = 'No packed orders ready to be invoiced.';
          this.batchInvoiceTextShow = true;
        }, 2000);

        setTimeout(() => {
          this.batchInvoiceLoading = false;
          this.batchInvoiceText = '';
          this.batchInvoicetextShow = false;
        }, 4000);

        return;
      }

      let auth = await Auth.currentAuthenticatedUser();
      let headers = {
        headers: {
          authorization: 'Bearer ' + auth.signInUserSession.accessToken.jwtToken,
        },
      };
      this.batchInvoiceTextShow = false;
      setTimeout(() => {
        this.batchInvoiceText = 'Creating invoices...';
        this.batchInvoiceTextShow = true;
      }, 1000);
      let res = await axios.post(
        this.$store.getters['global/endpoint'] + '/api/reckon/invoice/batch-create',
        packedOrders,
        headers
      );

      if (res.data == 'INVOICES CREATED') {
        this.initInvoiceDialog = false;
        this.batchInvoiceTextShow = false;

        setTimeout(() => {
          this.batchInvoiceText = 'Finalizing...';
          this.batchInvoiceTextShow = false;
          this.batchInvoiceLoading = false;
        }, 500);

        setTimeout(() => {
          this.color = 'success';
          this.notificationText = 'Invoices Successfully Created!';
          this.notify = true;
        }, 2000);
      }
      this.batchInvoiceLoading = false;
      this.isLoading = false;
    },
    async filterByDateRange () {
      await this.filterOrders();
      if (this.dateFilterRange.length > 1) {
        this.filterDialog = false;
        this.isLoading = true;

        let date1 = moment(this.dateFilterRange[0]).format('x');
        let date2 = moment(this.dateFilterRange[1]).format('x');

        let filtered = this.ordersList.filter(el => {
          let orderDate = parseInt(el.orderDate);
          if (date1 < date2) {
            return parseInt(date1) < orderDate && parseInt(date2) > orderDate;
          } else {
            return parseInt(date2) < orderDate && parseInt(date1) > orderDate;
          }
        });

        this.ordersList = filtered;

        this.isLoading = false;
      }
    },
    initFilterDialog () {
      this.filterDialog = true;
    },
    async watchSubscriptions () {
      const watchCreateOrder = API.graphql({
        query: subscriptions.createdOrder,
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      }).subscribe({
        next: () => {
          this.filterOrders();
        },
        error: error => console.warn(error),
      });
      const watchUpdateOrder = API.graphql({
        query: subscriptions.updatedOrder,
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      }).subscribe({
        next: () => {
          this.filterOrders();
        },
        error: error => console.warn(error),
      });
      const watchDeleteOrder = API.graphql({
        query: subscriptions.deletedOrder,
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      }).subscribe({
        next: () => {
          this.filterOrders();
        },
        error: error => console.warn(error),
      });

      this.subscriptionList.push(watchCreateOrder);
      this.subscriptionList.push(watchUpdateOrder);
      this.subscriptionList.push(watchDeleteOrder);
    },
    async syncDatabase () {
      try {
        this.isLoading = true;
        // let mappedOrders = this.ordersList.map(
        // 	({ orderId, customerId, orderStatus, invoiceRef }) => {
        // 		return { orderId, customerId, orderStatus, invoiceRef };
        // 	}
        // );
        let mappedOrders = this.ordersList;
        let auth = await Auth.currentAuthenticatedUser();
        let headers = {
          headers: {
            authorization: 'Bearer ' + auth.signInUserSession.accessToken.jwtToken,
          },
        };

        await axios.post(
          this.$store.getters['global/endpoint'] + '/api/production/order/multisync',
          mappedOrders,
          headers
        );

        await this.filterOrders();
      } catch (error) {
        console.log('Error');
      }

      this.isLoading = false;
    },
    async createReckonInvoice () {
      let auth = await Auth.currentAuthenticatedUser();
      let headers = {
        headers: {
          authorization: 'Bearer ' + auth.signInUserSession.accessToken.jwtToken,
        },
      };
      let res = await axios.post(
        this.$store.getters['global/endpoint'] + '/api/reckon/invoice/create',
        this.selectedOrder,
        headers
      );

      if (res.data == 'INVOICE CREATED') {
        this.initInvoiceDialog = false;

        setTimeout(() => {
          this.color = 'success';
          this.notificationText =
            'Invoice Successfully Created! Please Wait 1 minutes or less for the invoice to be produced.';
          this.notify = true;
        }, 500);
      }
    },

    initInvoice () {
      this.isLoading = true;
      this.initInvoiceDialog = true;
    },

    checkContent () {
      if (this.searchString != '' && this.searchString != null) {
        // this.searchMenu = true;
      }
    },
    limitStringShort (string) {
      if (string) {
        let limit = string.substr(0, 1);
        return limit;
      }
    },
    limitString (string) {
      if (string) {
        let limit = string.substr(0, 5);
        return limit;
      }
    },
    displayDate (val) {
      return moment(val).format('DD/MM/YYYY');
    },
    async initOrder (order) {
      if (!order) {
        this.$store.commit('orders/resetSelectedOrder');
        this.$router.push('/admin/order/new');
        // let data = {
        //   sk: "",
        //   pk: "",
        //   products: [],
        //   customer: null,
        //   customerId: null,
        //   invoiceNumber: null,
        //   isInvoiceCreated: null,
        //   orderDate: null,
        //   orderId: null,
        //   orderStatus: "PENDING",
        //   totalCost: null,
        // };
        // this.$store.commit("orders/setSelectedOrder", data);
        return;
      } else {
        this.$store.commit('orders/setSelectedOrder', order);
        this.$router.push('/admin/order/' + order.sk);
      }
    },
    async filterOrders () {
      this.isLoading = true;
      let statusFilter;
      let ordersList = [];
      let nextToken = null;

      if (this.selectedOrderStatus == 'ALL') {
        statusFilter = null;
      } else {
        statusFilter = this.selectedOrderStatus;
      }

      let res = await API.graphql({
        query: filterOrders,
        variables: {
          searchString: this.searchText.toLowerCase(),
          orderStatus: statusFilter,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      });
      nextToken = res.data.filterOrders.nextToken;
      ordersList = ordersList.concat(res.data.filterOrders.items);
      while (nextToken) {
        let res = await API.graphql({
          query: filterOrders,
          variables: {
            searchString: this.searchText.toLowerCase(),
            orderStatus: statusFilter,
            nextToken: nextToken,
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        });

        nextToken = res.data.filterOrders.nextToken;
        ordersList = ordersList.concat(res.data.filterOrders.items);
      }

      ordersList = ordersList.sort((a, b) => {
        a.orderDate > b.orderDate ? 1 : b.orderDate > a.orderDate ? -1 : 0;
      });

      this.ordersList = ordersList;

      this.isLoading = false;
    },
    initSearch () {
      this.isLoading = true;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.filterOrders();
      }, 300);
    },
    async listOrders () {
      this.isLoading = true;
      this.ordersList = [];
      let res = await API.graphql({
        query: listOrders,
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      });
      let orderList = res.data.listOrders.items.sort((a, b) =>
        a.orderDate > b.orderDate ? 1 : b.status > a.status ? -1 : 0
      );
      this.ordersList = orderList;
      this.isLoading = false;
    },
    async listProducts () {
      this.productsList = [];
      let res = await API.graphql({
        query: listProducts,
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      });
      let productsList = res.data.listProducts.items.sort((a, b) =>
        a.productName > b.productName ? 1 : b.productName > a.productName ? -1 : 0
      );
      this.productsList = productsList;
    },
    async checkUser () {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(user => {
          if (user.signInUserSession.accessToken.payload['cognito:groups'] == 'customers') {
            this.$router.push('/');
          }
        })
        .catch(err => {
          if (err == 'The user is not authenticated') {
            this.$router.push('/');
          }
        });
    },
    convertDate (d) {
      if (d) {
        return d && d.length > 0 ? moment(d).format('DD/MM/yyyy') : '';
      }
    },
    convertUnixDate (d) {
      if (d) {
        return moment.unix(d).format('DD/MM/yy');
      }
    },
    isMobile () {
      if (this.$vuetify.breakpoint.width < 800) {
        return true;
      }
    },
  },

  async mounted () {
    let status = localStorage.getItem('orderFilter');
    if (status) {
      this.selectedOrderStatus = status;
    }
    await this.checkUser();
    await this.filterOrders();
    this.watchSubscriptions();
  },

  computed: {
    dateFilterRangeText () {
      let text = '';
      if (moment(this.dateFilterRange[0]).isSameOrAfter(this.dateFilterRange[1])) {
        text =
          moment(this.dateFilterRange[1]).format('DD/MM/YY') +
          ' to ' +
          moment(this.dateFilterRange[0]).format('DD/MM/YY');
      } else {
        text =
          moment(this.dateFilterRange[0]).format('DD/MM/YY') +
          ' to ' +
          moment(this.dateFilterRange[1]).format('DD/MM/YY');
      }

      return text;
    },
    customers: {
      get () {
        return this.$store.getters['customers/customerList'];
      },
    },
    searchString: {
      get () {
        return this.$store.getters['customers/searchString'];
      },
      set (val) {
        this.$store.commit('customers/setSearchString', val);
      },
    },
    selectedOrder: {
      get () {
        return this.$store.getters['orders/selectedOrder'];
      },
    },
  },

  watch: {
    dateFilterRange: function () {
      this.filterByDateRange();
    },
    initInvoiceDialog: function (val) {
      if (val == true) {
        this.isLoading = false;
      }
    },
    selectedOrderStatus: function (val) {
      //Reset the date range
      this.dateFilterRange = [];
      localStorage.setItem('orderFilter', val);
      this.filterOrders();
    },
    // selectedFilterStatus: function () {
    //   if (this.selectedFilterStatus === "COMPLETED") {
    //     this.selectedOrder.orderStatus = "COMPLETED";
    //   } else if (this.selectedFilterStatus === "PENDING") {
    //     this.selectedOrder.orderStatus = "PENDING";
    //   } else if (this.selectedFilterStatus === "PACKED") {
    //     this.selectedOrder.orderStatus = "PACKED";
    //   } else {
    //     this.selectedOrder.orderStatus = "CANCELLED";
    //   }
    // },
  },

  components: {
    // AdminNavigation,
  },

  data () {
    return {
      subscriptionList: [],
      initInvoiceDialog: false,
      customer: null,
      limitedSearchResult: [],
      // orderStatuses: ["COMPLETED", "PENDING", "PACKED", "CANCELLED"],
      rules: [v => !!v || 'This field is required'],
      confirmDeleteDialog: false,
      random: '',
      selectedCustomer: '',
      selectedCustomerSk: '',
      createOrderDialog: false,
      isLoading: false,
      searchText: '',
      colors: {
        COMPLETED: 'green',
        PENDING: 'orange',
        PACKED: 'blue',
        CANCELLED: 'red',
      },
      searchItems: '',
      productsList: [],
      orderDateDialog: false,
      isAdd: null,
      color: null,
      notify: false,
      notificationText: '',
      customerList: [],
      selectedOrderStatus: 'PENDING',
      filterStatus: ['ALL', 'DEFAULT', 'PENDING', 'PACKED', 'COMPLETED', 'CANCELLED'],
      categoryList: [],
      // selectedFilterStatus: "",
      selectedDate: null,
      dateFilterDialog: false,
      productCategory: ['Test', '123', 'XYZ'],
      filters: false,
      filterDialog: false,
      menu: false,
      dateFilterRange: [],
      batchInvoiceLoading: false,
      batchInvoiceText: '',
      batchInvoiceTextShow: false,
      headers: [
        {
          text: 'Company',
          sortable: false,
          value: 'companyName',
        },
        {
          text: 'Status',
          value: 'orderStatus',
          sortable: false,
        },
        {
          text: 'Purchase Order',
          value: 'purchaseOrder',
          sortable: false,
        },
        {
          text: 'Invoice Created',
          value: 'invoiceRef',
          sortable: false,
        },
        {
          text: 'Order ID',
          value: 'orderId',
          sortable: false,
        },
        {
          text: 'Order Date',
          value: 'orderDate',
          align: 'start',
          sortable: true,
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
        },
      ],
      ordersList: [],
      actions: [{ title: 'Edit' }, { title: 'Delete' }],
    };
  },
};
</script>

<style scoped>
/* disables rotation animation when selected v-auctocomplete input boxes */
.v-autocomplete.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: none;
}

.right-input >>> input {
  text-align: right;
}
</style>
