<template>
	<v-card>
		<v-fab-transition>
			<v-tooltip top color="primary">
				<template #activator="{ on, attrs }">
					<v-btn
						@click="getReckonSalesStats()"
						v-on="on"
						v-bind="attrs"
						color="secondary"
						dark
						bottom
						right
						fab
						absolute
						style="position: fixed; bottom: 10px"
						elevation="4"
					>
						<v-icon>mdi-chart-areaspline</v-icon>
					</v-btn>
				</template>
				<span> Load Data </span>
			</v-tooltip>
		</v-fab-transition>
		<v-dialog v-model="initLoading" persistent width="300">
			<v-card color="success" height="110" class="pa-4">
				<h4>Waiting for Reckon data..</h4>
				<v-progress-linear indeterminate color="white" class="mb-2" />

				<v-btn class="mt-3" outlined color="black" @click="cancelDashboardRequests()">Cancel</v-btn>
			</v-card>
		</v-dialog>
		<!-- <AdminNavigation /> -->
		<v-container :class="!isMobile() ? `pa-12 ml-6` : ``" fluid>
			<v-card tile outlined elevation="2">
				<!-- Three cards summary thing -->
				<v-col>
					<v-row class="ma-0">
						<v-col cols="12" lg="4" md="4" sm="4">
							<v-card color="#0074C7" elevetaion="4" style="border-radius: 21px">
								<v-icon class="pt-2 white--text" color="black" size="46px"> mdi-account </v-icon>
								<h1 class="pa-4 white--text">{{ customerList.length }}</h1>
								<h4 class="white--text">Total Customers</h4>
							</v-card>
						</v-col>

						<v-col cols="12" lg="4" md="4" sm="4">
							<v-card color="secondary" elevetaion="4" style="border-radius: 21px">
								<v-icon class="pt-2 white--text" color="black" size="46px">
									mdi-currency-usd
								</v-icon>
								<h1 class="pa-4 white--text">{{ numberWithComma(dailySales) }}</h1>
								<h4 class="white--text">Daily Sales</h4>
							</v-card>
						</v-col>
						<v-col cols="12" lg="4" md="4" sm="4">
							<v-card color="#00488D" elevetaion="4" style="border-radius: 21px">
								<v-icon class="pt-2 white--text" color="black" size="46px">
									mdi-currency-usd
								</v-icon>
								<h1 class="pa-4 white--text">{{ numberWithComma(monthlySales) }}</h1>
								<h4 class="white--text">Monthly Sales</h4>
							</v-card>
						</v-col>
					</v-row>

					<!-- Recent Orders -->
					<v-col cols="12" lg="12" md="12" sm="12">
						<v-card class="pa-2">
							<v-row no-gutters>
								<v-card-title class="pb-6" style="font-size: 28px"> Orders History </v-card-title>
								<v-spacer></v-spacer>
							</v-row>
							<v-data-table
								:headers="headers"
								:items="ordersList"
								:items-per-page="20"
								:loading="isLoading"
								:footer-props="{ 'items-per-page-options': [20] }"
								mobile-breakpoint="0"
								sort-by="orderDate"
								sort-desc
							>
								<template v-slot:[`item.customerName`]="{ item }">
									<tr>
										<td v-if="item.customer">
											{{ item.customer.firstName + ` ` + item.customer.lastName }}
										</td>
										<td v-else>Unknown Customer (not in db)</td>
									</tr>
								</template>

								<template v-slot:[`item.orderId`]="{ item }">
									<tr>
										<td>
											{{ limitString(item.orderId).toUpperCase() }}
										</td>
									</tr>
								</template>

								<template v-slot:[`item.orderStatus`]="{ item }">
									<tr>
										<td>
											<v-chip style="color: white" :color="colors[item.orderStatus]">
												{{ isMobile() ? limitStringShort(item.orderStatus) : item.orderStatus }}
											</v-chip>
										</td>
									</tr>
								</template>

								<template v-slot:[`item.orderDate`]="{ item }">
									<tr>
										<td class="pl-3">
											{{ displayDate(item.orderDate) }}
										</td>
									</tr>
								</template>

								<template v-slot:[`item.actions`]="{ item }">
									<tr>
										<td class="pl-3">
											<v-tooltip top>
												<template #activator="{ on, attrs }">
													<v-icon
														v-on="on"
														v-bind="attrs"
														color="primary"
														size="20"
														@click="viewOrder(item)"
														>mdi-eye</v-icon
													>
												</template>
												<span> View Order </span>
											</v-tooltip>
										</td>
									</tr>
								</template>
							</v-data-table>
						</v-card>
					</v-col>

					<!-- Donut Chart -->
					<v-col cols="12">
						<v-card>
							<v-card-title>Top 10 Customers By Date Range (Default Monthly)</v-card-title>
							<v-row class="ma-0 mx-0 px-0 pa-0">
								<v-col cols="12" md="4">
									<v-text-field
										:value="displayDate(selectedFromDate)"
										@click="fromDateDialog = true"
										outlined
										readonly
										append-icon="mdi-calendar"
										label="From"
										dense
									/>
								</v-col>
								<v-col cols="12" md="4">
									<v-text-field
										:value="displayDate(selectedToDate)"
										@click="toDateDialog = true"
										outlined
										readonly
										append-icon="mdi-calendar"
										label="To"
										dense
									/>
								</v-col>

								<v-col cols="12" md="4" class="d-flex flex-row-reverse">
									<v-btn block @click="confirmChartDates()" color="primary"> Submit </v-btn>
								</v-col>
							</v-row>

							<v-row justify="center" class="text-left">
								<apexchart width="500" type="donut" :options="options" :series="series"></apexchart>
							</v-row>
						</v-card>
					</v-col>

					<!-- Version # -->
					<v-row class="ma-0 mx-0 pa-0 px-0 pl-4 pt-4">
						<p style="opacity: 30%; font-size: 14px">1.0.0</p>
					</v-row>

					<!-- From Date picker -->
					<v-dialog v-model="fromDateDialog" ref="fromDateRef" max-width="280">
						<v-card tile>
							<v-date-picker
								v-model="selectedFromDate"
								show-adjacent-months
								scrollable
								elevation="15"
							>
								<v-spacer></v-spacer>
								<v-btn text color="red" @click="fromDateDialog = false"> Cancel </v-btn>
								<v-btn text color="green" @click="$refs.fromDateRef.save(selectedFromDate)">
									Select
								</v-btn>
							</v-date-picker>
						</v-card>
					</v-dialog>

					<!-- To Date picker -->
					<v-dialog v-model="toDateDialog" ref="toDateRef" max-width="280">
						<v-card tile>
							<v-date-picker
								v-model="selectedToDate"
								show-adjacent-months
								scrollable
								elevation="15"
							>
								<v-spacer></v-spacer>
								<v-btn text color="red" @click="toDateDialog = false"> Cancel </v-btn>
								<v-btn text color="green" @click="$refs.toDateRef.save(selectedToDate)">
									Select
								</v-btn>
							</v-date-picker>
						</v-card>
					</v-dialog>

					<!-- <v-card-title v-if="total >= 1"> <b> Total: </b> ${{ numberWithComma(total) }} </v-card-title> -->
					<!-- <v-row class="pa-3">
            <v-col
              cols="12"
              lg="6"
              md="6"
              sm="6"
            >
              <v-card>
                <v-card-title>
                  Daily Top 10 Customers
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="getDailyBarChartSales()"
                    fab
                    small
                    color="primary"
                    :loading="isLoading"
                  >
                    <v-icon> mdi-reload </v-icon>
                  </v-btn>
                </v-card-title>
                <apexchart
                  type="bar"
                  :options="dailyChartOptions"
                  :series="series"
                ></apexchart>
              </v-card>
            </v-col>
            
            <v-col
              cols="12"
              lg="6"
              md="6"
              sm="6"
            >
              <v-card>
                <v-card-title>
                  Monthly Top 10 Customers
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="getMonthlyBarChartSales()"
                    fab
                    small
                    color="primary"
                    :loading="isLoading"
                  >
                    <v-icon> mdi-reload </v-icon>
                  </v-btn>
                </v-card-title>
                <apexchart
                  type="bar"
                  :options="monthlyChartOptions"
                  :series="monthlySeries"
                ></apexchart>
              </v-card>
            </v-col>

            <v-col
              cols="12"
              lg="12"
              md="12"
              sm="12"
            >
              <v-card>
                <v-card-title>
                  Year-To-Date Top 10 Customers
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="getYearlyBarChartSales()"
                    fab
                    small
                    color="primary"
                    :loading="isLoading"
                  >
                    <v-icon> mdi-reload </v-icon>
                  </v-btn>
                </v-card-title>
                <apexchart
                  height="600"
                  type="bar"
                  :options="yearlyChartOptions"
                  :series="yearlySeries"
                ></apexchart>
              </v-card>
            </v-col>
          </v-row> -->
				</v-col>
			</v-card>
		</v-container>
	</v-card>
</template>

<script>
	// import AdminNavigation from "../../components/AdminViewComponents/AdminNavigation.vue";
	import { Auth, API } from 'aws-amplify';
	import { listAdminPanelOrders, listCustomers } from '@/graphql/queries';
	import moment from 'moment';
	import axios from 'axios';

	export default {
		methods: {
			viewOrder(order) {
				if (order) {
					this.$router.push(`/admin/order/${order.sk}`);
				}
			},
			cancelDashboardRequests() {
				this.initLoading = false;
			},
			async confirmChartDates() {
				let auth = await Auth.currentAuthenticatedUser();
				let headers = {
					headers: {
						authorization: 'Bearer ' + auth.signInUserSession.accessToken.jwtToken,
					},
				};
				this.initLoading = true;
				let selectedDates = {
					from: this.selectedFromDate,
					to: this.selectedToDate,
				};
				let res = await axios.post(
					this.$store.getters['global/endpoint'] + '/api/reckon/admin-donut-chart/query',
					selectedDates,
					headers
				);
				if (res) {
					this.total = res.data.value;
					let data = res.data.customers;
					let customerNames = [];
					let value = [];
					for (let i = 0; i < data.length; i++) {
						value.push(parseFloat(data[i].value));
						customerNames.push(data[i].customerName);
					}
					// add customer names
					this.options = {
						labels: customerNames,
					};
					// customer spent
					this.series = value;
				}
				this.initLoading = false;
			},
			numberWithComma(x) {
				if (x == `$null`) {
					return 0;
				} else {
					return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
				}
			},
			async getYearlyBarChartSales() {
				this.isLoading = true;
				let yearly = await axios.get(
					this.$store.getters['global/endpoint'] + '/api/reckon/sales-year-to-date/query'
				);
				// console.log(yearly);
				this.isLoading = false;
				if (yearly) {
					let newData = [];
					let customerNames = [];
					let topYearlyCustomers = yearly.data;

					for (let i = 0; i < topYearlyCustomers.length; i++) {
						newData.push(topYearlyCustomers[i].value);
						customerNames.push(topYearlyCustomers[i].customerName);
					}
					//Update Data
					this.yearlySeries = [
						{
							data: newData,
						},
					];

					//Update Customer Names
					this.yearlyChartOptions = {
						xaxis: {
							categories: customerNames,
						},
					};

					this.isLoading = false;
				}
			},
			async getMonthlyBarChartSales() {
				this.isLoading = true;
				let monthly = await axios.get(
					this.$store.getters['global/endpoint'] + '/api/reckon/sales-month-to-date/query'
				);
				if (monthly) {
					let newData = [];
					let customerNames = [];
					let topMonthlyCustomers = monthly.data;

					for (let i = 0; i < topMonthlyCustomers.length; i++) {
						newData.push(topMonthlyCustomers[i].value);
						customerNames.push(topMonthlyCustomers[i].customerName);
					}
					//Update Data
					this.monthlySeries = [
						{
							// name: this.displayDate(Date.now()),
							data: newData,
						},
					];

					//Update Customer Names
					this.monthlyChartOptions = {
						xaxis: {
							categories: customerNames,
						},
					};
					this.isLoading = false;
				}
			},
			async getDailyBarChartSales() {
				// let auth = await Auth.currentAuthenticatedUser();
				// let headers = {
				//   headers: {
				//     authorization:
				//       "Bearer " + auth.signInUserSession.accessToken.jwtToken,
				//   },
				// };

				this.isLoading = true;
				let daily = await axios.get(
					this.$store.getters['global/endpoint'] + '/api/reckon/sales-daily/query'
				);
				if (daily) {
					let newData = [];
					let customerNames = [];
					let topDailyCustomers = daily.data.topCustomersDaily;

					for (let i = 0; i < topDailyCustomers.length; i++) {
						newData.push(topDailyCustomers[i].value);
						customerNames.push(topDailyCustomers[i].customerName);
					}
					//Update Data
					this.series = [
						{
							// name: this.displayDate(Date.now()),
							data: newData,
						},
					];

					//Update Customer Names
					this.dailyChartOptions = {
						xaxis: {
							categories: customerNames,
						},
					};
					this.isLoading = false;
				}
			},

			async getReckonSalesStats() {
				let auth = await Auth.currentAuthenticatedUser();
				let headers = {
					headers: {
						authorization: 'Bearer ' + auth.signInUserSession.accessToken.jwtToken,
					},
				};
				this.initLoading = true;
				this.confirmChartDates();
				axios
					.all([
						axios.get(
							this.$store.getters['global/endpoint'] + '/api/reckon/sales-month-to-date/query',
							headers
						),
						axios.get(
							this.$store.getters['global/endpoint'] + '/api/reckon/sales-daily/query',
							headers
						),
					])
					.then(
						axios.spread((monthly, daily) => {
							this.monthlySales = '$' + monthly.data.monthlySales;
							this.dailySales =
								daily.data.dailySalesTotal == null ? 0 : '$' + daily.data.dailySalesTotal;

							this.initLoading = false;
						})
					)
					.catch(err => {
						console.log(err);
					});
			},

			async listCustomers() {
				this.customerList = [];
				let res = await API.graphql({
					query: listCustomers,
					authMode: 'AMAZON_COGNITO_USER_POOLS',
				});
				let customerList = res.data.listCustomers.items;
				customerList = customerList.filter(el => el.reckonAttributes.isActive);
				this.customerList = customerList;
			},
			displayDate(val) {
				if (val) {
					return moment(val).format('DD/MM/YYYY');
				}
			},
			limitStringShort(string) {
				if (string) {
					let limit = string.substr(0, 1);
					return limit;
				}
			},
			limitString(string) {
				if (string) {
					let limit = string.substr(0, 5);
					return limit;
				}
			},
			async listOrders() {
				this.isLoading = true;
				this.ordersList = [];
				let res = await API.graphql({
					query: listAdminPanelOrders,
					authMode: 'AMAZON_COGNITO_USER_POOLS',
				});

				let orderList = res.data.listAdminPanelOrders.items.sort((a, b) =>
					a.orderDate > b.orderDate ? 1 : b.status > a.status ? -1 : 0
				);
				this.ordersList = orderList;
				this.isLoading = false;
			},
			async checkUser() {
				await Auth.currentAuthenticatedUser({
					bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
				})
					.then(user => {
						if (user.signInUserSession.accessToken.payload['cognito:groups'] == 'customers') {
							this.$router.push('/');
						}
					})
					.catch(err => {
						if (err == 'The user is not authenticated') {
							this.$router.push('/');
						}
					});
			},
			isMobile() {
				if (this.$vuetify.breakpoint.width < 800) {
					return true;
				}
			},
		},

		computed: {
			customers: {
				get() {
					return this.$store.getters['customers/customerList'];
				},
			},
			searchString: {
				get() {
					return this.$store.getters['customers/searchString'];
				},
				set(val) {
					this.$store.commit('customers/setSearchString', val);
				},
			},
			selectedOrder: {
				get() {
					return this.$store.getters['orders/selectedOrder'];
				},
			},
			dateManager: {
				get() {
					let convertedDate;
					if (this.selectedOrder.orderDate) {
						convertedDate = moment(this.selectedOrder.orderDate).format('YYYY-MM-DD');
					} else {
						moment(Date.now()).format('YYYY-MM-DD');
					}
					return convertedDate;
				},
				set(val) {
					let convertBackDate = moment(val).format('x');
					let realDate = (this.selectedOrder.orderDate = parseInt(convertBackDate));
					return realDate;
				},
			},
		},

		watch: {
			total: function (total) {
				this.options = {
					labels: this.options.labels, //Customer Name
					dataLabels: {
						enabled: false,
						formatter: function (val, opts) {
							return '$' + opts.w.config.series[opts.seriesIndex];
						},
					},
					legend: {
						show: true,
						position: 'right',
					},
					plotOptions: {
						pie: {
							donut: {
								labels: {
									show: true,
									name: {
										show: true,
										fontSize: '22px',
										fontFamily: 'Comfortaa',
										color: '#dfsda',
										offsetY: -10,
									},
									value: {
										show: true,
										fontSize: '16px',
										fontFamily: 'Comfortaa',

										color: undefined,
										offsetY: 16,
										formatter: function (val) {
											return '$' + parseFloat(val).toFixed(2);
										},
									},
									total: {
										show: true,
										fontSize: '22px',
										fontFamily: 'Comfortaa',
										color: '#dfsda',
										offsetY: -10,
										label: 'Total',
										formatter: function () {
											return '$' + parseFloat(total).toFixed(2);
										},
									},
								},
							},
						},
					},
					tooltip: {
						enabled: false,
						y: {
							formatter: function (val) {
								return '$' + val;
							},
						},
					},
				};
			},
		},

		async mounted() {
			await this.checkUser();
			await this.listOrders();
			await this.listCustomers();
		},

		components: {
			// AdminNavigation,
		},

		data() {
			return {
				selectedOrderStatus: 'ALL',
				initLoading: false,
				total: 0,
				fromDateDialog: false,
				toDateDialog: false,
				selectedFromDate: null,
				selectedToDate: null,
				options: {
					labels: [], //Customer Name
					dataLabels: {
						enabled: false,
						formatter: function (val, opts) {
							return '$' + opts.w.config.series[opts.seriesIndex];
						},
					},
					legend: {
						show: true,
						position: 'right',
					},
					plotOptions: {
						pie: {
							donut: {
								labels: {
									show: true,
									name: {
										show: true,
										fontSize: '22px',
										fontFamily: 'Comfortaa',
										color: '#dfsda',
										offsetY: -10,
									},
									value: {
										show: true,
										fontSize: '16px',
										fontFamily: 'Comfortaa',

										color: undefined,
										offsetY: 16,
										formatter: function (val) {
											return '$' + parseFloat(val).toFixed(2);
										},
									},
									total: {
										show: true,
										fontSize: '22px',
										fontFamily: 'Comfortaa',
										color: '#dfsda',
										offsetY: -10,
										label: 'Total',
										formatter: function () {
											return '$0';
										},
									},
								},
							},
						},
					},
					tooltip: {
						enabled: false,
						y: {
							formatter: function (val) {
								return '$' + val;
							},
						},
					},
				},
				series: [5, 5, 5, 5, 5], // value
				dailySales: 0,
				monthlySales: 0,
				// Year to Date Chart Options
				// yearlyChartOptions: {
				//   chart: {
				//     id: 'vuechart-yearly'
				//   },
				//   xaxis: {
				//     categories: [],
				//   }
				// },
				// yearlySeries: [{
				//   name: '',
				//   data: []
				// }],

				// Monthly Chart Options
				// monthlyChartOptions: {
				//   chart: {
				//     id: 'vuechart-monthly'
				//   },
				//   xaxis: {
				//     categories: [],
				//   },
				// },
				// monthlySeries: [{
				//   name: '',
				//   data: []
				// }],

				// Daily Chart Options
				// dailyChartOptions: {
				//   chart: {
				//     id: 'vuechart-daily'
				//   },
				//   xaxis: {
				//     categories: [],
				//   }
				// },
				// series: [{
				//   name: '',
				//   data: []
				// }],

				searchText: '',
				isLoading: false,
				customerList: [],
				ordersList: [],
				colors: {
					COMPLETED: 'green',
					PENDING: 'orange',
					PACKED: 'blue',
					CANCELLED: 'red',
				},
				headers: [
					{
						text: 'Customer',
						align: 'start',
						sortable: false,
						value: 'customer.companyName',
					},
					{
						text: 'Order ID',
						value: 'orderId',
						align: 'start',
						sortable: false,
					},
					{
						text: 'Status',
						value: 'orderStatus',
						sortable: false,
					},
					{
						text: 'Order Date',
						value: 'orderDate',
						sortable: true,
					},
					{
						text: 'Actions',
						value: 'actions',
						sortable: false,
					},
				],
				historyDate: 'TODAY',
				items: ['THIS WEEK', 'THIS MONTH', 'TODAY'],
			};
		},
	};
</script>

<style>
	.notificationCard {
		z-index: 99;
		top: 60px;
		position: fixed;
		right: 5px;
		border: 2px solid #003366;
	}
	.safe {
		top: 0;
		z-index: 99;
		width: 100vw;
	}

	/* hide scrollbar but allow scrolling */
	.hideScroll {
		-ms-overflow-style: none; /* for Internet Explorer, Edge */
		scrollbar-width: none; /* for Firefox */
		overflow-y: scroll;
	}

	.hideScroll::-webkit-scrollbar {
		display: none; /* for Chrome, Safari, and Opera */
	}
</style>
