<template>
  <div>
    <!-- <AdminNavigation /> -->
    <!-- <div v-if="!customer && !selectedRecurOrder && !isAdd">

      <h1
        class="mt-12 pt-12"
        v-show="invalidOrder"
      >Invalid Order</h1>
      <v-scroll-x-transition>
        <v-icon
          v-if="truck"
          size="200"
          color="primary"
        >mdi-truck-alert-outline</v-icon>
      </v-scroll-x-transition>
      <br>
      <v-btn
        v-show="invalidOrder"
        height="100"
        outlined
        width="250"
        @click="$router.push('/admin/orders')"
      >Back to Orders</v-btn>
    </div> -->

    <div :class="isMobile() ? `` : `ml-16`">
      <v-card-title>
        <div :style="mode ? `color: white` : `color: #black`">
          Recurring Order Template
        </div>
        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <!--  stopSchedule() -->
            <v-btn
              v-on="on"
              v-bind="attrs"
              @click="
                selectedRecurOrder.isActive = !selectedRecurOrder.isActive
              "
              :color="selectedRecurOrder.isActive ? `green` : `grey`"
              text
            >
              <v-icon large> mdi-clipboard-text-clock </v-icon>
            </v-btn>
          </template>
          <span> Toggle active </span>
        </v-tooltip>

        <v-tooltip v-if="!isAdd" bottom color="red">
          <template #activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              @click="confirmDeleteDialog = true"
              color="red"
              text
            >
              <v-icon large> mdi-delete </v-icon>
            </v-btn>
          </template>
          <span> Delete Order </span>
        </v-tooltip>

        <!-- confirm delete dialog -->
        <v-dialog
          v-model="confirmDeleteDialog"
          persistent
          style="position: aboslut; z-index: 999999"
          max-width="360"
        >
          <v-card>
            <v-card-title>
              Confirm Delete
              <v-spacer></v-spacer>
              <v-icon @click="cancelAction()"> mdi-close </v-icon>
            </v-card-title>
            <v-card-text style="font-weight: 900">
              Are you sure you want to delete this order?
            </v-card-text>
            <v-spacer></v-spacer>
            <v-card-actions>
              <v-row justify="end" class="ma-0">
                <v-btn
                  @click="confirmDeleteDialog = false"
                  class="mr-2"
                  color="red"
                  outlined
                >
                  Cancel
                </v-btn>
                <v-btn
                  @click="deleteRecurringOrder(selectedRecurOrder)"
                  :loading="isLoading"
                  color="primary"
                >
                  Confirm
                </v-btn>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-icon @click="cancelAction()" large> mdi-close </v-icon>
      </v-card-title>

      <v-form ref="form">
        <v-row class="mr-2">
          <v-spacer></v-spacer>
          <v-col cols="3">
            <v-text-field
              v-model="selectedRecurOrder.freight"
              prepend-inner-icon="mdi-currency-usd"
              outlined
              label="Freight Charge"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="selectedRecurOrder.cutting"
              prepend-inner-icon="mdi-currency-usd"
              outlined
              label="Cutting Charge"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-0 ml-3 mr-3 mt-2 mb-1">
          <v-spacer></v-spacer>
          <div v-if="!isAdd" class="mr-4"></div>

          <!-- Create Btn -->
          <v-btn
            dark
            rounded
            width="100%"
            color="blue"
            @click="isAdd ? createRecurOrder() : updateRecurringOrder()"
          >
            {{ isAdd ? `Create Scheduled Order` : `Update Scheduled Order` }}
          </v-btn>
        </v-row>
        <v-row class="ma-0 pa-0">
          <v-col cols="12" lg="4" md="4" sm="4">
            <!-- Customer Card -->
            <v-card
              outlined
              elevation="2"
              :height="isMobile() ? `100%` : `65vh`"
              class="overflow-auto container"
            >
              <v-card-title>
                <v-icon class="pa-2" size="31" color="blue">
                  mdi-account
                </v-icon>
                <strong> Customer </strong>
              </v-card-title>
              <v-container>
                <template>
                  <v-autocomplete
                    color="blue"
                    item-color="blue"
                    v-model="selectedRecurOrder.customer"
                    :items="
                      searchString >= 0 ? customerList : limitedSearchResult
                    "
                    :search-input.sync="searchString"
                    item-text="companyName"
                    label="Customer"
                    append-icon="mdi-account-search"
                    dense
                    outlined
                    hide-no-data
                    cache-items
                    :loading="isLoading"
                    @focus="checkContent"
                    @keydown="initSearchBar"
                    return-object
                    :rules="rules"
                    no-filter
                  >
                    <template slot="item" slot-scope="data">
                      {{ data.item.companyName }}
                    </template>
                    <template slot="selection" slot-scope="data">
                      {{ data.item.companyName }}
                    </template>
                  </v-autocomplete>
                </template>

                <div
                  v-if="selectedRecurOrder.customer"
                  class="text-left mb-6"
                  style="margin-top: -20px"
                >
                  <span style="font-size: 12px">Company Name:</span>
                  <h4>{{ selectedRecurOrder.customer.companyName }}</h4>
                  <span style="font-size: 12px">Contact Person:</span>
                  <h4>
                    {{
                      selectedRecurOrder.customer.firstName +
                      " " +
                      selectedRecurOrder.customer.lastName
                    }}
                  </h4>
                  <span style="font-size: 12px">Email:</span>
                  <h4>{{ selectedRecurOrder.customer.email }}</h4>
                  <span style="font-size: 12px">Phone:</span>
                  <h4>{{ selectedRecurOrder.customer.mobile }}</h4>
                </div>

                <v-divider></v-divider>
                <div v-if="selectedRecurOrder.customer">
                  <v-row class="pa-2">
                    <v-col
                      :cols="
                        selectedRecurOrder.frequency == 'Weekly' ||
                        selectedRecurOrder.frequency == 'Fortnightly'
                          ? 6
                          : 12
                      "
                      class="ma-0 mx-0 pa-0 px-0"
                    >
                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="startingDateComputed"
                            class="mt-6"
                            label="Starting Date"
                            append-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            outlined
                            dense
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="selectedRecurOrder.startingDate"
                          @input="menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>

                    <v-col
                      :cols="
                        selectedRecurOrder.frequency == 'Weekly' ||
                        selectedRecurOrder.frequency == 'Fortnightly'
                          ? 6
                          : 12
                      "
                      class="ma-0 mx-0 pa-0 px-0"
                    >
                      <v-select
                        v-model="selectedRecurOrder.frequency"
                        :items="frequencyList"
                        dense
                        class="mt-6"
                        label="Frequency"
                        outlined
                        :rules="rules"
                        color="blue"
                        item-color="blue"
                      />
                    </v-col>

                    <v-col cols="12" class="ma-0 mx-0 pa-0 px-0">
                      <v-select
                        dense
                        v-if="
                          selectedRecurOrder.frequency == 'Weekly' ||
                          selectedRecurOrder.frequency == 'Fortnightly'
                        "
                        v-model="computedSelectedDays"
                        :items="[
                          'Sun',
                          'Mon',
                          'Tue',
                          'Wed',
                          'Thu',
                          'Fri',
                          'Sat',
                        ]"
                        chips
                        label="Select Days"
                        multiple
                        small-chips
                        outlined
                      >
                      </v-select>
                    </v-col>

                    <v-select
                      :rules="[
                        (v) => v != null || 'Please Select Delivery Method',
                      ]"
                      @change="checkFreight()"
                      background-color="cyan"
                      color="black"
                      dense
                      item-text="name"
                      item-value="val"
                      v-model="selectedRecurOrder.isDelivery"
                      :items="deliveryMethods"
                      outlined
                      label="Delivery Method"
                      :append-icon="
                        selectedRecurOrder.isDelivery
                          ? `mdi-truck-delivery`
                          : `mdi-package-up`
                      "
                      :disabled="isLoading || priceLoading"
                    ></v-select>
                  </v-row>
                </div>
              </v-container>
            </v-card>
          </v-col>

          <v-col cols="12" lg="8" md="8" sm="8">
            <v-card height="65vh" style="overflow-y: scroll" class="container">
              <v-card-title>
                <strong> Products </strong>
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="checkCustomer()"
                  @click="addProductDialog = true"
                  color="blue"
                  dark
                >
                  <v-icon dark>mdi-plus</v-icon> Add Product
                </v-btn>
              </v-card-title>

              <!-- add product dialog -->
              <v-dialog
                v-model="addProductDialog"
                persistent
                scrollable
                max-width="1200px"
                transition="dialog-transition"
              >
                <v-card height="100%" min-height="105vh">
                  <v-card-title>
                    <strong> Add Product </strong>
                    <v-spacer></v-spacer>
                    <v-icon @click="addProductDialog = false" large>
                      mdi-close
                    </v-icon>
                  </v-card-title>

                  <!-- Products Table -->
                  <v-container>
                    <v-data-table
                      :loading="productsList.length <= 0 ? true : false"
                      :headers="headers"
                      :items="productsList"
                      :items-per-page="50"
                      :footer-props="{
                        'items-per-page-options': [50, 100, 150, -1],
                      }"
                      class="elevation-1"
                      mobile-breakpoint="0"
                    >
                      <!-- Header stuff -->
                      <template v-slot:top>
                        <v-row class="pa-4 d-flex ma-0 mx-0 pa-0 px-0">
                          <v-col cols="12" lg="8" md="8" sm="8" xs="8">
                            <v-text-field
                              v-model="searchText"
                              label="Search Product"
                              outlined
                              dense
                              @keyup="initSearch()"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" lg="4" md="4" sm="4" xs="4">
                            <v-select
                              v-model="selectedCategoryFilter"
                              dense
                              label="Filter by Category"
                              outlined
                              :items="categoryListFilter"
                              item-text="categoryName"
                              item-value="categoryName"
                            />
                          </v-col>
                        </v-row>
                      </template>
                      <template v-slot:[`item.productName`]="{ item }">
                        <tr>
                          <td class="pl-3">
                            [<b style="color: #1b8600">{{ item.productCode }}</b
                            >] {{ " " + item.productName }}
                          </td>
                        </tr>
                      </template>
                      <template v-slot:[`item.actions`]="{ item }">
                        <tr>
                          <td class="pl-3">
                            <v-tooltip top color="secondary">
                              <template #activator="{ on, attrs }">
                                <v-icon
                                  v-on="on"
                                  v-bind="attrs"
                                  color="green"
                                  size="20"
                                  @click="initProductQuantity(item)"
                                >
                                  {{ item.isAdded ? `mdi-check` : `mdi-plus` }}
                                </v-icon>
                              </template>
                              <span>
                                {{
                                  item.isAdded
                                    ? `Already Added`
                                    : `Edit Quantity`
                                }}
                              </span>
                            </v-tooltip>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-container>
                </v-card>
              </v-dialog>

              <v-row class="ma-0 mx-0">
                <v-col cols="12" lg="12" md="12" sm="12">
                  <div>
                    <v-container>
                      <v-divider></v-divider>
                    </v-container>
                  </div>
                  <!-- Items -->
                  <v-card
                    max-height="100%"
                    outlined
                    elevation="4"
                    class="ml-4 mr-4 mt-2 mb-4"
                    v-for="(item, i) in selectedRecurOrder.products"
                    v-bind:key="i"
                  >
                    <v-row class="ma-0">
                      <!-- Product Image -->
                      <v-col cols="1" lg="1" md="1" sm="1">
                        <v-img
                          alt="item.productName"
                          :src="
                            item.imageUrl
                              ? item.imageUrl
                              : 'https://png.pngtree.com/png-clipart/20210121/ourlarge/pngtree-food-meat-piece-clip-art-png-image_2770140.jpg'
                          "
                          contain
                          max-height="40"
                        ></v-img>
                      </v-col>

                      <!-- Item Name -->
                      <v-col cols="8" lg="5" md="5" sm="5" class="text-left">
                        [<b style="color: #1b8600">{{ item.productCode }}</b
                        >] {{ item.productName }}
                        <!-- <div class="pt-2">
                          <template v-for="item in item">
                            {{ item.productName }}
                          </template>
                        </div> -->
                      </v-col>
                      <v-col cols="3" lg="6" md="6" sm="6">
                        <v-progress-circular
                          v-if="priceLoading"
                          size="20"
                          indeterminate
                          color="secondary"
                        ></v-progress-circular>
                        <h5
                          v-if="
                            !priceLoading &&
                            (item.defaultPrice || item.newPrice)
                          "
                          class="mt-2"
                          @click="
                            {
                            }
                          "
                          :style="
                            item.newPrice
                              ? `color:${$vuetify.theme.themes.light.primary}; cursor: pointer`
                              : `cursor: pointer`
                          "
                        >
                          {{
                            item.newPrice
                              ? item.newPrice
                              : item.defaultPrice.toFixed(2)
                          }}/{{ item.productUnit }}
                        </h5>
                      </v-col>
                    </v-row>
                    <v-row class="ma-0 justify-center">
                      <!-- Quantity -->
                      <v-col cols="12" lg="6" md="6" sm="6" class="text-right">
                        <v-text-field
                          label="Quantity"
                          @keypress="isNumber($event)"
                          class="right-input"
                          v-model="item.quantity"
                          outlined
                          dense
                          :rules="rules"
                          :prepend-inner-icon="
                            item.productUnit == 'kg'
                              ? `mdi-weight-kilogram`
                              : ``
                          "
                        />
                      </v-col>

                      <!-- Remove -->
                      <v-col
                        class="mt-2 text-center"
                        cols="12"
                        lg="1"
                        md="1"
                        sm="1"
                      >
                        <v-tooltip color="" bottom>
                          <template #activator="{ on, attrs }">
                            <v-icon
                              @click="initRemove(item, i)"
                              class="mr-2"
                              color="red"
                              v-on="on"
                              v-bind="attrs"
                            >
                              mdi-close
                            </v-icon>
                          </template>
                          <span> Remove Item </span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </div>

    <v-container v-show="false">
      <v-divider></v-divider>
      <!--HIDDEN SUBTOTAL IN CASE IN THE FUTURE IT NEEDS TO BE DISPLAYED-->
      <v-col cols="7">
        <v-card-text>
          <div>${{ calculateSubtotal() }}</div>
          <div>${{ gst }}</div>
          <div v-if="selectedRecurOrder.isDelivery == true">
            ${{ parseFloat(freight).toFixed(2) }}
          </div>
          <div class="mt-4">
            <strong>${{ total }}</strong>
          </div>
        </v-card-text>
      </v-col>
    </v-container>

    <v-dialog v-model="editProductQntyDialog" max-width="500">
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn @click="editProductQntyDialog = false" icon>
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-title class="justify-center">
          {{ selectedProduct.productName }}
        </v-card-title>
        <v-row justify="center" class="pa-0 px-0 ma-0 mx-0">
          <v-col md="4">
            <v-text-field
              v-model="selectedProductQnty"
              outlined
              v-on:keypress="numbersOnly"
              hide-details
              class="shrink mx-4"
              dense
            />
          </v-col>
        </v-row>
        <v-btn
          class="text-center ma-2"
          @click="addProductToOrder(selectedProduct)"
          text
        >
          Set
        </v-btn>
      </v-card>
    </v-dialog>

    <!-- notification  -->
    <v-snackbar
      v-model="notify"
      class="pt-16"
      top
      :right="isMobile ? true : false"
      :timeout="2500"
      :color="color"
    >
      {{ notificationText }}
    </v-snackbar>
  </div>
</template>

<script>
import moment from "moment";
import { Auth, API } from "aws-amplify";
import {
  addRecurringOrder,
  updateRecurringOrder,
  deleteRecurringOrder,
} from "@/graphql/mutations";
import {
  getRecurringOrder,
  listProducts,
  searchCustomers,
  listCategories,
  filterProducts,
  listCustomPricesByPriceLevel,
  getProduct,
  getSettingsVariables,
} from "@/graphql/queries";
// import AdminNavigation from "@/components/AdminViewComponents/AdminNavigation.vue";

import axios from "axios";

export default {
  methods: {
    calculateSubtotal() {
      let subTotal = 0;
      this.selectedRecurOrder.products.forEach((el) => {
        const quantity = parseFloat(el.quantity);
        const price = el.newPrice
          ? parseFloat(el.newPrice)
          : parseFloat(el.defaultPrice);

        subTotal += quantity * price;
      });

      this.subTotal = subTotal.toFixed(2);
      this.gst = (subTotal * 0.15).toFixed(2);
      this.total = (
        subTotal +
        parseFloat(this.gst) +
        parseFloat(this.selectedRecurOrder.freight)
      ).toFixed(2);

      return subTotal.toFixed(2);
    },
    async getSettingsVariables() {
      let res = await API.graphql({
        query: getSettingsVariables,
      });

      let data = res.data.getSettingsVariables;

      this.cutoffTime = data.cutoffTime;
      this.freightCharge = data.freightCharge;
      this.freight = data.freightCharge;
      this.freightFreeOver = data.freightFreeOver;
    },
    checkFreight() {
      //Get saved freight cost
      const freight = this.freightCharge;
      //Get saved total limit to get free freight
      const limit = this.freightFreeOver;

      if (this.selectedRecurOrder.isDelivery == false) {
        this.selectedRecurOrder.freight = 0;
      } else if (this.selectedRecurOrder.isDelivery == true) {
        if (this.total > limit) {
          this.selectedRecurOrder.freight = 0;
        } else {
          this.selectedRecurOrder.freight = freight;
        }
      }
    },
    checkCustomer() {
      if (!this.selectedRecurOrder.customer) {
        return true;
      } else {
        return false;
      }
    },
    uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
    },
    async stopSchedule() {
      if (confirm("Are You Sure You Want to stop this?")) {
        await axios.post(
          this.$store.getters["global/endpoint"] +
            "/api/testing/scheduledOrder/update",
          this.selectedRecurOrder
        );
      }
    },
    async getRecurOrder() {
      this.priceLoading = true;
      await this.listCategories();
      const orderId = this.$route.params.id;

      let res = await API.graphql({
        query: getRecurringOrder,
        variables: {
          sk: orderId,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      let order = res.data.getRecurringOrder;

      this.selectedRecurOrder = await order;

      for (let i = 0; i < this.selectedRecurOrder.products.length; i++) {
        const element = this.selectedRecurOrder.products[i];

        let prod = await API.graphql({
          query: getProduct,
          variables: {
            productId: element.sk,
          },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });

        element.defaultPrice = prod.data.getProduct.defaultPrice;
      }

      this.loadCustomPrices();

      this.$forceUpdate();

      this.priceLoading = false;

      this.firstLoad = false;

      if (this.selectedRecurOrder.isDelivery == false)
        this.selectedRecurOrder.isDelivery = false;
      await this.listProducts();
    },

    async loadCustomPrices() {
      this.priceLoading = true;

      const priceLevel = this.selectedRecurOrder.customer
        ? this.selectedRecurOrder.customer.priceLevel
        : null;

      if (!priceLevel) {
        this.priceLoading = false;
        return;
      }

      if (priceLevel && this.customPriceList.length == 0) {
        let res = await API.graphql({
          query: listCustomPricesByPriceLevel,
          authMode: "AMAZON_COGNITO_USER_POOLS",
          variables: {
            priceLevel: priceLevel,
          },
        });
        this.customPriceList = res.data.listCustomPricesByPriceLevel.items;
      }

      this.selectedRecurOrder.products.forEach((product) => {
        this.customPriceList.forEach((customPrice) => {
          if (product.sk == customPrice.productId) {
            product.newPrice = customPrice.customPrice.toFixed(2);
            product.customPrice = customPrice;
          }
        });
      });

      this.priceLoading = false;
    },

    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    initRemove(item, index) {
      let productsList = this.selectedRecurOrder.products;
      for (let i = 0; i < productsList.length; i++) {
        if (productsList[i].sk == item.sk && index > -1) {
          productsList.splice(index, 1);
        }
      }
    },
    numbersOnly(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async checkIsAdded(val) {
      let orderProducts = this.selectedRecurOrder.products;
      if (val) {
        for (let i = 0; i < val.length; i++) {
          for (let j = 0; j < orderProducts.length; j++) {
            if (val[i].sk === orderProducts[j].sk) {
              this.notificationText = "Product Already Added";
              this.color = "info";
              this.notify = true;
            }
          }
        }
      } else {
        for (let i = 0; i < this.productsList.length; i++) {
          for (let j = 0; j < orderProducts.length; j++) {
            if (this.productsList[i].sk === orderProducts[j].sk) {
              this.productsList[i].isAdded = true;
            }
          }
        }
      }
    },
    initProductQuantity(item) {
      if (item.isAdded == true) {
        this.notificationText = "Product Already Added";
        this.color = "info";
        this.notify = true;
      } else {
        this.selectedProduct = item;
        this.selectedProductQnty = item.quantity;
        this.editProductQntyDialog = true;
      }
    },
    async addProductToOrder() {
      this.selectedProduct.quantity = this.selectedProductQnty;
      this.selectedProduct.isAdded = true;
      this.selectedRecurOrder.products.push(this.selectedProduct);

      this.loadCustomPrices();
      this.editProductQntyDialog = false;
    },
    async filterProducts() {
      try {
        let catFilter;
        let productList = [];
        let nextToken = null;

        if (this.selectedCategoryFilter == "All") {
          catFilter = null;
        } else {
          catFilter = this.selectedCategoryFilter;
        }

        let res = await API.graphql({
          query: filterProducts,
          variables: {
            searchString: this.searchText.toLowerCase(),
            category: catFilter,
          },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });

        nextToken = res.data.filterProducts.nextToken;
        productList = productList.concat(res.data.filterProducts.items);

        while (nextToken) {
          let res = await API.graphql({
            query: filterProducts,
            variables: {
              searchString: this.searchText.toLowerCase(),
              category: catFilter,
              nextToken: nextToken,
            },
            authMode: "AMAZON_COGNITO_USER_POOLS",
          });

          nextToken = res.data.filterProducts.nextToken;
          productList = productList.concat(res.data.filterProducts.items);
        }

        productList = productList.sort((a, b) =>
          a.productName > b.productName
            ? 1
            : b.productName > a.productName
            ? -1
            : 0
        );

        this.productsList = productList;
        await this.checkIsAdded();

        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
    initSearch() {
      this.isLoading = true;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.filterProducts();
      }, 300);
    },
    async listCategories() {
      let res = await API.graphql({
        query: listCategories,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      let categoryList = res.data.listCategories.items.sort(
        (a, b) => a.categoryName - b.categoryName
      );

      this.categoryListFilter = this.categoryListFilter.concat(categoryList);
      this.categoryList = this.categoryList.concat(categoryList);
    },
    statusColorSelector() {
      switch (this.selectedRecurOrder.orderStatus) {
        case "COMPLETED":
          return "primary";
        case "PENDING":
          return "warning";
        case "CANCELLED":
          return "error";

        default:
          break;
      }
    },
    async searchCustomers() {
      if (
        !this.searchString ||
        this.searchString == "" ||
        this.searchString.length < 3
      ) {
        this.isLoading = false;
        return;
      }
      this.isLoading = true;
      let res = await API.graphql({
        query: searchCustomers,
        variables: {
          nextToken: this.nextToken,
          searchString: this.searchString.toLowerCase(),
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      this.nextToken = res.data.searchCustomers.nextToken;
      this.limitedSearchResult = res.data.searchCustomers.items;

      while (this.limitedSearchResult.length < 7 && this.nextToken != null) {
        let res = await API.graphql({
          query: searchCustomers,
          authMode: "AMAZON_COGNITO_USER_POOLS",
          variables: {
            nextToken: this.nextToken,
            searchString: this.searchString.toLowerCase(),
          },
        });
        this.nextToken = res.data.searchCustomers.nextToken;
        this.limitedSearchResult = res.data.searchCustomers.items;
      }

      this.isLoading = false;
    },
    initSearchBar() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.searchCustomers();
      }, 300);
    },
    initInvoice() {
      this.isLoading = true;
      this.initInvoiceDialog = true;
    },

    checkContent() {
      if (this.searchString != "" && this.searchString != null) {
        this.searchMenu = true;
      }
    },

    async deleteRecurringOrder(order) {
      let sk = order.sk;

      if (order) {
        this.isLoading = true;
        let res = await API.graphql({
          query: deleteRecurringOrder,
          variables: {
            sk: order.sk,
          },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });

        if (res) {
          let auth = await Auth.currentAuthenticatedUser();
          let headers = {
            headers: {
              authorization:
                "Bearer " + auth.signInUserSession.accessToken.jwtToken,
            },
          };
          await axios.post(
            this.$store.getters["global/endpoint"] +
              `/api/production/scheduledOrder/delete`,
            {
              sk,
            },
            headers
          );
        }

        this.confirmDeleteDialog = false;
        this.color = "primary";
        this.notificationText = "Order deleted.";
        this.notify = true;
        this.isLoading = false;
        this.$router.push("/admin/scheduled-orders");
      } else {
        this.color = "orange";
        this.notificationText =
          "An error occured when deleting this order, please try agian.";
        this.notify = true;
      }
    },
    displayDate(val) {
      return moment(val).format("DD/MM/YYYY");
    },
    async updateRecurringOrder() {
      let isValid = this.$refs.form.validate();
      if (!isValid) return;

      let orderProducts = this.selectedRecurOrder.products;
      for (let i = 0; i < orderProducts.length; i++) {
        if (Object.prototype.hasOwnProperty.call(orderProducts[i], "isAdded"))
          delete orderProducts[i].isAdded;
      }

      if (this.selectedRecurOrder.products.length == 0) {
        this.color = "warning";
        this.notificationText =
          "Add one or more products to create recurring order.";
        this.notify = true;
        return;
      }
      this.isLoading = true;
      let userInput = {
        sk: this.selectedRecurOrder.sk,
        customer: this.selectedRecurOrder.customer,
        products: this.selectedRecurOrder.products,
        notes: this.selectedRecurOrder.notes,
        isActive: this.selectedRecurOrder.isActive,
        frequency: this.selectedRecurOrder.frequency,
        selectedDays: this.selectedRecurOrder.selectedDays,
        startingDate: this.selectedRecurOrder.startingDate,
        isDelivery: this.selectedRecurOrder.isDelivery,
        freight: this.selectedRecurOrder.freight,
        cutting: this.selectedRecurOrder.cutting,
      };
      userInput.products = userInput.products.map(
        ({
          productGroup,
          isInStock,
          reckonProductAttributes,
          newPrice,
          notes,
          customPrice,
          ...rest
        }) => {
          isInStock;
          productGroup;
          reckonProductAttributes;
          newPrice;
          notes;
          customPrice;
          return { ...rest };
        }
      );
      if (isValid) {
        let res = await API.graphql({
          query: updateRecurringOrder,
          variables: {
            input: userInput,
          },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });

        let graphqlResult = res.data.updateRecurringOrder;
        let auth = await Auth.currentAuthenticatedUser();
        let headers = {
          headers: {
            authorization:
              "Bearer " + auth.signInUserSession.accessToken.jwtToken,
          },
        };
        if (res) {
          await axios.post(
            this.$store.getters["global/endpoint"] +
              `/api/production/scheduledOrder/create`,
            graphqlResult,
            headers
          );

          // console.log(axiosResult);
        }

        this.isLoading = false;
        this.color = "primary";
        this.notificationText = "Order updated.";
        this.notify = true;
        this.$refs.form.reset();
        this.$router.push("/admin/scheduled-orders");
        return res;
      } else {
        this.color = "orange";
        this.notificationText = "Error updating order.";
        this.notify = true;
        this.isLoading = false;
      }
    },

    async listProducts() {
      this.productsList = [];
      let res = await API.graphql({
        query: listProducts,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      let productsList = res.data.listProducts.items.sort((a, b) =>
        a.productName > b.productName
          ? 1
          : b.productName > a.productName
          ? -1
          : 0
      );
      this.productsList = productsList;
    },
    cancelAction() {
      this.$router.push("/admin/scheduled-orders");
    },

    async createRecurOrder() {
      let isValid = this.$refs.form.validate();

      let orderProducts = this.selectedRecurOrder.products;
      for (let i = 0; i < orderProducts.length; i++) {
        if (Object.prototype.hasOwnProperty.call(orderProducts[i], "isAdded"))
          delete orderProducts[i].isAdded;
      }

      if (isValid) {
        if (this.selectedRecurOrder.products.length == 0) {
          this.color = "warning";
          this.notificationText =
            "Add one or more products to create recurring order.";
          this.notify = true;
          return;
        }
        this.isLoading = true;
        let data = {
          customer: this.selectedRecurOrder.customer,
          products: this.selectedRecurOrder.products,
          notes: this.selectedRecurOrder.notes,
          isActive: this.selectedRecurOrder.isActive,
          frequency: this.selectedRecurOrder.frequency,
          startingDate: this.selectedRecurOrder.startingDate,
          selectedDays: this.selectedRecurOrder.selectedDays,
          isDelivery: this.selectedRecurOrder.isDelivery,
          freight: this.selectedRecurOrder.freight,
          cutting: this.selectedRecurOrder.cutting,
        };

        data.products = data.products.map(
          ({
            isInStock,
            productGroup,
            reckonProductAttributes,
            newPrice,
            customPrice,
            ...rest
          }) => {
            isInStock;
            productGroup;
            newPrice;
            customPrice;
            reckonProductAttributes;
            // console.log(newPrice, customPrice, reckonProductAttributes);
            return { ...rest };
          }
        );

        //create in graphql
        let res = await API.graphql({
          query: addRecurringOrder,
          variables: {
            input: data,
          },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });

        let graphqlResult = res.data.addRecurringOrder;
        let auth = await Auth.currentAuthenticatedUser();
        let headers = {
          headers: {
            authorization:
              "Bearer " + auth.signInUserSession.accessToken.jwtToken,
          },
        };
        // create in backend sql
        if (res) {
          await axios.post(
            this.$store.getters["global/endpoint"] +
              `/api/production/scheduledOrder/create`,
            graphqlResult,
            headers
          );
        }

        // scheduled order endpoint

        this.color = "primary";
        this.notificationText = "Recurring Order Set.";
        this.notify = true;
        this.isLoading = false;
        this.addOrder = false;
        this.$refs.form.reset();
        this.$router.push("/admin/Scheduled-Orders");
        return res;
      } else {
        this.isLoading = false;
        this.color = "orange";
        this.notificationText = "Please fill in the required fields.";
        this.notify = true;
      }
    },
    async checkUser() {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then((user) => {
          if (
            user.signInUserSession.accessToken.payload["cognito:groups"] ==
            "customers"
          ) {
            this.$router.push("/");
          }
        })
        .catch((err) => {
          if (err == "The user is not authenticated") {
            this.$router.push("/");
          }
        });
    },
    convertDate(d) {
      if (d) {
        return d && d.length > 0 ? moment(d).format("DD/MM/yyyy") : "";
      }
    },
    convertUnixDate(d) {
      if (d) {
        return moment.unix(d).format("DD/MM/yy");
      }
    },
    isMobile() {
      if (this.$vuetify.breakpoint.width < 800) {
        return true;
      }
    },
    async checkRoute() {
      let currentPath = this.$router.currentRoute.fullPath;
      if (currentPath.includes("new")) {
        this.isAdd = true;
      } else {
        this.isAdd = false;
        this.getRecurOrder();
      }
    },
  },

  async mounted() {
    await this.checkUser();
    await this.checkRoute();
    await this.getSettingsVariables();
    if (!this.selectedRecurOrder) {
      await this.getRecurOrder();
      return;
    }
    await this.listCategories();
    await this.listProducts();
    await this.loadCustomPrices();
    this.firstLoad = false;
  },

  computed: {
    mode: {
      get() {
        return this.$vuetify.theme.dark;
      },

      set(val) {
        this.$vuetify.theme.dark = val;
      },
    },
    computedSelectedDays: {
      get() {
        return this.selectedRecurOrder.selectedDays;
      },

      set(list) {
        function sortDays(days) {
          var daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
          return daysOfWeek.filter(function (d) {
            return days.indexOf(d) >= 0;
          });
        }
        let arranged = sortDays(list);
        this.selectedRecurOrder.selectedDays = arranged;
      },
    },
    startingDateComputed: {
      get() {
        return moment(this.selectedRecurOrder.startingDate).format(
          "DD/MM/YYYY"
        );
      },
      set(val) {
        this.selectedRecurOrder.startingDate = val;
      },
    },
    customers: {
      get() {
        return this.$store.getters["customers/customerList"];
      },
    },
    searchString: {
      get() {
        return this.$store.getters["customers/searchString"];
      },
      set(val) {
        this.$store.commit("customers/setSearchString", val);
      },
    },
    // selectedRecurOrder: {
    //   get() {
    //     return this.$store.getters["orders/selectedRecurOrder"];
    //   },

    //   set(val) {
    //     this.$store.commit("orders/setselectedRecurOrder", val);
    //   },
    // },
    deliveryDateManager: {
      get() {
        let convertedDate;
        if (this.selectedRecurOrder && this.selectedRecurOrder.orderDate) {
          convertedDate = moment(this.selectedRecurOrder.orderDate).format(
            "YYYY-MM-DD"
          );
        } else {
          moment(Date.now()).format("YYYY-MM-DD");
        }
        return convertedDate;
      },
      set(val) {
        let convertBackDate = moment(val).format("x");
        let realDate = (this.selectedRecurOrder.orderDate =
          parseInt(convertBackDate));
        return realDate;
      },
    },
    dateManager: {
      get() {
        let convertedDate;
        if (this.selectedRecurOrder && this.selectedRecurOrder.orderDate) {
          convertedDate = moment(this.selectedRecurOrder.orderDate).format(
            "YYYY-MM-DD"
          );
        } else {
          moment(Date.now()).format("YYYY-MM-DD");
        }
        return convertedDate;
      },
      set(val) {
        let convertBackDate = moment(val).format("x");
        let realDate = (this.selectedRecurOrder.orderDate =
          parseInt(convertBackDate));
        return realDate;
      },
    },
  },

  watch: {
    editProductQntyDialog: function (val) {
      if (val == false) {
        this.selectedProduct = " ";
      }
    },
    addProductDialog: function (val) {
      console.log("Product Dialog: ", val);
      val ? this.checkIsAdded() : ` `;
    },
    "selectedRecurOrder.products": {
      handler() {
        if (this.firstLoad == true) return;
        this.selectedRecurOrder.isDelivery = null;
        this.selectedRecurOrder.freight = 0;
        this.checkIsAdded();
        // this.checkFreight();
      },
      deep: true,
    },
    "$route.params.id": function () {
      this.getRecurOrder();
    },
    // startingDate: function (val) {
    //   console.log("DATE: ", val);
    // },
    notify: function (val) {
      val ? !val : val;
    },
    selectedCategoryFilter: function () {
      this.filterProducts();
    },
    initInvoiceDialog: function (val) {
      if (val == true) {
        this.isLoading = false;
      }
    },
  },

  components: {
    // AdminNavigation,
  },

  data() {
    return {
      isAdded: false,
      selectedProductQnty: "",
      selectedProduct: "",
      editProductQntyDialog: false,
      firstLoad: true,
      deliveryMethods: [
        { name: "Delivery", val: true },
        { name: "Pick Up", val: false },
      ],
      selectedRecurOrder: {
        isDelivery: null,
        freight: 0,
        cutting: 0,
        notes: null,
        isActive: true,
        customer: null,
        frequency: "Daily",
        products: [],
        selectedDays: [],
        startingDate: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),
      },
      frequencyList: ["Daily", "Weekly"], //'Fortnightly'
      // selectedWeeklyDay: [],
      isAdd: null,
      customPriceList: [],
      priceLoading: true,
      truck: false,
      invalidOrder: false,
      timer: null,
      selectedCategoryFilter: "All",
      categoryListFilter: [{ categoryName: "All", pk: "0", sk: "0" }],
      categoryList: [],
      addProductDialog: false,
      initInvoiceDialog: false,
      customer: null,
      limitedSearchResult: [],
      orderStatuses: ["COMPLETED", "PENDING", "CANCELLED"],
      rules: [(v) => !!v || "This field is required"],
      confirmDeleteDialog: false,
      random: "",
      selectedCustomer: "",
      selectedCustomerSk: "",
      isLoading: false,
      searchText: "",
      colors: {
        COMPLETED: "green",
        PENDING: "orange",
        CANCELLED: "red",
      },
      searchItems: "",
      productsList: [],
      orderDateDialog: false,
      color: null,
      notify: false,
      notificationText: "",
      customerList: [],
      headers: [
        {
          text: "Product Name",
          sortable: true,
          value: "productName",
        },
        {
          text: "Category",
          sortable: true,
          value: "categoryName",
        },
        {
          text: "Description",
          sortable: false,
          value: "description",
        },
        // {
        //   text: "Quantity",
        //   sortable: false,
        //   value: "quantity",
        // },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ],
      menu2: false,

      subTotal: 0,
      gst: 0,
      freight: 5.0,
      total: 0,

      cutoffTime: "05:00",
      freightCharge: 0,
      freightFreeOver: 0,
    };
  },
};
</script>

<style scoped>
/* disables rotation animation when selected v-auctocomplete input boxes */
.v-autocomplete.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: none;
}

.right-input >>> input {
  text-align: right;
}

/* hide scrollbar but still able to scroll */
.container {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.v-autocomplete.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: none;
}
</style>
