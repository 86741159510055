/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updateSettingsVariable = /* GraphQL */ `
  mutation UpdateSettingsVariable($input: SettingsVariablesInput) {
    updateSettingsVariable(input: $input) {
      pk
      sk
      cutoffTime
      freightCharge
      freightFreeOver
    }
  }
`;
export const resendTempPassword = /* GraphQL */ `
  mutation ResendTempPassword($input: AWSJSON) {
    resendTempPassword(input: $input)
  }
`;
export const sendOrderEmail = /* GraphQL */ `
  mutation SendOrderEmail($input: AWSJSON) {
    sendOrderEmail(input: $input)
  }
`;
export const sendContactEmail = /* GraphQL */ `
  mutation SendContactEmail($input: AWSJSON) {
    sendContactEmail(input: $input)
  }
`;
export const addProductNotes = /* GraphQL */ `
  mutation AddProductNotes($input: ProductNotesInput) {
    addProductNotes(input: $input) {
      pk
      sk
      notes
      customerId
      productId
    }
  }
`;
export const updateProductNotes = /* GraphQL */ `
  mutation UpdateProductNotes($input: ProductNotesInput) {
    updateProductNotes(input: $input) {
      pk
      sk
      notes
      customerId
      productId
    }
  }
`;
export const deleteProductNotes = /* GraphQL */ `
  mutation DeleteProductNotes($input: ProductNotesInput) {
    deleteProductNotes(input: $input) {
      pk
      sk
      notes
      customerId
      productId
    }
  }
`;
export const updateOrderProductionRef = /* GraphQL */ `
  mutation UpdateOrderProductionRef($input: OrderInput) {
    updateOrderProductionRef(input: $input)
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification($sk: String) {
    deleteNotification(sk: $sk) {
      pk
      sk
      user
      message
      notificationTime
      notificationValueSk
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification($input: NotificationInput) {
    createNotification(input: $input) {
      pk
      sk
      user
      message
      notificationTime
      notificationValueSk
    }
  }
`;
export const addRecurringOrder = /* GraphQL */ `
  mutation AddRecurringOrder($input: RecurringOrderInput) {
    addRecurringOrder(input: $input) {
      pk
      sk
      customer {
        pk
        sk
        firstName
        lastName
        email
        mobile
        priceLevel
        companyName
        customerLevel
        reckonAttributes {
          reckonId
          editSequence
          isActive
          companyAddress {
            addr1
            addr2
            addr3
            city
            postalCode
          }
        }
        customerCode
      }
      products {
        pk
        sk
        productId
        productName
        categoryName
        description
        quantity
        packedWeight
        imageUrl
        productUnit
        productCode
        defaultPrice
        newPrice
        isEnabled
        reckonTxnId
        notes
        customerId
      }
      notes
      isActive
      frequency
      startingDate
      selectedDays
      freight
      cutting
      isDelivery
    }
  }
`;
export const updateRecurringOrder = /* GraphQL */ `
  mutation UpdateRecurringOrder($input: RecurringOrderInput) {
    updateRecurringOrder(input: $input) {
      pk
      sk
      customer {
        pk
        sk
        firstName
        lastName
        email
        mobile
        priceLevel
        companyName
        customerLevel
        reckonAttributes {
          reckonId
          editSequence
          isActive
          companyAddress {
            addr1
            addr2
            addr3
            city
            postalCode
          }
        }
        customerCode
      }
      products {
        pk
        sk
        productId
        productName
        categoryName
        description
        quantity
        packedWeight
        imageUrl
        productUnit
        productCode
        defaultPrice
        newPrice
        isEnabled
        reckonTxnId
        notes
        customerId
      }
      notes
      isActive
      frequency
      startingDate
      selectedDays
      freight
      cutting
      isDelivery
    }
  }
`;
export const deleteRecurringOrder = /* GraphQL */ `
  mutation DeleteRecurringOrder($sk: String) {
    deleteRecurringOrder(sk: $sk) {
      pk
      sk
      customer {
        pk
        sk
        firstName
        lastName
        email
        mobile
        priceLevel
        companyName
        customerLevel
        reckonAttributes {
          reckonId
          editSequence
          isActive
          companyAddress {
            addr1
            addr2
            addr3
            city
            postalCode
          }
        }
        customerCode
      }
      products {
        pk
        sk
        productId
        productName
        categoryName
        description
        quantity
        packedWeight
        imageUrl
        productUnit
        productCode
        defaultPrice
        newPrice
        isEnabled
        reckonTxnId
        notes
        customerId
      }
      notes
      isActive
      frequency
      startingDate
      selectedDays
      freight
      cutting
      isDelivery
    }
  }
`;
export const updateOrderStatus = /* GraphQL */ `
  mutation UpdateOrderStatus($input: OrderInput) {
    updateOrderStatus(input: $input) {
      pk
      sk
      customer {
        pk
        sk
        firstName
        lastName
        email
        mobile
        priceLevel
        companyName
        customerLevel
        reckonAttributes {
          reckonId
          editSequence
          isActive
          companyAddress {
            addr1
            addr2
            addr3
            city
            postalCode
          }
        }
        customerCode
      }
      customerId
      orderStatus
      orderDate
      orderId
      products {
        pk
        sk
        productId
        productName
        categoryName
        description
        quantity
        packedWeight
        imageUrl
        productUnit
        productCode
        defaultPrice
        newPrice
        isEnabled
        reckonTxnId
        notes
        customerId
      }
      invoiceRef {
        reckonRefNumber
        reckonTxnId
        reckonEditSequence
      }
      productionRef
      notes
      purchaseOrder
      line
      isDelivery
      freight
      cutting
    }
  }
`;
export const addToTrolley = /* GraphQL */ `
  mutation AddToTrolley($input: CustomerTrolleyInput) {
    addToTrolley(input: $input) {
      pk
      sk
      productName
      unit
      quantity
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct($input: ProductsInput) {
    createProduct(input: $input) {
      pk
      sk
      productName
      categoryName
      description
      quantity
      imageUrl
      productUnit
      productCode
      defaultPrice
      isEnabled
      reckonProductAttributes {
        listID
        editSequence
        isActive
        productType
      }
      productGroup
      isInStock
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct($input: ProductsInput) {
    updateProduct(input: $input) {
      pk
      sk
      productName
      categoryName
      description
      quantity
      imageUrl
      productUnit
      productCode
      defaultPrice
      isEnabled
      reckonProductAttributes {
        listID
        editSequence
        isActive
        productType
      }
      productGroup
      isInStock
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct($sk: String) {
    deleteProduct(sk: $sk) {
      pk
      sk
      productName
      categoryName
      description
      quantity
      imageUrl
      productUnit
      productCode
      defaultPrice
      isEnabled
      reckonProductAttributes {
        listID
        editSequence
        isActive
        productType
      }
      productGroup
      isInStock
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory($input: CategoryInput) {
    createCategory(input: $input) {
      pk
      sk
      categoryName
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory($input: CategoryInput) {
    updateCategory(input: $input) {
      pk
      sk
      categoryName
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory($sk: String) {
    deleteCategory(sk: $sk) {
      pk
      sk
      categoryName
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer($input: CustomerInput!) {
    createCustomer(input: $input) {
      pk
      sk
      firstName
      lastName
      email
      mobile
      priceLevel
      companyName
      customerLevel
      reckonAttributes {
        reckonId
        editSequence
        isActive
        companyAddress {
          addr1
          addr2
          addr3
          city
          postalCode
        }
      }
      customerCode
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer($input: CustomerInput!) {
    updateCustomer(input: $input) {
      pk
      sk
      firstName
      lastName
      email
      mobile
      priceLevel
      companyName
      customerLevel
      reckonAttributes {
        reckonId
        editSequence
        isActive
        companyAddress {
          addr1
          addr2
          addr3
          city
          postalCode
        }
      }
      customerCode
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer($sk: String) {
    deleteCustomer(sk: $sk) {
      pk
      sk
      firstName
      lastName
      email
      mobile
      priceLevel
      companyName
      customerLevel
      reckonAttributes {
        reckonId
        editSequence
        isActive
        companyAddress {
          addr1
          addr2
          addr3
          city
          postalCode
        }
      }
      customerCode
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder($input: OrderInput) {
    createOrder(input: $input) {
      pk
      sk
      customer {
        pk
        sk
        firstName
        lastName
        email
        mobile
        priceLevel
        companyName
        customerLevel
        reckonAttributes {
          reckonId
          editSequence
          isActive
          companyAddress {
            addr1
            addr2
            addr3
            city
            postalCode
          }
        }
        customerCode
      }
      customerId
      orderStatus
      orderDate
      orderId
      products {
        pk
        sk
        productId
        productName
        categoryName
        description
        quantity
        packedWeight
        imageUrl
        productUnit
        productCode
        defaultPrice
        newPrice
        isEnabled
        reckonTxnId
        notes
        customerId
      }
      invoiceRef {
        reckonRefNumber
        reckonTxnId
        reckonEditSequence
      }
      productionRef
      notes
      purchaseOrder
      line
      isDelivery
      freight
      cutting
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder($input: OrderInput) {
    updateOrder(input: $input) {
      pk
      sk
      customer {
        pk
        sk
        firstName
        lastName
        email
        mobile
        priceLevel
        companyName
        customerLevel
        reckonAttributes {
          reckonId
          editSequence
          isActive
          companyAddress {
            addr1
            addr2
            addr3
            city
            postalCode
          }
        }
        customerCode
      }
      customerId
      orderStatus
      orderDate
      orderId
      products {
        pk
        sk
        productId
        productName
        categoryName
        description
        quantity
        packedWeight
        imageUrl
        productUnit
        productCode
        defaultPrice
        newPrice
        isEnabled
        reckonTxnId
        notes
        customerId
      }
      invoiceRef {
        reckonRefNumber
        reckonTxnId
        reckonEditSequence
      }
      productionRef
      notes
      purchaseOrder
      line
      isDelivery
      freight
      cutting
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder($sk: String) {
    deleteOrder(sk: $sk) {
      pk
      sk
      customer {
        pk
        sk
        firstName
        lastName
        email
        mobile
        priceLevel
        companyName
        customerLevel
        reckonAttributes {
          reckonId
          editSequence
          isActive
          companyAddress {
            addr1
            addr2
            addr3
            city
            postalCode
          }
        }
        customerCode
      }
      customerId
      orderStatus
      orderDate
      orderId
      products {
        pk
        sk
        productId
        productName
        categoryName
        description
        quantity
        packedWeight
        imageUrl
        productUnit
        productCode
        defaultPrice
        newPrice
        isEnabled
        reckonTxnId
        notes
        customerId
      }
      invoiceRef {
        reckonRefNumber
        reckonTxnId
        reckonEditSequence
      }
      productionRef
      notes
      purchaseOrder
      line
      isDelivery
      freight
      cutting
    }
  }
`;
export const createAdmin = /* GraphQL */ `
  mutation CreateAdmin($input: CustomerInput) {
    createAdmin(input: $input)
  }
`;
export const removeAdmin = /* GraphQL */ `
  mutation RemoveAdmin($input: CustomerInput) {
    removeAdmin(input: $input)
  }
`;
export const addCustomPrice = /* GraphQL */ `
  mutation AddCustomPrice($input: CustomPriceInput) {
    addCustomPrice(input: $input) {
      pk
      sk
      product {
        pk
        sk
        productName
        categoryName
        description
        quantity
        imageUrl
        productUnit
        productCode
        defaultPrice
        isEnabled
        reckonProductAttributes {
          listID
          editSequence
          isActive
          productType
        }
        productGroup
        isInStock
      }
      priceLevelDetails {
        pk
        sk
        name
        reckonListID
        reckonEditSequence
      }
      productId
      customPrice
      priceLevel
    }
  }
`;
export const deleteCustomPrice = /* GraphQL */ `
  mutation DeleteCustomPrice($input: CustomPriceInput) {
    deleteCustomPrice(input: $input) {
      pk
      sk
      product {
        pk
        sk
        productName
        categoryName
        description
        quantity
        imageUrl
        productUnit
        productCode
        defaultPrice
        isEnabled
        reckonProductAttributes {
          listID
          editSequence
          isActive
          productType
        }
        productGroup
        isInStock
      }
      priceLevelDetails {
        pk
        sk
        name
        reckonListID
        reckonEditSequence
      }
      productId
      customPrice
      priceLevel
    }
  }
`;
export const createPriceLevel = /* GraphQL */ `
  mutation CreatePriceLevel($input: PriceLevelInput) {
    createPriceLevel(input: $input) {
      pk
      sk
      name
      reckonListID
      reckonEditSequence
    }
  }
`;
export const deletePriceLevel = /* GraphQL */ `
  mutation DeletePriceLevel($priceLevel: String) {
    deletePriceLevel(priceLevel: $priceLevel) {
      pk
      sk
      name
      reckonListID
      reckonEditSequence
    }
  }
`;
