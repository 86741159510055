import { API } from 'aws-amplify';
import { listProducts, filterProductsForCustomers } from '@/graphql/queries';
export default {
	namespaced: true,
	state: {
		selectedCategory: null,
		selectedProduct: null,
		items: [
			{
				text: 'Products',
				disabled: false,
				href: '/order',
			},
			//selected category
			{
				text: '',
				disabled: false,
				//href should be dynamic too?
				// when specific category is selected
				//and route is pressed it should also filter products? Sowy

				// DO SOMETHING LIKE THIS HEHE :
				// this.$store.commit("categories/setSelectedCategoryFilter", "All");

				// href: '/order',
				// link: false,
			},
			//current selected product / single item
			{
				text: '',
				disabled: true,
				// href: '/order/id',
			},
		],
	},

	mutations: {
		setSelectedCategory(state, payload) {
			state[payload.key][payload.index].text = payload.value;
		},
		setSelectedProduct(state, payload) {
			state[payload.key][payload.index].text = payload.value;
		},
	},
	actions: {
		async listProducts() {
			if (this.$route.query) {
				this.searchString = this.$route.query.search;
			}
			this.isLoading = true;
			let res = await API.graphql({
				query: listProducts,
				variables: {
					searchString: this.searchString,
					nextToken: this.nextToken,
				},
				authMode: 'AMAZON_COGNITO_USER_POOLS',
			});

			this.nextToken = res.data.listProducts.nextToken;
			this.products = res.data.listProducts.items;

			this.isLoading = false;
		},
		async filterProductsByCategory() {
			let res = await API.graphql({
				query: filterProductsForCustomers,
				variables: {
					category: this.selectedCategoryFilter,
					searchString: this.searchString,
					nextToken: this.nextToken,
				},
				authMode: 'AMAZON_COGNITO_USER_POOLS',
			});

			if (!this.nextToken) {
				this.products = res.data.filterProductsForCustomers.items;
			} else {
				this.products = this.products.concat(res.data.filterProductsForCustomers.items);
			}

			this.nextToken = res.data.filterProductsForCustomers.nextToken;
		},
	},

	getters: {
		items: state => state.items,
		selectedCategory: state => state.selectedCategory,
		selectedProduct: state => state.selectedProduct,
	},
};
