<template>
  <div>
    <ConfirmDialog ref="confirm" />

    <v-app-bar elevation="0" app color="primary" style="z-index: 99">
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        v-show="isMobile()"
        color="green darken-4"
      />

      <v-toolbar-title
        class="cursor"
        :class="!isMobile() ? `mt-2` : `text-left`"
        :style="
          !isMobile()
            ? `font-size: 21px; color: black `
            : `font-size: 18px; color: black; width: 900px`
        "
      >
        <div style="width: 100%; text-overflow: clip" class="text-no-wrap">
          <strong @click="routeToHome()"> FARM'S CHICKEN </strong>
          <strong style="color: #b71c1c">如意雞</strong>
        </div>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <!-----Search bar --->

      <div style="width: 50vw" v-if="!isMobile()">
        <ProductSearch />
      </div>

      <v-spacer></v-spacer>

      <div v-if="isLoggedIn" class="mr-4">
        <v-menu offset-y :nudge-width="150">
          <template #activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" color="black">
              mdi-account
            </v-icon>
          </template>
          <v-list>
            <v-list-item @click="routeToUserProfile()" v-if="!isAdmin">
              <v-list-item-title> Profile </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="!isAdmin" @click="routeToOrdersHistory()">
              <v-list-item-title> My Orders </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="isAdmin" @click="routeToAdmin()">
              <v-list-item-title> Admin Dashboard </v-list-item-title>
            </v-list-item>
            <v-divider class="ml-4 mr-4"></v-divider>
            <v-list-item @click="signOut()">
              <v-list-item-title> Logout </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <div v-else class="mr-4">
        <v-btn text small @click="routeToLogin()">
          <strong>Login</strong>
        </v-btn>
      </div>

      <!-- Cart -->
      <div :class="nudge ? 'mr-4 shake' : 'mr-4'">
        <v-badge
          v-if="cartCount >= 1"
          color="red"
          :content="cartCount"
          overlap
          absolute
          style="top: -18px; left: 30px; z-index: 1"
        />
        <v-icon
          :size="nudge ? `30` : `24`"
          class="ml-2"
          medium
          color="black"
          @click="routeToCart()"
          :style="isMobile() ? `margin-right: -20px` : ``"
        >
          mdi-cart-variant
        </v-icon>
      </div>
    </v-app-bar>

    <!-- Secondary app bar  -->
    <v-app-bar
      :style="breakPoint() + ';z-index: 88'"
      flat
      app
      color="primary"
      width="2000"
      elevate-on-scroll
      height="80"
      min-width="100%"
      v-if="$route.name != 'Cart' || !isMobile()"
    >
      <div v-if="isMobile()" style="width: 100vw">
        <!-----Search bar --->
        <ProductSearch />
        <!-----Search bar end --->
      </div>
      <div v-else>
        <!-- Home -->
        <v-btn text @click="routeToHome()" class="black--text"> Home </v-btn>

        <!-- Products Dropdown -->
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              append-icon="mdi-chevron-down"
              text
              v-bind="attrs"
              v-on="on"
              class="black--text"
            >
              ORDER NOW
              <v-icon class="pl-1"> mdi-chevron-down </v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              v-for="(item, index) in items"
              :key="index"
              @click="routeToProducts(item)"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
            <v-container>
              <v-divider />
            </v-container>
            <v-list-item @click="routeToProducts()">
              <v-list-item-title> View All Products </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <!-- About -->
        <!-- <v-btn @click="routeToAbout()" class="black--text" text> About </v-btn> -->

        <!-- Contact -->
        <v-btn @click="routeToContact()" class="black--text" text>
          Contact
        </v-btn>
      </div>
    </v-app-bar>

    <!-- Navigation drawer -->
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      style="position: fixed; overflow-y: auto; z-index: 100"
    >
      <v-list dense class="text-left">
        <!-- Home -->
        <v-list-item @click="routeToHome()" class="pb-6">
          <v-list-item-icon>
            <v-icon color="black"> mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Order Now -->
        <v-list-item class="pb-6">
          <v-list-item-icon>
            <v-icon color="black"> mdi-cart-variant </v-icon>
          </v-list-item-icon>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-content v-on="on" v-bind="attrs">
                <v-list-item-title
                  >Order Now <v-icon class="pl-2"> mdi-chevron-down </v-icon>
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list dense>
              <v-list-item
                v-for="(item, index) in items"
                :key="index"
                @click="routeToProducts(item)"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
              <v-container>
                <v-divider />
              </v-container>
              <v-list-item @click="routeToProducts()">
                <v-list-item-title> View All Products </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item>
        <!-- About -->
        <v-list-item @click="routeToAbout()" class="pb-6">
          <v-list-item-icon>
            <v-icon color="black"> mdi-lightbulb-on </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>About</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Contact -->
        <v-list-item @click="routeToContact()" class="pb-6">
          <v-list-item-icon>
            <v-icon color="black"> mdi-phone </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Contact</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import ProductSearch from "../components/CustomerViewComponents/ProductSearch.vue";
import ConfirmDialog from "../components/ConfirmDialog.vue";

export default {
  components: {
    ProductSearch,
    ConfirmDialog,
  },
  watch: {
    cart: {
      handler() {
        // console.log(val);
        this.nudge = true;

        setTimeout(() => {
          this.nudge = false;
        }, 1000);
      },
      deep: true,
    },
    searchMenu: function (val) {
      if (val == false) {
        this.closeSearchBar();
      }
    },
    // selectedCategoryFilter: function (category) {
    //   if (window.location.pathname != "/order") {
    //     this.$router.push("/order");
    //     this.$store.commit("categories/setSelectedCategoryFilter", category);
    //   } else {
    //     this.$store.commit("categories/setSelectedCategoryFilter", category);
    //   }
    // },
  },
  computed: {
    selectedCategoryFilter: {
      get() {
        return this.$store.getters["categories/selectedCategoryFilter"];
      },
      set(val) {
        this.$store.commit("categories/setSelectedCategoryFilter", val);
      },
    },
    cart: {
      get() {
        return this.$store.getters["cartTotal/cart"];
      },
    },
    cartCount: {
      get() {
        return this.$store.getters["cartTotal/cartCount"];
      },
    },
    cartItemsCount: {
      get() {
        return this.$store.getters["cartTotal/cartItemsCount"];
      },
    },
  },
  methods: {
    breakPoint() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return `top: 56px`;
        case "sm":
          return `top: 56px`;
        case "md":
          return `top: 64px`;
        case "lg":
          return `top: 64px`;
        case "xl":
          return `top: 64px`;
      }
    },
    // async totalCartItems() {
    //   db.transaction("r", db.trolleyItems, async () => {
    //     let results = await Promise.all([
    //       db.trolleyItems.where("sk").startsWith("01GA0").primaryKeys(),
    //     ]);

    //     let intersection = results.reduce((ids1, ids2) => {
    //       let set = new Set(ids1);
    //       return ids2.filter((id) => set.has(id));
    //     });

    //     let resultArray = await db.trolleyItems.bulkGet(intersection);
    //     let sum = resultArray.map((a) => a.quantity);
    //     let result = sum.reduce((partialSum, a) => partialSum + a, 0);
    //     this.cartTotal = this.$store.commit("cartTotal/addItemCount", result);

    //     console.log(this.cartTotal);
    //   });
    // },
    initSearchBar() {
      this.searchWidth = this.$refs.searchBar.getBoundingClientRect().width;
      this.searchY = this.$refs.searchBar.getBoundingClientRect().y;
      this.searchX = this.$refs.searchBar.getBoundingClientRect().x;

      this.isLoading = true;

      this.searchMenu = true;
    },
    closeSearchBar() {
      this.isLoading = false;
      this.searchMenu = false;
    },
    async isUser() {
      await Auth.currentAuthenticatedUser()
        .then((data) => {
          this.isLoggedIn = true;
          return data;
        })
        .catch((err) => {
          if (err == "The user is not authenticated") {
            this.isLoggedIn = false;
          }
        });
    },
    async checkUser() {
      try {
        let userGroup = await Auth.currentAuthenticatedUser();
        let result = userGroup.signInUserSession.accessToken.payload;
        if (
          result["cognito:groups"] !== undefined &&
          result["cognito:groups"].includes("admin")
        ) {
          this.isAdmin = true;
        } else {
          this.isAdmin = false;
        }
      } catch (error) {
        return;
      }
    },
    async signOut() {
      try {
        let text = "Are you sure you want to logout?";

        if ((await this.$refs.confirm.open("Confirm", text)) == true) {
          this.$store.dispatch("cartTotal/clearCart");
          await Auth.signOut();
          this.routeToLogin();
        }
      } catch (error) {
        console.log("error signing out: ", error);
      }
    },
    routeToAdmin() {
      if (window.location.pathname != "/Admin") {
        this.$router.push("/Admin");
      }
    },
    routeToLogin() {
      if (window.location.pathname != "/login") {
        this.$router.push("/login");
      }
    },
    routeToAbout() {
      if (window.location.pathname != "/about") {
        this.$router.push("/about");
      }
    },
    routeToContact() {
      if (window.location.pathname != "/contact") {
        this.$router.push("/contact");
      }
    },
    routeToUserProfile() {
      if (window.location.pathname != "/profile") {
        this.$router.push("/profile");
      }
    },
    routeToOrdersHistory() {
      if (window.location.pathname != "/myorders") {
        this.$router.push("/myorders");
      }
    },
    checkRoute(name) {
      if (this.$route.name == name) {
        return true;
      } else {
        return false;
      }
    },
    routeToCart() {
      if (window.location.pathname != "/cart") {
        this.$router.push("/cart");
      }
    },
    async routeToProducts(category) {
      if (category == undefined) {
        if (window.location.pathname != "/order") {
          this.$store.commit("products/resetProductList");
          this.$router.push("/order");
        } else {
          if (this.$route.query.search) {
            this.$store.commit("products/resetProductList");
            this.$router.push("/order");
          }
        }

        this.selectedCategoryFilter = "All";
        this.$store.commit("products/setNextTokenFilter", null);
        this.$store.commit("products/setSearchString", null);
      } else {
        if (this.selectedCategoryFilter != category.title) {
          this.$store.commit("products/resetProductList");
        }
        if (this.$route.path == "/order") {
          if (this.$route.query.search) {
            this.$router.push("/order");
          }
          this.selectedCategoryFilter = category.title;
          this.$store.commit("products/setNextTokenFilter", null);
          this.$store.commit("products/setSearchString", null);
        } else {
          this.selectedCategoryFilter = await category.title;
          await this.$store.commit("products/setNextTokenFilter", null);
          await this.$store.commit("products/setSearchString", null);
          this.$router.push("/order");
        }
      }

      // if (window.location.pathname != "/order") {
      //   this.$router.push("/order");
      // }
    },
    routeToHome() {
      if (window.location.pathname != "/") {
        this.$router.push("/");
      }
    },
    isMobile() {
      if (this.$vuetify.breakpoint.width < 800) {
        return true;
      }
    },
  },

  async mounted() {
    await this.$store.dispatch("cartTotal/initCartCount");
    await this.checkUser();
    await this.isUser();
  },

  data: () => ({
    selectedCategory: "",
    nudge: false,
    cartTotal: [],
    searchWidth: 500,
    searchX: 0,
    searchY: 0,
    searchMenu: false,
    isLoading: false,
    isLoggedIn: false,
    isAdmin: false,
    profileMenu: false,
    searchBox: false,
    drawer: false,
    items: [
      { title: "Chicken" },
      { title: "Pork" },
      { title: "Beef" },
      { title: "Lamb" },
      { title: "Duck" },
    ],
  }),
};
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: white;
}

.cursor {
  cursor: pointer;
}

.v-text-field--outlined >>> fieldset {
  border-color: rgba(156, 156, 49, 0.5);
}

.v-app-bar-title__content {
  width: 200px !important;
}

.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
