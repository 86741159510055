<template>
	<div>
		<!-- Banner Img -->
		<v-img
			alt="chicken, yummy chicken, fried chicken, manok, yummy, farm, farms, chicken, https://www.pexels.com/photo/peep-of-brown-chicken-1300375/ "
			class="bannerImg"
			src="@/assets/imgs/about.jpg"
			gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
		>
			<div class="class">
				<h1 class="hello" style="font-size: 100px; color: white">About Us</h1>
				<h4 style="color: white">
					如意鸡(Farm's Chicken)创建于2001年。
					由于实惠的价格，一流的品质,优质的服务。受到客户广泛的支持。我们从零售到批发，从单一的鸡肉供应到猪，牛，羊肉等不间断的丰富我们的产品，多品种的发展，使之更能符合市场的需求。我们一直致力于为各餐厅，快餐店，LUNCH
					BAR, 酒店等供货,服务品质一流，口碑极佳。
					如意鸡拥有自己的加工厂,从整只鸡，猪，牛，羊的处理,到后期装箱,再到送货上门一条龙服务。
					整洁而宽敞的工作环境;熟练的工人队伍，保证了我们的品质恒定，让您无后顾之忧。我们不需要支付店铺租金,再加上大量的购买，您就得了到最大的实惠。
					我们将一如既往地竭诚提供最好的服务，最好的品质，最好的价格。
				</h4>
			</div>
		</v-img>

		<!-- Certs lol idk -->
		<!-- <h1> Fillers not sure </h1>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          lg="6"
          md="6"
          sm="6"
        >
          <v-card
            outlined
            elevation="4"
          >
            <v-img
              alt="cert1"
              src="https://www.farmschicken.co.nz/img/cert1.png"
            />
          </v-card>
        </v-col>

        <v-col
          cols="12"
          lg="6"
          md="6"
          sm="6"
        >
          <v-card
            outlined
            elevation="4"
          >
            <v-img
              alt="cert2"
              src="https://www.farmschicken.co.nz/img/cert2.png"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-container> -->
	</div>
</template>

<script>
	export default {
		methods: {
			isMobile() {
				if (this.$vuetify.breakpoint.width < 800) {
					return true;
				}
			},
		},
	};
</script>

<style>
	.class {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.hello {
		mix-blend-mode: multiply;
	}

	.bannerImg {
		background-position: center;
		background-attachment: fixed;
		min-height: 100vh;
	}
</style>
