export default {
	namespaced: true,
	state: {
		selectedCategory: null,
		selectedCategoryFilter: 'All',
	},
	mutations: {
		setSelectedCategory(state, payload) {
			state.selectedCategory = payload;
		},
		setSelectedCategoryFilter(state, payload) {
			state.selectedCategoryFilter = payload;
		},
		resetSelectedCategory(state) {
			state.selectedCategory = {
				categoryName: '',
				// description: "",
				// quantity: "",
			};
		},
	},
	actions: {
		//
	},
	getters: {
		selectedCategory: state => state.selectedCategory,
		selectedCategoryFilter: state => state.selectedCategoryFilter,
	},
};