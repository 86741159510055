export default {
	namespaced: true,
	state: {
		endpoint: 'https://api.farmschicken.co.nz', //'https://api.farmschicken.co.nz',
		dialogLoading: false,
	},

	mutations: {
		setDialogLoading(state, payload) {
			state.dialogLoading = payload;
		},
	},

	getters: {
		dialogLoading: state => state.dialogLoading,
		endpoint: state => state.endpoint,
	},
};
