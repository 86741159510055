import Vue from 'vue';
import Vuex from 'vuex';
import Breadcrumbs from './breadcrumbs';
import Products from './products';
import Customers from './customers';
import Categories from './categories';
import Orders from './orders';
import productSearch from './productSearch';
import cartTotal from './cartTotal';
import Global from './global';
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		breadcrumbs: Breadcrumbs,
		products: Products,
		customers: Customers,
		categories: Categories,
		orders: Orders,
		productSearch: productSearch,
		cartTotal: cartTotal,
		global: Global,
	},
});
