<template>
	<v-container fill-height>
		<v-row justify="center">
			<v-col class="pt-16 d-flex align-center flex-column" cols="12" lg="12" md="12" sm="12">
				<!-- <v-icon
          x-large
          style="font-size: 125px"
          color="#5eb331"
        > mdi-account </v-icon> -->

				<!-- login form card -->

				<v-card
					:style="isMobile() ? `top:10%` : `top: 30%`"
					v-if="!firstTimeSignIn"
					:elevation="isMobile() ? `0` : `8`"
					background-color="
          #1A2867"
					width="525px"
				>
					<v-container v-if="!forgotPassSubmit">
						<v-form ref="form" lazy-validation>
							<v-card-actions class="justify-center">
								<v-img src="https://www.farmschicken.co.nz/img/logo.png" max-width="100px"></v-img>
							</v-card-actions>

							<v-card-title class="justify-center">
								<!-- <h3 v-if="!forgotPass"> Farms Chicken Login </h3> -->
								<p v-if="forgotPass" style="font-size: 14px">
									Enter your email address and we will send you a verification <br />
									code to reset your password
								</p>
							</v-card-title>
							<v-text-field
								v-model="email"
								type="email"
								:rules="emailRules"
								placeholder="Email"
								outlined
								@keydown.enter="
									() => {
										$event.preventDefault();
									}
								"
								@keypress.enter="
									() => {
										$event.preventDefault();
									}
								"
							/>
							<v-text-field
								v-if="!forgotPass"
								v-model="password"
								:type="viewPass ? `text` : `password`"
								:append-icon="viewPass ? `mdi-eye-off` : `mdi-eye`"
								@click:append="() => (viewPass = !viewPass)"
								placeholder="Password"
								outlined
								@keydown.enter.exact.prevent="signIn()"
							/>
							<v-text-field
								v-if="forgotPassSubmit"
								v-model="password"
								placeholder="Password"
								outlined
								@keydown.enter.exact.prevent="signIn()"
								type="password"
							/>
							<v-spacer></v-spacer>
							<v-btn
								v-if="!forgotPass"
								:loading="isLoading"
								class="mb-3"
								@click="signIn()"
								color="primary"
							>
								Login
							</v-btn>
							<v-row v-else justify="center">
								<v-btn
									@click="
										() => {
											forgotPass = !forgotPass && `${this.$refs.form.reset()}`;
										}
									"
									color="primary"
									outlined
									class="mr-2"
									@keydown.enter.exact.prevent="signIn()"
								>
									Cancel
								</v-btn>
								<!-- forgotPassword() -->
								<v-btn @click="forgotPassword()" color="primary"> Reset Password </v-btn>
							</v-row>
							<br />
							<br />
							<a v-if="!forgotPass" style="text-decoration: none" @click="noLog()">
								Continue without logging in
							</a>
							<v-divider></v-divider>
							<a
								v-if="!forgotPass"
								style="text-decoration: none"
								@click="
									() => {
										forgotPass = !forgotPass && `${this.$refs.form.reset()}`;
									}
								"
							>
								Forgot Password
							</a>
						</v-form>
					</v-container>

					<!-- forgot password authenticate -->
					<v-container v-else>
						<v-form ref="newPassForm">
							<h3 class="pa-2">
								Check your email for the verification <br />
								code sent to you and create your <br />
								new password
							</h3>
							<v-text-field
								v-model="email"
								type="email"
								:rules="emailRules"
								placeholder="Email"
								outlined
							/>
							<v-text-field
								v-model="code"
								type="text"
								:rules="codeRules"
								placeholder="Verification Code"
								outlined
							/>
							<v-text-field
								v-model="newPass"
								:type="viewPass ? `text` : `password`"
								:append-icon="viewPass ? `mdi-eye-off` : `mdi-eye`"
								@click:append="() => (viewPass = !viewPass)"
								:rules="passRules"
								placeholder="New Password"
								outlined
							/>
							<v-text-field
								v-model="confirmPass"
								:type="viewPass ? `text` : `password`"
								:rules="passRules"
								placeholder="Confirm New Password"
								outlined
							/>
							<p v-if="!match" style="color: red">Passwords does not match</p>
							<v-spacer></v-spacer>

							<v-row no-gutters>
								<v-col cols="12">
									<v-btn text :disabled="resendCodeTimer">
										<p
											style="text-decoration: underline; cursor: pointer"
											@click="resendConfirmationCode()"
										>
											Resend Code
										</p>
									</v-btn>
								</v-col>
								<v-col cols="12">
									<v-btn
										:loading="isLoading"
										class="mb-3"
										@click="confirmNewPass()"
										color="primary"
									>
										Change Password
									</v-btn>
								</v-col>
							</v-row>
						</v-form>
					</v-container>
				</v-card>

				<!-- First time sign in card -->
				<v-card
					v-else
					elevation="8"
					background-color="
          #1A2867"
					width="525px"
				>
					<v-container>
						<v-form ref="firstTimeSignInForm" lazy-validation>
							<v-container> Create new password </v-container>
							<v-text-field
								v-model="email"
								type="email"
								:rules="emailRules"
								placeholder="Email"
								outlined
								readonly
							/>
							<!-- <v-card-text class="text-left" style="color: red">
								**New Password Must Have 8 Characters or More.
							</v-card-text> -->
							<v-text-field
								v-model="newPass"
								placeholder="New Password"
								outlined
								:rules="passRules"
								:type="viewPass ? `text` : `password`"
								:append-icon="viewPass ? `mdi-eye-off` : `mdi-eye`"
								@click:append="() => (viewPass = !viewPass)"
							/>
							<v-text-field
								v-model="confirmPass"
								placeholder="Confirm Password"
								outlined
								type="password"
								:rules="passRules"
							/>
							<p v-if="!match" style="color: red; font-size: 12px">Passwords does not match</p>
							<v-spacer></v-spacer>
							<v-btn :loading="isLoading" class="mb-3" @click="newPassword()" color="primary">
								Login
							</v-btn>
							<br />
						</v-form>
					</v-container>
				</v-card>
			</v-col>
		</v-row>

		<!-- notification  -->
		<v-snackbar v-model="notify" class="pt-16" top :timeout="2500" :color="color">
			{{ notificationText }}
		</v-snackbar>
	</v-container>
</template>

<script>
	import { Auth, API } from 'aws-amplify';
	import { getCustomer } from '@/graphql/queries';
	import * as subscriptions from '@/graphql/subscriptions';

	export default {
		watch: {
			resendCodeTimer: function (val) {
				if (val == true) {
					let interval = setInterval(() => {
						this.timer++;
						// console.log(this.timer);
						if (this.timer == 25) {
							this.resendCodeTimer = false;
							clearInterval(interval);
						}
					}, 1000);
				}
			},
		},
		methods: {
			noLog() {
				this.$router.push('/');
			},
			isMobile() {
				if (this.$vuetify.breakpoint.width < 800) {
					return true;
				}
			},
			//confirmation of creating new password of forgotten password
			async confirmNewPass() {
				this.isLoading = true;
				let valid = this.$refs.newPassForm.validate();
				if (valid == true) {
					if (this.newPass != this.confirmPass) {
						//basic check if passwords match
						this.match = false;
						this.isLoading = false;
					} else {
						await Auth.forgotPasswordSubmit(this.email, this.code, this.newPass)
							.then(data => {
								if (data == 'SUCCESS') {
									this.color = 'primary';
									this.notificationText = 'Successfully changed password.';
									this.notify = true;
									this.isLoading = false;
									this.$router.go();
								}
							})
							.catch(err => {
								this.isLoading = false;
								let error = err.toString();
								switch (error) {
									case error.includes('Username/client id combination not found'):
										this.color = 'orange';
										this.notificationText = 'Email not found';
										this.notify = true;
										break;
									case error.includes('Invalid verification code provided'):
										this.color = 'orange';
										this.notificationText = 'Invalid code provided';
										this.notify = true;
										break;
									default:
										break;
								}
								// console.log(error);
							});
					}
				}
			},
			// forgot password for forgotten password of already activated created account
			async forgotPassword() {
				let valid = this.$refs.form.validate();
				// console.log(valid);
				if (valid == true) {
					try {
						await Auth.forgotPassword(this.email).then(data => {
							this.forgotPassSubmit = true;
							this.isLoading = false;
							this.color = 'primary';
							this.notificationText = 'Verification code sent to email.';
							this.notify = true;
							return data;
						});
					} catch (error) {
						if (error.toString().includes('User does not exist')) {
							this.color = 'orange';
							this.notificationText = 'Email not found.';
							this.notify = true;
						} else if (error.name == 'LimitExceededException') {
							this.color = 'orange';
							this.notificationText = 'To many failed attempts, please try again later.';
							this.notify = true;
						} else if (error.toString().includes('User is disabled')) {
							this.color = 'error';
							this.notificationText = 'Accoutn disabled';
							this.notify = true;
						} else {
							console.log('Error: ', error);
						}
					}
				} else {
					this.color = 'orange';
					this.notificationText = 'Please enter your email.';
					this.notify = true;
					this.isLoading = false;
				}
				this.isLoading = false;
			},
			// resend confirmation code for newly created account
			async resendConfirmationCode() {
				let isValid = this.$refs.newPassForm.validate();
				if (isValid == true) {
					this.resendCodeTimer = true;
					await Auth.forgotPassword(this.email)
						.then(data => {
							this.match = true;
							data;
							// console.log(data);
						})
						.catch(err => {
							if (err.toString().includes('LimitExceededException')) {
								this.color = 'orange';
								this.notificationText = 'Attempt limit exceeded, please try again later.';
								this.notify = true;
								this.isLoading = false;
							}
						});
				}
				this.isLoading = false;
			},
			async newPassword() {
				try {
					let isValid = this.$refs.firstTimeSignInForm.validate();
					if (isValid) {
						this.isLoading = true;
						if (this.newPass != this.confirmPass) {
							this.match = false;
							this.isLoading = false;
						} else {
							await Auth.completeNewPassword(this.cognitoUser, this.newPass).then(() => {
								this.cognitoUser = null;
								this.isLoading = false;
								this.color = 'primary';
								this.notificationText = 'Successfully logged in.';
								this.notify = true;
								this.routeToHome();
							});
						}
					} else {
						return;
					}
				} catch (error) {
					this.color = 'error';
					this.notificationText =
						'There was an error updating your account. Please try again or refresh the page.';
					this.notify = true;
					this.isLoading = false;
				}
			},
			routeToLogin() {
				if (window.location.pathname != '/login') {
					this.$router.push('/login');
				}
			},
			async signOut() {
				try {
					this.$store.dispatch('cartTotal/clearCart');
					await Auth.signOut();
					this.routeToLogin();
				} catch (error) {
					console.log('error signing out: ', error);
				}
			},

			async watchCustomerUpdating() {
				//updating customer
				await API.graphql({
					query: subscriptions.updatedCustomer,
					authMode: 'AMAZON_COGNITO_USER_POOLS',
				}).subscribe({
					next: async ({ value }) => {
						if (value) {
							let payload = await Auth.currentAuthenticatedUser();
							let user = payload.username;
							if (value.data.updatedCustomer.sk == user) {
								let isActive = value.data.updatedCustomer.reckonAttributes.isActive;
								if (isActive == false) this.signOut();
							}
						}
					},
					error: error => console.warn(error),
				});
			},
			async signIn() {
				let isValid = this.$refs.form.validate();
				if (isValid) {
					this.isLoading = true;
					try {
						await Auth.signIn(this.email, this.password).then(async user => {
							if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
								this.firstTimeSignIn = true;
								this.cognitoUser = user;
								this.isLoading = false;
							} else {
								if (user.username) {
									let res = await API.graphql({
										query: getCustomer,
										variables: {
											customerId: user.username,
										},
										authMode: 'AMAZON_COGNITO_USER_POOLS',
									});
									let authenticatedUser = res.data.getCustomer;
									this.$store.commit('customers/setAuthenticatedUser', authenticatedUser);
								}
								this.isLoading = false;
								this.color = 'primary';
								this.notificationText = 'Successfully logged in.';
								this.notify = true;
								if (user.signInUserSession.idToken.payload['cognito:groups'].includes('admin')) {
									this.routeToAdmin();
								} else {
									this.watchCustomerUpdating();
									this.routeToHome();
								}
							}
						});
					} catch (err) {
						// console.log(err);
						this.isLoading = false;
						if (err.name === 'UserNotConfirmedException') {
							this.$router.push('/firsttimesignin');
						} else if (err.toString().includes('Incorrect username or password')) {
							this.color = 'error';
							this.notificationText = 'Email or Password is incorrect, please try again.';
							this.notify = true;
							this.isLoading = false;
						} else if (err.toString().includes('User is disabled')) {
							this.color = 'error';
							this.notificationText = 'Account disabled.';
							this.notify = true;
							this.isLoading = false;
						} else if (err.toString().includes('Temporary password has expired')) {
							this.color = 'error';
							this.notificationText =
								'Temporary password has expired. Please contact farms chicken to get a new one.';
							this.notify = true;
							this.isLoading = false;
						} else if (err.toString().includes('User does not exist')) {
							this.color = 'error';
							this.notificationText = 'Email does not exist.';
							this.notify = true;
							this.isLoading = false;
						} else {
							this.color = 'error';
							this.notificationText = 'Error signing in. ';
							this.notify = true;
							this.isLoading = false;
						}
					}
				} else {
					this.color = 'orange';
					this.notificationText = 'Email is required.';
					this.notify = true;
				}
			},
			async routeToHome() {
				let payload = await Auth.currentAuthenticatedUser();
				let user = payload.username;
				let res = await API.graphql({
					query: getCustomer,
					variables: {
						customerId: user,
					},
					authMode: 'AMAZON_COGNITO_USER_POOLS',
				});

				let authenticatedUser = res.data.getCustomer;
				this.$store.commit('customers/setAuthenticatedUser', authenticatedUser);

				if (window.location.pathname != '/') {
					this.$router.push('/');
				}
			},
			routeToAdmin() {
				this.$router.push('/Admin');
			},

			async checkAuthenticatedUser() {
				try {
					await Auth.currentAuthenticatedUser();
					return true;
				} catch {
					return false;
				}
			},
		},

		async mounted() {
			let isAuth = await this.checkAuthenticatedUser();

			if (isAuth) {
				this.$router.push('/admin');
			}
		},

		data() {
			return {
				timer: 0,
				resendCodeTimer: false,
				notificationText: '',
				color: null,
				notify: false,
				code: '',
				forgotPassSubmit: false,
				forgotPass: false,
				cognitoUser: null,
				firstTimeSignIn: false,
				isLoading: false,
				email: '',
				password: '',
				newPass: '',
				confirmPass: '',
				match: true,
				viewPass: false,
				//rules
				emailRules: [v => !!v || 'E-mail is required'],
				passRules: [v => !!v || 'Password is required', v => v.length >= 8 || 'Min 8 characters'],
				codeRules: [v => !!v || 'Verification code is required'],
			};
		},
	};
</script>

<style></style>
