<template>
	<div>
		<v-container :class="!isMobile() ? `px-12 ml-6` : ``" fluid>
			<v-card outlined elevation="4">
				<v-card-title>
					<strong> Category List</strong>
					<v-spacer></v-spacer>
					<v-btn @click="initAddCategory()" elevation="4" color="secondary" :loading="isLoading">
						<v-icon small class="mr-2"> mdi-plus </v-icon>
						ADD CATEGORY
					</v-btn>
				</v-card-title>

				<!-- Add Category Form -->
				<v-dialog
					v-model="addCategoryDialog"
					v-if="selectedCategory"
					persistent
					scrollable
					max-width="800px"
					transition="dialog-transition"
				>
					<v-card height="100%">
						<v-form ref="form">
							<v-card-title>
								<strong> {{ isAdd ? `Add Category` : `Edit Category` }} </strong>
								<v-spacer></v-spacer>
								<v-icon v-if="!isAdd" @click="initDeleteCategory()" color="red" large>
									mdi-delete
								</v-icon>
								<v-icon @click="addCategoryDialog = false" large> mdi-close </v-icon>
							</v-card-title>
							<v-container>
								<v-text-field
									v-model="selectedCategory.categoryName"
									outlined
									label="Category Name"
									:rules="categoryRules"
								/>

								<!-- <v-textarea
                  v-model="selectedCategory.description"
                  outlined
                  dense
                  no-resize
                  label="Category Description"
                /> -->
								<v-card-actions class="pt-6 mr-1">
									<v-row justify="end">
										<v-btn class="mr-2 white--text" color="red lighten-2" @click="cancelAction()">
											Cancel
										</v-btn>
										<v-btn
											@click="isAdd ? createCategory() : updateCategory()"
											:loading="isLoading"
											color="secondary"
										>
											{{ isAdd ? `Add Category` : `Update Category` }}
										</v-btn>
									</v-row>
								</v-card-actions>
							</v-container>
						</v-form>
					</v-card>
				</v-dialog>

				<!-- Confirm delete dialog -->
				<v-dialog
					v-model="confirmDeleteDialog"
					persistent
					style="position: aboslut; z-index: 999999"
					max-width="360"
				>
					<v-card>
						<v-card-title>
							Confirm Delete
							<v-spacer></v-spacer>
							<v-icon @click="confirmDeleteDialog = false"> mdi-close </v-icon>
						</v-card-title>
						<v-card-text style="font-weight: 900">
							Are you sure you want to delete this category?
						</v-card-text>
						<v-spacer></v-spacer>
						<v-card-actions>
							<v-row justify="end" class="ma-0">
								<v-btn @click="confirmDeleteDialog = false" class="mr-2" color="red" outlined>
									Cancel
								</v-btn>
								<v-btn @click="deleteCategory()" :loading="isLoading" color="primary">
									Confirm
								</v-btn>
							</v-row>
						</v-card-actions>
					</v-card>
				</v-dialog>

				<!-- Category Table -->
				<v-data-table
					:loading="isLoading"
					:headers="headers"
					:items="categoryList"
					:items-per-page="50"
					:footer-props="{ 'items-per-page-options': [50, 100, 150, -1] }"
					class="elevation-1"
					mobile-breakpoint="0"
				>
					<template v-slot:[`item.categoryName`]="{ item }">
						<tr>
							<td>
								{{ item.categoryName }}
							</td>
						</tr>
					</template>

					<template v-slot:[`item.actions`]="{ item, index }">
						<tr class="d-flex justify-end">
							<td class="pr-2">
								<v-icon
									class="d-flex justify-end"
									color="primary"
									size="20"
									@click="initUpdateCategory(item, index)"
									>mdi-pencil</v-icon
								>
							</td>
						</tr>
					</template>
				</v-data-table>
			</v-card>
		</v-container>
	</div>
</template>

<script>
	// import AdminNavigation from "../../components/AdminViewComponents/AdminNavigation.vue";
	import { API, Auth } from 'aws-amplify';
	import {
		createCategory,
		updateCategory,
		deleteCategory,
		createNotification,
	} from '@/graphql/mutations';
	import { listCategories } from '@/graphql/queries';
	import * as subscriptions from '@/graphql/subscriptions';

	export default {
		methods: {
			async initCurrentUser() {
				const userInfo = await Auth.currentUserInfo();
				const attributes = userInfo.attributes;
				const firstName = attributes['custom:FirstName'];
				const lastName = attributes['custom:LastName'];
				this.currentUser = firstName + ' ' + lastName;
			},
			initDeleteCategory() {
				this.confirmDeleteDialog = true;
			},
			watchSubscriptions() {
				//adding category
				const addCategory = API.graphql({
					query: subscriptions.addedCategory,
					authMode: 'AMAZON_COGNITO_USER_POOLS',
				}).subscribe({
					next: ({ value }) => {
						if (value) {
							this.listCategories();
						}
					},
					error: error => console.warn(error),
				});
				//updating category
				const updateCategory = API.graphql({
					query: subscriptions.updatedCategory,
					authMode: 'AMAZON_COGNITO_USER_POOLS',
				}).subscribe({
					next: ({ value }) => {
						if (value) {
							this.listCategories();
						}
					},
					error: error => console.warn(error),
				});
				//deleting category
				const deleteCategory = API.graphql({
					query: subscriptions.deletedCategory,
					authMode: 'AMAZON_COGNITO_USER_POOLS',
				}).subscribe({
					next: ({ value }) => {
						if (value) {
							this.listCategories();
						}
					},
					error: error => console.warn(error),
				});
				this.subscriptionList.push(addCategory);
				this.subscriptionList.push(updateCategory);
				this.subscriptionList.push(deleteCategory);
			},
			isMobile() {
				if (this.$vuetify.breakpoint.width < 800) {
					return true;
				}
			},
			cancelAction() {
				this.addCategoryDialog = false;
			},
			async deleteCategory() {
				let cat = this.selectedCategory;
				await API.graphql({
					query: deleteCategory,
					variables: {
						sk: cat.sk,
					},
					authMode: 'AMAZON_COGNITO_USER_POOLS',
				}).then(async res => {
					let params = {
						user: this.currentUser,
						message: `Deleted Category: ${this.selectedCategory.categoryName}`,
						notificationTime: Date.now(),
					};
					await API.graphql({
						query: createNotification,
						variables: {
							input: params,
						},
						authMode: 'AMAZON_COGNITO_USER_POOLS',
					});
					this.confirmDeleteDialog = false;
					this.addCategoryDialog = false;
					return res;
				});
			},

			async createCategory() {
				let isValid = this.$refs.form.validate();

				if (isValid) {
					this.isLoading = true;
					try {
						await API.graphql({
							query: createCategory,
							variables: {
								input: this.selectedCategory,
							},
							authMode: 'AMAZON_COGNITO_USER_POOLS',
						});
						let params = {
							user: this.currentUser,
							message: `Created Category: ${this.selectedCategory.categoryName}`,
							notificationTime: Date.now(),
						};
						await API.graphql({
							query: createNotification,
							variables: {
								input: params,
							},
							authMode: 'AMAZON_COGNITO_USER_POOLS',
						});
						this.isLoading = false;
						this.addCategoryDialog = false;
						this.$refs.form.reset();
					} catch (error) {
						this.isLoading = false;
						console.log('Error creating category: ', error);
					}
				} else {
					this.isLoading = false;
					console.log('Error creating category');
				}
			},

			async updateCategory() {
				this.isLoading = true;
				let isValid = this.$refs.form.validate();
				let category = {
					sk: this.selectedCategory.sk,
					pk: this.selectedCategory.pk,
					categoryName: this.selectedCategory.categoryName,
					// description: this.selectedCategory.description,
				};
				if (isValid) {
					await API.graphql({
						query: updateCategory,
						variables: {
							input: category,
						},
						authMode: 'AMAZON_COGNITO_USER_POOLS',
					}).then(async res => {
						let params = {
							user: this.currentUser,
							message: `Updated Category: ${this.selectedCategory.categoryName}`,
							notificationTime: Date.now(),
						};
						await API.graphql({
							query: createNotification,
							variables: {
								input: params,
							},
							authMode: 'AMAZON_COGNITO_USER_POOLS',
						});
						this.isLoading = false;
						this.addCategoryDialog = false;
						return res;
					});
				} else {
					console.log('Error updating category');
				}
			},

			async listCategories() {
				this.categoryList = [];
				let res = await API.graphql({
					query: listCategories,
					authMode: 'AMAZON_COGNITO_USER_POOLS',
				});
				let categoryList = res.data.listCategories.items.sort((a, b) =>
					a.productName > b.productName ? 1 : b.productName > a.productName ? -1 : 0
				);

				this.categoryList = categoryList;
			},

			initAddCategory() {
				this.isAdd = true;
				this.$store.commit('categories/resetSelectedCategory');
				this.addCategoryDialog = true;
			},

			initUpdateCategory(category) {
				this.isAdd = false;
				this.$store.commit('categories/setSelectedCategory', category);
				this.addCategoryDialog = true;
			},

			async checkUser() {
				await Auth.currentAuthenticatedUser({
					bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
				})
					.then(user => {
						if (user.signInUserSession.accessToken.payload['cognito:groups'] == 'customers') {
							this.$router.push('/');
						}
					})
					.catch(err => {
						if (err == 'The user is not authenticated') {
							this.$router.push('/');
						}
					});
			},
		},
		beforeDestroy() {
			this.subscriptionList.forEach(fnc => {
				fnc.unsubscribe();
			});
		},
		mounted() {
			this.checkUser();
			this.listCategories();
			this.watchSubscriptions();
			this.initCurrentUser();
		},

		watch: {},

		computed: {
			selectedCategory: {
				get() {
					return this.$store.getters['categories/selectedCategory'];
				},
			},
		},

		components: {
			// AdminNavigation,
		},

		data: () => ({
			subscriptionList: [],
			currentUser: null,
			categoryRules: [v => !!v || 'Category is required'],
			categoryList: [],
			isLoading: false,
			confirmDeleteDialog: false,
			isAdd: null,
			addCategoryDialog: false,
			headers: [
				{
					text: 'Category Name',
					align: 'start',
					sortable: true,
					value: 'categoryName',
				},
				// {
				//   text: "Description",
				//   sortable: false,
				//   value: "description",
				// },
				// {
				//   text: "Quantity",
				//   sortable: false,
				//   value: "quantity",
				// },
				{
					text: 'Actions',
					align: 'end',
					value: 'actions',
					sortable: false,
				},
			],
		}),
	};
</script>

<style>
	/*  */
</style>
