<template>
	<div>
		<!-- <AdminNavigation /> -->
		<v-container :class="!isMobile() ? `px-12 ml-6` : ``" fluid>
			<v-card elevation="4" outlined>
				<v-card-title>
					<strong> Recurring Orders </strong>
					<v-spacer></v-spacer>
					<v-tooltip color="accent" bottom>
						<template #activator="{ on, attrs }">
							<v-btn dark elevation="4" color="blue" v-on="on" v-bind="attrs" @click="initOrder()">
								<v-icon small> mdi-plus </v-icon>
							</v-btn>
						</template>
						<span> Create Scheduled Order </span>
					</v-tooltip>
				</v-card-title>

				<!-- Add Product Form -->
				<v-container fluid>
					<v-row>
						<v-col cols="12" lg="9" md="9" sm="9">
							<v-text-field
								v-model="searchText"
								label="Search Orders"
								dense
								@keyup="initSearch()"
								outlined
								append-icon="mdi-search"
								hide-details
							/>
						</v-col>
						<!-- Status Dropdown -->
						<v-col cols="12" lg="3" md="3" sm="3">
							<v-select
								v-model="selectedFrequency"
								label="Filter"
								:items="frequencyList"
								dense
								outlined
								hide-details
							/>
						</v-col>
					</v-row>
				</v-container>

				<!-- Orders Table -->
				<v-data-table
					:headers="headers"
					:items="recurringOrderList"
					:items-per-page="50"
					:loading="isLoading"
					:footer-props="{ 'items-per-page-options': [50, 100, 150, -1] }"
					mobile-breakpoint="0"
				>
					<template v-slot:[`item.customer`]="{ item }">
						<tr>
							<td>
								{{ item.customer.companyName }}
							</td>
						</tr>
					</template>
					<template v-slot:[`item.frequency`]="{ item }">
						<tr>
							<td>
								<b>{{ item.frequency }}</b>

								{{ item.frequency == 'Weekly' ? `(` : `` }}{{ displaySelectedDays(item)
								}}{{ item.frequency == 'Weekly' ? `)` : `` }}
							</td>
						</tr>
					</template>

					<template v-slot:[`item.isActive`]="{ item }">
						<tr>
							<td :style="item.isActive ? `color: #2196F3` : `color: red`">
								{{ item.isActive ? `Active` : 'Inactive' }}
							</td>
						</tr>
					</template>

					<template v-slot:[`item.actions`]="{ item, index }">
						<tr>
							<td class="pl-3">
								<v-icon color="blue" size="20" @click="initOrder(item, index)">mdi-pencil</v-icon>
							</td>
						</tr>
					</template>
				</v-data-table>
			</v-card>
		</v-container>
		<!-- notification  -->
		<v-snackbar v-model="notify" class="pt-16" top right :timeout="2500" :color="color">
			{{ notificationText }}
		</v-snackbar>
	</div>
</template>

<script>
	import moment from 'moment';
	// import AdminNavigation from "@/components/AdminViewComponents/AdminNavigation.vue";
	import { Auth, API } from 'aws-amplify';
	import { filterRecurringOrders } from '@/graphql/queries';

	export default {
		methods: {
			displaySelectedDays(item) {
				let text = '';
				item.selectedDays.forEach((day, i) => {
					if (i == item.selectedDays.length - 1) {
						text += `${day}`;
					} else {
						text += `${day}, `;
					}
				});

				return text;
			},
			// testFortnight() {
			// 	const date = new Date('2022-11-14'); //date to be checked if is in current week

			// 	const todayObj = new Date('2022-11-16');
			// 	// console.log("TODAY OBJ: ", todayObj);
			// 	const todayDate = todayObj.getDate();
			// 	// console.log("TodayDate: ", todayDate);
			// 	const todayDay = todayObj.getDay(); //day of the week
			// 	// console.log("TodayDay: ", todayDay);

			// 	// get first date of week
			// 	const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay));
			// 	console.log('firstDayOfWeek: ', firstDayOfWeek);

			// 	// get last date of week
			// 	const lastDayOfWeek = new Date(firstDayOfWeek);
			// 	lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);
			// 	console.log('lastDayOfWeek: ', lastDayOfWeek);

			// 	// if date is equal or within the first and last dates of the week
			// 	console.log(date >= firstDayOfWeek && date <= lastDayOfWeek);
			// },
			async initOrder(order) {
				if (!order) {
					this.$router.push('/admin/scheduled-order/new');
					// let data = {
					//   sk: "",
					//   pk: "",
					//   products: [],
					//   customer: null,
					//   customerId: null,
					//   invoiceNumber: null,
					//   isInvoiceCreated: null,
					//   orderDate: null,
					//   orderId: null,
					//   orderStatus: "PENDING",
					//   totalCost: null,
					// };
					// console.log(data);
				} else {
					this.$router.push('/admin/scheduled-order/' + order.sk);
				}
			},
			async filterOrderTemplates() {
				//List all here
				let frequency = null;

				if (this.selectedFrequency != 'ALL') {
					frequency = this.selectedFrequency;
				}
				let res = await API.graphql({
					query: filterRecurringOrders,
					variables: {
						frequency: frequency,
					},
					authMode: 'AMAZON_COGNITO_USER_POOLS',
				});
				this.recurringOrderList = res.data.filterRecurringOrders.items;
			},
			initSearch() {
				//Search Code
				if (this.searchText.length > 1) {
					this.recurringOrderList = this.recurringOrderList.filter(item =>
						item.customer.companyName.toLowerCase().includes(this.searchText.toLowerCase())
					);
				} else {
					this.filterOrderTemplates();
				}
			},
			async checkUser() {
				await Auth.currentAuthenticatedUser({
					bypassCache: false,
				})
					.then(user => {
						if (user.signInUserSession.accessToken.payload['cognito:groups'] == 'customers') {
							this.$router.push('/');
						}
					})
					.catch(err => {
						if (err == 'The user is not authenticated') {
							this.$router.push('/');
						}
					});
			},
			convertDate(d) {
				if (d) {
					return d && d.length > 0 ? moment(d).format('DD/MM/yyyy') : '';
				}
			},
			convertUnixDate(d) {
				if (d) {
					return moment.unix(d).format('DD/MM/yy');
				}
			},
			isMobile() {
				if (this.$vuetify.breakpoint.width < 800) {
					return true;
				}
			},
		},

		async mounted() {
			await this.checkUser();
			this.filterOrderTemplates();
		},

		computed: {
			searchString: {
				get() {
					return this.$store.getters['customers/searchString'];
				},
				set(val) {
					this.$store.commit('customers/setSearchString', val);
				},
			},
			selectedScheduledOrder: {
				get() {
					return this.$store.getters['scheduledOrders/selectedScheduledOrder'];
				},
			},
		},

		watch: {
			initInvoiceDialog: function (val) {
				if (val == true) {
					this.isLoading = false;
				}
			},
			selectedFrequency: function () {
				this.filterOrderTemplates();
			},
		},

		components: {
			// AdminNavigation,
		},

		data() {
			return {
				customer: null,
				rules: [v => !!v || 'This field is required'],
				recurringOrderList: [],
				isLoading: false,
				searchText: '',
				productsList: [],
				isAdd: null,
				color: null,
				notify: false,
				notificationText: '',
				selectedFrequency: 'ALL',
				frequencyList: ['ALL', 'Daily', 'Weekly'], //"Fortnightly", "Monthly"
				headers: [
					{
						text: 'Company',
						value: 'customer',
						sortable: true,
					},
					{
						text: 'Frequency',
						value: 'frequency',
						sortable: true,
					},
					{
						text: 'Is Active',
						value: 'isActive',
						sortable: true,
					},
					{
						text: 'Actions',
						value: 'actions',
						sortable: false,
					},
				],
				actions: [{ title: 'Edit' }, { title: 'Delete' }],
			};
		},
	};
</script>

<style scoped>
	/* disables rotation animation when selected v-auctocomplete input boxes */
	.v-autocomplete.v-select--is-menu-active .v-input__icon--append .v-icon {
		transform: none;
	}

	.right-input >>> input {
		text-align: right;
	}
</style>
