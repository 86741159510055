/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
	aws_project_region: 'ap-southeast-2',
	aws_cognito_identity_pool_id: 'ap-southeast-2:9cc9cfea-f5e8-41b2-aff1-6b1760d8b0ce',
	aws_cognito_region: 'ap-southeast-2',
	aws_user_pools_id: 'ap-southeast-2_BkY2TmAxC',
	aws_user_pools_web_client_id: '7tt3303si68hdlncahkttdvomo',
	oauth: {},
	aws_cognito_username_attributes: ['EMAIL'],
	aws_cognito_social_providers: [],
	aws_cognito_signup_attributes: ['EMAIL'],
	aws_cognito_mfa_configuration: 'OFF',
	aws_cognito_mfa_types: ['SMS'],
	aws_cognito_password_protection_settings: {
		passwordPolicyMinLength: 8,
		passwordPolicyCharacters: [],
	},
	aws_cognito_verification_mechanisms: ['EMAIL'],
	aws_cloud_logic_custom: [
		{
			name: 'AdminQueries',
			endpoint: 'https://ss7un7tww8.execute-api.ap-southeast-2.amazonaws.com/dev',
			region: 'ap-southeast-2',
		},
	],
	aws_appsync_graphqlEndpoint:
		'https://vubbtto75jbrzjp6oudds6tosa.appsync-api.ap-southeast-2.amazonaws.com/graphql',
	aws_appsync_region: 'ap-southeast-2',
	aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
	aws_appsync_apiKey: 'da2-h2un4mckordz7auat45b7dzopi',
	aws_dynamodb_all_tables_region: 'ap-southeast-2',
	aws_dynamodb_table_schemas: [
		{
			tableName: 'FarmsChickenDB',
			region: 'ap-southeast-2',
		},
	],
	aws_user_files_s3_bucket: 'farmschickenbucket131937-dev',
	aws_user_files_s3_bucket_region: 'ap-southeast-2',
};

export default awsmobile;
