/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createdNotification = /* GraphQL */ `
  subscription CreatedNotification {
    createdNotification {
      pk
      sk
      user
      message
      notificationTime
      notificationValueSk
    }
  }
`;
export const addedProduct = /* GraphQL */ `
  subscription AddedProduct {
    addedProduct {
      pk
      sk
      productName
      categoryName
      description
      quantity
      imageUrl
      productUnit
      productCode
      defaultPrice
      isEnabled
      reckonProductAttributes {
        listID
        editSequence
        isActive
        productType
      }
      productGroup
      isInStock
    }
  }
`;
export const updatedProduct = /* GraphQL */ `
  subscription UpdatedProduct {
    updatedProduct {
      pk
      sk
      productName
      categoryName
      description
      quantity
      imageUrl
      productUnit
      productCode
      defaultPrice
      isEnabled
      reckonProductAttributes {
        listID
        editSequence
        isActive
        productType
      }
      productGroup
      isInStock
    }
  }
`;
export const deletedProduct = /* GraphQL */ `
  subscription DeletedProduct {
    deletedProduct {
      pk
      sk
      productName
      categoryName
      description
      quantity
      imageUrl
      productUnit
      productCode
      defaultPrice
      isEnabled
      reckonProductAttributes {
        listID
        editSequence
        isActive
        productType
      }
      productGroup
      isInStock
    }
  }
`;
export const addedCategory = /* GraphQL */ `
  subscription AddedCategory {
    addedCategory {
      pk
      sk
      categoryName
    }
  }
`;
export const updatedCategory = /* GraphQL */ `
  subscription UpdatedCategory {
    updatedCategory {
      pk
      sk
      categoryName
    }
  }
`;
export const deletedCategory = /* GraphQL */ `
  subscription DeletedCategory {
    deletedCategory {
      pk
      sk
      categoryName
    }
  }
`;
export const createdCustomer = /* GraphQL */ `
  subscription CreatedCustomer {
    createdCustomer {
      pk
      sk
      firstName
      lastName
      email
      mobile
      priceLevel
      companyName
      customerLevel
      reckonAttributes {
        reckonId
        editSequence
        isActive
        companyAddress {
          addr1
          addr2
          addr3
          city
          postalCode
        }
      }
      customerCode
    }
  }
`;
export const updatedCustomer = /* GraphQL */ `
  subscription UpdatedCustomer {
    updatedCustomer {
      pk
      sk
      firstName
      lastName
      email
      mobile
      priceLevel
      companyName
      customerLevel
      reckonAttributes {
        reckonId
        editSequence
        isActive
        companyAddress {
          addr1
          addr2
          addr3
          city
          postalCode
        }
      }
      customerCode
    }
  }
`;
export const deletedCustomer = /* GraphQL */ `
  subscription DeletedCustomer {
    deletedCustomer {
      pk
      sk
      firstName
      lastName
      email
      mobile
      priceLevel
      companyName
      customerLevel
      reckonAttributes {
        reckonId
        editSequence
        isActive
        companyAddress {
          addr1
          addr2
          addr3
          city
          postalCode
        }
      }
      customerCode
    }
  }
`;
export const createdOrder = /* GraphQL */ `
  subscription CreatedOrder {
    createdOrder {
      pk
      sk
      customer {
        pk
        sk
        firstName
        lastName
        email
        mobile
        priceLevel
        companyName
        customerLevel
        reckonAttributes {
          reckonId
          editSequence
          isActive
          companyAddress {
            addr1
            addr2
            addr3
            city
            postalCode
          }
        }
        customerCode
      }
      customerId
      orderStatus
      orderDate
      orderId
      products {
        pk
        sk
        productId
        productName
        categoryName
        description
        quantity
        packedWeight
        imageUrl
        productUnit
        productCode
        defaultPrice
        newPrice
        isEnabled
        reckonTxnId
        notes
        customerId
      }
      invoiceRef {
        reckonRefNumber
        reckonTxnId
        reckonEditSequence
      }
      productionRef
      notes
      purchaseOrder
      line
      isDelivery
      freight
      cutting
    }
  }
`;
export const updatedOrder = /* GraphQL */ `
  subscription UpdatedOrder {
    updatedOrder {
      pk
      sk
      customer {
        pk
        sk
        firstName
        lastName
        email
        mobile
        priceLevel
        companyName
        customerLevel
        reckonAttributes {
          reckonId
          editSequence
          isActive
          companyAddress {
            addr1
            addr2
            addr3
            city
            postalCode
          }
        }
        customerCode
      }
      customerId
      orderStatus
      orderDate
      orderId
      products {
        pk
        sk
        productId
        productName
        categoryName
        description
        quantity
        packedWeight
        imageUrl
        productUnit
        productCode
        defaultPrice
        newPrice
        isEnabled
        reckonTxnId
        notes
        customerId
      }
      invoiceRef {
        reckonRefNumber
        reckonTxnId
        reckonEditSequence
      }
      productionRef
      notes
      purchaseOrder
      line
      isDelivery
      freight
      cutting
    }
  }
`;
export const deletedOrder = /* GraphQL */ `
  subscription DeletedOrder {
    deletedOrder {
      pk
      sk
      customer {
        pk
        sk
        firstName
        lastName
        email
        mobile
        priceLevel
        companyName
        customerLevel
        reckonAttributes {
          reckonId
          editSequence
          isActive
          companyAddress {
            addr1
            addr2
            addr3
            city
            postalCode
          }
        }
        customerCode
      }
      customerId
      orderStatus
      orderDate
      orderId
      products {
        pk
        sk
        productId
        productName
        categoryName
        description
        quantity
        packedWeight
        imageUrl
        productUnit
        productCode
        defaultPrice
        newPrice
        isEnabled
        reckonTxnId
        notes
        customerId
      }
      invoiceRef {
        reckonRefNumber
        reckonTxnId
        reckonEditSequence
      }
      productionRef
      notes
      purchaseOrder
      line
      isDelivery
      freight
      cutting
    }
  }
`;
