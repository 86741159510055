<template>
	<div>
		<ConfirmDialog ref="confirm" />
		<!-- <AdminNavigation /> -->
		<div v-if="!selectedOrder && !isAdd">
			<v-progress-circular
				v-if="!invalidOrder"
				indeterminate
				size="100"
				width="7"
				color="primary"
				style="margin-top: 35vh"
			></v-progress-circular>

			<h1 class="mt-12 pt-12" v-show="invalidOrder">Invalid Order</h1>
			<v-scroll-x-transition>
				<v-icon v-if="truck" size="200" color="primary">mdi-truck-alert-outline</v-icon>
			</v-scroll-x-transition>
			<br />
			<v-btn
				v-show="invalidOrder"
				height="100"
				outlined
				width="250"
				@click="$router.push('/admin/orders')"
				>Back to Orders</v-btn
			>
		</div>
		<div v-else :class="isMobile() ? `` : `ml-16`">
			<v-card-title>
				<strong> {{ isAdd ? `Create Order` : `Update Order` }} </strong>
				<v-spacer></v-spacer>

				<div v-if="!isMobile()">
					<!-- Sync Order -->
					<v-tooltip bottom color="green darken-2">
						<template #activator="{ on, attrs }">
							<v-btn
								v-if="!isAdd"
								@click="syncButton()"
								v-bind="attrs"
								v-on="on"
								color="secondary"
								text
								:disabled="isLoading || priceLoading"
							>
								<v-icon large>mdi-database-sync</v-icon>
							</v-btn>
						</template>
						<span>Sync From Production</span>
					</v-tooltip>

					<!-- Create Invoice -->
					<v-tooltip bottom color="green darken-2">
						<template #activator="{ on, attrs }">
							<v-btn
								v-if="!isAdd"
								@click="initInvoice()"
								v-bind="attrs"
								v-on="on"
								:color="
									selectedOrder.invoiceRef && selectedOrder.invoiceRef.reckonRefNumber
										? `secondary`
										: `orange`
								"
								text
								:disabled="isLoading || priceLoading"
							>
								<v-icon large>
									{{
										selectedOrder.invoiceRef && selectedOrder.invoiceRef.reckonRefNumber
											? `mdi-receipt-text-check`
											: `mdi-receipt-text`
									}}
								</v-icon>
							</v-btn>
						</template>
						<span>
							{{
								selectedOrder.invoiceRef && selectedOrder.invoiceRef.reckonRefNumber
									? `Update Invoice`
									: `Create Invoice`
							}}
						</span>
					</v-tooltip>

					<!-- Delete Order  -->
					<v-tooltip bottom color="red">
						<template #activator="{ on, attrs }">
							<v-btn
								v-on="on"
								v-bind="attrs"
								v-if="!isAdd"
								@click="confirmDeleteDialog = true"
								color="red"
								text
								:disabled="isLoading || priceLoading"
							>
								<v-icon large> mdi-delete </v-icon>
							</v-btn>
						</template>
						<span> Delete Order </span>
					</v-tooltip>

					<!-- Close button -->
					<v-icon @click="cancelAction()" large> mdi-close </v-icon>
				</div>

				<v-row v-if="isMobile()">
					<!-- Sync Order -->
					<v-tooltip bottom color="green darken-2">
						<template #activator="{ on, attrs }">
							<v-btn
								v-if="!isAdd"
								@click="syncButton()"
								v-bind="attrs"
								v-on="on"
								color="secondary"
								text
							>
								<v-icon>mdi-database-sync</v-icon>
							</v-btn>
						</template>
						<span>Sync From Production</span>
					</v-tooltip>

					<!-- Create Invoice -->
					<v-tooltip bottom color="green darken-2">
						<template #activator="{ on, attrs }">
							<v-btn
								v-if="!isAdd"
								@click="initInvoice()"
								v-bind="attrs"
								v-on="on"
								:color="
									selectedOrder.invoiceRef && selectedOrder.invoiceRef.reckonRefNumber
										? `orange`
										: `secondary`
								"
								text
							>
								<v-icon> mdi-printer </v-icon>
							</v-btn>
						</template>
						<span>
							{{
								selectedOrder.invoiceRef && selectedOrder.invoiceRef.reckonRefNumber
									? `Update Invoice`
									: `Create Invoice`
							}}
						</span>
					</v-tooltip>

					<!-- Delete Order  -->
					<v-tooltip bottom color="red">
						<template #activator="{ on, attrs }">
							<v-btn
								v-on="on"
								v-bind="attrs"
								v-if="!isAdd"
								@click="confirmDeleteDialog = true"
								color="red"
								text
							>
								<v-icon> mdi-delete </v-icon>
							</v-btn>
						</template>
						<span> Delete Order </span>
					</v-tooltip>

					<!-- Close button -->
					<v-icon @click="cancelAction()"> mdi-close </v-icon>
				</v-row>
			</v-card-title>

			<!-- confirm delete dialog -->
			<v-dialog
				v-model="confirmDeleteDialog"
				persistent
				style="position: aboslut; z-index: 999999"
				max-width="360"
			>
				<v-card>
					<v-card-title>
						Confirm Delete
						<v-spacer></v-spacer>
						<v-icon @click="cancelAction()"> mdi-close </v-icon>
					</v-card-title>
					<v-card-text style="font-weight: 900">
						Are you sure you want to delete this order?
					</v-card-text>
					<v-spacer></v-spacer>
					<v-card-actions>
						<v-row justify="end" class="ma-0">
							<v-btn @click="confirmDeleteDialog = false" class="mr-2" color="red" outlined>
								Cancel
							</v-btn>
							<v-btn @click="deleteOrder(selectedOrder)" :loading="isLoading" color="primary">
								Confirm
							</v-btn>
						</v-row>
					</v-card-actions>
				</v-card>
			</v-dialog>

			<v-form ref="form">
				<v-row class="mx-0 ml-3 mr-3 mt-2 mb-1">
					<!-- calendar -->
					<v-col cols="12" md="2">
						<v-text-field
							:value="displayDate(dateManager)"
							@click="orderDateDialog = true"
							outlined
							readonly
							append-icon="mdi-calendar"
							label="Order Date"
							:rules="rules"
							:disabled="isLoading || priceLoading"
						/>
					</v-col>
					<v-col cols="12" md="1">
						<v-select
							v-model="selectedOrder.line"
							:items="lineList"
							outlined
							label="Line"
							:disabled="isLoading || priceLoading"
						></v-select>
					</v-col>
					<v-col cols="12" md="3">
						<v-text-field
							v-model="selectedOrder.purchaseOrder"
							label="Purchase Order"
							outlined
							:disabled="isLoading || priceLoading"
						></v-text-field>
					</v-col>

					<v-col cols="12" md="3">
						<v-text-field
							v-model="selectedOrder.freight"
							outlined
							label="Freight Charge"
							prepend-inner-icon="mdi-currency-usd"
							:disabled="isLoading || priceLoading"
						></v-text-field>
					</v-col>
					<v-col cols="12" md="3">
						<v-text-field
							v-model="selectedOrder.cutting"
							outlined
							label="Cutting Charge"
							prepend-inner-icon="mdi-currency-usd"
							:disabled="isLoading || priceLoading"
						></v-text-field>
					</v-col>

					<!-- <v-spacer></v-spacer>
					<div v-if="!isAdd" class="mr-4"></div> -->

					<!-- init create invoice dialog -->
					<v-dialog v-model="initInvoiceDialog" v-if="!isAdd" max-width="380" persistent>
						<v-card>
							<div class="pa-4">
								<h3>
									{{
										selectedOrder.invoiceRef && selectedOrder.invoiceRef.reckonRefNumber
											? `Update invoice for`
											: `Create invoice for`
									}}
									{{
										selectedOrder.customer
											? selectedOrder.customer.firstName + ` ` + selectedOrder.customer.lastName
											: `Unknown Customer`
									}}?
								</h3>
							</div>
							<v-spacer></v-spacer>
							<v-card-actions>
								<v-btn
									:disabled="isLoading"
									@click="initInvoiceDialog = false"
									small
									outlined
									color="red"
								>
									Cancel
								</v-btn>
								<v-btn small color="secondary" @click="createReckonInvoice()" :loading="isLoading">
									Confirm
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>

					<!-- Date picker -->
					<v-dialog v-model="orderDateDialog" ref="orderDateRef" max-width="280">
						<v-card tile>
							<!-- New testing one -->
							<v-date-picker v-model="dateManager" show-adjacent-months scrollable elevation="15">
								<v-spacer></v-spacer>
								<v-btn text color="red" @click="orderDateDialog = false"> Cancel </v-btn>
								<v-btn text color="green" @click="$refs.orderDateRef.save(dateManager)">
									Save
								</v-btn>
							</v-date-picker>
						</v-card>
					</v-dialog>

					<!-- Create Btn -->
					<v-btn
						rounded
						width="100%"
						color="secondary"
						@click="isAdd ? createOrder() : updateOrder()"
						:loading="isLoading || priceLoading"
					>
						{{ isAdd ? `Create Order` : `Update Order` }}
					</v-btn>
				</v-row>
				<v-row class="ma-0 pa-0">
					<v-col cols="12" lg="4" md="4" sm="4">
						<!-- Customer Card -->
						<v-card
							outlined
							elevation="2"
							:height="isMobile() ? `100%` : `65vh`"
							style="overflow-y: auto"
							class="container"
						>
							<v-card-title>
								<v-icon class="pa-2" size="31" color="secondary"> mdi-account </v-icon>
								<strong> Customer </strong>
							</v-card-title>
							<v-container>
								<template>
									<v-autocomplete
										v-model="selectedOrder.customer"
										:items="searchString >= 0 ? customerList : limitedSearchResult"
										:search-input.sync="searchString"
										item-text="companyName"
										:label="isAdd ? 'Select Customer' : 'Change Customer'"
										append-icon="mdi-magnify"
										dense
										outlined
										hide-no-data
										cache-items
										:loading="isLoading"
										@focus="checkContent"
										@keydown="initSearchBar"
										return-object
										:rules="rules"
										no-filter
										:disabled="isLoading || priceLoading"
									>
										<template slot="item" slot-scope="data">
											{{ data.item.companyName }}
										</template>
										<template slot="selection" slot-scope="data">
											{{ data.item.companyName }}
										</template>
									</v-autocomplete>
								</template>

								<div
									v-if="selectedOrder.customer"
									class="text-left mb-6 ml-2"
									style="margin-top: -20px"
								>
									<span style="font-size: 12px">Company Name:</span>
									<h4>{{ selectedOrder.customer.companyName }}</h4>
									<span style="font-size: 12px">Contact Person:</span>
									<h4>
										{{ selectedOrder.customer.firstName + ' ' + selectedOrder.customer.lastName }}
									</h4>
									<span style="font-size: 12px">Email:</span>
									<h4>{{ selectedOrder.customer.email }}</h4>
									<span style="font-size: 12px">Phone:</span>
									<h4>{{ selectedOrder.customer.mobile }}</h4>
								</div>

								<div v-if="!selectedOrder.customer && !isAdd">
									<v-skeleton-loader type="article"></v-skeleton-loader>
								</div>

								<v-divider></v-divider>

								<div class="text-left mt-2 mx-2 ml-2">
									<span v-if="selectedOrder.productionRef"
										>Production Number: <b>{{ this.selectedOrder.productionRef }}</b></span
									><br />
									<span v-if="selectedOrder.invoiceRef && selectedOrder.invoiceRef.reckonRefNumber"
										>Invoice Number:
										<strong>{{ selectedOrder.invoiceRef.reckonRefNumber }}</strong></span
									>
								</div>

								<!-- Status and Delivery Methods -->
								<v-container>
									<v-select
										v-show="selectedOrder.customer"
										v-model="selectedOrder.orderStatus"
										:items="orderStatuses"
										dense
										label="Status"
										outlined
										:rules="rules"
										color="black"
										:background-color="statusColorSelector()"
										:disabled="isLoading || priceLoading"
									/>
									<v-select
										v-show="selectedOrder.customer"
										:rules="[v => v != null || 'Please Select Delivery Method']"
										@change="checkFreight()"
										background-color="cyan"
										color="black"
										dense
										item-text="name"
										item-value="val"
										v-model="selectedOrder.isDelivery"
										:items="deliveryMethods"
										outlined
										label="Delivery Method"
										:append-icon="
											selectedOrder.isDelivery ? `mdi-truck-delivery` : `mdi-package-up`
										"
										:disabled="isLoading || priceLoading"
									></v-select>
								</v-container>
							</v-container>
						</v-card>
					</v-col>

					<v-col cols="12" lg="8" md="8" sm="8">
						<v-card height="65vh" style="overflow-y: scroll" class="container">
							<v-card-title>
								<strong> Products -</strong>
								<div>
									<v-card-title>
										{{ selectedOrder.products.length }}
										{{ selectedOrder.products.length == 1 ? 'item' : 'items' }}
										<v-spacer></v-spacer>
										<!-- <b v-if="selectedOrder.cost"> $69.99 </b> -->
									</v-card-title>
								</div>
								<v-spacer></v-spacer>
								<v-btn
									:block="isMobile()"
									@click="addProductDialog = true"
									color="secondary"
									:disabled="checkCustomer() || isLoading || priceLoading"
								>
									<v-icon dark>mdi-plus</v-icon> Add Product
								</v-btn>
							</v-card-title>

							<v-container>
								<v-divider></v-divider>
								<!--HIDDEN SUBTOTAL IN CASE IN THE FUTURE IT NEEDS TO BE DISPLAYED-->
								<v-col cols="7" v-show="false">
									<v-card-text>
										<div>${{ calculateSubtotal() }}</div>
										<div>${{ gst }}</div>
										<div v-if="selectedOrder.isDelivery == true">
											${{ parseFloat(freight).toFixed(2) }}
										</div>
										<div class="mt-4">
											<strong>${{ total }}</strong>
										</div>
									</v-card-text>
								</v-col>
							</v-container>

							<v-col cols="12" lg="12" md="12" sm="12">
								<!-- Items -->
								<v-card
									max-height="100%"
									outlined
									elevation="4"
									class="ml-4 mr-4 mt-2 mb-4"
									v-for="(item, i) in selectedOrder.products"
									v-bind:key="i"
								>
									<v-row class="ma-0">
										<!-- Product Image -->
										<v-col cols="12" lg="4" md="4" sm="4">
											<v-img
												alt="item.productName"
												:src="
													item.imageUrl
														? item.imageUrl
														: 'https://png.pngtree.com/png-clipart/20210121/ourlarge/pngtree-food-meat-piece-clip-art-png-image_2770140.jpg'
												"
												contain
												max-height="40"
											></v-img>
										</v-col>

										<!-- Item Name -->
										<v-col :cols="isMobile() ? `12` : `8`" lg="4" md="4" sm="4">
											[<b style="color: #1b8600">{{ item.productCode }}</b
											>] {{ item.productName }}
										</v-col>
										<v-col cols="12" lg="4" md="4" sm="4">
											<v-progress-circular
												v-if="priceLoading"
												size="20"
												indeterminate
												color="secondary"
											></v-progress-circular>
											<h5
												v-if="!priceLoading && (item.defaultPrice || item.newPrice)"
												@click="
													{
													}
												"
												:style="
													item.newPrice
														? `color:${$vuetify.theme.themes.light.primary}; cursor: pointer`
														: `cursor: pointer`
												"
											>
												${{ item.newPrice ? item.newPrice : item.defaultPrice.toFixed(2) }}/{{
													item.productUnit
												}}
											</h5>
										</v-col>
									</v-row>
									<v-row class="ma-0 justify-center">
										<!-- Quantity -->
										<v-col cols="12" lg="4" md="4" sm="4" class="text-right">
											<v-text-field
												label="Ordered"
												@keypress="isNumber($event)"
												class="right-input"
												v-model="item.quantity"
												outlined
												dense
												:rules="rules"
												:prepend-inner-icon="item.productUnit == 'kg' ? `mdi-weight-kilogram` : ``"
												hide-details
												:disabled="isLoading || priceLoading"
											/>
										</v-col>
										<v-col cols="12" lg="4" md="4" sm="4">
											<v-text-field
												label="Supplied"
												class="right-input"
												:value="item.packedWeight"
												outlined
												dense
												disabled
												:prepend-inner-icon="item.productUnit == 'kg' ? `mdi-weight-kilogram` : ``"
												hide-details
											/>
										</v-col>

										<!-- Remove -->
										<v-col class="mt-2 text-center" cols="12" lg="1" md="1" sm="1">
											<v-tooltip color="" bottom>
												<template #activator="{ on, attrs }">
													<v-btn
														@click="initRemove(item, i)"
														color="red"
														v-on="on"
														v-bind="attrs"
														v-if="isMobile()"
														text
														:disabled="isLoading || priceLoading"
													>
														Remove Item
													</v-btn>
													<v-icon
														v-else
														@click="initRemove(item, i)"
														class="mr-2"
														color="red"
														v-on="on"
														v-bind="attrs"
														:disabled="isLoading || priceLoading"
													>
														mdi-close
													</v-icon>
												</template>
												<span> Remove Item </span>
											</v-tooltip>
										</v-col>

										<v-col cols="12">
											<v-text-field
												v-model="item.notes"
												outlined
												label="Notes"
												dense
											></v-text-field>
										</v-col>
									</v-row>
								</v-card>
							</v-col>

							<!-- add product dialog -->
							<v-dialog
								v-model="addProductDialog"
								scrollable
								max-width="1200px"
								transition="dialog-transition"
							>
								<v-card height="100%" min-height="105vh">
									<v-card-title>
										<strong> Add Product </strong>
										<v-spacer></v-spacer>
										<v-icon @click="addProductDialog = false" large> mdi-close </v-icon>
									</v-card-title>

									<!-- Products Table -->
									<v-container>
										<v-data-table
											:loading="isLoading"
											:headers="headers"
											:items="productsList"
											:items-per-page="50"
											:footer-props="{
												'items-per-page-options': [50, 100, 150, -1],
											}"
											class="elevation-1"
											mobile-breakpoint="0"
										>
											<!-- Header stuff -->
											<template v-slot:top>
												<v-row class="pa-4 d-flex ma-0 mx-0 pa-0 px-0">
													<v-col cols="12" lg="8" md="8" sm="8" xs="8">
														<v-text-field
															v-model="searchText"
															label="Search Product"
															outlined
															dense
															@keyup="initSearch()"
															:loading="isLoading"
														></v-text-field>
													</v-col>
													<v-col cols="12" lg="4" md="4" sm="4" xs="4">
														<v-select
															v-model="selectedCategoryFilter"
															dense
															label="Filter by Category"
															outlined
															:items="categoryListFilter"
															item-text="categoryName"
															item-value="categoryName"
														/>
													</v-col>
												</v-row>
											</template>
											<template v-slot:[`item.productName`]="{ item }">
												<tr>
													<td class="pl-3">
														[<b style="color: #1b8600">{{ item.productCode }}</b
														>] {{ ' ' + item.productName }}
													</td>
												</tr>
											</template>
											<template v-slot:[`item.actions`]="{ item }">
												<tr>
													<td class="pl-3">
														<v-tooltip top color="secondary">
															<template #activator="{ on, attrs }">
																<v-icon
																	v-on="on"
																	v-bind="attrs"
																	color="green"
																	size="20"
																	@click="initProductQuantity(item)"
																>
																	{{ item.isAdded ? `mdi-check` : `mdi-plus` }}
																</v-icon>
															</template>
															<span>
																{{ item.isAdded ? `Already Added` : `Edit Quantity` }}
															</span>
														</v-tooltip>
													</td>
												</tr>
											</template>
										</v-data-table>
									</v-container>
								</v-card>
							</v-dialog>
						</v-card>
					</v-col>
				</v-row>
			</v-form>
			<div class="ma-3">
				<v-textarea v-model="selectedOrder.notes" solo outlined label="Notes"></v-textarea>
				<!-- <div class="d-flex">
          <v-select
            outlined
            solo
            label="Priority Label"
            :items="['High', 'Normal', 'Low']"
          ></v-select>
        </div> -->
			</div>
		</div>

		<v-dialog v-model="editProductQntyDialog" max-width="500">
			<v-card>
				<v-card-title>
					<v-spacer></v-spacer>
					<v-btn @click="editProductQntyDialog = false" icon>
						<v-icon> mdi-close </v-icon>
					</v-btn>
				</v-card-title>
				<v-card-title class="justify-center">
					{{ selectedProduct.productName }}
				</v-card-title>
				<v-row justify="center" class="pa-0 px-0 ma-0 mx-0">
					<v-col md="4">
						<v-text-field
							v-model="selectedProductQnty"
							outlined
							v-on:keypress="numbersOnly"
							hide-details
							class="shrink mx-4"
							dense
						/>
					</v-col>
				</v-row>
				<v-btn class="text-center ma-2" @click="addProductToOrder(selectedProduct)" text>
					Set
				</v-btn>
			</v-card>
		</v-dialog>

		<!-- notification  -->
		<v-snackbar
			v-model="notify"
			class="pt-16"
			top
			:right="isMobile ? true : false"
			:timeout="2500"
			:color="color"
		>
			{{ notificationText }}
		</v-snackbar>
	</div>
</template>

<script>
	import moment from 'moment';
	import { Auth, API } from 'aws-amplify';
	import { createOrder, updateOrder, deleteOrder, createNotification } from '@/graphql/mutations';
	import {
		getOrder,
		listProducts,
		searchCustomers,
		listCategories,
		filterProducts,
		listCustomPricesByPriceLevel,
		getSettingsVariables,
	} from '@/graphql/queries';
	import axios from 'axios';
	import ConfirmDialog from '../../components/ConfirmDialog.vue';
	// import AdminNavigation from "@/components/AdminViewComponents/AdminNavigation.vue";

	export default {
		beforeDestroy() {
			this.selectedOrder = null;
			this.controller.abort();
		},
		components: {
			ConfirmDialog,
		},
		methods: {
			numbersOnly(evt) {
				evt = evt ? evt : window.event;
				var charCode = evt.which ? evt.which : evt.keyCode;
				if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
					evt.preventDefault();
				} else {
					return true;
				}
			},
			initProductQuantity(item) {
				if (item.isAdded == true) {
					this.notificationText = 'Product Already Added';
					this.color = 'info';
					this.notify = true;
				} else {
					this.selectedProduct = item;
					this.selectedProductQnty = item.quantity;
					this.editProductQntyDialog = true;
				}
			},
			async checkIsAdded(val) {
				// console.log(this.productsList);
				// console.log("Selelcted order products: ", this.selectedOrder.products);
				let orderProducts = this.selectedOrder.products;
				if (val) {
					for (let i = 0; i < val.length; i++) {
						for (let j = 0; j < orderProducts.length; j++) {
							if (val[i].sk === orderProducts[j].sk) {
								this.notificationText = 'Product Already Added';
								this.color = 'info';
								this.notify = true;
							}
						}
					}
				} else {
					for (let i = 0; i < this.productsList.length; i++) {
						for (let j = 0; j < orderProducts.length; j++) {
							if (this.productsList[i].sk === orderProducts[j].sk) {
								this.productsList[i].isAdded = true;
							}
						}
					}
				}
			},
			async getSettingsVariables() {
				let res = await API.graphql({
					query: getSettingsVariables,
				});

				let data = res.data.getSettingsVariables;

				this.cutoffTime = data.cutoffTime;
				this.freightCharge = data.freightCharge;
				this.freight = data.freightCharge;
				this.freightFreeOver = data.freightFreeOver;
			},
			calculateSubtotal() {
				let subTotal = 0;
				this.selectedOrder.products.forEach(el => {
					const quantity = parseFloat(el.quantity);
					const price = el.newPrice ? parseFloat(el.newPrice) : parseFloat(el.defaultPrice);

					subTotal += quantity * price;
				});

				this.subTotal = subTotal.toFixed(2);
				this.gst = (subTotal * 0.15).toFixed(2);
				this.total = (
					subTotal +
					parseFloat(this.gst) +
					parseFloat(this.selectedOrder.freight)
				).toFixed(2);

				return subTotal.toFixed(2);
			},
			checkFreight() {
				//Get saved freight cost
				const freight = this.freightCharge;
				//Get saved total limit to get free freight
				const limit = this.freightFreeOver;

				// if (this.selectedOrder.isDelivery == false) {
				// 	this.selectedOrder.freight = 0;
				// } else if (this.selectedOrder.freight && this.selectedOrder.freight != freight) {
				// 	return;
				// } else if (this.total > limit) {
				// 	this.selectedOrder.freight = 0;
				// } else {
				// 	this.selectedOrder.freight = freight;
				// }
				if (this.selectedOrder.isDelivery == false) {
					this.selectedOrder.freight = 0;
				} else if (this.selectedOrder.isDelivery == true) {
					if (this.total > limit) {
						this.selectedOrder.freight = 0;
					} else {
						this.selectedOrder.freight = freight;
					}
				}

				this.$forceUpdate();
			},
			async resetSelectedOrder() {
				this.selectedOrder = null;
			},
			limitString(string) {
				if (string) {
					let limit = string.substr(0, 5);
					return limit;
				}
			},
			async initCurrentUser() {
				const userInfo = await Auth.currentUserInfo();
				const attributes = userInfo.attributes;
				const firstName = attributes['custom:FirstName'];
				const lastName = attributes['custom:LastName'];
				this.currentUser = firstName + ' ' + lastName;
			},
			checkCustomer() {
				if (!this.selectedOrder.customer) {
					return true;
				} else {
					return false;
				}
			},
			syncButton() {
				// location.syncButton();

				this.syncFromProduction(this.selectedOrder, true);
			},
			async syncFromProduction(order, fromButton) {
				this.firstLoad = true;
				this.priceLoading = true;
				let auth = await Auth.currentAuthenticatedUser();
				let headers = {
					headers: {
						authorization: 'Bearer ' + auth.signInUserSession.accessToken.jwtToken,
					},

					signal: this.controller.signal,
				};

				try {
					if (this.$route.params.id == 'new') return;

					let result = await axios.post(
						this.$store.getters['global/endpoint'] + '/api/production/order/singlesync',
						order,
						headers
					);

					let isChanged = false;
					if (result.data.orderStatus != order.orderStatus) {
						isChanged = true;
					} else {
						if (result.data.orderStatus === order.orderStatus) {
							result.data.products.forEach(prod => {
								// if (!prod.packedWeight) {
								//   prod.packedWeight = 0;
								// }
								order.products.forEach(line => {
									if (prod.sk == line.sk) {
										if (
											prod.packedWeight != null &&
											line.packedWeight != 0 &&
											prod.packedWeight != line.packedWeight
										) {
											isChanged = true;
										}
									}
								});
							});
						}
					}

					if (isChanged) {
						this.color = 'success';
						this.notificationText =
							'Looks like there was an update from production system. \nMake sure to update the order before you exit.';
						this.notify = true;
					}
					// else {
					//   this.color = "success";
					//   this.notificationText = "No changes detected.";
					//   this.notify = true;
					// }

					// console.log(result.data);
					this.selectedOrder = result.data;

					if (fromButton == true) {
						setTimeout(() => {
							this.firstLoad = false;
						}, 500);
					}
				} catch (error) {
					// console.log(error);
					let text =
						error.response && error.response.data == 'SQL Database Offline'
							? 'Could not connect to production database.'
							: 'Sync Problem. Please Refresh Page.';
					this.color = 'error';
					this.notificationText = text;
					this.notify = true;
					// location.syncButton();
				}

				this.priceLoading = false;
			},

			async getOrder() {
				this.firstLoad = true;
				await this.listCategories();
				await this.listProducts();
				const orderId = this.$route.params.id;
				let res = await API.graphql({
					query: getOrder,
					variables: {
						orderId: orderId,
					},
					authMode: 'AMAZON_COGNITO_USER_POOLS',
				});

				let order = res.data.getOrder;

				// console.log(order);

				if (!order) {
					this.selectedOrder = null;
					this.invalidOrder = true;
					this.truck = true;
					setInterval(() => {
						this.truck = !this.truck;
					}, 5000);
					return;
				}

				if (!order.line) {
					order.line = order.customer.customerLevel;
				}

				this.selectedOrder = await order;
				if (this.selectedOrder.orderStatus == 'PENDING') {
					await this.syncFromProduction(order);
				}
				await this.loadCustomPrices();
				this.firstLoad = false;
				this.$forceUpdate();
			},

			async loadCustomPrices() {
				const priceLevel =
					this.selectedOrder && this.selectedOrder.customer
						? this.selectedOrder.customer.priceLevel
						: null;
				if (!priceLevel) {
					this.priceLoading = false;
					return;
				}

				if (priceLevel && this.customPriceList.length == 0) {
					let res = await API.graphql({
						query: listCustomPricesByPriceLevel,
						variables: {
							priceLevel: priceLevel,
						},
						authMode: 'AMAZON_COGNITO_USER_POOLS',
					});
					this.customPriceList = res.data.listCustomPricesByPriceLevel.items;
				}

				this.selectedOrder.products.forEach(product => {
					this.customPriceList.forEach(customPrice => {
						if (product.sk == customPrice.productId) {
							product.newPrice = customPrice.customPrice.toFixed(2);
							product.customPrice = customPrice;
						}
					});
				});

				this.priceLoading = false;
			},

			isNumber(evt) {
				evt = evt ? evt : window.event;
				var charCode = evt.which ? evt.which : evt.keyCode;
				if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
					evt.preventDefault();
				} else {
					return true;
				}
			},

			initRemove(item, index) {
				let productsList = this.selectedOrder.products;
				for (let i = 0; i < productsList.length; i++) {
					if (productsList[i].sk == item.sk && index > -1) {
						this.removalQueue.push(productsList[i]);
						productsList.splice(index, 1);
					}
				}
			},

			async removeItem(item) {
				let auth = await Auth.currentAuthenticatedUser();
				let headers = {
					headers: {
						authorization: 'Bearer ' + auth.signInUserSession.accessToken.jwtToken,
					},
				};
				let orderId = this.selectedOrder.sk;
				await axios.delete(
					this.$store.getters['global/endpoint'] + '/api/production/order/removesingleproduct',
					{
						data: {
							source: item,
							orderId,
						},
						...headers,
					}
				);
			},

			async addProductToOrder() {
				// console.log("selectedProductQnty: ", this.selectedProductQnty);
				// console.log("selected product: ", this.selectedProduct);

				this.selectedProduct.quantity = this.selectedProductQnty;
				this.selectedProduct.isAdded = true;
				this.selectedOrder.products.push(this.selectedProduct);

				this.loadCustomPrices();
				this.editProductQntyDialog = false;
			},

			async filterProducts() {
				try {
					let catFilter;
					let productList = [];
					let nextToken = null;

					if (this.selectedCategoryFilter == 'All') {
						catFilter = null;
					} else {
						catFilter = this.selectedCategoryFilter;
					}

					let res = await API.graphql({
						query: filterProducts,
						variables: {
							searchString: this.searchText.toLowerCase(),
							category: catFilter,
						},
						authMode: 'AMAZON_COGNITO_USER_POOLS',
					});

					nextToken = res.data.filterProducts.nextToken;
					productList = productList.concat(res.data.filterProducts.items);

					while (nextToken) {
						let res = await API.graphql({
							query: filterProducts,
							variables: {
								searchString: this.searchText.toLowerCase(),
								category: catFilter,
								nextToken: nextToken,
							},
							authMode: 'AMAZON_COGNITO_USER_POOLS',
						});

						nextToken = res.data.filterProducts.nextToken;
						productList = productList.concat(res.data.filterProducts.items);
					}

					productList = productList.filter(prod => prod.isEnabled == true);

					productList = productList.sort((a, b) =>
						a.productName > b.productName ? 1 : b.productName > a.productName ? -1 : 0
					);
					this.productsList = productList;
					await this.checkIsAdded();

					this.isLoading = false;
				} catch (error) {
					console.log(error);
					this.isLoading = false;
				}
			},
			initSearch() {
				this.isLoading = true;
				if (this.timer) {
					clearTimeout(this.timer);
					this.timer = null;
				}
				this.timer = setTimeout(() => {
					this.filterProducts();
				}, 300);
			},
			async listCategories() {
				let res = await API.graphql({
					query: listCategories,
					authMode: 'AMAZON_COGNITO_USER_POOLS',
				});
				let categoryList = res.data.listCategories.items.sort(
					(a, b) => a.categoryName - b.categoryName
				);

				this.categoryListFilter = this.categoryListFilter.concat(categoryList);
				this.categoryList = this.categoryList.concat(categoryList);
			},
			statusColorSelector() {
				switch (this.selectedOrder.orderStatus) {
					case 'COMPLETED':
						return 'primary';
					case 'PENDING':
						return 'warning';
					case 'PACKED':
						return 'blue';
					case 'CANCELLED':
						return 'error';

					default:
						break;
				}
			},
			async searchCustomers() {
				if (!this.searchString || this.searchString == '' || this.searchString.length < 3) {
					this.isLoading = false;
					return;
				}
				// this.isLoading = true;
				let res = await API.graphql({
					query: searchCustomers,
					variables: {
						nextToken: this.nextToken,
						searchString: this.searchString.toLowerCase(),
					},
					authMode: 'AMAZON_COGNITO_USER_POOLS',
				});
				this.nextToken = res.data.searchCustomers.nextToken;
				this.limitedSearchResult = res.data.searchCustomers.items;

				while (this.limitedSearchResult.length < 7 && this.nextToken != null) {
					let res = await API.graphql({
						query: searchCustomers,
						variables: {
							nextToken: this.nextToken,
							searchString: this.searchString.toLowerCase(),
						},
						authMode: 'AMAZON_COGNITO_USER_POOLS',
					});
					this.nextToken = res.data.searchCustomers.nextToken;
					this.limitedSearchResult = res.data.searchCustomers.items;
				}

				this.isLoading = false;
			},
			initSearchBar() {
				if (this.timer) {
					clearTimeout(this.timer);
					this.timer = null;
				}
				this.timer = setTimeout(() => {
					this.searchCustomers();
				}, 300);
			},
			async createReckonInvoice() {
				try {
					this.firstLoad = true;
					let auth = await Auth.currentAuthenticatedUser();
					let headers = {
						headers: {
							authorization: 'Bearer ' + auth.signInUserSession.accessToken.jwtToken,
						},
					};
					if (this.selectedOrder.invoiceRef && this.selectedOrder.invoiceRef.reckonRefNumber) {
						this.selectedOrder.products = this.selectedOrder.products.map(
							({
								isInStock,
								productGroup,
								reckonProductAttributes,
								// newPrice,
								customPrice,
								notes,
								...rest
							}) => {
								isInStock;
								productGroup;
								reckonProductAttributes;
								notes;
								// newPrice;
								customPrice;
								return { ...rest };
							}
						);
						this.isLoading = true;
						await axios.post(
							this.$store.getters['global/endpoint'] + '/api/reckon/invoice/update',
							this.selectedOrder,
							headers
						);

						this.initInvoiceDialog = false;
						this.getOrder();
						this.isLoading = false;
					} else {
						this.isLoading = true;
						this.selectedOrder.products = this.selectedOrder.products.map(
							({
								isInStock,
								productGroup,
								reckonProductAttributes,
								// newPrice,
								customPrice,
								notes,
								...rest
							}) => {
								isInStock;
								productGroup;
								reckonProductAttributes;
								notes;
								// newPrice;
								customPrice;
								return { ...rest };
							}
						);
						await axios.post(
							this.$store.getters['global/endpoint'] + '/api/reckon/invoice/create',
							this.selectedOrder,
							headers
						);
						this.initInvoiceDialog = false;
						this.getOrder();
						this.isLoading = false;
					}
				} catch (error) {
					console.error(error);
					this.isLoading = false;
					this.color = 'error';
					this.notificationText = 'Error Creating Invoice. Try Again.';
					this.notify = true;
				}
			},

			async initInvoice() {
				await this.updateOrderSilent();
				this.isLoading = true;
				this.initInvoiceDialog = true;
			},

			checkContent() {
				if (this.searchString != '' && this.searchString != null) {
					// this.searchMenu = true;
				}
			},

			async deleteOrder(order) {
				if (order) {
					let auth = await Auth.currentAuthenticatedUser();
					let headers = {
						headers: {
							authorization: 'Bearer ' + auth.signInUserSession.accessToken.jwtToken,
						},
					};

					this.isLoading = true;
					let deleteRes = await API.graphql({
						query: deleteOrder,
						variables: {
							sk: order.sk,
						},
						authMode: 'AMAZON_COGNITO_USER_POOLS',
					});
					await axios.delete(
						this.$store.getters['global/endpoint'] + '/api/production/order/delete',
						{
							data: {
								source: order,
							},
							...headers,
						}
					);
					this.confirmDeleteDialog = false;
					this.createOrderDialog = false;
					this.color = 'primary';
					this.notificationText = 'Order deleted.';
					this.notify = true;
					this.isLoading = false;
					this.$router.push('/admin/orders');

					let PO = order.purchaseOrder;
					let orderID = order.orderId;

					let params = {
						user: this.currentUser,
						message: `Deleted Order: ${PO ? PO : this.limitString(orderID).toUpperCase()}`,
						notificationTime: Date.now(),
					};
					if (deleteRes) {
						await API.graphql({
							query: createNotification,
							variables: {
								input: params,
							},
							authMode: 'AMAZON_COGNITO_USER_POOLS',
						});
					}
				} else {
					this.color = 'orange';
					this.notificationText = 'An error occured when deleting this order, please try agian.';
					this.notify = true;
				}
			},
			displayDate(val) {
				return moment(val).format('DD/MM/YYYY');
			},
			async updateOrder() {
				let isValid = this.$refs.form.validate();

				if (!isValid) return;

				let orderProducts = this.selectedOrder.products;
				for (let i = 0; i < orderProducts.length; i++) {
					if (Object.prototype.hasOwnProperty.call(orderProducts[i], 'isAdded'))
						delete orderProducts[i].isAdded;
				}

				if (!this.selectedOrder.products || this.selectedOrder.products.length == 0) {
					this.color = 'warning';
					this.notificationText = 'At least one product is needed product to update this order.';
					this.createOrderDialog = false;
					this.notify = true;
					return;
				}

				this.isLoading = true;

				// if (this.selectedOrder.invoiceRef) {
				// 	this.selectedOrder.orderStatus = 'COMPLETED';
				// }

				let userInput = {
					sk: this.selectedOrder.sk,
					customer: this.selectedOrder.customer,
					customerId: this.selectedOrder.customer.sk,
					orderDate:
						this.selectedOrder.orderDate == null || isNaN(this.selectedOrder.orderDate)
							? Date.now()
							: parseInt(this.selectedOrder.orderDate),
					orderId: this.selectedOrder.orderId,
					products: this.selectedOrder.products,
					orderStatus: this.selectedOrder.orderStatus,
					invoiceRef: this.selectedOrder.invoiceRef,
					notes: this.selectedOrder.notes,
					purchaseOrder: this.selectedOrder.purchaseOrder,
					line: this.selectedOrder.line,
					freight: this.selectedOrder.freight,
					isDelivery: this.selectedOrder.isDelivery,
					cutting: this.selectedOrder.cutting,
					productionRef: this.selectedOrder.productionRef,
				};

				userInput.products = userInput.products.map(
					({
						isInStock,
						productGroup,
						reckonProductAttributes,
						// newPrice,
						// notes,
						customPrice,
						...rest
					}) => {
						isInStock;
						productGroup;
						reckonProductAttributes;
						// newPrice;
						// notes;
						customPrice;
						return { ...rest };
					}
				);

				if (isValid) {
					// Update NoSQL
					let res = await API.graphql({
						query: updateOrder,
						variables: {
							input: userInput,
						},
						authMode: 'AMAZON_COGNITO_USER_POOLS',
					});

					if (this.removalQueue.length >= 1) {
						// console.log("items removed: ", this.removalQueue);
						await this.removeItem(this.removalQueue);
					}
					let auth = await Auth.currentAuthenticatedUser();
					let headers = {
						headers: {
							authorization: 'Bearer ' + auth.signInUserSession.accessToken.jwtToken,
						},
					};

					// Update SQL
					if (res.data.updateOrder.orderStatus == 'PENDING') {
						let text = 'Would you like to send an email to the customer after update?';
						if (
							(await this.$refs.confirm.open('Confirm', text, {
								yesNoBtn: true,
							})) == true
						) {
							res.data.updateOrder.sendCustomerEmail = true;
						}
					}

					await axios.patch(
						this.$store.getters['global/endpoint'] + '/api/production/order/update',
						res.data.updateOrder,
						headers
					);

					this.isLoading = false;
					this.createOrderDialog = false;
					this.color = 'primary';
					this.notificationText = 'Order updated.';
					this.notify = true;
					this.$refs.form.reset();
					this.$router.push('/admin/orders');

					let PO = res.data.updateOrder.purchaseOrder;
					let orderID = res.data.updateOrder.orderId;

					let params = {
						user: this.currentUser,
						message: `Updated Order: ${PO ? PO : this.limitString(orderID).toUpperCase()}`,
						notificationTime: Date.now(),
						notificationValueSk: this.selectedOrder.sk,
					};

					await API.graphql({
						query: createNotification,
						variables: {
							input: params,
						},
						authMode: 'AMAZON_COGNITO_USER_POOLS',
					});
				} else {
					this.color = 'orange';
					this.notificationText = 'Error updating order.';
					this.notify = true;
					this.isLoading = false;
				}
			},
			async updateOrderSilent() {
				this.isLoading = true;
				let isValid = this.$refs.form.validate();
				let orderProducts = this.selectedOrder.products;
				for (let i = 0; i < orderProducts.length; i++) {
					if (Object.prototype.hasOwnProperty.call(orderProducts[i], 'isAdded'))
						delete orderProducts[i].isAdded;
				}
				let userInput = {
					sk: this.selectedOrder.sk,
					customer: this.selectedOrder.customer,
					customerId: this.selectedOrder.customer.sk,
					orderDate:
						this.selectedOrder.orderDate == null || isNaN(this.selectedOrder.orderDate)
							? Date.now()
							: parseInt(this.selectedOrder.orderDate),
					orderId: this.selectedOrder.orderId,
					products: this.selectedOrder.products,
					orderStatus: this.selectedOrder.orderStatus,
					invoiceRef: this.selectedOrder.invoiceRef,
					notes: this.selectedOrder.notes,
					purchaseOrder: this.selectedOrder.purchaseOrder,
					line: this.selectedOrder.line,
					freight: this.selectedOrder.freight,
					isDelivery: this.selectedOrder.isDelivery,
					cutting: this.selectedOrder.cutting,
					productionRef: this.selectedOrder.productionRef,
				};
				userInput.products = userInput.products.map(
					({
						isInStock,
						productGroup,
						reckonProductAttributes,
						// newPrice,
						customPrice,
						notes,
						...rest
					}) => {
						isInStock;
						productGroup;
						reckonProductAttributes;
						notes;
						// newPrice;
						customPrice;
						return { ...rest };
					}
				);

				if (isValid) {
					let res = await API.graphql({
						query: updateOrder,
						variables: {
							input: userInput,
						},
						authMode: 'AMAZON_COGNITO_USER_POOLS',
					});

					// console.log(res);

					if (this.removalQueue.length >= 1) {
						// console.log("items removed: ", this.removalQueue);
						await this.removeItem(this.removalQueue);
					}
					let auth = await Auth.currentAuthenticatedUser();
					let headers = {
						headers: {
							authorization: 'Bearer ' + auth.signInUserSession.accessToken.jwtToken,
						},
					};

					await axios.patch(
						this.$store.getters['global/endpoint'] + '/api/production/order/update',
						res.data.updateOrder,
						headers
					);

					this.isLoading = false;
				} else {
					this.color = 'orange';
					this.notificationText = 'Error updating order.';
					this.notify = true;
					this.isLoading = false;
				}
			},

			async listProducts() {
				this.productsList = [];
				let res = await API.graphql({
					query: listProducts,
					authMode: 'AMAZON_COGNITO_USER_POOLS',
				});
				let productsList = res.data.listProducts.items.sort((a, b) =>
					a.productName > b.productName ? 1 : b.productName > a.productName ? -1 : 0
				);

				productsList = productsList.filter(prod => prod.isEnabled == true);
				this.productsList = productsList;
				// await this.checkIsAdded();
			},
			cancelAction() {
				this.$router.push('/admin/orders');
			},

			async createOrder() {
				let isValid = this.$refs.form.validate();
				let orderProducts = this.selectedOrder.products;
				for (let i = 0; i < orderProducts.length; i++) {
					if (Object.prototype.hasOwnProperty.call(orderProducts[i], 'isAdded'))
						delete orderProducts[i].isAdded;
				}

				if (isValid) {
					if (!this.selectedOrder.products || this.selectedOrder.products.length == 0) {
						this.color = 'warning';
						this.notificationText = 'Please add at least one product to create order.';
						this.createOrderDialog = false;
						this.notify = true;
						return;
					}
					this.isLoading = true;
					let data = {
						customer: this.selectedOrder.customer,
						customerId: this.selectedOrder.customer.sk,
						orderDate:
							this.selectedOrder.orderDate == null || isNaN(this.selectedOrder.orderDate)
								? Date.now()
								: parseInt(this.selectedOrder.orderDate),
						orderId: this.selectedOrder.orderId,
						products: this.selectedOrder.products,
						orderStatus: this.selectedOrder.orderStatus,
						purchaseOrder: this.selectedOrder.purchaseOrder,
						notes: this.selectedOrder.notes,
						line: this.selectedOrder.line,
						freight: this.selectedOrder.freight,
						isDelivery: this.selectedOrder.isDelivery,
						cutting: this.selectedOrder.cutting,
					};

					data.products = data.products.map(
						({
							isInStock,
							productGroup,
							reckonProductAttributes,
							// newPrice,
							// notes,
							customPrice,
							...rest
						}) => {
							isInStock;
							productGroup;
							reckonProductAttributes;
							// newPrice;
							// notes;
							customPrice;
							return { ...rest };
						}
					);

					let res = null;

					res = await API.graphql({
						query: createOrder,
						variables: {
							input: data,
						},
						authMode: 'AMAZON_COGNITO_USER_POOLS',
					});
					// console.log("RES", res.data.createOrder);

					let auth = await Auth.currentAuthenticatedUser();
					let headers = {
						headers: {
							authorization: 'Bearer ' + auth.signInUserSession.accessToken.jwtToken,
						},
					};

					let text = 'Would you like to send an email to the customer after creating?';
					if (
						(await this.$refs.confirm.open('Confirm', text, {
							yesNoBtn: true,
						})) == true
					) {
						res.data.createOrder.sendCustomerEmail = true;
					}

					await axios.post(
						this.$store.getters['global/endpoint'] + '/api/production/order/create',
						res.data.createOrder,
						headers
					);

					this.color = 'primary';
					this.notificationText = 'Order created.';
					this.createOrderDialog = false;
					this.notify = true;
					this.isLoading = false;
					this.addOrder = false;
					this.$refs.form.reset();
					this.$router.push('/admin/orders');

					let PO = res.data.createOrder.purchaseOrder;
					let orderID = res.data.createOrder.orderId;

					let params = {
						user: this.currentUser,
						message: `Created Order: ${PO ? PO : this.limitString(orderID).toUpperCase()}`,
						notificationTime: Date.now(),
						notificationValueSk: this.selectedOrder.sk,
					};

					await API.graphql({
						query: createNotification,
						variables: {
							input: params,
						},
						authMode: 'AMAZON_COGNITO_USER_POOLS',
					});
					// console.log(this.selectedOrder);
				} else {
					this.isLoading = false;
					this.color = 'orange';
					this.notificationText = 'Please fill in the required fields.';
					this.notify = true;
				}
			},
			async checkUser() {
				await Auth.currentAuthenticatedUser({
					bypassCache: false,
				})
					.then(user => {
						if (user.signInUserSession.accessToken.payload['cognito:groups'] == 'customers') {
							this.$router.push('/');
						}
					})
					.catch(err => {
						if (err == 'The user is not authenticated') {
							this.$router.push('/');
						}
					});
			},
			convertDate(d) {
				if (d) {
					return d && d.length > 0 ? moment(d).format('DD/MM/yyyy') : '';
				}
			},
			convertUnixDate(d) {
				if (d) {
					return moment.unix(d).format('DD/MM/yy');
				}
			},
			isMobile() {
				if (this.$vuetify.breakpoint.width < 800) {
					return true;
				}
			},
			async checkRoute() {
				let currentPath = this.$router.currentRoute.fullPath;
				if (currentPath.includes('new')) {
					this.isAdd = true;
					let data = {
						sk: '',
						pk: '',
						products: [],
						customer: null,
						orderDate: null,
						orderId: null,
						orderStatus: 'PENDING',
					};
					this.$store.commit('orders/setSelectedOrder', data);
				} else {
					this.isAdd = false;
				}
			},
		},

		async mounted() {
			this.controller = new AbortController();
			await this.resetSelectedOrder();
			await this.checkUser();
			await this.initCurrentUser();
			await this.checkRoute();
			await this.getSettingsVariables();
			if (!this.selectedOrder) {
				await this.getOrder();
				return;
			}
			await this.listCategories();
			await this.listProducts();
			if (this.selectedOrder.orderStatus == 'PENDING') {
				await this.syncFromProduction(this.selectedOrder);
			}
			await this.loadCustomPrices();
			this.$forceUpdate();
			this.firstLoad = false;
		},

		computed: {
			customers: {
				get() {
					return this.$store.getters['customers/customerList'];
				},
			},
			searchString: {
				get() {
					return this.$store.getters['customers/searchString'];
				},
				set(val) {
					this.$store.commit('customers/setSearchString', val);
				},
			},
			selectedOrder: {
				get() {
					return this.$store.getters['orders/selectedOrder'];
				},

				set(val) {
					this.$store.commit('orders/setSelectedOrder', val);
				},
			},
			dateManager: {
				get() {
					let convertedDate;
					if (this.selectedOrder && this.selectedOrder.orderDate) {
						convertedDate = moment(this.selectedOrder.orderDate).format('YYYY-MM-DD');
					} else {
						moment(Date.now()).format('YYYY-MM-DD');
					}
					return convertedDate;
				},
				set(val) {
					let convertBackDate = moment(val).format('x');
					let realDate = (this.selectedOrder.orderDate = parseInt(convertBackDate));
					return realDate;
				},
			},
		},

		watch: {
			editProductQntyDialog: function (val) {
				if (val == false) {
					this.selectedProduct = ' ';
				}
			},
			addProductDialog: function (val) {
				val ? this.checkIsAdded() : ` `;
			},
			'selectedOrder.customer': function (val) {
				if (val) {
					this.selectedOrder.line = this.selectedOrder.customer.customerLevel;
				}
			},
			'selectedOrder.products': {
				handler() {
					if (this.selectedOrder == null) return;
					if (this.selectedOrder.products.length == 0) return;
					if (this.firstLoad) return;

					this.selectedOrder.isDelivery = null;
					this.selectedOrder.freight = 0;
					this.checkIsAdded();
				},
				deep: true,
			},
			'$route.params.id': function () {
				this.getOrder();
			},
			notify: function (val) {
				val ? !val : val;
			},
			selectedCategoryFilter: function () {
				this.filterProducts();
			},
			initInvoiceDialog: function (val) {
				if (val == true) {
					this.isLoading = false;
				}
			},
		},

		data() {
			return {
				isAdded: false,
				selectedProductQnty: '',
				selectedProduct: '',
				editProductQntyDialog: false,
				firstLoad: true,
				currentUser: null,
				customPriceList: [],
				priceLoading: true,
				truck: false,
				invalidOrder: false,
				// selectedCustomerName: "",
				removalQueue: [],
				timer: null,
				selectedCategoryFilter: 'All',
				categoryListFilter: [{ categoryName: 'All', pk: '0', sk: '0' }],
				categoryList: [],
				addProductDialog: false,
				initInvoiceDialog: false,
				// customer: null,
				limitedSearchResult: [],
				orderStatuses: ['PENDING', 'PACKED', 'COMPLETED', 'CANCELLED'],
				rules: [v => !!v || 'This field is required'],
				confirmDeleteDialog: false,
				random: '',
				selectedCustomer: '',
				selectedCustomerSk: '',
				createOrderDialog: false,
				isLoading: false,
				searchText: '',
				colors: {
					COMPLETED: 'green',
					PENDING: 'orange',
					PACKED: 'blue',
					CANCELLED: 'red',
				},
				searchItems: '',
				productsList: [],
				orderDateDialog: false,
				isAdd: null,
				color: null,
				notify: false,
				notificationText: '',
				customerList: [],
				headers: [
					{
						text: 'Product Name',
						sortable: true,
						value: 'productName',
					},
					{
						text: 'Category',
						sortable: true,
						value: 'categoryName',
					},
					{
						text: 'Description',
						sortable: false,
						value: 'description',
					},
					// {
					//   text: "Quantity",
					//   sortable: false,
					//   value: "quantity",
					// },
					{
						text: 'Actions',
						value: 'actions',
						sortable: false,
					},
				],

				deliveryMethods: [
					{ name: 'Delivery', val: true },
					{ name: 'Pick Up', val: false },
				],
				lineList: ['S', 'A', 'B', 'C', 'D', 'E', 'N'], //'AC', 'AH'
				//calculated
				subTotal: 0,
				gst: 0,
				freight: 5.0,
				total: 0,

				cutoffTime: '05:00',
				freightCharge: 0,
				freightFreeOver: 0,
				controller: null,
			};
		},
	};
</script>

<style scoped>
	/* disables rotation animation when selected v-auctocomplete input boxes */
	.v-autocomplete.v-select--is-menu-active .v-input__icon--append .v-icon {
		transform: none;
	}

	.right-input >>> input {
		text-align: right;
	}

	/* hide scrollbar but still able to scroll */
	.container {
		-ms-overflow-style: none; /* Internet Explorer 10+ */
		scrollbar-width: none; /* Firefox */
	}
	.container::-webkit-scrollbar {
		display: none; /* Safari and Chrome */
	}
</style>
