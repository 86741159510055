<template>
	<v-container class="mb-16 pb-16">
		<GlobalConfirmDialog ref="confirm" />
		<h1 class="pt-4">Your Cart</h1>
		<v-divider class="ma-4 ma-md-12" />
		<div v-if="$vuetify.breakpoint.width < 1264 && products.length > 0">
			<!-- <v-card-text class="text-left">PO Number (Optional)</v-card-text> F -->
			<v-text-field
				v-model="purchaseOrder"
				outlined
				class="mr-3 ml-3"
				label="Enter PO Number (Optional)"
				dense
			></v-text-field>
			<div class="ml-4" style="margin-top: -30px">
				<v-radio-group v-model="isDelivery" row>
					<v-radio label="Delivery" color="orange" :value="true"></v-radio>
					<v-radio label="Pick Up" color="primary" :value="false"></v-radio>
				</v-radio-group>
			</div>
		</div>

		<v-row>
			<v-col lg="8" md="12" sm="11" cols="11" v-if="products && products.length >= 0">
				<v-card
					class="ma-4 rounded-md pa-2"
					width="100%"
					v-for="(item, i) in products"
					v-bind:key="i"
					min-width="300"
				>
					<v-card-text class="text-left d-flex">
						<v-row>
							<v-col lg="2" md="2" sm="2" cols="3">
								<v-img
									@click="routeToSingleProduct(item)"
									style="cursor: pointer"
									:src="
										item.imageUrl
											? item.imageUrl
											: `https://archive.org/download/no-photo-available/no-photo-available.png`
									"
									width="200px"
									max-height="90px"
								/>
							</v-col>
							<v-col lg="4" md="4" sm="4" cols="9">
								<strong>{{ item.productName }} ({{ item.productCode }})</strong>
								<br />

								<span style="color: green" v-if="item.newPrice"
									>${{ parseFloat(item.newPrice).toFixed(2) }}
									{{ item.productUnit == 'EACH' ? ` ` : `/ ` }}{{ item.productUnit }}</span
								>
								<span v-else
									>${{ parseFloat(item.defaultPrice).toFixed(2) }}
									{{ item.productUnit == 'EACH' ? ` ` : `/ ` }}{{ item.productUnit }}</span
								>
							</v-col>

							<v-col lg="5" md="5" sm="5" cols="12" class="d-flex justify-center">
								<div style="width: 204px" class="d-flex justify-center">
									<v-btn
										@click="minusFromTrolley(item, i)"
										outlined
										height="40"
										class="mr-1"
										x-small
										color="secondary"
										:loading="isLoading"
										:disabled="item.quantity < 2"
									>
										<v-icon>mdi-minus</v-icon>
									</v-btn>
									<v-text-field
										@change="updateQuantityFromField(item, i)"
										v-on:keyup.enter="updateQuantityFromField(item, i)"
										v-model="item.quantity"
										filled
										outlined
										dense
										v-on:keypress="numbersOnly"
										:append-icon="item.productUnit == 'kg' ? `mdi-weight-kilogram` : ``"
										:loading="isLoading"
									></v-text-field>
									<v-btn
										@click="addToTrolley(item, i)"
										outlined
										height="40"
										class="ml-1"
										x-small
										color="secondary"
										:loading="isLoading"
									>
										<v-icon>mdi-plus</v-icon>
									</v-btn>
								</div>
							</v-col>
							<v-col cols="1" class="text-right mt-2" v-show="$vuetify.breakpoint.width > 600">
								<v-tooltip bottom color="green darken-2">
									<template #activator="{ attrs, on }">
										<v-icon
											v-on="on"
											v-bind="attrs"
											@click="removeFromTrolley(item, i)"
											color="#cd0b26"
										>
											mdi-close
										</v-icon>
									</template>
									<span> Remove from trolley </span>
								</v-tooltip>
							</v-col>
						</v-row>
					</v-card-text>
					<v-btn
						small
						text
						v-show="$vuetify.breakpoint.width < 600"
						@click="removeFromTrolley(item, i)"
						color="red"
					>
						Remove Item
					</v-btn>
				</v-card>
			</v-col>
			<v-col lg="8" md="12" sm="12" cols="12" v-if="products.length <= 0">
				<v-card-text>Your trolley is empty.</v-card-text>
			</v-col>
			<v-col cols="4" v-show="$vuetify.breakpoint.width > 1264">
				<v-card class="ma-4" style="position: sticky; top: 20vh">
					<v-card-title>Order Summary</v-card-title>
					<div v-if="authenticatedUser">
						<div>
							<!-- <v-card-text class="text-left">PO Number (Optional)</v-card-text> -->
							<v-text-field
								v-model="purchaseOrder"
								outlined
								class="mr-3 ml-3 mt-2"
								label="Enter PO Number (optional)"
								dense
							></v-text-field>
						</div>

						<div class="ml-4">
							<v-radio-group v-model="isDelivery" row>
								<v-radio label="Delivery" color="orange" :value="true"></v-radio>
								<v-radio label="Pick Up" color="primary" :value="false"></v-radio>
							</v-radio-group>
						</div>

						<v-row class="text-left">
							<v-col cols="5">
								<v-card-text>
									<div>Sub-Total</div>
									<div v-if="isDelivery == true">Freight</div>
									<div>GST</div>

									<div class="mt-4">
										Total <span style="font-size: 10px"><strong>(Estimate)</strong></span>
									</div>
								</v-card-text>
							</v-col>
							<v-col cols="7" v-if="products">
								<v-card-text>
									<div>${{ calculateSubtotal() }}</div>
									<div v-if="isDelivery == true">${{ parseFloat(freight).toFixed(2) }}</div>
									<div>${{ gst }}</div>
									<div class="mt-4">
										<strong>${{ total }}</strong>
									</div>
								</v-card-text>
							</v-col>
						</v-row>

						<h6 class="pb-4 mx-4 text-left">
							** Price and total may vary from final calculations. Check the invoice carefully at
							the completion of order for exact amounts to pay.
						</h6>
					</div>

					<v-divider style="width: 100%" />

					<v-btn
						v-if="authenticatedUser"
						x-large
						color="primary"
						rounded
						class="mt-6 mb-6"
						width="90%"
						:disabled="isLoading || products.length == 0"
						@click="products.length == 0 ? routeToProducts() : confirmOrder()"
					>
						{{ products.length == 0 ? 'Order Now' : 'Confirm Order' }}
					</v-btn>

					<v-btn
						v-else
						x-large
						color="primary"
						rounded
						class="mt-6 mb-6"
						width="90%"
						@click="$router.push('/Login')"
						>Login To Order</v-btn
					>

					<v-row v-show="products.length >= 1" class="pb-3" justify="center">
						<v-btn @click="confirmClearDialog = true" text color="red" x-small> Clear Cart </v-btn>
					</v-row>
				</v-card>
			</v-col>

			<v-dialog v-model="confirmClearDialog" persistent max-width="285px">
				<v-card>
					<div class="pa-4">
						<h4>Are you sure you want to clear your cart?</h4>
					</div>
					<h5>This will remove all items</h5>
					<v-card-actions class="pa-2">
						<v-row justify="center" class="ma-0 pa-2">
							<v-btn
								@click="
									confirmClearDialog = false;
									hideCard = false;
								"
								class="mr-2"
								outlined
								color="red"
							>
								Cancel
							</v-btn>
							<v-btn
								@click="
									clearCart();
									hideCard = false;
								"
								style="color: white"
								color="green darken-2"
							>
								Confirm
							</v-btn>
						</v-row>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-row>
		<v-slide-y-reverse-transition>
			<v-card
				tile
				style="z-index: 999999"
				v-model="sheet"
				class="orderSummaryCard"
				v-if="$vuetify.breakpoint.width < 1264"
				v-show="!hideCard"
			>
				<v-sheet
					class="text-center"
					height="100%"
					elevation="12"
					:hidden="confirmDialog"
					style="padding: env(safe-area-inset-bottom)"
				>
					<div class="elevation-1" @click="hideCard = !hideCard" style="height: 100%">
						<v-icon>mdi-menu-down</v-icon>
					</div>

					<div v-if="authenticatedUser">
						<v-row class="text-left">
							<v-col cols="5">
								<v-card-text>
									<div>Sub-Total</div>
									<div v-if="isDelivery == true">Freight</div>
									<div>GST</div>

									<div class="mt-4">
										Total <span style="font-size: 10px"><strong>(Estimate)</strong></span>
									</div>
								</v-card-text>
							</v-col>
							<v-col cols="7" v-if="products">
								<v-card-text>
									<div>${{ calculateSubtotal() }}</div>
									<div v-if="isDelivery == true">${{ parseFloat(freight).toFixed(2) }}</div>
									<div>${{ gst }}</div>
									<div class="mt-4">
										<strong>${{ total }}</strong>
									</div>
								</v-card-text>
							</v-col>
						</v-row>

						<h6 class="pb-4 mx-4 text-left">
							** Price and total may vary from final calculations. Check the invoice carefully at
							the completion of order for exact amounts to pay.
						</h6>
					</div>
					<v-divider />

					<v-btn
						x-large
						color="primary"
						rounded
						class="mt-6 mb-6"
						width="90%"
						:disabled="isLoading"
						@click="products.length == 0 ? routeToProducts() : confirmOrder()"
					>
						{{ products.length == 0 ? 'Order Now' : 'Confirm Order' }}
					</v-btn>

					<div v-if="products.length >= 1" class="pb-2" style="margin-top: -20px">
						<v-btn
							@click="
								confirmClearDialog = true;
								hideCard = true;
							"
							text
							color="red"
							x-small
						>
							Clear Cart
						</v-btn>
					</div>
				</v-sheet>
			</v-card>
		</v-slide-y-reverse-transition>

		<v-fade-transition
			><v-card
				tile
				style="z-index: 999999; padding: env(safe-area-inset-bottom)"
				v-model="sheet"
				class="orderSummaryCard"
				v-if="$vuetify.breakpoint.width < 1264"
				v-show="hideCard"
				@click="hideCard = !hideCard"
				><v-icon>mdi-menu</v-icon></v-card
			></v-fade-transition
		>

		<v-dialog
			v-model="confirmDialog"
			width="500"
			persistent
			:fullscreen="$vuetify.breakpoint.width < 1264"
		>
			<v-card class="pa-6" height="350px">
				<div class="container2" v-if="!orderSent">
					<div class="box">
						<div class="border one"></div>
						<div class="border two"></div>
						<div class="border three"></div>
						<div class="border four"></div>
						<div class="line one"></div>
						<div class="line two"></div>
						<div class="line three"></div>
					</div>
				</div>
				<div class="sentContainer" v-else>Order Sent!</div>
			</v-card>
		</v-dialog>
		<!-- notification  -->
		<v-snackbar v-model="notify" class="pt-16" top :timeout="2500" :color="color">
			{{ notificationText }}
		</v-snackbar>
	</v-container>
</template>

<script>
	import { Auth } from 'aws-amplify';
	import { API } from 'aws-amplify';
	import { getCustomer, getSettingsVariables } from '@/graphql/queries';
	import { createOrder, createNotification } from '@/graphql/mutations';
	import axios from 'axios';
	import GlobalConfirmDialog from '../../components/ConfirmDialog.vue';
	import moment from 'moment';

	export default {
		components: {
			GlobalConfirmDialog,
		},
		async mounted() {
			if (this.$vuetify.breakpoint.width < 1200) {
				this.sheet = true;
			}
			await this.checkAuthenticatedUser();
			await this.getSettingsVariables();
			this.scrollWatcher();
			document.title =
				'Cart - ' +
				` ${
					this.products.length == 0
						? `Empty Cart`
						: this.products.length > 1
						? this.products.length + ' Items'
						: this.products.length + ' Item'
				}`;

			// await this.loadCustomPrice(this.products);
		},
		methods: {
			async getSettingsVariables() {
				let res = await API.graphql({
					query: getSettingsVariables,
				});

				let data = res.data.getSettingsVariables;

				this.cutoffTime = data.cutoffTime;
				this.freightCharge = data.freightCharge;
				this.freight = data.freightCharge;
				this.freightFreeOver = data.freightFreeOver;
			},
			// checkFreight() {
			// 	if (this.isDelivery == false) {
			// 		this.freight = 0;
			// 	} else if (this.total > this.freightFreeOver) {
			// 		this.freight = 0;
			// 	} else {
			// 		this.freight = this.freightCharge;
			// 	}
			// },
			calculateSubtotal() {
				let subTotal = 0;
				this.products.forEach(el => {
					const quantity = parseFloat(el.quantity);
					const price = el.newPrice ? parseFloat(el.newPrice) : parseFloat(el.defaultPrice);

					subTotal += quantity * price;
				});

				if (this.isDelivery == false) {
					this.freight = 0;
				} else if (subTotal * 1.15 > this.freightFreeOver) {
					this.freight = 0;
				} else {
					this.freight = this.freightCharge;
				}

				this.subTotal = subTotal.toFixed(2);

				this.gst = ((subTotal + this.freight) * 0.15).toFixed(2);

				// let total = parseFloat(subTotal) + parseFloat(this.gst);

				// if (this.isDelivery == false) {
				// 	this.freight = 0;
				// } else if (total > this.freightFreeOver) {
				// 	this.freight = 0;
				// } else {
				// 	this.freight = this.freightCharge;
				// }

				this.total = (subTotal + parseFloat(this.gst) + parseFloat(this.freight)).toFixed(2);

				return subTotal.toFixed(2);
			},
			scrollWatcher() {
				var lastScrollTop = 0;

				let that = this;

				// element should be replaced with the actual target element on which you have applied scroll, use window in case of no target element.
				window.addEventListener(
					'scroll',
					function () {
						// or window.addEventListener("scroll"....
						var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
						if (st > lastScrollTop) {
							that.hideCard = true;
						} else {
							that.hideCard = false;
						}

						lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
						that.$forceUpdate();
					},
					false
				);
			},
			async createNotification(val) {
				let customerName = val.customer.firstName + ' ' + val.customer.lastName;
				let params = {
					user: customerName,
					message: `${customerName} placed an order`,

					notificationTime: Date.now(),
					notificationValueSk: val.orderSk,
				};

				await API.graphql({
					query: createNotification,
					variables: {
						input: params,
					},
					authMode: 'AMAZON_COGNITO_USER_POOLS',
				});
			},
			// async loadCustomPrice(products) {
			//   setTimeout(() => {
			//     if (!this.authenticatedUser && !this.authenticatedUser.priceLevel)
			//       return;
			//     products.forEach(async (prod) => {
			//       let priceResult = await API.graphql({
			//         query: getProductCustomPrice,
			//         variables: {
			//           productId: prod.sk,
			//           priceLevel: this.authenticatedUser.priceLevel,
			//         },
			//       });
			//       if (priceResult.data.getProductCustomPrice) {
			//         prod.newPrice = priceResult.data.getProductCustomPrice.customPrice;
			//         this.$forceUpdate();
			//       }
			//     });
			//   }, 1000);
			// },
			routeToProducts() {
				this.$router.push(`/order`);
			},
			routeToSingleProduct(item) {
				this.$router.push(`/order/product?id=${item.sk}&name=${item.productName}`);
			},
			async clearCart() {
				try {
					this.$store.dispatch('cartTotal/clearCart');
					this.confirmClearDialog = false;
					this.isLoading = false;
				} catch (error) {
					console.log('Error trying to clear cart:', error);
				}
			},
			numbersOnly(evt) {
				evt = evt ? evt : window.event;
				var charCode = evt.which ? evt.which : evt.keyCode;
				if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
					evt.preventDefault();
				} else {
					return true;
				}
			},
			async checkConnection() {
				try {
					let connection = await axios.get(this.$store.getters['global/endpoint'] + '/connection');
					if (connection.data == 'CONNECTION_OK') {
						return true;
					} else {
						return false;
					}
				} catch (error) {
					return false;
				}
			},
			async confirmOrder() {
				this.hideCard = true;
				this.isLoading = true;
				if ((await this.$refs.confirm.open('Confirm', 'Are you sure about your order?')) == false) {
					this.isLoading = false;
					this.hideCard = false;
					return;
				}

				try {
					let auth = await Auth.currentAuthenticatedUser();
					if (auth.signInUserSession.idToken.payload['cognito:groups'].includes('admin')) {
						this.color = 'warning';
						this.notificationText =
							'This is a simulation only.\n\n You need to sign in as a cusotmer to order properly.';
						this.notify = true;
						this.isLoading = false;
						this.$store.dispatch('cartTotal/clearCart');
						return;
					}
					let conn = await this.checkConnection();
					if (conn == false) {
						this.color = 'error';
						this.notificationText = 'Server Not Available. Please Contact Administrator.';
						this.notify = true;
						this.isLoading = false;
						return;
					}

					this.confirmDialog = true;

					//TODO: send order to aws
					let userAttrs = await Auth.currentAuthenticatedUser();

					let customerId = userAttrs.username;

					//getCustomer first
					let customerResult = await API.graphql({
						query: getCustomer,
						variables: {
							customerId: customerId,
						},
						authMode: 'AMAZON_COGNITO_USER_POOLS',
					});

					this.products.forEach(prod => {
						delete prod['inCart'];
						delete prod['reckonProductAttributes'];
					});

					/**
					 * CUT OFF TIME
					 */

					let weekday = moment().weekday();
					let orderDate = null;

					if ((await this.checkCutoffTime()) == true) {
						if (weekday == 6) {
							orderDate = Date.now() + 172800000;
							await this.$refs.confirm.open(
								'Notice',
								`Our cutoff time today is ${this.cutoffTime}. We also close on sunday therefore your order will be processed on monday.`,
								{ noconfirm: true, color: 'warning', okBtn: true }
							);
						} else {
							orderDate = Date.now() + 86400000;
							await this.$refs.confirm.open(
								'Notice',
								`Our cutoff time today is ${this.cutoffTime}. Your order will be processed tomorrow.`,
								{ noconfirm: true, color: 'warning', okBtn: true }
							);
						}
					} else {
						if (weekday == 7) {
							orderDate = Date.now() + 86400000;
							await this.$refs.confirm.open(
								'Notice',
								`We are closed on sundays therefore your order will be processed tomorrow.`,
								{ noconfirm: true, color: 'warning', okBtn: true }
							);
						} else {
							orderDate = Date.now();
						}
					}

					let customer = customerResult.data.getCustomer;
					let data = {
						customer: customer,
						customerId: customer.sk,
						orderDate: orderDate,
						orderId: '',
						products: this.products,
						orderStatus: 'PENDING',
						purchaseOrder: this.purchaseOrder,
						line: customer.customerLevel,
						freight: this.freight,
						isDelivery: this.isDelivery,
					};

					// console.log('SAVED ORDER DATE:', moment(data.orderDate).format('DD/MM/YYYY HH:mm:ss'));

					data.products = data.products.map(
						({
							productGroup,
							reckonProductAttributes,
							// newPrice,
							isInStock,
							customPrice,
							...rest
						}) => {
							productGroup;
							reckonProductAttributes;
							// newPrice;
							isInStock;
							customPrice;
							return { ...rest };
						}
					);

					// setInterval(() => { }, 500);

					let res = await API.graphql({
						query: createOrder,
						variables: {
							input: data,
						},
						authMode: 'AMAZON_COGNITO_USER_POOLS',
					});

					//TODO: send order to nodejs
					if (res.data.createOrder) {
						//Initialize this key/pair to send Customer Email
						res.data.createOrder.sendCustomerEmail = true;

						let headers = {
							headers: {
								authorization: 'Bearer ' + auth.signInUserSession.accessToken.jwtToken,
							},
						};
						let result = await axios.post(
							this.$store.getters['global/endpoint'] + '/api/production/order/create',
							res.data.createOrder,
							headers
						);

						let obj = {
							customer: customer,
							orderSk: res.data.createOrder.sk,
						};

						if (result.data == 'Order Created!') {
							this.createNotification(obj);
							this.$store.dispatch('cartTotal/clearCart');
							this.products = [];

							setTimeout(() => {
								this.orderSent = true;
								setTimeout(() => {
									this.confirmDialog = false;
									this.orderSent = false;
								}, 2000);
							}, 4000);
						}
					} else {
						this.color = 'error';
						this.notificationText = 'There was something wrong while sending order';
						this.notify = true;
						this.isLoading = false;
					}
				} catch (error) {
					this.isLoading = false;
					console.log(error);
					this.confirmDialog = false;
					this.orderSent = false;
					this.color = 'error';
					this.notificationText =
						'There was something wrong while sending order. Please Try Again Later.';
					this.notify = true;
				}

				this.isLoading = false;
			},

			async removeFromTrolley(product) {
				this.isLoading = true;
				await this.$store.dispatch('cartTotal/removeFromCart', {
					product: product,
				});
				this.isLoading = false;
			},

			async updateQuantityFromField(product, index) {
				if (product.quantity <= 0) {
					this.removeFromTrolley(product, index);
				}
				this.isLoading = true;
				await this.$store.dispatch('cartTotal/updateQuantity', {
					product: product,
				});
				if (!product.inCart && product.quantity >= 0.1) {
					this.isLoading = false;
				}
				if (product.quantity <= 0) {
					this.removeFromTrolley(product, index);
					this.isLoading = false;
				}

				this.isLoading = false;
			},

			async addToTrolley(product) {
				await this.$store.dispatch('cartTotal/addToCart', {
					product: product,
				});
			},

			async minusFromTrolley(product) {
				await this.$store.dispatch('cartTotal/minusFromCart', {
					product: product,
				});
			},
			async checkAuthenticatedUser() {
				try {
					let currentSession = await Auth.currentAuthenticatedUser();
					let userGroup = currentSession; //currentSession.signInUserSession.accessToken.payload["cognito:groups"]

					return userGroup;
				} catch (error) {
					error;
				}
			},
			confirm() {
				this.confirmDialog = true;
			},

			async checkCutoffTime() {
				let date = new Date();
				let currentTime = date.getHours();

				let convertedTime = parseInt(this.cutoffTime);
				// console.log(convertedTime);
				// console.log(currentTime);

				if (currentTime >= convertedTime) {
					return true;
				} else {
					return false;
				}
			},
		},
		watch: {
			products: function (val) {
				if (val.length <= 0) {
					this.cartCount = 0;
				}
				document.title =
					'Cart - ' +
					` ${
						this.products.length == 0
							? `Empty Cart`
							: this.products.length > 1
							? this.products.length + ' Items'
							: this.products.length + ' Item'
					}`;
			},
			windowWidth: function (val) {
				if (val < 1200) {
					this.sheet = true;
				} else {
					this.sheet = false;
				}
			},
		},
		computed: {
			authenticatedUser: {
				get() {
					return this.$store.getters['customers/authenticatedUser'];
				},
			},
			purchaseOrder: {
				get() {
					return this.$store.getters['cartTotal/purchaseOrder'];
				},
				set(val) {
					this.$store.commit('cartTotal/setPurchaseOrder', val);
				},
			},
			products: {
				get() {
					return this.$store.getters['cartTotal/cart'];
				},
				set(val) {
					this.$store.commit('cartTotal/setCart', val);
				},
			},
			cartCount: {
				get() {
					return this.$store.getters['cartTotal/cartCount'];
				},
				set(val) {
					this.$store.commit('cartTotal/setCartCount', val);
				},
			},
			windowWidth: {
				get() {
					return this.$vuetify.breakpoint.width;
				},
			},
		},
		data: () => ({
			hideCard: false,
			color: 'white',
			notificationText: '',
			notify: false,
			confirmClearDialog: false,
			orderSent: false,
			cancelOrder: false,
			orderCancelDuration: 0,
			isLoading: false,
			scrollable: true,
			sheet: false,
			confirmDialog: false,
			isDelivery: true,

			//calculated
			subTotal: 0,
			gst: 0,
			freight: 5.0,
			total: 0,

			cutoffTime: '05:00',
			freightCharge: 0,
			freightFreeOver: 0,
		}),
	};
</script>

<style lang="scss" scoped>
	.orderSummaryCard {
		position: fixed;
		left: 0px;
		width: 100%;
		bottom: 0;
	}

	///SENDING NOTIFICATION
	.sentContainer {
		height: 50px;
		width: 100%;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		content: 'Order Sent!';
		color: #1b8600;
		font-weight: 700;
		font-size: 50px;
	}

	.container2 {
		height: 50px;
		width: 40px;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
	}

	.container2::after {
		content: 'Sending';
		color: #1b8600;
		font-weight: 700;
		position: absolute;
		bottom: -50px;
		left: -10px;
	}

	.box {
		position: relative;
		height: 50px;
		width: 40px;
		animation: box 5s infinite linear;
	}

	.border {
		background: #1b8600;
		position: absolute;
	}

	.border.one {
		height: 4px;
		top: 0;
		left: 0;
		animation: border-one 5s infinite linear;
	}

	.border.two {
		top: 0;
		right: 0;
		height: 100%;
		width: 4px;
		animation: border-two 5s infinite linear;
	}

	.border.three {
		bottom: 0;
		right: 0;
		height: 4px;
		width: 100%;
		animation: border-three 5s infinite linear;
	}

	.border.four {
		bottom: 0;
		left: 0;
		height: 100%;
		width: 4px;
		animation: border-four 5s infinite linear;
	}

	.line {
		height: 4px;
		background: #1b8600;
		position: absolute;
		width: 0%;
		left: 25%;
	}

	.line.one {
		top: 25%;
		width: 0%;
		animation: line-one 5s infinite linear;
	}

	.line.two {
		top: 45%;
		animation: line-two 5s infinite linear;
	}

	.line.three {
		top: 65%;
		animation: line-three 5s infinite linear;
	}

	@keyframes border-one {
		0% {
			width: 0;
		}

		10% {
			width: 100%;
		}

		100% {
			width: 100%;
		}
	}

	@keyframes border-two {
		0% {
			height: 0;
		}

		10% {
			height: 0%;
		}

		20% {
			height: 100%;
		}

		100% {
			height: 100%;
		}
	}

	@keyframes border-three {
		0% {
			width: 0;
		}

		20% {
			width: 0%;
		}

		30% {
			width: 100%;
		}

		100% {
			width: 100%;
		}
	}

	@keyframes border-four {
		0% {
			height: 0;
		}

		30% {
			height: 0%;
		}

		40% {
			height: 100%;
		}

		100% {
			height: 100%;
		}
	}

	@keyframes line-one {
		0% {
			left: 25%;
			width: 0;
		}

		40% {
			left: 25%;
			width: 0%;
		}

		43% {
			left: 25%;
			width: 50%;
		}

		52% {
			left: 25%;
			width: 50%;
		}

		54% {
			left: 25%;
			width: 0%;
		}

		55% {
			right: 25%;
			left: auto;
		}

		63% {
			width: 10%;
			right: 25%;
			left: auto;
		}

		100% {
			width: 10%;
			right: 25%;
			left: auto;
		}
	}

	@keyframes line-two {
		0% {
			width: 0;
		}

		42% {
			width: 0%;
		}

		45% {
			width: 50%;
		}

		53% {
			width: 50%;
		}

		54% {
			width: 0%;
		}

		60% {
			width: 50%;
		}

		100% {
			width: 50%;
		}
	}

	@keyframes line-three {
		0% {
			width: 0;
		}

		45% {
			width: 0%;
		}

		48% {
			width: 50%;
		}

		51% {
			width: 50%;
		}

		52% {
			width: 0%;
		}

		100% {
			width: 0%;
		}
	}

	@keyframes box {
		0% {
			opacity: 1;
			margin-left: 0px;
			height: 50px;
			width: 40px;
		}

		55% {
			margin-left: 0px;
			height: 50px;
			width: 40px;
		}

		60% {
			margin-left: 0px;
			height: 35px;
			width: 50px;
		}

		74% {
			msthin-left: 0;
		}

		80% {
			margin-left: -50px;
			opacity: 1;
		}

		90% {
			height: 35px;
			width: 50px;
			margin-left: 50px;
			opacity: 0;
		}

		100% {
			opacity: 0;
		}
	}
</style>
